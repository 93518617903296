import React, { useEffect, useState } from "react";
import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api/index";
import { toast } from "material-react-toastify";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

function AdvPortfolioSummary({ bookingId, data }) {
  // getting the hotel id and the currency
  const hotelId = data.hotelId;
  const hotelCurrency = data.accessControl.hotel.hotel_currency;

  // advanced invoice data
  const [advancedInvoiceData, setAdvancedInvoiceData] = useState(null);
  const handleAdvancedInvoiceData = () => {
    djangoHandleDataRequests(
      `invoice/advancedInvoiceDataRender/?hotelId=${hotelId}&bookingID=${bookingId}`
    )
      .then((res) => setAdvancedInvoiceData(res))
      .catch((error) =>
        toast.error("Error fethcing the advanced invoice data.")
      );
  };

  useEffect(() => {
    handleAdvancedInvoiceData();
  }, [bookingId]);

  // handling the checkin api

  const [checkinLoading, setCheckinLoading] = useState(false);
  const handleCheckin = () => {
    console.log("hello");
    setCheckinLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/adminConfirmBooking/?bookingId=${bookingId}`
      )
      .then((res) => toast.success("Successfully checked in"))
      .then((res) => handleAdvancedInvoiceData())
      .then((res) => setCheckinLoading(false))
      .catch((err) => toast.error("Error checking in."));
  };

  // booking checking out
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const handleCheckout = () => {
    setCheckoutLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/checkout-booking/?bookingId=${bookingId}&releaseInventory=false`,
        {}
      )
      .then((res) => toast.success("Successfully checked out."))
      .then((res) => handleAdvancedInvoiceData())
      .then((res) => setCheckoutLoading(false))
      .catch((err) => toast.error("Error checking out the user"));
  };

  return (
    <div className="invoiceSummary">
      <div className="summaryHeader">Advanced folio summary</div>
      <div className="invoiceContent">
        <div className="feeBreakdownWrapper">
          <div className="feeBreakdown">
            <div className="breakdownHeader">Total Excluding Taxes</div>
            <div className="breakdownVal" align="right">
              {hotelCurrency} {advancedInvoiceData?.invoice?.sub_total}
            </div>
          </div>
          <div className="feeBreakdown">
            <div className="breakdownHeader">CGST</div>
            <div className="breakdownVal">
              {hotelCurrency} {advancedInvoiceData?.invoice?.cgst}
            </div>
          </div>
          <div className="feeBreakdown">
            <div className="breakdownHeader">SGST</div>
            <div className="breakdownVal">
              {hotelCurrency} {advancedInvoiceData?.invoice?.sgst}
            </div>
          </div>
          <div className="feeBreakdown">
            <div className="breakdownHeader">Total with taxes and fees</div>
            <div className="breakdownVal">
              {hotelCurrency} {advancedInvoiceData?.invoice?.total_amt}
            </div>
          </div>
          <div className="feeBreakdown">
            <div className="breakdownHeader">Balance Due</div>
            <div className="breakdownVal">
              {hotelCurrency} {advancedInvoiceData?.invoice?.balance_due}
            </div>
          </div>
        </div>

        <div className="separator" />

        <div className="taxesBreakdownWrapper">
          <div className="taxBreakdownHeader">Booking Taxes Breakdown</div>
          <div className="taxBreakdown">
            {advancedInvoiceData &&
              Object.entries(
                advancedInvoiceData.invoice.bookingInfo.tax_breakdown
              ).map(([key, value]) => (
                <div className="breakdowns">
                  <div className="breakdownHeader">{key}</div>
                  <div className="breakdownVal">
                    {hotelCurrency} {value}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="invoiceButtons">
        {advancedInvoiceData &&
          advancedInvoiceData.invoice.bookingInfo.booking_status ===
            "CONFIRMED" && (
            <>
              <div className="settleDuesBtn">Settle dues</div>bookingInfo
              <div className="bookingCheckin">
                {checkinLoading ? (
                  <button className="checkoutBtn">
                    <CircularProgress size="15px" />
                  </button>
                ) : (
                  <button
                    className="checkoutBtn"
                    onClick={() => handleCheckin()}
                  >
                    Check-in
                  </button>
                )}
              </div>
            </>
          )}
        {advancedInvoiceData &&
          advancedInvoiceData.invoice.bookingInfo.booking_status ===
            "ADMIN_CONFIRMED" && (
            <>
              <div className="settleDuesBtn">Settle dues</div>
              <div className="bookingCheckin">
                {checkinLoading ? (
                  <button className="checkoutBtn">
                    <CircularProgress size="15px" />
                  </button>
                ) : (
                  <button
                    className="checkoutBtn"
                    onClick={() => handleCheckout()}
                  >
                    Check-out
                  </button>
                )}
              </div>
            </>
          )}
        {advancedInvoiceData &&
          advancedInvoiceData.invoice.bookingInfo.booking_status ===
            "CHECKED_OUT" && (
            <button className="settleDuesBtn" style={{ width: "100%" }}>
              Settle dues
            </button>
          )}
        {advancedInvoiceData &&
          advancedInvoiceData.invoice.bookingInfo.booking_status ===
            "CANCELLED" && (
            <button className="settleDuesBtn" style={{ width: "100%" }}>
              Settle dues
            </button>
          )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(AdvPortfolioSummary);
