import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Modal,
  Divider,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import Box from "@mui/material/Box";
import { toast } from "material-react-toastify";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

const GroupAdmin = ({
  groupId,
  bookingData,
  hotelIds,
  data,
  selectedLanguage,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [groupAccessTableData, setgroupAccessTableData] = useState([]);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [selectedHotels, setselectedHotels] = useState(["ALL"]);
  const [loader, setloader] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);
  const [revokeAccessLoader, setrevokeAccessLoader] = useState(false);
  const [deleteAdminUserEmail, setdeleteAdminUserEmail] = useState("");
  const [revokeAccessEmail, setrevokeAccessEmail] = useState("");
  const [revokeAccessName, setrevokeAccessName] = useState("");
  const [revokeAccessHotelId, setrevokeAccessHotelId] = useState("");
  const [hotelEmails, sethotelEmails] = useState([]);
  const [selectedEmail, setselectedEmail] = useState("");
  const [uniqueEmail, setuniqueEmail] = useState([]);
  const [selectedEditHotels, setselectedEditHotels] = useState([]);
  const [totalEditedHotels, settotalEditedHotels] = useState([]);

  const getHotelEmails = () => {
    djangoHandleDataRequests(`metaAdmin/getMetaAdminMasterDashData/`)
      .then((res) =>
        sethotelEmails(res.hotelTableData.map((item) => item.email_id))
      )
      .catch((err) => console.error(err));
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setselectedHotels(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleEditChange = (event) => {
    const {
      target: { value },
    } = event;

    setselectedEditHotels(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  // add user modal
  const [adduserModal, setAdduserModal] = useState(false);
  const handleOpenAddUserModal = () => {
    setAdduserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setAdduserModal(false);
  };

  // update user modal
  const [updateuserModal, setUpdateuserModal] = useState(false);
  const handleOpenUpdateUserModal = () => {
    setUpdateuserModal(true);
  };

  const handleCloseUpdateUserModal = () => {
    setUpdateuserModal(false);
  };
  const [searchTerm, setsearchTerm] = useState("");
  // quick search
  const onFilterTextChange = (event) => {
    setsearchTerm(event.target.value);
  };
  // Delete modal
  const [deleteModal, setdeleteModal] = useState(false);
  const handleOpendeleteModal = (email) => {
    setdeleteAdminUserEmail(email);
    setdeleteModal(true);
  };

  const handleClosedeleteModal = () => {
    setdeleteModal(false);
  };

  // revoke access modal
  const [revokeModal, setrevokeModal] = useState(false);
  const handleOpenRevokeModal = (email, name, hotelId) => {
    setrevokeAccessEmail(email);
    setrevokeAccessName(name);
    setrevokeAccessHotelId(hotelId);
    setrevokeModal(true);
  };

  const handleCloseRevokeModal = () => {
    setrevokeModal(false);
  };

  const getAllData = () => {
    handleSpringDataRequest(
      `core/api/v1/groupaccess/getAllUsers?groupId=${groupId}`
    )
      .then((res) => {
        setgroupAccessTableData(res);
        const uniqueEmails = res.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.user_email === item.user_email)
        );

        setuniqueEmail(uniqueEmails);
      })
      .catch((err) => toast.error(`${t("Error in fetching users")}`));
  };

  useEffect(() => {
    if (
      selectedEmail !== "" &&
      selectedEmail !== undefined &&
      selectedEmail !== null
    ) {
      const data = groupAccessTableData
        .filter((item) => item.user_email === selectedEmail)
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.hotel_id === item.hotel_id)
        )
        .map((item) => item.hotel_id);
      settotalEditedHotels(data);
    }
  }, [groupAccessTableData, selectedEmail]);

  useEffect(() => {
    getAllData();
    getHotelEmails();
  }, []);

  const deleteAdmin = () => {
    setdeleteLoader(true);
    handleSpringDataRequest(
      `core/api/v1/groupaccess/deleteUser?userEmail=${deleteAdminUserEmail}`
    )
      .then((res) => {
        toast.success(`${t("Successfully deleted admin")}`);
        getAllData();
        setdeleteLoader(false);
        setdeleteAdminUserEmail("");
        handleClosedeleteModal();
      })
      .catch((err) => {
        toast.error(`${t("Error in deleting admin")}`);
        setdeleteLoader(false);
      });
  };

  const revokeAccess = (email, hotelId) => {
    setrevokeAccessLoader(true);
    handleSpringDataRequest(
      `core/api/v1/groupaccess/revokeUserAccess?userEmail=${revokeAccessEmail}&hotel_id=${revokeAccessHotelId}&hotelId=${revokeAccessHotelId}`
    )
      .then((res) => {
        toast.success(`${t("Successfully revoked access")}`);
        getAllData();
        setrevokeAccessEmail("");
        setrevokeAccessName("");
        setrevokeAccessHotelId("");
        setrevokeAccessLoader(false);
        handleCloseRevokeModal();
      })
      .catch((err) => toast.error(`${t("Error in revoking access")}`));
  };

  const editDetails = () => {
    if (
      selectedEmail === null ||
      selectedEmail === undefined ||
      selectedEmail === ""
    ) {
      return toast.error("Please select email");
    }
    if (selectedEditHotels.length <= 0) {
      return toast.error("Please select hotels");
    }
    setloader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/groupaccess/UpdateUser?userEmail=${selectedEmail}&groupId=${groupId}`,
      selectedEditHotels
    )
      .then((res) => {
        toast.success(`${t("Successfully edit admin")}`);
        getAllData();
        setemail("");
        setpassword("");
        setconfirmPassword("");
        setselectedEditHotels([]);
        setloader(false);

        handleCloseUpdateUserModal();
      })
      .catch((err) => {
        toast.error(`${t("Error in edit admin")}`);
        setloader(false);
      });
  };

  const saveDetails = () => {
    const payload = {
      adminEmail: email.toLowerCase().trim(),
      hotel_ids: selectedHotels,
      logged_group_id: groupId,
      password: password,
      password1: confirmPassword,
    };
    if (hotelEmails.includes(email)) {
      toast.error(`${t("Please enter different email")}`);
    } else {
      if (password === confirmPassword) {
        setloader(true);
        handleSpringDataMutationRequest(
          "POST",
          `core/api/v1/groupaccess/addUpdateUser/`,
          payload
        )
          .then((res) => {
            toast.success(`${t("Successfully added admin")}`);
            getAllData();
            setemail("");
            setpassword("");
            setconfirmPassword("");
            setselectedHotels(["ALL"]);
            setloader(false);

            handleCloseAddUserModal();
          })
          .catch((err) => {
            toast.error(`${t("Error in adding admin")}`);
            setloader(false);
          });
      } else {
        toast.error(`${t("Confirm password doesnt match with password")}`);
      }
    }
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, marginBottom: "2rem" }}>
        <div
          className="d-flex align-items-center mt-3"
          style={{ marginLeft: "1.2rem" }}
        >
          <h4 className="mb-0">{t("Group Access Control - Admins")}</h4>

          <Button
            variant="custom-button"
            className="ml-4"
            onClick={() => handleOpenAddUserModal()}
          >
            {t("Add Admin")}
          </Button>

          <Button
            variant="custom-button"
            className="ml-4"
            onClick={() => handleOpenUpdateUserModal()}
          >
            {t("Edit admin access")}
          </Button>
          <OutlinedInput
            onChange={onFilterTextChange}
            placeholder={t("Search Hotels")}
            label={t("Search by hotelid, hotel name, and email")}
            notched={false}
            type="search"
            style={{
              boxShadow: "0px 0px 16px 6px #0000001a",
              borderRadius: "5px",
              backgroundColor: "white",
              width: "22rem",
              marginLeft: "1rem",
            }}
          />
        </div>
        <p
          className="text-muted mt-3"
          style={{ marginLeft: "1.2rem", marginRight: "1.2rem" }}
        >
          {t(
            "This page allows you to give users full administrative access to one or all of your properties. Once you have granted access, the user will have the same permissions as you, which means they will be able to manage and control everything related to the property except creating and assigning sub-admins."
          )}
        </p>
        <CardContent>
          <div
            style={{
              display: "flex",
              direction: "row",
            }}
            spacing={2}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: "#F5F8FA" }}>
                  <TableRow>
                    <TableCell>{t("PROPERTY")}</TableCell>
                    <TableCell>{t("USER EMAIL")}</TableCell>
                    <TableCell>{t("ACTIONS")}</TableCell>
                    {/* <TableCell>TYPE</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupAccessTableData &&
                    groupAccessTableData
                      .filter((val) => {
                        if (searchTerm === "") {
                          return val;
                        } else if (
                          val.hotel_name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return val;
                        } else if (
                          val.hotel_id
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return val;
                        } else if (
                          val.user_email
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((res, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            // onClick={() => {
                            //   toggleHotelId(res.hotelId);
                            // }}
                          >
                            {`${res.hotel_name}[${res.hotel_id}]`}
                          </TableCell>

                          <TableCell>{res.user_email}</TableCell>
                          <TableCell>
                            <Button
                              sx={{
                                backgroundColor: "red",
                                marginRight: "1rem",
                                color: "white",
                                "&:hover": { backgroundColor: "red" },
                              }}
                              key={index}
                              onClick={() =>
                                handleOpendeleteModal(res.user_email)
                              }
                            >
                              {t("Delete admin")}
                            </Button>
                            <Button
                              sx={{
                                backgroundColor: "red",
                                color: "white",
                                "&:hover": { backgroundColor: "red" },
                              }}
                              key={index}
                              onClick={() =>
                                handleOpenRevokeModal(
                                  res.user_email,
                                  res.hotel_name,
                                  res.hotel_id
                                )
                              }
                            >
                              {t("Revoke access")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </CardContent>
      </Card>
      <Modal open={adduserModal} onClose={handleCloseAddUserModal}>
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <h3> {t("Add Admin")}</h3>
          <Divider />
          <div className="d-flex flex-column">
            <div className="d-flex">
              <TextField
                label={t("Email")}
                className="input-lg w-100 mt-3 mr-3"
                placeholder={t("Login ID (Enter a valid email address)")}
                onChange={(e) => setemail(e.target.value)}
                value={email}
                name={t("Email")}
                id="Email"
              />
              <FormControl fullWidth style={{ marginTop: "9px" }}>
                <InputLabel id="demo-simple-select-label">
                  {t("Select hotels")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t("Select hotels")}
                  value={selectedHotels}
                  onChange={handleChange}
                  required="true"
                  multiple
                  // MenuProps={MenuProps}
                  placeholder={t("Select hotels")}
                >
                  <MenuItem value={"ALL"}>{t("ALL")}</MenuItem>
                  {bookingData
                    ?.filter((item) => item.status === "ACTIVE")
                    ?.map((e) => (
                      <MenuItem value={`${e?.hotelId}`}>
                        {`${e?.hotelName} - ${e?.hotelId}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="d-flex">
              <TextField
                label={t("Password")}
                className="input-lg w-100 mt-3 mr-3"
                placeholder={t("Enter password here")}
                onChange={(e) => setpassword(e.target.value)}
                value={password}
                name={t("Password")}
                id="Password"
                type="password"
              />
              <TextField
                label={t("Confirm Password")}
                className="input-lg w-100 mt-3"
                placeholder={t("Enter confirm password here")}
                onChange={(e) => setconfirmPassword(e.target.value)}
                value={confirmPassword}
                name={t("Confirm Password")}
                id="Confirm Password"
                type="password"
              />
            </div>
          </div>
          <Button
            variant="custom-button"
            className="mt-3 mb-3"
            onClick={saveDetails}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("Submit")}`
            )}
          </Button>
        </Box>
      </Modal>

      <Modal open={updateuserModal} onClose={handleCloseUpdateUserModal}>
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <h3> {t("Edit Admin Access")}</h3>
          <Divider />
          <div className="d-flex flex-column">
            <div className="d-flex">
              {uniqueEmail?.length > 0 && (
                <FormControl fullWidth style={{ marginTop: "9px" }}>
                  <InputLabel id="demo-simple-select-label">
                    {t("Select email")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("Select email")}
                    value={selectedEmail}
                    onChange={(e) => setselectedEmail(e.target.value)}
                    required="true"
                    // MenuProps={MenuProps}
                    placeholder={t("Select email")}
                  >
                    {uniqueEmail?.map((e) => (
                      <MenuItem value={`${e.user_email}`}>
                        {e.user_email}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {totalEditedHotels.length > 0 && (
                <FormControl fullWidth style={{ marginTop: "9px" }}>
                  <InputLabel id="demo-simple-select-label">
                    {t("Select hotels")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("Select hotels")}
                    value={selectedEditHotels}
                    onChange={handleEditChange}
                    required="true"
                    multiple
                    // MenuProps={MenuProps}
                    placeholder={t("Select hotels")}
                  >
                    {bookingData
                      ?.filter(
                        (item) =>
                          item.status === "ACTIVE" &&
                          !totalEditedHotels.includes(item.hotelId)
                      )
                      ?.map((e) => (
                        <MenuItem key={e.hotelId} value={`${e?.hotelId}`}>
                          {`${e?.hotelName} - ${e?.hotelId}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
          <Button
            variant="custom-button"
            className="mt-3 mb-3"
            onClick={editDetails}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("Submit")}`
            )}
          </Button>
        </Box>
      </Modal>

      <Modal open={deleteModal} onClose={handleClosedeleteModal}>
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {" "}
          <h3>{t("Confirm Delete Admin?")}</h3>
          <Divider />
          <p className="mt-3">{`${t(
            "Delete admin"
          )} ${deleteAdminUserEmail}`}</p>
          <p>{t("This would delete the admin and revoke all the rights")}</p>
          <Button
            variant="custom-button"
            className="mt-3 mb-3"
            onClick={deleteAdmin}
            disabled={deleteLoader}
          >
            {deleteLoader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("Delete admin")}`
            )}
          </Button>
        </Box>
      </Modal>

      <Modal open={revokeModal} onClose={handleCloseRevokeModal}>
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {" "}
          <h3>{t("Confirm Revoke Access")}</h3>
          <Divider />
          <p className="mt-3">{`Remove All access for user ${revokeAccessEmail} for property ${revokeAccessName}[${revokeAccessHotelId}]`}</p>
          <p>
            {t(
              "This access will revoke all the access the user has for this property."
            )}
          </p>
          <Button
            variant="custom-button"
            className="mt-3 mb-3"
            onClick={revokeAccess}
            disabled={revokeAccessLoader}
          >
            {revokeAccessLoader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("Yes, revoke for this hotel")}`
            )}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth };
}
export default connect(mapStateToProps)(GroupAdmin);
