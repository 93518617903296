import React from "react";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "material-react-toastify";

function RazorpayConfiguration() {
  const [razorpayData, setRazorpayData] = useState({});
  const [accountEmail, setAccountEmail] = useState("");
  const [accountPhone, setAccountPhone] = useState("");
  const [updateRazorpayLoader, setUpdateRazorpayLoader] = useState();
  const getRazorpayConfig = () => {
    handleSpringDataRequest(
      `core/api/v1/payment-service/get-razorpay-merchant-config`
    )
      .then((data) => {
        setRazorpayData(data);
      })
      .catch(() => {});
  };
  const updateRazorpay = () => {
    setUpdateRazorpayLoader(true);
    const payload = {
      hotelId: razorpayData?.hotelId,
      accountEmail: accountEmail,
      accountPhone: accountPhone,
      status: razorpayData?.status,
      onboardingStatus: razorpayData?.onboardingStatus,
      accountId: razorpayData?.accountId,
      publicToken: razorpayData?.publicToken,
    };
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/payment-service/update-razorpay-merchant-config`,
      payload
    )
      .then(() => {
        getRazorpayConfig();
        setUpdateRazorpayLoader(false);
        toast.success("Razorpay updated successfully");
      })
      .catch(() => {
        setUpdateRazorpayLoader(false);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getRazorpayConfig();
  }, []);
  useEffect(() => {
    setAccountEmail(razorpayData?.accountEmail);
    setAccountPhone(razorpayData?.accountPhone);
  }, [razorpayData]);

  const handlePhoneChange = (value) => {
    const phoneRegex = /^[0-9\b]+$/;
    if (value === "" || (phoneRegex.test(value) && value.length <= 10)) {
      setAccountPhone(value);
    }
  };
  return (
    <div>
      <h2>{"Razorpay configuration"}</h2>

      <Card sx={{ width: "100%", mt: 4 }}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 16, fontWeight: 600 }}
                color="gray"
                gutterBottom
                align="center"
              >
                Account Id
              </Typography>
              <Typography sx={{ fontSize: 14 }} align="center">
                {razorpayData?.accountId}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 16, fontWeight: 600 }}
                color="gray"
                gutterBottom
                align="center"
              >
                Public token
              </Typography>
              <Typography sx={{ fontSize: 14 }} align="center">
                {razorpayData?.publicToken}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 16, fontWeight: 600 }}
                color="gray"
                gutterBottom
                align="center"
              >
                Status
              </Typography>
              <Typography sx={{ fontSize: 14 }} align="center">
                {razorpayData?.status}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 16, fontWeight: 600 }}
                color="gray"
                gutterBottom
                align="center"
              >
                Onboarding status
              </Typography>
              <Typography sx={{ fontSize: 14 }} align="center">
                {razorpayData?.onboardingStatus}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Account email"
                id="outlined-size-small"
                size="small"
                fullWidth
                value={accountEmail}
                onChange={(e) => setAccountEmail(e?.target?.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Account phone"
                id="outlined-size-small"
                size="small"
                fullWidth
                value={accountPhone}
                onChange={(e) => handlePhoneChange(e?.target?.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="custom-button"
                sx={{ width: "220px" }}
                onClick={updateRazorpay}
                disabled={updateRazorpayLoader}
              >
                Update info{" "}
                {updateRazorpayLoader && (
                  <CircularProgress size={15} color="inherit" />
                )}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default RazorpayConfiguration;
