import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETALLHOTELADDONS = "core/api/v1/hotelAddons/get-all";
const CREATEHOTELADDON = "core/api/v1/hotelAddons/create";
const UPDATEHOTELADDON = "core/api/v1/hotelAddons/update";
const DELETEADDONS = "core/api/v1/hotelAddons/delete-hotel-addon";
const SHIFT = "core/api/v1/pc/delete-shift-config";

export const getHotelAddons = async () => {
  return axios
    .get(COMMON_URL + GETALLHOTELADDONS)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getHotelAddonsWithHotelId = async (hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}core/api/v1/hotelAddons/get-all-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`
    );
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err);
  }
};


export const createHotelAddons = async (data) => {
  return axios
    .post(COMMON_URL + CREATEHOTELADDON, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateHotelAddons = async (data) => {
  return axios
    .put(COMMON_URL + UPDATEHOTELADDON, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteAddon = async (id) => {
  return axios
    .delete(COMMON_URL + DELETEADDONS + "/?hotelAddonId=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteShiftConfig = async (id) => {
  return axios
    .delete(COMMON_URL + SHIFT + "?id=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
