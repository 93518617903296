import axios from "axios";
import { toast } from "material-react-toastify";

const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETPROMOCODES = "core/api/v1/promocode/get-promocodes";
const ADDPROMOCODES = "core/api/v1/promocode/create-promocode";
const DEACTIVATEPROMOCODES = "core/api/v1/promocode/deactivate-promocode";
const ACTIVATEPROMOCODES = "core/api/v1/promocode/activate-promocode";
const GETUSERS = "core/api/v1/getGuestInfo";
const GETUSERBOOKINGDETAIL = "core/api/v1/getBookingUserDetails";
const GETSELECTEDGUESTUSERS = "core/api/v1/getSelectedGuestInfo";
const GETISHAGUESTDATA = "core/api/v1/getIshaGuestConfig";
const SETISHAGUESTDATA = "core/api/v1/setIshaGuestConfig";
const ADDANDEDITGUESTUSER = "core/api/v1/addGuestInfo";
const PROMOCODEPRICELIST = "core/api/v1/reservation/get-day-level-price-list";
const PROMOCODEPRICELISTNOAUTH =
  "core/api/v1/reservation/get-day-level-price-list-noAuth";
const CHECKPROMO = "core/api/v1/be/promo-code-discount-rates";
const CHECKPROMOQUICKBOOK = "core/api/v1/promocode/apply-promo-code";
const REMOVECUSTOMERBOOKING = "core/api/v1/removeGuestFromBooking";
const UPDATEPRIMARYGUEST = "core/api/v1/updatePrimaryGuestInfo";
const UPDATEGUESTCOUNT = "core/api/v1/updateGuestOfReservation";
const UPDATEROOMPRIMARY = "core/api/v1/reservation/set-res-primary";

const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotelId");

export const getAllPromocodes = async () => {
  return axios
    .get(COMMON_URL + GETPROMOCODES)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllPromocodesWithHotelId = async (hotelId) => {
  return axios
    .get(
      COMMON_URL +
        GETPROMOCODES +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllPromocodesPriceList = async (
  checkin,
  checkout,
  roomTypeId,
  ratePlanId
) => {
  return axios
    .get(
      COMMON_URL +
        PROMOCODEPRICELIST +
        "/?checkin=" +
        checkin +
        "&checkout=" +
        checkout +
        "&roomTypeId=" +
        roomTypeId +
        "&ratePlanId=" +
        ratePlanId
    )
    .then((resp) => resp.data)
    .catch((err) => {});
};

export const getAllPromocodesPriceListWithHotelId = async (
  checkin,
  checkout,
  roomTypeId,
  ratePlanId,
  hotelId
) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${PROMOCODEPRICELISTNOAUTH}/?checkin=${checkin}&checkout=${checkout}&roomTypeId=${roomTypeId}&ratePlanId=${ratePlanId}&hotelId=${hotelId}&hotel_id=${hotelId}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    toast.error(err.response.data);
  }
};

export const activiatePromocode = async (id) => {
  return axios
    .get(
      COMMON_URL +
        ACTIVATEPROMOCODES +
        "?hotel_id=" +
        hotelId +
        "&promotionId=" +
        id
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deactiviatePromocode = async (id) => {
  return axios
    .get(
      COMMON_URL +
        DEACTIVATEPROMOCODES +
        "?hotel_id=" +
        hotelId +
        "&promotionId=" +
        id
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addNewPromocode = async (data) => {
  return axios
    .post(COMMON_URL + ADDPROMOCODES, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const checkPromoCode = async (data) => {
  return axios
    .post(COMMON_URL + CHECKPROMO, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const checkPromoCodeQuickBook = async (data) => {
  return axios
    .post(COMMON_URL + CHECKPROMOQUICKBOOK, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const checkPromoCodeQuickBookWithHotelId = async (data, hotelId) => {
  return axios
    .post(
      COMMON_URL +
        CHECKPROMOQUICKBOOK +
        "?hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId,
      data
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllGuestUsers = async (id) => {
  return axios
    .get(COMMON_URL + GETUSERS + "?bookingId=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllGuestUsersWithHotelId = async (id, hotelId) => {
  return axios
    .get(
      COMMON_URL +
        GETUSERS +
        "?bookingId=" +
        id +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getBookingUserDetailsWithHotelId = async (id, hotelId) => {
  return axios
    .get(
      COMMON_URL +
        GETUSERBOOKINGDETAIL +
        "?bookingId=" +
        id +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getBookingUserDetails = async (id) => {
  return axios
    .get(COMMON_URL + GETUSERBOOKINGDETAIL + "?bookingId=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getSelectedGuest = async (id, bookingId) => {
  return axios
    .get(
      COMMON_URL +
        GETSELECTEDGUESTUSERS +
        "?email=" +
        id +
        "&bookingId=" +
        bookingId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getIshaGuestConfig = async (id) => {
  return axios
    .get(COMMON_URL + GETISHAGUESTDATA + "?email=" + id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addEditGuestUser = async (data) => {
  return axios
    .post(COMMON_URL + ADDANDEDITGUESTUSER, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setIshaGuestConfig = async (data) => {
  return axios
    .post(COMMON_URL + SETISHAGUESTDATA, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const removeCustomerBooking = async (data) => {
  return axios
    .post(COMMON_URL + REMOVECUSTOMERBOOKING, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updatePrimaryGuest = async (data) => {
  return axios
    .post(COMMON_URL + UPDATEPRIMARYGUEST, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateGuestCount = async (data) => {
  return axios
    .post(COMMON_URL + UPDATEGUESTCOUNT, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setRoomPrimary = async (data) => {
  return axios
    .post(COMMON_URL + UPDATEROOMPRIMARY, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
