import {
  Stack,
  Button,
  Box,
  ClickAwayListener,
  Divider,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Typography,
} from "@mui/material";
import { toast } from "material-react-toastify";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { djangoHandleDataRequests } from "../../../../api";
import ShopWrapper from "../ShopWrapper";
import moment from "moment";
import { DateRange } from "react-date-range";
import { SecondaryButton } from "../../../../Utils/ButtonHelper";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import ReportsLoader from "../../../../Utils/ReportsLoader";
import { useTranslation } from "react-i18next";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const AllRestaurantDayWiseReport = ({ data }) => {
  const { t } = useTranslation();

  // getting the hotel currency
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId } = useParams();

  // Getting the start date as well as the end date to load the data
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Get All report data
  const [reportsData, setreportsData] = useState([]);
  const [copyreportsData, setCopyreportsData] = useState(null);

  //Function to get report data
  const getAllRestaurantReportData = () => {
    setCopyreportsData(null);
    shopId &&
      djangoHandleDataRequests(
        `pos/allRestaurantDayWiseSalesSummary/?shop_id=${shopId}&start_date=${moment(
          dateRange[0].startDate
        ).format("YYYY-MM-DD 00:00:00")}&end_date=${moment(
          dateRange[0].endDate
        ).format("YYYY-MM-DD 23:59:59")}&hotel_id=${hotelId}`
      )
        .then((res) => {
          setreportsData(res);
          setCopyreportsData(res);
          // setrowsdats(productsGenerator(res.length, res));
        })
        .catch((err) =>
          toast.error(`${t("Error in getting data! Please try again later.")}`)
        );
  };

  useEffect(() => {
    getAllRestaurantReportData();
  }, []);

  // columns
  const columns = [
    {
      dataField: "shop_name",
      text: `${t("Shop name")}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "order_date",
      text: `${t("Order date")}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "total_no_of_bills",
      text: `${t("Total no. of bills")}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "my_amount",
      text: `${t(`My amount (${hotelCurrency})`)}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "total_discount",
      text: `${t(`Total discount (${hotelCurrency})`)}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "net_sales",
      text: `${t(`Net sales (${hotelCurrency})`)}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "total_tax",
      text: `${t(`Total tax (${hotelCurrency})`)}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "service_charge",
      text: `${t(`Service charge (${hotelCurrency})`)}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "cgst_amount",
      text: `${t(`CGST (${hotelCurrency})`)}`,
      hidden: hotelCurrency?.toLowerCase() === "inr" ? false : true,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "sgst_amount",
      text: `${
        localStorage.getItem("utgstStatus") === "ENABLED" ? "UTGST" : "SGST"
      } (${hotelCurrency})`,
      hidden: hotelCurrency.toLowerCase() === "inr" ? false : true,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "total_sales",
      text: `${t(`Total sales (${hotelCurrency})`)}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "settled_amount",
      text: `${t(`Settled amount (${hotelCurrency})`)}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "balance_amount",
      text: `${t("Balance amount")}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "card",
      text: `${t(`Card payments (${hotelCurrency})`)}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "cash",
      text: `${t(`Cash payments (${hotelCurrency})`)}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "emv_card",
      text: `${t("EMV card payments")}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
      hidden: hotelCurrency?.toLowerCase() === "usd" ? false : true,
    },
    {
      dataField: "wallet",
      text: `${t("Wallet payments")}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "part",
      text: `${t("Part payments")}`,
      hidden: true,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "other",
      text: `${t("Other type payments")}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
    {
      dataField: "order_status",
      text: `${t("Order status")}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
      hidden: true,
    },
    {
      dataField: "order_user_source",
      text: `${t("Order src.")}`,
      style: {
        wordWrap: "break-word",
        overflow: "hidden",
      },
      headerStyle: {
        wordWrap: "break-word",
        overflow: "hidden",
        width: "150px",
      },
    },
  ];

  // useEffect(() => {
  //   if (hotelCurrency.toLowerCase() !== "usd") {
  //     const emv_card_index = columns.findIndex(
  //       (col) => col.field === "emv_card"
  //     );
  //     columns.splice(emv_card_index, 1);
  //   }
  // }, [hotelCurrency]);

  //Rows data generator
  // const productsGenerator = (quantity, data) => {
  //   const items = [];
  //   for (let i = 0; i < quantity; i++) {
  //     items.push({
  //       shop_name: data[i].shop_name,
  //       order_date: data[i].order_date,
  //       total_no_of_bills: data[i].total_no_of_bills,
  //       my_amount: data[i].my_amount,
  //       total_discount: data[i].total_discount,
  //       net_sales: data[i].net_sales,
  //       total_tax: data[i].total_tax,
  //       cgst_amount: data[i].cgst_amount,
  //       sgst_amount: data[i].sgst_amount,
  //       total_sales: data[i].total_sales,
  //       settled_amount: data[i].settled_amount,
  //       balance_amount: data[i].balance_amount,
  //       card: data[i].card,
  //       cash: data[i].cash,
  //       wallet: data[i].wallet,
  //       emv_card: data[i].emv_card,
  //       part: data[i].part,
  //       other: data[i].other,
  //       order_status: data[i].order_status,
  //       order_user_source: data[i].order_user_source,
  //     });
  //   }
  //   return items;
  // };

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  //columns of table

  // const [rowsdats, setrowsdats] = useState([]);
  // const [reportsColumns, setreportsColumns] = useState([]);
  // const names = [
  //   "Shop Name",
  //   "Order Date",
  //   "Total no of bills",
  //   `My amount (${hotelCurrency})`,
  //   `Total discount (${hotelCurrency})`,
  //   `Net sales (${hotelCurrency})`,
  //   `Total tax (${hotelCurrency})`,
  //   `CGST Amount (${hotelCurrency})`,
  //   `EMV card (${hotelCurrency})`,
  //   `SGST Amount (${hotelCurrency})`,
  //   `Total sales (${hotelCurrency})`,
  //   `Settled Amount (${hotelCurrency})`,
  //   `Balanced Amount (${hotelCurrency})`,
  //   `Card (${hotelCurrency})`,
  //   `Cash (${hotelCurrency})`,
  //   `Wallet (${hotelCurrency})`,
  //   "Part",
  //   `Other (${hotelCurrency})`,
  //   "Order Status",
  //   "Order Source",
  // ];
  // useEffect(() => {
  //   if (reportsData?.length > 0) {
  //     let arr = [];
  //     const array = Object.keys(reportsData[0]);
  //     for (var i = 0; i < array?.length; i++) {
  //       arr.push({
  //         dataField: array[i],
  //         text: names[i],
  //         sort: true,
  //         style: {
  //           wordWrap: "break-word",
  //           overflow: "hidden",
  //         },
  //         headerStyle: {
  //           wordWrap: "break-word",
  //           overflow: "hidden",
  //         },
  //       });
  //     }

  //     // if (hotelCurrency.toLowerCase() !== "usd") {
  //     //   const emv_card_index = arr.findIndex(
  //     //     (ob) => ob.dataField.toLowerCase() === "emv_card"
  //     //   );
  //     //   arr.splice(emv_card_index, 1);
  //     //   setreportsColumns(arr);
  //     // }

  //     const index_part = arr.findIndex(
  //       (ob) => ob.dataField.toLowerCase() === "part"
  //     );
  //     arr.splice(index_part, 1);

  //     const index_order_status = arr.findIndex(
  //       (ob) => ob.dataField.toLowerCase() === "order_status"
  //     );
  //     arr.splice(index_order_status, 1);

  //     if (hotelCurrency.toLowerCase() !== "inr") {
  //       const index_cgst = arr.findIndex(
  //         (ob) => ob.dataField.toLowerCase() === "cgst_amount"
  //       );
  //       arr.splice(index_cgst + 1, 1);
  //       arr.splice(index_cgst, 1);

  //       return setreportsColumns(arr);
  //     } else {
  //       setreportsColumns(arr);
  //     }
  //     // setreportsColumns(arr);
  //   }
  // }, [reportsData, hotelCurrency]);

  const [orderStatus, setorderStatus] = useState("all");
  const handleOrderStatus = (value) => {
    setorderStatus(value);
    if (value === "all") {
      // setCopyreportsData(productsGenerator(reportsData.length, reportsData));
      setCopyreportsData(reportsData);
    } else {
      const data = reportsData?.filter(
        (e) =>
          e.order_status?.trim()?.toUpperCase() === value?.trim()?.toUpperCase()
      );
      // setCopyreportsData(productsGenerator(data.length, data));
      setCopyreportsData(data);
    }
  };
  const orders = [
    { id: 1, name: `${t("All")}`, value: "all" },
    { id: 2, name: `${t("Settled")}`, value: "SETTLED" },
    { id: 3, name: `${t("Due")}`, value: "DUE" },
    { id: 4, name: `${t("Complimentary")}`, value: "COMPLIMENTARY" },
    { id: 5, name: `${t("Cancelled")}`, value: "CANCELLED" },
    { id: 6, name: `${t("Inroom")}`, value: "INROOM" },
  ];

  useEffect(() => {
    setorderStatus("all");
  }, [dateRange[0].startDate, dateRange[0].endDate]);

  return (
    <ShopWrapper
      backBtn={true}
      contentArea={
        <>
          <h1 className="mt-3 mb-3">{t("Outlet day wise sales summary")}</h1>
          <Divider className="mt-2 mb-2" />
          {copyreportsData ? (
            <div className="App">
              {/* {reportsColumns.length > 0 && ( */}
              <ToolkitProvider
                keyField="id"
                data={copyreportsData}
                columns={columns}
                exportCSV
                search
                noDataIndication="No data found"
              >
                {(props) => (
                  <Stack>
                    <Stack
                      className="mt-4 position-relative w-100 justify-content-between"
                      direction="row"
                    >
                      <Stack direction="row" spacing={2}>
                        <SearchBar
                          {...props.searchProps}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        />
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <Box
                            sx={{
                              position: "relative",
                            }}
                          >
                            <button
                              onClick={handleClick}
                              className="border rounded p-2"
                              style={{
                                backgroundColor: "transparent",
                                height: "37.5px",
                              }}
                            >
                              {moment(dateRange[0].startDate).format(
                                "DD MMM YYYY"
                              )}{" "}
                              {" to "}
                              {moment(dateRange[0].endDate).format(
                                "DD MMM YYYY"
                              )}
                            </button>
                            {open ? (
                              <Box sx={styles}>
                                <Stack spacing={3}>
                                  <DateRange
                                    editableDateInputs={true}
                                    onChange={(item) =>
                                      setDateRange([item.selection])
                                    }
                                    moveRangeOnFirstSelection={false}
                                    ranges={dateRange}
                                  />
                                  <Stack
                                    className="position-relative w-100 d-flex justify-content-between"
                                    direction="row"
                                  >
                                    <SecondaryButton
                                      text={t("Cancel")}
                                      onClick={() => setOpen(false)}
                                    />
                                    <Button
                                      variant="custom-button"
                                      onClick={() => {
                                        getAllRestaurantReportData();
                                        handleClickAway();
                                      }}
                                    >
                                      {t("Apply")}
                                    </Button>
                                  </Stack>
                                </Stack>
                              </Box>
                            ) : null}
                          </Box>
                        </ClickAwayListener>
                        <FormControl style={{ width: "11rem" }} size="small">
                          <InputLabel id="demo-simple-select-label">
                            {t("Order status")}
                          </InputLabel>
                          <Select
                            style={{ width: "11rem" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={orderStatus}
                            onChange={(e) => handleOrderStatus(e.target.value)}
                            label={t("Order status")}
                          >
                            {orders?.map((item) => (
                              <MenuItem key={item.id} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                      <ExportCSVButton
                        {...props.csvProps}
                        style={{
                          display: "block",
                          background: "black",
                          color: "white",
                          marginTop: -1.5,
                          marginBottom: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        {t("Export CSV")}
                      </ExportCSVButton>
                    </Stack>
                    <hr />
                    {columns.length > 0 && copyreportsData.length > 0 ? (
                      <BootstrapTable
                        pagination={paginationFactory({ sizePerPage: 10 })}
                        {...props.baseProps}
                      />
                    ) : (
                      <Typography
                        className="position-relative mt-8 text-align-center"
                        style={{
                          fontSize: "15px",
                          marginTop: "15px",
                          color: "grey",
                          textAlign: "center",
                        }}
                      >
                        {t("No data found!")}
                      </Typography>
                    )}
                  </Stack>
                )}
              </ToolkitProvider>
            </div>
          ) : (
            <ReportsLoader />
          )}
        </>
      }
    />
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(AllRestaurantDayWiseReport);
