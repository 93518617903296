import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { handleSpringDataMutationRequest } from "../../../api";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import i18n from "../../../modules/Auth/pages/i18n";
import axios from "axios";

function UnlockRoom(props) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [selectedRooms, setselectedRooms] = useState([
    props.invoiceData.invoice.room_ids_list[0],
  ]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      setselectedRooms(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  useEffect(() => {}, [selectedRooms]);
  const [loader, setloader] = useState(false);

  const save = () => {
    setloader(true);

    axios
      .post(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/set-single-reservation-locked-status?status=UNLOCKED&reservationId=${props?.reservationId[selectedRooms]}`
      )
      .then((res) => (props.isGrid ? props.reloadGrid() : ""))
      .then((res) => props.updateInvoice())
      .then((res) => props.updateSummaryData())
      .then((res) => toast.success(`${t("Successfully unlocked room")}`))
      .then((res) => setloader(false))
      .catch((err) => toast.error(`${t("Something went wrong")}`))
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <h4
        style={{ marginLeft: "2rem", marginTop: "2rem", marginBottom: "1rem" }}
      >
        {t("Select the room id to unlock room")}
      </h4>

      <div
        className="d-flex"
        style={{ marginLeft: "2rem", marginRight: "2rem" }}
      >
        <FormControl sx={{ width: "100%" }}>
          <InputLabel> {t("Room ids")}</InputLabel>
          <Select
            value={selectedRooms}
            onChange={handleChange}
            required="true"
            MenuProps={MenuProps}
            label={t("Room ids")}
          >
            {props?.invoiceData?.invoice?.room_ids_list?.map((name) => (
              <MenuItem key={name.id} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <h2>
        <span
          class="badge"
          style={{
            background: "#96C7C1",
            marginLeft: "2rem",
            marginRight: "2rem",
            marginTop: "1rem",
            width: "90%",
          }}
        >
          {t("You are about to unlock room")} {selectedRooms}
        </span>
      </h2>
      <Divider />
      <Button
        className="float-right mr-4 mb-3 mt-4"
        variant="custom-button"
        onClick={save}
        disabled={loader}
      >
        {loader ? (
          <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
        ) : (
          `${t("Yes, Unlock room")}`
        )}
      </Button>
    </div>
  );
}

export default UnlockRoom;
