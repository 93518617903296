import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  FormControl,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const CancelBooking = (props) => {
  const {
    reservationData,
    closeModal,
    closeBackDrop,
    title,
    value,
    buttonText,
    buttonFunction,
    load,
    reservation_id,
    setwaiveNoShowFee,
    waiveNoShowFee,
    selectedLanguage,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const [voidOption, setVoidOption] = useState("");
  const [checked, setChecked] = useState(
    reservationData?.checkin_all === "yes" ? true : false
  );
  const classes = useStyles();

  const getParams = () => {
    return {
      bookingId: reservationData.booking_id,
      applyRefund: false,
      isExternal: false,
      isReservation: false,
      excludeChannelIds: null,
      bookingSrc: reservationData.booking_source,
    };
  };
  const getVoidParams = () => {
    return {
      bookingId: reservationData.booking_id,
      voidType: voidOption,
    };
  };
  const getParamsReservation = () => {
    return {
      resIds: [reservation_id],
    };
  };
  const getVoidParamsReservation = () => {
    return {
      resIds: [reservation_id],
      voidType: voidOption,
    };
  };
  // confirmation modal for handling the booking to no show status
  const [openBookingNoShow, setOpenBookingNoShow] = useState(false);
  const handleOpenBookingNoShow = () => setOpenBookingNoShow(true);
  const handleCloseBookingNoShow = () => setOpenBookingNoShow(false);

  return (
    <div className={classes.root}>
      <CommonSlider onBackdropClick={closeBackDrop} open={true}>
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: "right" }} onClick={closeModal}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        {/* Booking dot com modal for putting the booking on no show */}
        <Modal
          open={openBookingNoShow}
          onClose={handleCloseBookingNoShow}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="foliosConfirmationModal">
            <div
              className="folioConfirmationWrapper"
              style={{
                position: "relative",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                className="folioHeader"
                style={{ position: "relative", width: "100%" }}
              >
                <div className="headerName" style={{ fontWeight: 500 }}>
                  {t("Mark as a no-show")}
                </div>
                <div
                  className="cancelText"
                  style={{ fontWeight: 500, cursor: "pointer" }}
                  onClick={() => handleCloseBookingNoShow()}
                >
                  {"X"}
                </div>
              </div>
              <div className="horizontalSeparator" />
              {/* <div className="folioConfContent">Are you sure you want to cancel?</div> */}
              <div
                className="folioContent"
                style={{
                  marginTop: "15px",
                  width: "100%",
                  position: "relative",
                }}
              >
                <div>
                  <h4>{reservationData?.room_type_name}</h4>
                  <FormControl style={{ marginTop: "10px" }}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      {t("Mark as a no-show")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={waiveNoShowFee}
                      onChange={(e) => setwaiveNoShowFee(e.target.value)}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label={t("YES")}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label={t("NO")}
                      />
                    </RadioGroup>
                  </FormControl>
                  <p>{t("We will inform the guest accordingly")}</p>
                  <p>
                    {t(
                      "If you charge a cancellation or no-show fee for any reservation. Booking.com will charge commission on this fee"
                    )}
                  </p>
                  <p>
                    {t(
                      "In case the guest leaves a review, it will be automatically removed after the no-show is confirmed and your review score will not be impacted."
                    )}
                  </p>
                </div>
              </div>
              <div className="folioConfButtons">
                <button
                  className="cancel"
                  onClick={() => handleCloseBookingNoShow()}
                >
                  {t("Cancel")}
                </button>

                <button
                  className="approved"
                  style={{ width: "14rem" }}
                  onClick={() => buttonFunction(getParams(), getVoidParams())}
                >
                  {t("Yes mark as a no show")}
                </button>
              </div>
            </div>
          </Box>
        </Modal>
        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <Box className={classes.custom_box} bgcolor="primary.main">
              {value === "no-show" && (
                <Typography>
                  {`${t("You are setting NO SHOW status for Room")} ${
                    reservationData.reservation_rooms
                  }`}
                </Typography>
              )}
              {value === "cancel-booking" && (
                <Typography>
                  {`${t("You are about to Cancel Room")}  ${
                    reservationData.reservation_rooms
                  }`}
                </Typography>
              )}
            </Box>
          </Grid>
          {value === "no-show" && (
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox disabled checked />}
                label={`${t("Set NO SHOW for all rooms in booking")} ${
                  reservationData.booking_id
                }`}
              />
            </Grid>
          )}
          {value === "cancel-booking" && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={checked}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                    defaultChecked={checked}
                  />
                }
                label={`${t("Cancel all rooms in booking")} ${
                  reservationData.booking_id
                }`}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              select
              variant="outlined"
              label="Please select"
              value={voidOption}
              fullWidth
              name="options"
              onChange={(e) => {
                setVoidOption(e.target.value);
              }}
            >
              <MenuItem key={"APPLY_REFUND"} value={"APPLY_REFUND"}>
                {t("Apply Cancellation Policy")}
              </MenuItem>
              <MenuItem key={"NO_VOID"} value={"NO_VOID"}>
                {t("Don't Void")}
              </MenuItem>
              <MenuItem key={"CHARGE_ONE_NIGHT"} value={"CHARGE_ONE_NIGHT"}>
                {t("Charge one night and void remaining")}
              </MenuItem>
              <MenuItem key={"VOID"} value={"VOID"}>
                {t("Void all remaining charges")}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            className={classes.textField}
          >
            <Fab
              type="button"
              color="primary"
              onClick={() => {
                if (value === "no-show") {
                  buttonFunction(getParams(), getVoidParams());
                }
                if (value === "cancel-booking") {
                  if (checked) {
                    buttonFunction(checked, getParams(), getVoidParams());
                  } else {
                    buttonFunction(
                      checked,
                      getParamsReservation(),
                      getVoidParamsReservation()
                    );
                  }
                }
              }}
              variant="extended"
              disabled={load || !voidOption}
            >
              {buttonText}
              {load && (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              )}
            </Fab>
          </Grid>
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default CancelBooking;
