import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { handleFullAddString, validateAddress } from "../../../../Utils/Utils";
import { getMapForTheRatePlans } from "../../FolioUtils";
import axios from "axios";
import { unionTerritories } from "../../../../Utils/UnionTerritories";
import { useTranslation } from "react-i18next";
import i18n from "../../../../modules/Auth/pages/i18n";
import { djangoHandleDataRequests } from "../../../../api";

function TaxInvoicePage({
  advInvoice,
  invoice,
  symbol,
  hotelData,
  customDetails,
  userId,
  hotelLogo,
  summaryData,
  customerIdentification,
  ctaDetails,
  billToPref,
  custCompanyDetails,
  ifShowNewCancellationPolicy,
  rowItems,
  igst,
  dbigst,
  utgst,
  customerState,
  propertyState,
  correntPage,
  totalPage,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const hotelCurrency = localStorage.getItem("hotelCurrency");
  const [convertedAmount, setconvertedAmount] = useState(0);

  const [hotelConvertedCurrency, sethotelConvertedCurrency] = useState(
    localStorage.getItem("hotelCurrency")
  );
  const [hotelConvertedCurrencySymbol, sethotelConvertedCurrencySymbol] =
    useState(localStorage.getItem("symbol"));

  const [folioConfig, setFolioConfig] = useState({});
  const getConvertedvalue = async () => {
    if (
      sessionStorage.getItem("defaultRate") === 0 &&
      sessionStorage.getItem("defaultRate") === undefined &&
      sessionStorage.getItem("defaultRate") === null
    ) {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}`
      );
      setconvertedAmount(data.data);
    } else {
      const data = await axios.get(
        `${
          process.env.REACT_APP_SPRING_API_URL
        }web-api/currency-convert-value?from=${hotelCurrency}&to=${sessionStorage.getItem(
          "convertedHotelCurrency"
        )}&amount=${sessionStorage.getItem("defaultRate")}`
      );
      setconvertedAmount(data.data);
    }
    symbol =
      hotelCurrency === hotelConvertedCurrency
        ? symbol
        : hotelConvertedCurrencySymbol;
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("convertedHotelCurrency") != null &&
      sessionStorage.getItem("convertedHotelCurrency") !== undefined
    ) {
      sethotelConvertedCurrency(
        sessionStorage.getItem("convertedHotelCurrency")
      );
      sethotelConvertedCurrencySymbol(
        sessionStorage.getItem("convertedHotelCurrencySymbol")
      );
      if (
        hotelCurrency !== "" &&
        hotelCurrency !== undefined &&
        hotelCurrency !== null &&
        sessionStorage.getItem("convertedHotelCurrency") !== "" &&
        sessionStorage.getItem("convertedHotelCurrency") !== undefined &&
        sessionStorage.getItem("convertedHotelCurrency") !== null
      ) {
        getConvertedvalue();
      }
    }
  }, [
    sessionStorage.getItem("convertedHotelCurrency"),
    sessionStorage.getItem("defaultRate"),
  ]);

  const [tableHeader, settableHeader] = useState([
    `${t("Date")}`,
    `${t("HSN/SAC code")}`,
    `${t("Description")}`,
    `${t("Rate Plan")}`,
    `${t("Debit")}`,
    `${t("Credit")}`,
    `${t("Balance")}`,
  ]);

  const currencyArr = ["Rs.", "rs.", "INR", "inr"];
  useEffect(() => {
    if (
      currencyArr.includes(String(symbol)) &&
      folioConfig?.display_hsn_code_invoice === "YES"
    ) {
      settableHeader([
        `${t("Date")}`,
        `${t("HSN/SAC code")}`,
        `${t("Description")}`,
        `${t("Rate Plan")}`,
        `${t("Debit")}`,
        `${t("Credit")}`,
        `${t("Balance")}`,
      ]);
    } else {
      settableHeader([
        `${t("Date")}`,
        `${t("Description")}`,
        `${t("Rate Plan")}`,
        `${t("Debit")}`,
        `${t("Credit")}`,
        `${t("Balance")}`,
      ]);
    }
  }, [symbol, folioConfig]);

  useEffect(() => {
    djangoHandleDataRequests(`invoice/getInvoiceConfig/`)
      .then((data) => {
        setFolioConfig(data?.config);
      })
      .catch(() => {});
  }, []);

  return (
    <View style={{ width: "100%", margin: 20 }}>
      {/* Header ----------------------------------------------------------------*/}
      <View style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <View style={{ width: "90%" }}>
          <Text
            style={{
              fontSize: 16,
              fontFamily: "Helvetica-Bold",
              marginBottom: 2,
            }}
          >
            {hotelData?.hotel_name}
          </Text>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.HeaderLeft}>{t("Plot")}</Text>
            <Text style={styles.HeaderRight}>
              {": "}
              {handleFullAddString(hotelData?.hotel_address)}
            </Text>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.HeaderLeft}>{t("Phone")}</Text>
            <Text style={styles.HeaderRight}>
              {": "}
              {customDetails?.invoice_contact_phone &&
              !customDetails?.invoice_contact_phone
                .toLowerCase()
                .includes("null", "n/a")
                ? customDetails?.invoice_contact_phone
                : hotelData?.hotel_contact}
            </Text>
          </View>

          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.HeaderLeft}>{t("Email")}</Text>
            <Text style={styles.HeaderRight}>
              {": "}
              {customDetails?.invoice_contact_email &&
              !customDetails?.invoice_contact_email
                .toLowerCase()
                .includes("null", "n/a")
                ? customDetails?.invoice_contact_email
                : hotelData?.hotel_email}
            </Text>
          </View>

          {hotelData?.tax_identification_number.length > 0 && (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HeaderLeft}>
                {String(symbol)?.includes("Rs") ? "GST No." : "Tax IN"}
              </Text>
              <Text style={styles.HeaderRight}>
                {": "}
                {hotelData.tax_identification_number}
              </Text>
            </View>
          )}
          {/* <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.HeaderLeft}>IRN</Text>
            <Text style={styles.HeaderRight}> {": "}Plot value</Text>
          </View> */}
        </View>
        <View style={{ width: "10%" }}>
          {customDetails?.show_logo.toLowerCase() === "yes" &&
            getExtension(hotelLogo) && <Image src={getExtension(hotelLogo)} />}
        </View>
      </View>

      <View style={styles.table}>
        {/* --------------TAX INVOICE Row---------------------------------- */}
        <View style={styles.tableRow}>
          <Text
            style={[
              styles.tableCell,
              {
                width: "100%",
                textAlign: "center",
                fontSize: 16,
                fontFamily: "Helvetica-Bold",
              },
            ]}
          >
            {t("TAX INVOICE")}
          </Text>
        </View>

        {/* //--------------------------Hotel Detail Row------------------------------- */}
        <View
          style={[styles.tableRow, { display: "flex", flexDirection: "row" }]}
        >
          {/* //-----------------------------Left Side 60%----------------------------------- */}
          <View style={{ width: "60%" }}>
            <View style={{ marginBottom: 3 }}></View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}>Guest</Text>
              <Text style={styles.HotelDetailsRight}>
                {": "}
                {invoice?.segment === "DIRECT_BILLING"
                  ? ctaDetails?.cta_name
                  : billToPref === "COMPANY"
                  ? custCompanyDetails[0]?.company_name
                  : invoice?.customer_name}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}> {t("Guest Address")}</Text>
              {invoice.segment === "DIRECT_BILLING" ? (
                <Text style={styles.HotelDetailsRight}>
                  {": "} {ctaDetails?.cta_address_line1}
                  {ctaDetails?.cta_address_line2} {ctaDetails?.cta_address_city}{" "}
                  {ctaDetails?.cta_address_state}
                </Text>
              ) : !invoice?.customer_address_full_str.includes("NA", "null") ||
                billToPref === "COMPANY" ? (
                <Text style={styles.HotelDetailsRight}>
                  {": "}
                  {billToPref === "COMPANY"
                    ? custCompanyDetails[0]?.company_address
                    : handleFullAddString(invoice?.customer_address_full_str)}
                </Text>
              ) : (
                <Text style={styles.HotelDetailsRight}>
                  {": "}
                  {handleCustomerAddress(invoice?.customer_address)}
                </Text>
              )}
            </View>

            {invoice?.segment === "DIRECT_BILLING" ? (
              ctaDetails?.cta_phone?.length > 0 &&
              ctaDetails?.cta_phone !== "NA" ? (
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text style={styles.HotelDetailsLeft}>{t("Phone")}</Text>
                  <Text style={styles.HotelDetailsRight}>
                    {": "}
                    {ctaDetails?.cta_phone}
                  </Text>
                </View>
              ) : null
            ) : invoice?.customer_phone.length > 0 ||
              billToPref === "COMPANY" ? (
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={styles.HotelDetailsLeft}>{t("Phone")}</Text>
                <Text style={styles.HotelDetailsRight}>
                  {": "}
                  {billToPref === "COMPANY"
                    ? custCompanyDetails[0]?.company_phone
                    : invoice?.customer_phone}
                </Text>
              </View>
            ) : null}

            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}>{t("Email")}</Text>
              {invoice?.segment === "DIRECT_BILLING" ? (
                ctaDetails?.cta_email.length > 0 ? (
                  <Text style={styles.HotelDetailsRight}>
                    {": "}
                    {ctaDetails?.cta_email}
                  </Text>
                ) : null
              ) : invoice?.customer_email.length > 0 ||
                billToPref === "COMPANY" ? (
                <Text style={styles.HotelDetailsRight}>
                  {": "}
                  {billToPref === "COMPANY"
                    ? custCompanyDetails[0]?.company_email
                    : invoice?.customer_email}
                </Text>
              ) : null}
            </View>

            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}>
                {t("Bill Instruction")}
              </Text>
              <Text style={styles.HotelDetailsRight}>{": "}</Text>
            </View>
            {/* <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}>IRN</Text>
              <Text style={styles.HotelDetailsRight}> {": "}Plot value</Text>
            </View> */}
          </View>

          {/* //---------------------Right side 40 %---------------------------------------------------- */}
          <View style={{ width: "40%", borderLeft: 1 }}>
            <View style={{ marginBottom: 3 }}></View>
            {customDetails &&
            (Number(summaryData?.payment_made) > 0 ||
              handleGetBillToCta(invoice, summaryData) > 0) &&
            handleCustomBookingId(invoice, customDetails).trim().length > 0 ? (
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={styles.HotelDetailsLeft}> {t("Invoice No.")}</Text>
                <Text style={styles.HotelDetailsRight}>
                  {": "}
                  {handleCustomBookingId(invoice, customDetails)}
                </Text>
              </View>
            ) : null}
            {customDetails?.invoice_date_rule !== "DISABLED" && (
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={styles.HotelDetailsLeft}>
                  {t("Invoice Date")}{" "}
                </Text>
                <Text style={styles.HotelDetailsRight}>
                  {": "}
                  {customDetails?.invoice_date_rule === "SHOW_CURRENT_DATE"
                    ? moment().format(`MMM DD YYYY`)
                    : moment(invoice?.actual_checkout).format("MMM DD YYYY")}
                </Text>
              </View>
            )}
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}> {t("Arrival Date")} </Text>
              <Text style={styles.HotelDetailsRight}>
                {": "}
                {formatDate(invoice?.actual_checkin)}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}>
                {t("Departure Date")}{" "}
              </Text>
              <Text style={styles.HotelDetailsRight}>
                {": "}
                {formatDate(invoice?.actual_checkout)}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}>{t("Pax")}</Text>
              <Text style={styles.HotelDetailsRight}>
                {": "}
                {invoice?.adults
                  .split(",")
                  .reduce((acc, cur) => acc + Number(cur), 0) +
                  invoice?.children
                    .split(",")
                    .reduce((acc, cur) => acc + Number(cur), 0)}{" "}
                <span style={styles.boldFontFamily}>
                  {"     "}
                  {t("Adult:")}
                </span>{" "}
                {invoice?.adults
                  .split(",")
                  .reduce((acc, cur) => acc + Number(cur), 0)}{" "}
                <span style={styles.boldFontFamily}>
                  {"     "} {t("Child:")}
                </span>{" "}
                {invoice?.children
                  .split(",")
                  .reduce((acc, cur) => acc + Number(cur), 0)}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}>{t("Nights.")} </Text>
              <Text style={styles.HotelDetailsRight}>
                {": "}
                {invoice?.num_nights}
              </Text>
              {/* <Text style={styles.HotelDetailsRight}>
                {": "}
                {invoice.hasOwnProperty("confirmed_room_ids_list")
                  ? invoice?.confirmed_room_ids_list.join(",")
                  : invoice?.roomids}
                {"     "}
                <span style={styles.boldFontFamily}>{"     "}Nights:</span>
                {": "}
                {invoice?.num_nights}
              </Text> */}
            </View>
            {/* <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}>Rate plan(s)</Text>
              <Text style={styles.HotelDetailsRight}>
                {": "}
                {getMapForTheRatePlans(invoice?.rate_plan_names)}
              </Text>
            </View> */}
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}>{t("Source")}</Text>
              <Text style={styles.HotelDetailsRight}>
                {": "}
                {invoice?.booking_source_displayname}
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={{ marginTop: 12, marginBottom: 12 }}></Text>
              <Text style={{ marginTop: 12, marginBottom: 12 }}></Text>
            </View>
            {/* <View style={{ display: "flex", flexDirection: "row" }}>
              <Text style={styles.HotelDetailsLeft}></Text>
              <Text style={styles.HotelDetailsRight}>{": "}</Text>
            </View> */}
          </View>
        </View>
        {/* --------------------------------Table header -------------------------------------*/}

        <View style={styles.tableRow}>
          {tableHeader?.map((elm) => (
            <Text style={[styles.tableCell, styles.headingText]}>{elm}</Text>
          ))}
        </View>
        {/* --------------Table rows------------ */}
        <View style={{ border: 0.4 }}>
          <View style={[styles.tableRowUnlined, { height: 2 }]}></View>
          {rowItems.map((row) =>
            row?.service_id === "grandTotal" ? (
              <View style={styles.tableRowUnlined}>
                {currencyArr.includes(String(symbol)) &&
                  folioConfig?.display_hsn_code_invoice === "YES" && (
                    <Text style={[styles.tableCell, styles.bodyText]}></Text>
                  )}
                <Text style={[styles.tableCell, styles.bodyText]}></Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.bodyText,
                    styles.boldFontFamily,
                  ]}
                >
                  {t("Grand Total")}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.bodyText,
                    styles.boldFontFamily,
                  ]}
                >
                  {}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.bodyText,
                    styles.boldFontFamily,
                  ]}
                >
                  {hotelCurrency === hotelConvertedCurrency
                    ? symbol +
                      "" +
                      handleDigits(Math.abs(row.service_total_debit))
                    : symbol +
                      "" +
                      handleDigits(
                        Math.abs(row.service_total_debit) * convertedAmount
                      )}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.bodyText,
                    styles.boldFontFamily,
                  ]}
                >
                  {hotelCurrency === hotelConvertedCurrency
                    ? symbol +
                      "" +
                      handleDigits(Math.abs(row.service_total_credit))
                    : symbol +
                      "" +
                      handleDigits(
                        Math.abs(row.service_total_credit) * convertedAmount
                      )}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.bodyText,
                    styles.boldFontFamily,
                  ]}
                >
                  {hotelCurrency === hotelConvertedCurrency
                    ? symbol + "" + handleDigits(Math.abs(row.balance_due))
                    : symbol +
                      "" +
                      handleDigits(Math.abs(row.balance_due) * convertedAmount)}
                </Text>

                {/* <Text style={[styles.tableCell, styles.bodyText]}>{""}</Text> */}
              </View>
            ) : (
              <>
                {/* ---------Room Tarrife Row--------------------- */}
                <View style={styles.tableRowUnlined}>
                  <Text style={[styles.tableCell, styles.bodyText]}>
                    {row.service_date}
                  </Text>
                  {currencyArr.includes(String(symbol)) &&
                    folioConfig?.display_hsn_code_invoice === "YES" && (
                      <Text style={[styles.tableCell, styles.bodyText]}>
                        {row.hsn_code}
                      </Text>
                    )}
                  <Text style={[styles.tableCell, styles.bodyText]}>
                    {/* {row?.line_item_type} */}
                    {row?.service_desc.split(",")[0]},
                    {row?.service_desc.split(",")[1] &&
                      row?.service_desc.split(",")[1]?.split(":")[1]}
                  </Text>
                  <Text style={[styles.tableCell, styles.bodyText]}>
                    {row?.service_desc.split(",")[3]?.split(":")[1] || ""}
                  </Text>
                  <Text style={[styles.tableCell, styles.bodyText]}>
                    {row?.service_amount_type === "DEBIT" &&
                    hotelCurrency === hotelConvertedCurrency
                      ? symbol + "" + handleDigits(Math.abs(row.service_amount))
                      : symbol +
                        "" +
                        handleDigits(
                          Math.abs(row.service_amount) * convertedAmount
                        )}
                  </Text>
                  <Text style={[styles.tableCell, styles.bodyText]}>
                    {row?.service_amount_type === "CREDIT" &&
                    hotelCurrency === hotelConvertedCurrency
                      ? symbol + "" + handleDigits(Math.abs(row.service_amount))
                      : symbol +
                        "" +
                        handleDigits(
                          Math.abs(row.service_amount) * convertedAmount
                        )}
                  </Text>

                  <Text style={[styles.tableCell, styles.bodyText]}>{""}</Text>
                </View>
                {/* -----------------tax Row------------------ */}
                {row?.service_tax !== "-" &&
                ((igst?.status === "ENABLED" &&
                  customerState !== "NA" &&
                  customerState !== propertyState) ||
                  dbigst?.status === "ENABLED") ? (
                  //-----------------------IGST-----------------------------------------
                  <View style={styles.tableRowUnlined}>
                    <Text style={[styles.tableCell, styles.bodyText]}>
                      {row.service_date}
                    </Text>
                    {currencyArr.includes(String(symbol)) &&
                      folioConfig?.display_hsn_code_invoice === "YES" && (
                        <Text
                          style={[styles.tableCell, styles.bodyText]}
                        ></Text>
                      )}
                    <Text
                      style={[
                        styles.tableCell,
                        styles.bodyText,
                        row.service_desc === "Grand Total" &&
                          styles.boldFontFamily,
                      ]}
                    >
                      {t("IGST")} (
                      {!isNaN(taxPercentageFormatter(row)) &&
                        Math.round(taxPercentageFormatter(row)).toFixed(2) +
                          "%"}
                      )
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.bodyText,
                        row.service_desc === "Grand Total" &&
                          styles.boldFontFamily,
                      ]}
                    >
                      {}
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.bodyText,
                        row.service_desc === "Grand Total" &&
                          styles.boldFontFamily,
                      ]}
                    >
                      {row?.service_amount_type === "DEBIT" &&
                      hotelCurrency === hotelConvertedCurrency
                        ? symbol + "" + handleDigits(Math.abs(row.service_tax))
                        : symbol +
                          "" +
                          handleDigits(
                            Math.abs(row.service_tax) * convertedAmount
                          )}
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.bodyText,
                        row.service_desc === "Grand Total" &&
                          styles.boldFontFamily,
                      ]}
                    >
                      {row?.service_amount_type === "CREDIT" &&
                      hotelCurrency === hotelConvertedCurrency
                        ? symbol + "" + handleDigits(Math.abs(row.service_tax))
                        : symbol +
                          "" +
                          handleDigits(
                            Math.abs(row.service_tax) * convertedAmount
                          )}
                    </Text>
                    <Text style={[styles.tableCell, styles.bodyText]}>{}</Text>
                  </View>
                ) : (
                  row?.service_tax !== "-" && (
                    //---------------------CGST, SGST----------------------------
                    <>
                      <View style={styles.tableRowUnlined}>
                        <Text style={[styles.tableCell, styles.bodyText]}>
                          {row.service_date}
                        </Text>
                        {currencyArr.includes(String(symbol)) &&
                          folioConfig?.display_hsn_code_invoice === "YES" && (
                            <Text
                              style={[styles.tableCell, styles.bodyText]}
                            ></Text>
                          )}
                        <Text
                          style={[
                            styles.tableCell,
                            styles.bodyText,
                            row.service_desc === "Grand Total" &&
                              styles.boldFontFamily,
                          ]}
                        >
                          {t("CGST")} (
                          {!isNaN(taxPercentageFormatter(row)) &&
                            Math.round(taxPercentageFormatter(row) / 2).toFixed(
                              2
                            ) + "%"}
                          )
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            styles.bodyText,
                            row.service_desc === "Grand Total" &&
                              styles.boldFontFamily,
                          ]}
                        >
                          {}
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            styles.bodyText,
                            row.service_desc === "Grand Total" &&
                              styles.boldFontFamily,
                          ]}
                        >
                          {row?.service_amount_type === "DEBIT" &&
                          hotelCurrency === hotelConvertedCurrency
                            ? symbol +
                              "" +
                              handleDigits(Math.abs(row.service_tax) / 2)
                            : symbol +
                              "" +
                              handleDigits(
                                (Math.abs(row.service_tax) / 2) *
                                  convertedAmount
                              )}
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            styles.bodyText,
                            row.service_desc === "Grand Total" &&
                              styles.boldFontFamily,
                          ]}
                        >
                          {row?.service_amount_type === "CREDIT" &&
                          hotelCurrency === hotelConvertedCurrency
                            ? symbol +
                              "" +
                              handleDigits(Math.abs(row.service_tax) / 2)
                            : symbol +
                              "" +
                              handleDigits(
                                (Math.abs(row.service_tax) / 2) *
                                  convertedAmount
                              )}
                        </Text>
                        <Text style={[styles.tableCell, styles.bodyText]}>
                          {}
                        </Text>
                      </View>

                      <View style={styles.tableRowUnlined}>
                        <Text
                          style={[
                            styles.tableCell,
                            styles.bodyText,
                            row.service_desc === "Grand Total" &&
                              styles.boldFontFamily,
                          ]}
                        >
                          {row.service_date}
                        </Text>
                        {currencyArr.includes(String(symbol)) &&
                          folioConfig?.display_hsn_code_invoice === "YES" && (
                            <Text
                              style={[
                                styles.tableCell,
                                styles.bodyText,
                                row.service_desc === "Grand Total" &&
                                  styles.boldFontFamily,
                              ]}
                            ></Text>
                          )}
                        <Text
                          style={[
                            styles.tableCell,
                            styles.bodyText,
                            row.service_desc === "Grand Total" &&
                              styles.boldFontFamily,
                          ]}
                        >
                          {unionTerritories?.includes(propertyState) &&
                          utgst?.status === "ENABLED"
                            ? "UTGST"
                            : "SGST"}{" "}
                          (
                          {!isNaN(taxPercentageFormatter(row)) &&
                            Math.round(taxPercentageFormatter(row) / 2).toFixed(
                              2
                            ) + "%"}
                          )
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            styles.bodyText,
                            row.service_desc === "Grand Total" &&
                              styles.boldFontFamily,
                          ]}
                        >
                          {}
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            styles.bodyText,
                            row.service_desc === "Grand Total" &&
                              styles.boldFontFamily,
                          ]}
                        >
                          {row?.service_amount_type === "DEBIT" &&
                          hotelCurrency === hotelConvertedCurrency
                            ? symbol +
                              "" +
                              handleDigits(Math.abs(row.service_tax) / 2)
                            : symbol +
                              "" +
                              handleDigits(
                                (Math.abs(row.service_tax) / 2) *
                                  convertedAmount
                              )}
                        </Text>
                        <Text
                          style={[
                            styles.tableCell,
                            styles.bodyText,
                            row.service_desc === "Grand Total" &&
                              styles.boldFontFamily,
                          ]}
                        >
                          {row?.service_amount_type === "CREDIT" &&
                          hotelCurrency === hotelConvertedCurrency
                            ? symbol +
                              "" +
                              handleDigits(Math.abs(row.service_tax) / 2)
                            : symbol +
                              "" +
                              handleDigits(
                                (Math.abs(row.service_tax) / 2) *
                                  convertedAmount
                              )}
                        </Text>
                        <Text style={[styles.tableCell, styles.bodyText]}>
                          {}
                        </Text>
                      </View>
                    </>
                  )
                )}
                {/* //------------Day Total---------------------------------- */}
                <View style={styles.tableRowUnlined}>
                  {currencyArr.includes(String(symbol)) &&
                    folioConfig?.display_hsn_code_invoice === "YES" && (
                      <Text
                        style={[
                          styles.tableCell,
                          styles.bodyText,
                          { fontFamily: "Helvetica-Bold" },
                        ]}
                      >
                        {}
                      </Text>
                    )}
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bodyText,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bodyText,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    {t("Day Total")}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bodyText,
                      row.service_desc === "Grand Total" &&
                        styles.boldFontFamily,
                    ]}
                  >
                    {}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bodyText,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    {row?.service_amount_type === "DEBIT" &&
                    hotelCurrency === hotelConvertedCurrency
                      ? symbol + "" + handleDigits(Math.abs(row.service_total))
                      : symbol +
                        "" +
                        handleDigits(
                          Math.abs(row.service_total) * convertedAmount
                        )}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bodyText,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    {row?.service_amount_type === "CREDIT" &&
                    hotelCurrency === hotelConvertedCurrency
                      ? symbol + "" + handleDigits(Math.abs(row.service_total))
                      : symbol +
                        "" +
                        handleDigits(
                          Math.abs(row.service_total) * convertedAmount
                        )}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.bodyText,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    {hotelCurrency === hotelConvertedCurrency
                      ? symbol + "" + handleDigits(Math.abs(row.service_total))
                      : symbol +
                        "" +
                        handleDigits(
                          Math.abs(row.service_total) * convertedAmount
                        )}
                  </Text>
                </View>

                <View style={[styles.tableRowUnlined, { height: 5 }]}></View>
              </>
            )
          )}
        </View>

        {/* //----------------------------Footer row---------------------- */}
        <View
          style={[
            styles.tableRow,
            {
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            },
          ]}
        >
          <Text
            style={[
              styles.tableCell,
              {
                width: "70%",
                textAlign: "left",
                fontSize: 9,
                fontWeight: "heavy",
                height: 70,
                marginLeft: 4,
                marginTop: 2,
              },
            ]}
          >
            {t(
              "WE HOPE YOU ENJOYED YOUR STAY AND WOULD LIKE TO WELCOME YOU BACK"
            )}
          </Text>
          <Text
            style={[
              styles.tableCell,
              {
                width: "28%",
                textAlign: "right",
                fontSize: 9,
                fontWeight: "heavy",
                marginRight: 4,
                marginTop: 2,
              },
            ]}
          >
            {t("Please Deposit Your ROOM KEY")}
          </Text>
          <Text
            style={[
              styles.tableCell,
              {
                width: "70%",
                textAlign: "left",
                fontSize: 9,
                fontWeight: "heavy",
                marginLeft: 4,
                marginBottom: 2,
              },
            ]}
          >
            {t("Cashier Signature")}
          </Text>
          <Text
            style={[
              styles.tableCell,
              {
                width: "28%",
                textAlign: "right",
                fontSize: 9,
                fontWeight: "heavy",
                marginRight: 4,
                marginBottom: 2,
              },
            ]}
          >
            {t("Guest Signature")}
          </Text>
        </View>
      </View>
      <View style={{ width: "100%" }}>
        <Text style={[styles.footerText]}>
          {t("Page")} {correntPage} / {totalPage}
        </Text>
      </View>
    </View>
  );
}

export default TaxInvoicePage;

//----------------------------------------------CSIs-------------------------------------

const styles = StyleSheet.create({
  table: {
    marginTop: 10,
    display: "table",
    width: "100%",
    borderStyle: "solid",
    // borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    flexDirection: "row",
    borderStyle: "solid",
    borderWidth: 0.4,
    borderColor: "#000",
    margin: -0.2,
  },
  tableRowUnlined: {
    flexDirection: "row",
    borderStyle: "solid",
    // borderWidth: 0.4,
    // borderColor: "#000",
    marginBottom: 5,
  },
  tableCell: {
    width: "25%",
    alignItems: "center",
    justifyContent: "center",
  },

  cellText: { textAlign: "center", fontSize: "9px" },
  headingText: {
    textAlign: "center",
    fontSize: "9px",
    fontFamily: "Helvetica-Bold",
    marginTop: 3,
    marginBottom: 3,
  },
  bodyText: {
    textAlign: "center",
    fontSize: "9px",
    marginTop: 2,
    marginBottom: 2,
  },
  footerText: {
    textAlign: "left",
    fontSize: "9px",
    marginTop: 2,
  },

  HeaderLeft: {
    width: "8%",
    fontSize: "9px",
    marginTop: 2,
    marginBottom: 2,
    fontFamily: "Helvetica-Bold",
  },
  HeaderRight: {
    width: "92%",
    fontSize: "9px",
    marginTop: 2,
    marginBottom: 2,
  },
  HotelDetailsLeft: {
    width: "35%",
    fontSize: "9px",
    marginLeft: 4,
    marginBottom: 2,
    marginTop: 2,
    fontFamily: "Helvetica-Bold",
  },
  HotelDetailsRight: {
    width: "65%",
    fontSize: "9px",
    marginRight: 4,
    marginBottom: 2,
    marginTop: 2,
  },
  boldFontFamily: {
    fontFamily: "Helvetica-Bold",
  },
});

//-------------------------Functions------------------------------------------------

const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

const handleGetBillToCta = (invoice, summaryData) => {
  let adjustedAmt = 0;
  let finalAmt = 0;
  if (invoice?.cta_billed_items.length > 0) {
    const data = invoice.cta_billed_items?.forEach(
      (item) => (adjustedAmt += item?.refund_amount)
    );
    finalAmt = Number(summaryData?.bill_to_cta_amt) - Number(adjustedAmt);
  } else {
    finalAmt = 0;
  }
  return parseFloat(finalAmt).toFixed(2);
};

const handleCustomBookingId = (invoice, customFolioConfig) => {
  if (
    customFolioConfig?.generate_cust_invoice_cancel_noshow === "YES" &&
    (invoice?.status === "NO_SHOW" || invoice?.status === "CANCELLED")
  ) {
    return String(invoice?.custom_invoice_id);
  }
  if (invoice?.status === "CHECKED_OUT") {
    return String(invoice?.custom_invoice_id);
  } else {
    return "";
  }
};
const handleCustomerAddress = (address) => {
  let formattedAddress = "";

  Object.values(address).map(function (add) {
    if (validateAddress(add)) {
      formattedAddress += add + ", ";
    }
  });

  const lastCommaIndex = formattedAddress.lastIndexOf(", ");
  const newFormattedAddress = formattedAddress.substring(0, lastCommaIndex);
  return newFormattedAddress;
};
const formatDate = (date) => {
  return moment(date).format("MMM DD YYYY hh:mm A");
};

// rounding of the digits
function handleDigits(num) {
  let formattedNum = parseFloat(num);
  if (isNaN(formattedNum)) {
    return 0.0;
  } else {
    return Math.abs(formattedNum).toFixed(2);
  }
}

const taxPercentageFormatter = (item) => {
  let taxPercent = 0;
  if (Math.abs(item?.service_amount) != 0) {
    taxPercent =
      Number(Math.abs(item?.service_tax)) /
      Number(Math.abs(item?.service_amount));
    taxPercent = taxPercent * 100;
  } else {
    taxPercent = 0;
  }
  return Number(taxPercent).toFixed(2);
};
