import React from "react";
import { Button } from "react-bootstrap";

const AirbnbConnect = () => {
  return (
    <div style={{ marginLeft: "2rem" }}>
      <span
        className="airbnb-connect btn btn-block btn-large btn-success"
        data-client-id="40qx7vz9ylf1b2htzilwu1ek"
        data-scope="property_management,messages_read,messages_write"
        data-redirect-uri="https://cmapi.stayflexi.com/cm/api/airbnb/redirect/"
      >
        Connect with aribnb
      </span>
    </div>
  );
};

export default AirbnbConnect;
