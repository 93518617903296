import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import FolioLog from "./FolioLog";
import { useTranslation } from "react-i18next";
import i18n from "../../modules/Auth/pages/i18n";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

function FolioLogTable({
  folioLogData,
  bookingId,
  invoiceData,
  hotelLogo,
  selectedLanguage,
}) {
  // getting the services data for the invoice

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const { SearchBar } = Search;

  const [data, setData] = useState(null);
  useEffect(() => {
    const products = folioLogData.invoice.folio_log_data;
    // const updatedStr = `, Room Id : ${invoiceData.invoice.roomids}`;
    // products.map((item) => {
    //   item.log_text = item.log_text + updatedStr;
    // });

    return setData(products);
  }, [bookingId]);
  // {invoiceData.invoice.roomids}

  const columns = [
    {
      dataField: "id",
      text: `${t("Id")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
        width: "200px",
      },
      hidden: true,
    },
    {
      dataField: "log_timestamp",
      text: `${t("Log time")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
        width: "250px",
      },
      editable: false,
      formatter: (cell) => {
        return moment(cell).format("MMM DD, YYYY hh:mm A");
      },
    },
    {
      dataField: "user_email",
      text: `${t("User")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
        whiteSpace: "nowrap",
        width: "425px",
      },
      editable: false,
    },
    {
      dataField: "log_text",
      text: `${t("Logged action")}`,
      sort: true,
      headerStyle: {
        fontSize: "15px",
      },
      editable: false,
    },
  ];

  return (
    <>
      <div className="folioLogTable">
        {data && (
          <ToolkitProvider data={data} columns={columns} keyField="id" search>
            {(props) => (
              <>
                <div className="filtersWrapper">
                  <div className="searchWrapper">
                    <SearchBar
                      {...props.searchProps}
                      className="folioActionSearchBar"
                    />
                  </div>
                  <div className="printFolio">
                    <PDFDownloadLink
                      document={
                        <Document title={bookingId}>
                          <Page size="A4" style={styles.page} wrap>
                            <FolioLog
                              invoice={invoiceData.invoice}
                              //   symbol={hotelCurrency}
                              hotelData={invoiceData.hotel}
                              logs={folioLogData}
                              hotelLogo={hotelLogo}
                            />
                          </Page>
                        </Document>
                      }
                      fileName={`${bookingId}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <p style={{ fontSize: "0.8rem" }}>
                            {t("Loading document...")}
                          </p>
                        ) : (
                          <a
                            href={url}
                            style={{ fontSize: "0.8rem" }}
                            rel={"noreferrer"}
                            target="_blank"
                          >
                            {window.screen.width > 416 && (
                              <button className="printFolioLogBtn">
                                {t("Print folio log")}
                              </button>
                            )}
                          </a>
                        )
                      }
                    </PDFDownloadLink>
                  </div>
                </div>
                <div className="folioLogs">
                  <BootstrapTable
                    {...props.baseProps}
                    tabIndexCell
                    headerWrapperClasses="foo"
                    bodyClasses="tableBody"
                    bodyStyle={{ background: "#00ff00" }}
                    bordered={false}
                    pagination={paginationFactory()}
                  />
                </div>
              </>
            )}
          </ToolkitProvider>
        )}
      </div>
    </>
  );
}

export default FolioLogTable;
