import moment from "moment";
// import { check } from "prettier";
import { dateFormat } from "../constants/Calendar-constants";

export class NewReservationModel {
  roomTypeId = "";
  roomNumber = "";
  userBookingInfo = {};
  userEmail = "";
  checkin = "";
  checkout = "";
  actualCheckin = "";
  actualCheckout = "";
  bookingAmount = 0;
  bookingDiscount = 0;
  bookingSavings = 0;
  hotelId = "";
  ratePlanId = "";
  bookingSource = "STAYFLEXI_HD";
  refund = 0;
  taxPercentage = 0;
  externalPayment = 0;
  externalPaymentCard = 0;
  username = "";
  userPhone = "";
  adults = 0;
  children = 0;
  reservationInfo = {};
  booking_id = "";
  timestamps = null;
  rooms_requested = {
    12353: 1,
  };
  checkin = "";
  checkout = "";
  type = "Guest";
  all_reservation_room_info = {
    12353: [
      {
        adults: 4,
        children: 0,
        amount: 36000,
      },
    ],
  };
  message_source = "STAYFLEXI_HD";
  message_type = null;
  is_external = false;
  is_enquiry = false;
  zipCode = "";
  state = "";
  country = "";
  address = "";
  address1 = "";
  city = "";
  taxNames = [];
  taxId = "";
  durationType = "";
  expiryTime = "";
  expiryType = "";
  isEnquiry = "";
  govtIdType = "";
  govtIdValue = "";
  frontData = "";
  frontType = "";
  backData = "";
  backType = "";
  specialRequests = "";
  addons = [];
  appliedPromocode = "";
  segment = "";
  promoAmount = 0;
  dateOfBirth = "";
  gender = "";
  nationality = "";
  occupation = "";
  companyName = "";
  purposeOfVisit = "";
  programsAttended = "";
  eventType = "";
  emergency_contact = "";
  emergency_contact_name = "";
  customer_eta = "";
  customer_etd = "";
  ocoCheckinComplete = "";
  approvedByOverseas = "";
  otherGuestDetails = [];
  requestorName = "";
  requestorContact = "";
  requestorDept = "";
  requestorApprover = "";
  requestorRemarks = "";
  isComplimentary = "false";
  prpList = [];
  bookingReferenceId = "";
  companyGstNumber = "";
  is_hourly_booking = "false";
  ratePlanName = "";
  sendEmail = true;

  constructor(data) {
    this.hotelId = data.hotelId;
    this.userEmail = data.email;
    this.checkin = moment(data.actual_start).format(dateFormat);
    this.checkout = moment(data.actual_end).format(dateFormat);
    this.actualCheckin = moment(data.actual_start).format(dateFormat);
    this.actualCheckout = moment(data.actual_end).format(dateFormat);
    this.bookingAmount = data.bookingAmount;
    this.username = data.title;
    this.userPhone = data.phoneNumber;
    this.adults = data.guests.Adults;
    this.children = data.guests.Children;
    this.roomNumber = data.roomNumber;
    this.roomTypeId = data.roomTypeId;
    this.ratePlanId = data.ratePlanId;
    this.state = data.state;
    this.city = data.city;
    this.country = data.country;
    this.address = data.address1;
    this.address1 = data.address2;
    this.zipCode = data.zipcode;
    this.taxId = data.taxId;
    this.taxNames = data.taxNames;
    this.durationType = data.durationType;
    this.expiryTime = data.expiryTime;
    this.expiryType = data.expiryType;
    this.isEnquiry = data.isEnquiry;
    this.govtIdType = data.govtIdType;
    this.govtIdValue = data.govtIdValue;
    this.bookingSource = data.bookingSource;
    this.message_source = data.bookingSource;
    this.frontData = data.frontData;
    this.frontType = data.frontType;
    this.backData = data.backData;
    this.backType = data.backType;
    this.specialRequests = data.specialRequests;
    this.addons = data.addons;
    this.appliedPromocode = data.appliedPromocode;
    this.segment = data.segment;
    this.promoAmount = data.promoAmount;
    this.dateOfBirth = data.dateOfBirth;
    this.gender = data.gender;
    this.nationality = data.nationality;
    this.occupation = data.occupation;
    this.companyName = data.companyName;
    this.purposeOfVisit = data.purposeOfVisit;
    this.programsAttended = data.programsAttended;
    this.otherGuestDetails = data.otherGuestDetails;
    this.emergency_contact = data.emergency_contact;
    this.eventType = data.eventType;
    this.emergency_contact_name = data.emergency_contact_name;
    this.customer_eta = moment(data.customer_eta).format(dateFormat);
    this.customer_etd = moment(data.customer_etd).format(dateFormat);
    this.ocoCheckinComplete = data.ocoCheckinComplete;
    this.approvedByOverseas = data.approvedByOverseas;
    this.requestorName = data.requestorName;
    this.requestorContact = data.requestorContact;
    this.requestorDept = data.requestorDept;
    this.requestorApprover = data.requestorApprover;
    this.requestorRemarks = data.requestorRemarks; //will be added in case needed for future
    this.isComplimentary = data.isComplimentary;
    this.prpList = data.prpList;
    this.bookingReferenceId = data.bookingReferenceId;
    this.companyGstNumber = data.companyGstNumber;
    this.is_hourly_booking = data.is_hourly_booking;
    this.ratePlanName = data.ratePlanName;
    this.sendEmail = data.sendEmail;
  }

  getNewBookingParams = () => {
    const roomNumber = this.roomNumber;
    let rooms_requested_obj = {};
    rooms_requested_obj[`${this.roomTypeId}-${this.ratePlanId}`] = [
      {
        adults: this.adults,
        children: this.children,
        amount: this.bookingAmount,
        ratePlanName: this.ratePlanName,
      },
    ];
    let rooms = {};
    rooms[`${this.roomTypeId}-${this.ratePlanId}`] = 1;
    let physical_rooms = {};
    physical_rooms[`${this.roomTypeId}-${this.ratePlanId}`] = [roomNumber];

    const resp = {
      userBookingInfo: {
        id: this.booking_id,
        userEmail: this.userEmail,
        checkin: this.checkin,
        checkout: this.checkout,
        actualCheckin: this.checkin,
        actualCheckout: this.checkout,
        bookingAmount: this.bookingAmount,
        bookingDiscount: 0,
        bookingSavings: 0,
        hotelId: this.hotelId,
        ratePlanId: this.ratePlanId,
        bookingSource: this.bookingSource,
        refund: 0,
        taxPercentage: 0,
        externalPayment: 0,
        externalPaymentCard: 0,
        username: this.username,
        userPhone: this.userPhone,
        specialRequests: this.specialRequests,
        appliedPromocode: this.appliedPromocode,
        segment: this.segment,
        promoAmount: this.promoAmount,
        bookingReferenceId: this.bookingReferenceId,
        companyGstNumber: this.companyGstNumber,
        taxExemptId: this.taxId,
        sendEmail: this.sendEmail,
      },
      userId: {
        frontData: this.frontData,
        frontType: this.frontType,
        backData: this.backData,
        backType: this.backType,
      },
      admin_reservation_info: {
        newReservationInfo: {
          booking_id: this.booking_id,
          hotel_id: this.hotelId,
          timestamps: null,
          rooms_requested: rooms,
          checkin: this.checkin,
          checkout: this.checkout,
          type: "Guest",
          all_reservation_room_info: rooms_requested_obj,
          message_source: this.message_source,
          message_type: null,
        },
        physicalRoomIdsMap: physical_rooms,
      },
      is_external: false,
      is_enquiry: this.isEnquiry,
      expiry_time: this.expiryTime,
      expiry_type: this.expiryType,
      duration_type: this.durationType,
      addons: this.addons,
      is_hourly_booking: this.is_hourly_booking,

      userDetails: {
        userName: this.username,
        phoneNumber: this.userPhone,
        email: this.userEmail,
        zipCode: this.zipCode,
        address: this.address,
        city: this.city,
        state: this.state,
        country: this.country,
        address1: this.address1,
        govtIdType: this.govtIdType,
        govtIdValue: this.govtIdValue,
        dateOfBirth: this.dateOfBirth,
        gender: this.gender,
        nationality: this.nationality,
        occupation: this.occupation,
        emergency_contact: this.emergency_contact,
        emergency_contact_name: this.emergency_contact_name,
      },
      miscDetails: {
        companyName: this.companyName,
        programsAttended: this.programsAttended,
        purposeOfVisit: this.purposeOfVisit,
        customerETA: this.customer_eta,
        customerETD: this.customer_etd,
        ocoCheckinComplete: this.ocoCheckinComplete,
        approvedByOverseas: this.approvedByOverseas,
        isComplimentary: this.isComplimentary,
        compRequestName: this.requestorName,
        compRequestContact: this.requestorContact,
        compRequestDepartment: this.requestorDept,
        compRequestApprover: this.requestorApprover,
        compRemarks: this.requestorRemarks,
        eventType: this.eventType,
      },
      otherGuestDetails: this.otherGuestDetails,
      exempt_tax_names: this.taxNames,
      taxId: this.taxId,
      prpList: this.prpList,
    };
    return resp;
  };
}
