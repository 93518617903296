import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import GroupReportsWrapper from "./GroupReportsWrapper.js";
import {
  filterParams,
  cellRenderer,
  generateOriginalValue,
  handleNumDisplay,
} from "../Reports/utils/ReportUtils";
import moment from "moment";
import i18n from "../../modules/Auth/pages/i18n.js";
import { useTranslation } from "react-i18next";

function GroupRoomRevenueReport(props) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  // getting the hotel id from the params
  //   const url = new URL(window.location.href);
  //   const hotelId = url.searchParams.get("hotelId");
  //   const hotelCurrency = data.accessControl.hotel.hotel_currency;

  // default hotel currency
  const hotelCurrency = props.data.hotelCurrency;
  // column definitions

  // defining the column headers, etc.
  const columns = [
    {
      field: "hotelId",
      headerName: `${t("Hotel Id")}`,
      hide: false,
      dataType: "varchar",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "hotelName",
      headerName: `${t("Hotel Name")}`,
      hide: false,
      dataType: "varchar",
    },
    {
      field: "bookingId",
      headerName: `${t("Booking ID")}`,
      // cellRendererFramework: (params) => cellRenderer(params),
      dataType: "varchar",
    },
    {
      field: "email",
      headerName: `${t("Email")}`,
      dataType: "varchar",
      hide: true,
    },
    {
      field: "userName",
      headerName: `${t("User name")}`,
      dataType: "varchar",
      hide: false,
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      dataType: "varchar",
    },
    {
      field: "source",
      headerName: `${t("Source")}`,
      dataType: "varchar",
    },
    {
      field: "bookingAmount",
      headerName: `${t(`Booking Amount (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "bookingAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "checkIn",
      headerName: `${t("Checkin")}`,
      //filter: "agDateColumnFilter",
      filterParams: filterParams,
      dataType: "date",
    },
    {
      field: "checkOut",
      headerName: `${t("Checkout")}`,
      //filter: "agDateColumnFilter",
      filterParams: filterParams,
      dataType: "date",
    },
    {
      field: "bookingMadeOn",
      headerName: `${t("Booking Made On")}`,
      //filter: "agDateColumnFilter",
      filterParams: filterParams,
      dataType: "date",
    },
    {
      field: "refund",
      headerName: `${t(`Refund (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "refund"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "offlinePayment",
      headerName: `${t(`Offline Payment (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "offlinePayment"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "servicesAmount",
      headerName: `${t(`Services Amount (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "servicesAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "paymentExternal",
      headerName: `${t(`Payment External (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "paymentExternal"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "externalPaymentCard",
      headerName: `${t(`External Payment Card (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) =>
        generateOriginalValue(params, "externalPaymentCard"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "pgAmount",
      headerName: `${t(`Payment Gateway Amount (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "pgAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "totalAmtWithServices",
      headerName: `${t(`Total Amount With Services (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) =>
        generateOriginalValue(params, "totalAmtWithServices"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "taxesSplit",
      headerName: `${t(`Taxes split (${hotelCurrency})`)}`,
      dataType: "varchar",
    },
    {
      field: "totalTaxes",
      headerName: `${t(`Total Taxes (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "totalTaxes"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "partialExempt",
      headerName: `${t("Partial Exempt")}`,
      hide: true,
      dataType: "varchar",
    },
    {
      field: "totalExempt",
      headerName: `${t("Total Exempt")}`,
      hide: true,
      dataType: "varchar",
    },
    {
      field: "exemptCount",
      headerName: `${t("Exempt Count")}`,
      hide: true,
      aggFunc: "sum",
      dataType: "integer",
      valueGetter: (params) => generateOriginalValue(params, "exemptCount"),
      valueFormatter: (params) => handleNumDisplay(params, "integer"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "totalCount",
      headerName: `${t("Total Count")}`,
      hide: true,
      aggFunc: "sum",
      dataType: "integer",
      valueGetter: (params) => generateOriginalValue(params, "totalCount"),
      valueFormatter: (params) => handleNumDisplay(params, "integer"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "excessExempt",
      headerName: `${t("Excess Exempt")}`,
      hide: true,
      dataType: "varchar",
    },
    {
      field: "taxExemptRevenue",
      headerName: `${t(`Tax Exempt Revenue (${hotelCurrency})`)}`,
      dataType: "float",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
      valueGetter: (params) =>
        generateOriginalValue(params, "taxExemptRevenue"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
    },
    {
      field: "taxableRevenue",
      headerName: `${t(`Taxable Revenue (${hotelCurrency})`)}`,
      dataType: "float",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => generateOriginalValue(params, "taxableRevenue"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
    },
    {
      field: "balanceAmount",
      headerName: `${t(`Balance Amount (${hotelCurrency})`)}`,
      dataType: "float",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => generateOriginalValue(params, "balanceAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
    },
    {
      field: "roomRevenue",
      headerName: `${t(`Room Revenue (${hotelCurrency})`)}`,
      dataType: "float",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => generateOriginalValue(params, "roomRevenue"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
    },
    {
      field: "roomTaxes",
      headerName: `${t(`Room Taxes (${hotelCurrency})`)}`,
      dataType: "float",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => generateOriginalValue(params, "roomTaxes"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
    },
    {
      field: "taxPerc",
      headerName: `${t("Tax Percentage")}`,
      dataType: "float",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => generateOriginalValue(params, "taxPerc"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => "",
    },
  ];

  // Setting the pinned row
  const [pinnedRow, setPinnedRow] = useState(null);
  function createPinnedData(params, rowsData) {
    // let result = {
    //   bookingid_display: "Total",
    //   tax: 0,
    //   commission: 0,
    //   net_amount: 0,
    //   gross_amount: 0,
    //   tds: 0,
    // };

    let result = {};

    for (let i = 0; i < columns.length; i++) {
      if (["integer", "float"].includes(columns[i]["dataType"])) {
        result[columns[i]["field"]] = 0;
      }
    }

    // function updateVals(i) {
    //   result.tax += i.tax;
    //   result.commission += i.commission;
    //   result.net_amount += i.net_amount;
    //   result.gross_amount += i.gross_amount;
    //   result.tds = i.tds;
    // }

    function updateVals(i) {
      Object.keys(result).forEach((fields) => {
        result[fields] += i[`original_${fields}`];
      });
    }

    params.api.getSelectedRows().length === 0
      ? params.api.forEachNodeAfterFilter((row) => updateVals(row.data))
      : params.api.getSelectedRows().map((row) => updateVals(row));

    result["bookingId"] = "Total";

    setPinnedRow([result]);
    params.api.setPinnedBottomRowData([result]);
  }

  return (
    <GroupReportsWrapper
      reportName={t("Group Room Revenue Report")}
      columns={columns}
      reportActualType={"roomRevenue"}
      createPinnedData={createPinnedData}
      pinnedRow={pinnedRow}
      grpEmail={props.grpEmail}
      resetToDefault={props.resetToDefault}
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.hotels[0] };
}
export default connect(mapStateToProps)(GroupRoomRevenueReport);
