import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Button } from "@mui/material";
import { connect, shallowEqual, useSelector } from "react-redux";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

function GroupDayLevelPricing({
  dayLevelPricindData,
  setdayLevelTotalTaxAmount,
  setdayLevelPreTaxAmount,
  setdayLevelTotalTax,
  setisDaylevelPricingSet,
  onCancelClick,
  currency,
  isUnitLevel,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const [isFieldsEmpty, setIsFieldsEmpty] = useState(false);
  const [isUnitLevelFieldsEmpty, setIsUnitLevelFieldsEmpty] = useState(false);
  const [uniqueDayLevelPricindData, setuniqueDayLevelPricindData] = useState(
    []
  );
  const [columns, setcolumns] = useState([
    {
      dataField: "date",
      text: `${t("Date")}`,
      editable: false,
      formatter: (cell, row) => {
        return cell.split(" ")[0];
      },
    },
    {
      dataField: "amountBeforeTax",
      text: `${t("Amount Before Tax")} (${currency})`,
      formatter: (cell, row) => {
        return parseFloat(cell).toFixed(2);
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: `${t("Price should be numeric")}`,
          };
        }
        return true;
      },
      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: "500",
          textDecoration: "underline",
        };
      },
    },
    {
      dataField: "tax",
      text: `${t("Tax Amount")} (${currency})`,
      editable: false,
      formatter: (cell, row) => {
        return parseFloat(cell).toFixed(2);
      },
    },
    {
      dataField: "amountAfterTax",
      text: `${t("Total Amount With Tax")} (${currency})`,
      formatter: (cell, row) => {
        return parseFloat(cell).toFixed(2);
      },
      style: (cell, row, rowIndex, colIndex) => {
        return {
          fontWeight: "500",
          textDecoration: "underline",
        };
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: `${t("Price should be numeric")}`,
          };
        }
        return true;
      },
    },
  ]);

  useEffect(() => {
    setcolumns([
      {
        dataField: "date",
        text: `${t("Date")}`,
        editable: false,
        formatter: (cell, row) => {
          return cell.split(" ")[0];
        },
      },
      {
        dataField: "amountBeforeTax",
        text: `${t("Amount Before Tax")} (${currency})`,
        formatter: (cell, row) => {
          return parseFloat(cell).toFixed(2);
        },
        editable:
          JSON.parse(hotel?.accessControl?.user_feature_map?.EditBookingPrice)
            .read === "YES",
        validator: (newValue, row, column) => {
          if (isNaN(newValue)) {
            return {
              valid: false,
              message: `${t("Price should be numeric")}`,
            };
          }
          return true;
        },
        style: (cell, row, rowIndex, colIndex) => {
          return {
            fontWeight: "500",
            textDecoration: "underline",
          };
        },
      },
      {
        dataField: "tax",
        text: `${t("Tax Amount")} (${currency})`,
        editable: false,
        formatter: (cell, row) => {
          return parseFloat(cell).toFixed(2);
        },
      },
      {
        dataField: "amountAfterTax",
        text: `${t("Total Amount With Tax")} (${currency})`,
        formatter: (cell, row) => {
          return parseFloat(cell).toFixed(2);
        },
        editable:
          JSON.parse(hotel?.accessControl?.user_feature_map?.EditBookingPrice)
            .read === "YES",
        style: (cell, row, rowIndex, colIndex) => {
          return {
            fontWeight: "500",
            textDecoration: "underline",
          };
        },
        validator: (newValue, row, column) => {
          if (isNaN(newValue)) {
            return {
              valid: false,
              message: `${t("Price should be numeric")}`,
            };
          }
          return true;
        },
      },
    ]);
  }, [hotel]);

  useEffect(() => {
    // console.log("dayLevelPricindData", dayLevelPricindData);
    const dayLevelArr = [];
    const arr = [];
    dayLevelPricindData.map((item) => {
      if (!arr.includes(item?.roomTypeId)) {
        arr.push(item?.roomTypeId);
        dayLevelArr.push(item);
      }
    });
    setuniqueDayLevelPricindData(dayLevelArr);
  }, [dayLevelPricindData, isUnitLevel]);

  const getDetailsAmount = () => {
    let totalTax = 0;
    let amountWithTax = 0;
    let amountBeforeTax = 0;

    const datas = dayLevelPricindData?.map((item) => {
      const rope = item?.perDayPrice.map((res) => {
        amountWithTax =
          parseFloat(amountWithTax) + parseFloat(res.amountAfterTax);
        totalTax = parseFloat(totalTax) + parseFloat(res.tax);
        amountBeforeTax =
          parseFloat(amountBeforeTax) + parseFloat(res.amountBeforeTax);
      });
    });

    setdayLevelTotalTaxAmount(parseFloat(amountWithTax));
    setdayLevelPreTaxAmount(parseFloat(amountBeforeTax));
    setdayLevelTotalTax(parseFloat(totalTax));
  };

  const getUniqueDetailsAmount = () => {
    let totalTax = 0;
    let amountWithTax = 0;
    let amountBeforeTax = 0;

    const datas = dayLevelPricindData?.map((item) => {
      const unidata = uniqueDayLevelPricindData?.filter(
        (ell) => ell?.roomTypeId === item?.roomTypeId
      )[0];
      const rope = item?.perDayPrice.map((res) => {
        res.amountAfterTax = unidata?.perDayPrice[0]?.amountAfterTax;
        res.tax = unidata?.perDayPrice[0]?.tax;
        res.amountBeforeTax = unidata?.perDayPrice[0]?.amountBeforeTax;
        amountWithTax =
          parseFloat(amountWithTax) +
          parseFloat(unidata?.perDayPrice[0]?.amountAfterTax);
        totalTax =
          parseFloat(totalTax) + parseFloat(unidata?.perDayPrice[0]?.tax);
        amountBeforeTax =
          parseFloat(amountBeforeTax) +
          parseFloat(unidata?.perDayPrice[0]?.amountBeforeTax);
      });
    });

    setdayLevelTotalTaxAmount(parseFloat(amountWithTax));
    setdayLevelPreTaxAmount(parseFloat(amountBeforeTax));
    setdayLevelTotalTax(parseFloat(totalTax));
  };

  const [counter, setcounter] = useState(0);
  const [editIndiaPrices, seteditIndiaPrices] = useState(true);
  useEffect(() => {
    if (counter === 0 && dayLevelPricindData[0]?.perDayPrice.length > 0) {
      seteditIndiaPrices(
        dayLevelPricindData[0]?.perDayPrice[0].taxPercent === 0 ? false : true
      );
      setcounter(1);
    }
  }, [dayLevelPricindData]);

  console.log("editIndiaPrices", editIndiaPrices);

  if (isUnitLevel) {
    return (
      <div>
        {" "}
        <div
          style={{ marginLeft: "1rem", marginTop: "1rem", marginRight: "1rem" }}
        >
          {" "}
          {uniqueDayLevelPricindData?.length > 0 &&
            uniqueDayLevelPricindData
              ?.sort((a, b) => {
                const roomTypeIdSort = a?.roomTypeId?.localeCompare(
                  b?.roomTypeId
                );
                return roomTypeIdSort === 0
                  ? a?.ratePlanId?.localeCompare(b?.ratePlanId)
                  : roomTypeIdSort;
              })
              ?.map((item) => (
                <>
                  <h3 className="mt-3 mb-3">
                    {`${item.roomTypeName} - ${item.ratePlanName}`}
                  </h3>
                  <BootstrapTable
                    bootstrap4
                    keyField="timestamp"
                    data={[item?.perDayPrice[0]]}
                    columns={columns}
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      afterSaveCell: (oldValue, newValue, row, column) => {
                        if (currency !== "INR") {
                          if (column?.dataField === "amountBeforeTax") {
                            row.amountBeforeTax = parseFloat(newValue);
                            row.tax = parseFloat(
                              (parseFloat(row.amountBeforeTax) *
                                parseFloat(row.taxPercent)) /
                                100
                            );
                            row.amountAfterTax = parseFloat(
                              parseFloat(row.amountBeforeTax) +
                                parseFloat(row.tax)
                            );
                          }
                          if (column?.dataField === "amountAfterTax") {
                            row.amountAfterTax = parseFloat(newValue);
                            row.amountBeforeTax = parseFloat(
                              (parseFloat(row.amountAfterTax) * 100) /
                                (100 + parseFloat(row.taxPercent))
                            );

                            row.tax = parseFloat(
                              (parseFloat(row.amountBeforeTax) *
                                parseFloat(row.taxPercent)) /
                                100
                            );
                          }
                        } else {
                          if (column?.dataField === "amountBeforeTax") {
                            row.amountBeforeTax = parseFloat(newValue);
                            const taxPercent =
                              editIndiaPrices === false
                                ? 0
                                : parseFloat(row.amountBeforeTax) <= 7500
                                ? 12
                                : 18;
                            row.tax = parseFloat(
                              (parseFloat(row.amountBeforeTax) *
                                parseFloat(taxPercent)) /
                                100
                            );
                            row.amountAfterTax = parseFloat(
                              parseFloat(row.amountBeforeTax) +
                                parseFloat(row.tax)
                            );
                          }
                          if (column?.dataField === "amountAfterTax") {
                            row.amountAfterTax = parseFloat(newValue);
                            {
                            }
                            if (
                              parseFloat(row.amountAfterTax) >= 8400 &&
                              parseFloat(row.amountAfterTax) <= 8850
                            ) {
                            } else {
                              const taxPercent =
                                editIndiaPrices === false
                                  ? 0
                                  : parseFloat(row.amountAfterTax) < 8400
                                  ? 12
                                  : 18;
                              row.amountBeforeTax = parseFloat(
                                (parseFloat(row.amountAfterTax) * 100) /
                                  (100 + parseFloat(taxPercent))
                              );

                              row.tax = parseFloat(
                                (parseFloat(row.amountBeforeTax) *
                                  parseFloat(taxPercent)) /
                                  100
                              );
                            }
                          }
                          if (column?.dataField === "amountAfterTax") {
                            row.amountAfterTax = parseFloat(newValue);
                            if (
                              parseFloat(row.amountAfterTax) >= 8400 &&
                              parseFloat(row.amountAfterTax) <= 8850
                            ) {
                              row.amountBeforeTax = parseFloat(7500);

                              row.tax = parseFloat(
                                parseFloat(row.amountAfterTax) -
                                  parseFloat(row.amountBeforeTax)
                              );
                            }
                          }
                        }

                        const editableFields = [
                          "amountBeforeTax",
                          "amountAfterTax",
                        ];
                        const isEmpty = editableFields.some(
                          (field) =>
                            row[field] === "" ||
                            (!row[field] && row[field] !== 0)
                        );

                        setIsUnitLevelFieldsEmpty(isEmpty);
                      },
                    })}
                  />
                </>
              ))}
        </div>
        <div style={{ float: "right", marginRight: "2rem" }}>
          <Button
            variant="custom-button"
            onClick={() => {
              setisDaylevelPricingSet(false);
              onCancelClick();
            }}
            style={{ marginRight: "1rem" }}
          >
            {t("Cancel")}
          </Button>
          <Button
            disabled={isUnitLevelFieldsEmpty}
            variant="custom-button"
            onClick={() => {
              getUniqueDetailsAmount();
              setisDaylevelPricingSet(true);
              onCancelClick();
            }}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div>
      {" "}
      <div
        style={{ marginLeft: "1rem", marginTop: "1rem", marginRight: "1rem" }}
      >
        {" "}
        {dayLevelPricindData?.length > 0 &&
          dayLevelPricindData
            ?.sort((a, b) => {
              const roomTypeIdSort = a?.roomTypeId?.localeCompare(
                b?.roomTypeId
              );
              return roomTypeIdSort === 0
                ? a?.ratePlanId?.localeCompare(b?.ratePlanId)
                : roomTypeIdSort;
            })
            ?.map((item) => (
              <>
                <h3 className="mt-3 mb-3">
                  {`${item.roomTypeName} - ${item.ratePlanName}`}
                </h3>
                <BootstrapTable
                  bootstrap4
                  keyField="timestamp"
                  data={item?.perDayPrice}
                  columns={columns}
                  cellEdit={cellEditFactory({
                    mode: "click",
                    blurToSave: true,
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      if (currency !== "INR") {
                        if (column?.dataField === "amountBeforeTax") {
                          row.amountBeforeTax = parseFloat(newValue);
                          row.tax = parseFloat(
                            (parseFloat(row.amountBeforeTax) *
                              parseFloat(row.taxPercent)) /
                              100
                          );
                          row.amountAfterTax = parseFloat(
                            parseFloat(row.amountBeforeTax) +
                              parseFloat(row.tax)
                          );
                        }
                        if (column?.dataField === "amountAfterTax") {
                          row.amountAfterTax = parseFloat(newValue);
                          row.amountBeforeTax = parseFloat(
                            (parseFloat(row.amountAfterTax) * 100) /
                              (100 + parseFloat(row.taxPercent))
                          );

                          row.tax = parseFloat(
                            (parseFloat(row.amountBeforeTax) *
                              parseFloat(row.taxPercent)) /
                              100
                          );
                        }
                      } else {
                        if (column?.dataField === "amountBeforeTax") {
                          row.amountBeforeTax = parseFloat(newValue);
                          const taxPercent =
                            editIndiaPrices === false
                              ? 0
                              : parseFloat(row.amountBeforeTax) <= 7500
                              ? 12
                              : 18;
                          row.tax = parseFloat(
                            (parseFloat(row.amountBeforeTax) *
                              parseFloat(taxPercent)) /
                              100
                          );
                          row.amountAfterTax = parseFloat(
                            parseFloat(row.amountBeforeTax) +
                              parseFloat(row.tax)
                          );
                        }
                        if (column?.dataField === "amountAfterTax") {
                          row.amountAfterTax = parseFloat(newValue);
                          {
                          }
                          if (
                            parseFloat(row.amountAfterTax) >= 8400 &&
                            parseFloat(row.amountAfterTax) <= 8850
                          ) {
                          } else {
                            const taxPercent =
                              editIndiaPrices === false
                                ? 0
                                : parseFloat(row.amountAfterTax) < 8400
                                ? 12
                                : 18;
                            row.amountBeforeTax = parseFloat(
                              (parseFloat(row.amountAfterTax) * 100) /
                                (100 + parseFloat(taxPercent))
                            );

                            row.tax = parseFloat(
                              (parseFloat(row.amountBeforeTax) *
                                parseFloat(taxPercent)) /
                                100
                            );
                          }
                        }
                        if (column?.dataField === "amountAfterTax") {
                          row.amountAfterTax = parseFloat(newValue);
                          if (
                            parseFloat(row.amountAfterTax) >= 8400 &&
                            parseFloat(row.amountAfterTax) <= 8850
                          ) {
                            row.amountBeforeTax = parseFloat(7500);

                            row.tax = parseFloat(
                              parseFloat(row.amountAfterTax) -
                                parseFloat(row.amountBeforeTax)
                            );
                          }
                        }
                      }
                      const editableFields = [
                        "amountBeforeTax",
                        "amountAfterTax",
                      ];
                      const isEmpty = editableFields.some(
                        (field) =>
                          row[field] === "" || (!row[field] && row[field] !== 0)
                      );

                      setIsFieldsEmpty(isEmpty);
                    },
                  })}
                />
              </>
            ))}
      </div>
      <div style={{ float: "right", marginRight: "2rem" }}>
        <Button
          variant="custom-button"
          onClick={() => {
            setisDaylevelPricingSet(false);
            onCancelClick();
          }}
          style={{ marginRight: "1rem" }}
        >
          {t("Cancel")}
        </Button>
        <Button
          disabled={isFieldsEmpty}
          variant="custom-button"
          onClick={() => {
            getDetailsAmount();
            setisDaylevelPricingSet(true);
            onCancelClick();
          }}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
}

export default GroupDayLevelPricing;
