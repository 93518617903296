import { Divider } from "@mui/material";
import React from "react";
import { Button, Modal, ModalBody, ModalTitle } from "react-bootstrap";
import "../RevenueManagement/RatePlans/Views/RatePlanModal.css";

const NotesModal = (props) => {
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.hide}
        dialogClassName={"myModalBodysxsse"}
        contentClassName={"myModalContent"}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <h2 style={{ marginLeft: "2rem", marginTop: "1rem" }}>
              {`Night Audit Notes : ${props.notesData.audit_date}`}
            </h2>
            <Button variant="white" onClick={props.hide}>
              {" "}
              X{" "}
            </Button>
          </div>
        </ModalTitle>
        <ModalBody>
          <Divider style={{ marginBottom: "1rem" }} />
          {props.notesData.notes.split(" , ").map((item) => (
            <ul>
              <li>
                <p style={{ fontSize: "15px", fontWeight: "400" }}>{item}</p>
              </li>
            </ul>
          ))}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NotesModal;

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
