import React, { useEffect, useState } from "react";
import {
  getAllChangedPrice,
  getAllCurrentConfig,
  saveUpdatedPrices,
} from "./EditAdultChildrenApi";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

const EditAdultChildrenInfo = ({
  bookingId,
  updatedDatas,
  updateSummaryData,
  selectedLanguage,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  //get All config data
  const [getAllConfigData, setgetAllConfigData] = useState([]);
  useEffect(async () => {
    await getAllData();
  }, []);

  const [payload, setpayload] = useState(getAllConfigData);

  const getAllData = async () => {
    try {
      const data = await getAllCurrentConfig(bookingId);
      setpayload(data);
      setgetAllConfigData(data);
    } catch (e) {
      console.error(e);
    }
  };
  //calculate updated price
  const calculateUpdatedPrice = async (resId) => {
    var newArray =
      payload &&
      payload.filter(function (el) {
        return el.reservationId === resId;
      });

    const data = await getAllChangedPrice(
      newArray[0].reservationId,
      newArray[0].numAdults,
      newArray[0].numChildren
    );
    return data;
  };
  //calculate total amount
  let totalPrice = 0;
  let Price =
    getAllConfigData &&
    getAllConfigData.map((e) => {
      totalPrice += parseFloat(e.amount.toFixed(2));
    });

  // save updated price
  const [saveLoader, setsaveLoader] = useState(false);
  const saveData = async () => {
    setsaveLoader(true);
    const data = await saveUpdatedPrices(payload, bookingId);
    setsaveLoader(false);
    if (data === "Operation Successful") {
      toast.success(`${t("Successfully updated prices")}`);
    } else {
      toast.error(`${t("Something went wrong")}`);
    }
    getAllData();
    updatedDatas();
    updateSummaryData();
  };

  return (
    <div
      style={{
        width: "90%",
        marginLeft: "5%",
        marginRight: "5%",
        position: "relative",
      }}
    >
      <Stack spacing={3}>
        {payload?.map((item, index) => {
          return (
            <div className="d-flex align-items-center ml-4 mt-4">
              <h3 className="mb-0 mr-4 d-flex">
                {t("Room id ")}- {item.roomId}
              </h3>

              <FormControl style={{ width: "20%" }} className="mr-4">
                <InputLabel id="demo-simple-select-label">
                  {t("Adults")}
                </InputLabel>
                <Select
                  onChange={(e) => {
                    payload[index].numAdults = e.target.value;
                    setpayload([...payload]);
                  }}
                  label={t("Adults")}
                  value={item?.numAdults}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  {item?.maxOccupancy < item?.maxPossible - item?.numChildren
                    ? Array.from(
                        { length: item?.maxOccupancy },
                        (item, index) => index + 1
                      ).map((itm) => <MenuItem value={itm}>{itm}</MenuItem>)
                    : Array.from(
                        { length: item?.maxPossible - item?.numChildren },
                        (item, index) => index + 1
                      ).map((itm) => <MenuItem value={itm}>{itm}</MenuItem>)}
                  {/* {[...Array(item.maxOccupancy + 1)].map((e, i) => {
                    return i !== 0 && <MenuItem value={i}>{i}</MenuItem>;
                  })} */}
                </Select>
              </FormControl>
              <FormControl style={{ width: "20%" }} className="mr-4">
                <InputLabel id="demo-simple-select-label">
                  {" "}
                  {t("Childrens")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => {
                    payload[index].numChildren = e.target.value;
                    setpayload([...payload]);
                  }}
                  value={item?.numChildren}
                  label={t("Childrens")}
                >
                  {item?.maxChildren < item?.maxPossible - item?.numAdults
                    ? Array.from(
                        { length: item?.maxChildren + 1 },
                        (item, index) => index
                      ).map((itm) => <MenuItem value={itm}>{itm}</MenuItem>)
                    : Array.from(
                        { length: item?.maxPossible + 1 - item?.numAdults },
                        (item, index) => index
                      ).map((itm) => <MenuItem value={itm}>{itm}</MenuItem>)}
                  {/* {[...Array(item.maxChildren + 1)].map((e, i) => {
                    return <MenuItem value={i}>{i}</MenuItem>;
                  })} */}
                </Select>
              </FormControl>
              <Button
                variant="custom-button"
                className="mr-4"
                onClick={async () => {
                  const data = await calculateUpdatedPrice(item.reservationId);
                  payload[index].amount = data;
                  setpayload([...payload]);
                }}
              >
                {t("Calculate")}
              </Button>
              <OutlinedInput
                label=""
                notched={false}
                className="input-lg "
                // readOnly="true"
                onChange={(e) => {
                  if (e.target.value === "") {
                    payload[index].amount = parseFloat(0);
                    setpayload([...payload]);
                  } else {
                    let amount = e.target.value;
                    if (amount <= 0) {
                      payload[index].amount = parseFloat(0);
                      setpayload([...payload]);
                    } else {
                      payload[index].amount = parseFloat(amount);
                      setpayload([...payload]);
                    }
                  }
                  // payload[index].amount = {amount < 0 ?parseFloat(0):parseFloat(amount)};
                  setpayload([...payload]);
                }}
                value={item.amount.toFixed(0)}
              />
            </div>
          );
        })}
        <div
          style={{ display: "flex", marginLeft: "auto", marginRight: "9rem" }}
        >
          <h4 className="mb-0 mr-4">{t("Total")} : </h4>
          <h4
            style={{
              textDecoration: "underline",
              marginBottom: "0px",
            }}
          >
            {totalPrice.toFixed(0)}
          </h4>
        </div>
        <Button
          variant="custom-button"
          onClick={saveData}
          disabled={saveLoader}
          style={{ float: "right" }}
        >
          {saveLoader ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            `${t("Save")}`
          )}
        </Button>
      </Stack>
    </div>
  );
};

export default EditAdultChildrenInfo;
