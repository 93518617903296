import {
  Stack,
  Button,
  Box,
  ClickAwayListener,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "material-react-toastify";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { djangoHandleDataRequests } from "../../../../api";
import ShopWrapper from "../ShopWrapper";
import moment from "moment";
import { DateRange } from "react-date-range";
import { SecondaryButton } from "../../../../Utils/ButtonHelper";
import paginationFactory from "react-bootstrap-table2-paginator";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@material-ui/pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { connect } from "react-redux";
import ReportsLoader from "../../../../Utils/ReportsLoader";
import { useTranslation } from "react-i18next";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const HourlyItemSalesReport = ({ data }) => {
  const { t } = useTranslation();

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId } = useParams();
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;

  // Getting the start date as well as the end date to load the data
  const [dateVal, setDateVal] = useState(dayjs(new Date()));
  const handleChange = (newVal) => {
    setDateVal(newVal);
  };

  // Get All report data
  const [reportsData, setreportsData] = useState([]);

  //Function to get report data
  const getAllRestaurantReportData = () => {
    setrowsdats(null);
    shopId &&
      djangoHandleDataRequests(
        `pos/hourlyItemSalesSummaryReport/?shop_id=${shopId}&start_date=${moment(
          new Date(dateVal)
        ).format("YYYY-MM-DD 00:00:00")}&end_date=${moment(
          new Date(dateVal)
        ).format("YYYY-MM-DD 23:59:59")}&hotel_id=${hotelId}`
      )
        .then((res) => {
          setreportsData(res);
          setrowsdats(productsGenerator(res.length, res));
        })
        .catch((err) =>
          toast.error(`${t("Error in getting  data! Please try again later.")}`)
        );
  };

  useEffect(() => {
    getAllRestaurantReportData();
  }, [dateVal]);

  //Rows data generator
  const productsGenerator = (quantity, data) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        date: data[i].date,
        shop_name: data[i].shop_name,
        hour:
          Number(data[i].hour) < 12
            ? `${data[i].hour} AM`
            : `${
                Number(data[i].hour) - 12 === 0
                  ? "12"
                  : Number(data[i].hour) - 12
              } PM`,
        product_name: data[i].product_name,
        product_price: data[i].product_price,
        product_qty: data[i].product_qty,
        total: data[i].total,
      });
    }
    return items;
  };

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  //columns of table
  const [rowsdats, setrowsdats] = useState(null);
  const [reportsColumns, setreportsColumns] = useState([]);
  useEffect(() => {
    const names = [
      `${t("Date")}`,
      `${t("Shop Name")}`,
      `${t("Hour")}`,
      `${t("Item name")}`,
      `${t("Item price")} (${hotelCurrency})`,
      `${t("Item Qty")}`,
      `${t("Total")} (${hotelCurrency})`,
    ];
    if (reportsData?.length > 0) {
      let arr = [];
      const array = Object.keys(reportsData[0]);
      for (var i = 0; i < array?.length; i++) {
        arr.push({
          dataField: array[i],
          text: names[i],
          sort: true,
          headerStyle: {
            width: "150px",
          },
        });
      }

      setreportsColumns(arr);
    }
  }, [reportsData]);

  return (
    <ShopWrapper
      backBtn={true}
      contentArea={
        <>
          <h1 className="mt-3 mb-3">{t("Hourly item sales summary report")}</h1>
          <Divider className="mt-2 mb-2" />
          {rowsdats ? (
            <div className="App">
              {/* {reportsColumns.length > 0 && ( */}
              <ToolkitProvider
                keyField="id"
                data={rowsdats}
                columns={reportsColumns}
                exportCSV
                search
              >
                {(props) => (
                  <div>
                    <Stack
                      className="position-relative w-100 justify-content-between mt-8"
                      direction={"row"}
                    >
                      <Stack
                        className="position-relative"
                        direction="row"
                        spacing={2}
                      >
                        <SearchBar
                          {...props.searchProps}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        />
                        <Stack
                          spacing={4}
                          className="position-relative"
                          style={{ width: "fit-content" }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label={t("Select date")}
                              // inputFormat="MM/DD/YYYY"
                              inputFormat="DD MMM YYYY"
                              value={dateVal}
                              onChange={handleChange}
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Stack>

                      <ExportCSVButton
                        {...props.csvProps}
                        style={{
                          display: "block",
                          background: "black",
                          color: "white",
                          marginTop: -1.5,
                          marginBottom: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        {t("Export CSV")}
                      </ExportCSVButton>
                    </Stack>
                    <Stack className="position-relativre mt-8">
                      {reportsColumns.length > 0 && rowsdats.length > 0 ? (
                        <BootstrapTable
                          pagination={paginationFactory({ sizePerPage: 10 })}
                          {...props.baseProps}
                        />
                      ) : (
                        <Typography
                          className="position-relative mt-8 text-align-center"
                          style={{
                            fontSize: "15px",
                            marginTop: "15px",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          {t("No data found!")}
                        </Typography>
                      )}
                    </Stack>
                  </div>
                )}
              </ToolkitProvider>
            </div>
          ) : (
            <ReportsLoader />
          )}
        </>
      }
    />
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(HourlyItemSalesReport);
