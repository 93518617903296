import React, { Fragment, useEffect } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import {
  readableTimeConverter,
  validateAddress,
  handleFullAddString,
} from "../../Utils/Utils";
import { useState } from "react";
import { getMapForTheRatePlans } from "./FolioUtils";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import Currencies from "../../Utils/Currencies.json";

const borderColor = "#E5e5e5";

const InvoiceDualText = ({ boldText, normalText, color = "black" }) => {
  return (
    <View
      style={[
        styles.dualText,
        // { position: "relative", display: "flex", flexWrap: "wrap" },
      ]}
    >
      <Text style={[styles.boldText, { color: color }]}>{boldText}:</Text>
      <Text style={[styles.normalText, { color: color }]}>{normalText}</Text>
    </View>
  );
};

const InvoiceDualTextForAddress = ({
  boldText,
  normalText,
  color = "black",
}) => {
  return (
    <View
      style={[
        styles.dualText,
        { position: "relative", display: "flex", flexWrap: "wrap" },
      ]}
    >
      <Text style={[styles.boldText, { color: color }]}>{boldText}:</Text>
      <Text style={[styles.normalText, { color: color }]}>{normalText}</Text>
    </View>
  );
};

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={[styles.description, { fontSize: "10px" }]}>
      Date {"&"} Description
    </Text>
    <Text style={[styles.qty, { fontSize: "10px" }]}>Type</Text>
    <Text style={[styles.rate, { fontSize: "10px" }]}>Sub-total</Text>
    <Text style={[styles.rate, { fontSize: "10px" }]}>Tax</Text>
    <Text style={[styles.amount, { fontSize: "10px" }]}>Total</Text>
  </View>
);

// Handling customer address
const handleCustomerAddress = (address) => {
  let formattedAddress = "";

  Object.values(address).map(function (add) {
    if (validateAddress(add)) {
      formattedAddress += add + ", ";
    }
  });

  const lastCommaIndex = formattedAddress.lastIndexOf(", ");
  const newFormattedAddress = formattedAddress.substring(0, lastCommaIndex);
  return newFormattedAddress;
};

// Handling the date format
const formatDate = (date) => {
  return moment(date).format("MMM DD YYYY hh:mm A");
};

// generating the guest information table

const InvoiceRoomRow = ({ items, symbol, hotelType, HotelEmail, invoice }) => {
  const rows = items.map((item, index) => (
    <View style={styles.row} key={index}>
      {/* <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flexStart",
          flexWrap: "wrap",
          marginLeft: "15px",
        }}
      > */}
      <Text
        style={{
          // marginBottom: 3,
          fontSize: "10px",
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {item?.roomTypeName}
      </Text>
      <Text
        style={{
          // marginBottom: 3,
          fontSize: "10px",
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {item?.roomId}
      </Text>
      <Text
        style={{
          fontSize: "10px",
          // marginBottom: 5,
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {item?.ratePlanName}
      </Text>
      {hotelType?.toUpperCase() !== "ISHA" &&
        (HotelEmail === "df@stayflexi.com" ||
          HotelEmail === "bookings@revenuefirst.in") && (
          <Text
            style={{
              fontSize: "10px",
              // marginBottom: 5,
              width: "33.33%",
              // marginTop: "10px",
            }}
          >
            {item?.roomRate}
          </Text>
        )}

      <Text
        style={{
          fontSize: "10px",
          // marginBottom: 5,
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {item.adults} Adult(s) {item.children} Children
      </Text>
      {/* <Text
        style={{
          fontSize: "10px",
          // marginBottom: 5,
          width: "25%",
          // marginTop: "10px",
        }}
      >
        {symbol} {parseInt(item.roomRate).toFixed(2)}
      </Text> */}
      {/* </View> */}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

// handle invoice header
const handleInvoiceHeader = (customFolioConfig) => {
  if (
    customFolioConfig?.booking_invoice_header &&
    !customFolioConfig?.booking_invoice_header
      .toLowerCase()
      .includes("null", "n/a")
  ) {
    return customFolioConfig?.booking_invoice_header;
  } else {
    return "Folio";
  }
};

// Check if the data is not null or includes n/a
const checkInvoiceData = (data) => {
  if (data === null) {
    return "";
  } else if (String(data).toLowerCase().startsWith("null")) {
    return "";
  } else if (String(data).toLowerCase().startsWith("n/a")) {
    return "";
  } else {
    return String(data);
  }
};

const handleCustomBookingId = (invoice, customFolioConfig) => {
  if (invoice?.status === "CHECKED_OUT") {
    // return (
    //   String(checkInvoiceData(customFolioConfig.cust_booking_invoice_prefix)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_seq_start)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_invoice_suffix))
    // );
    return String(invoice?.custom_invoice_id);
  } else {
    return "";
  }
};

function PrintResCard({
  allCompanyDetails,
  invoice,
  hotelData,
  symbol,
  customDetails,
  hotelLogo,
  summaryData,
  hotelType,
  ctaDetails,
  selectedLanguage,
}) {
  // console.log("invoiceinvoice", invoice);
  // console.log("customDetailscustomDetails", customDetails);
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const ReservationGuestInfo = ({ symbol, invoice, company, hotelType }) => (
    <View style={([styles.container], { fontSize: "18px" })}>
      <View style={styles.resCardWrapper}>
        {(symbol === "Rs." || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText
                  boldText={t("Registration No. ")}
                  normalText={
                    customDetails?.show_reservation_num === "YES"
                      ? `${t(invoice.bookingid.split("_")[2])}`
                      : ""
                  }
                />
              </View>
              <View style={styles.resCardRight}>
                <InvoiceDualText
                  boldText={t("Payment mode ")}
                  normalText={""}
                />
              </View>
            </View>
          )}

        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText
              boldText={t("Guest name ")}
              normalText={invoice?.customer_name}
            />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText
              boldText={t("Nationality ")}
              normalText={
                invoice?.customer_address.customer_address_country || ""
              }
            />
          </View>
        </View>
        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText
              boldText={t("Email ")}
              normalText={invoice?.customer_email}
            />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText
              boldText={t("Phone ")}
              normalText={invoice?.customer_phone}
            />
          </View>
        </View>
        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText
              boldText={t("Source ")}
              normalText={invoice?.booking_source_displayname || ""}
            />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText
              boldText={t(
                customDetails?.show_room_tariff_reservation_card?.toUpperCase() ===
                  "YES"
                  ? "Booking amount"
                  : "Gov ID "
              )}
              normalText={
                customDetails?.show_room_tariff_reservation_card?.toUpperCase() ===
                "YES"
                  ? `${
                      Currencies?.filter((e) => e.code === symbol)?.length > 0
                        ? Currencies?.filter((e) => e.code === symbol)?.[0]
                            ?.symbol
                        : symbol
                    } ${invoice?.booking_amount}` || ""
                  : invoice?.govt_id_value || ""
              }
            />
          </View>
        </View>
        <View style={styles.resCardContent}>
          <View
            style={[
              styles.resCardLeft,
              {
                position: "relative",
                display: "flex",
                flexWrap: "wrap",
                breakWord: "break-all",
              },
            ]}
          >
            <InvoiceDualTextForAddress
              boldText={t("Address ")}
              normalText={
                handleFullAddString(invoice?.customer_address_full_str) || ""
              }
            />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText
              boldText={t("Visa No/Issue/Expiry ")}
              normalText={""}
            />
          </View>
        </View>

        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText
              boldText={t("DOB ")}
              normalText={invoice?.customer_dob || ""}
            />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText
              boldText={t("Purpose of visit ")}
              normalText={invoice?.customer_purpose_of_visit || ""}
            />
          </View>
        </View>
        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText boldText={t("Arrived from ")} normalText={""} />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText boldText={t("Preferences ")} normalText={""} />
          </View>
        </View>
        {(symbol === "Rs." || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText
                  boldText={t("Company name ")}
                  normalText={company[0]?.company_name || ""}
                />
              </View>

              <View style={styles.resCardRight}>
                <InvoiceDualText
                  boldText={t("Company GST ")}
                  normalText={invoice?.company_gst_number || ""}
                />
              </View>
            </View>
          )}
        {(symbol === "Rs." || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText boldText={t("Designation ")} normalText={""} />
              </View>
              <View style={styles.resCardRight}>
                <InvoiceDualText
                  boldText={t("Company address ")}
                  normalText={company[0]?.company_address || ""}
                />
              </View>
            </View>
          )}
        {(symbol === "Rs." || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText
                  boldText={t("Company email ")}
                  normalText={company[0]?.company_email || ""}
                />
              </View>
              <View style={styles.resCardRight}>
                <InvoiceDualText
                  boldText={t("Company contact ")}
                  normalText={company[0]?.company_phone || ""}
                />
              </View>
            </View>
          )}
        {hotelType?.toUpperCase() !== "ISHA" && (
          <View style={styles.resCardContent}>
            <View style={styles.resCardLeft}>
              <InvoiceDualText boldText={t("Vehicle No. ")} normalText={""} />
            </View>

            {(symbol === "Rs." || symbol === "INR") && (
              <View style={styles.resCardRight}>
                <InvoiceDualText boldText={t("Anniversary ")} normalText={""} />
              </View>
            )}
          </View>
        )}
        {customDetails?.show_room_tariff_reservation_card?.toUpperCase() ===
          "YES" && (
          <View style={styles.resCardContent}>
            <View style={styles.resCardLeft}>
              <InvoiceDualText
                boldText={t("Gov ID ")}
                normalText={invoice?.govt_id_value || ""}
              />
            </View>
          </View>
        )}
        {(symbol === "Rs." || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText boldText={t("Remark ")} normalText={""} />
              </View>
            </View>
          )}
      </View>
    </View>
  );

  const InvoiceRoomHeader = ({ symbol, hotelType, HotelEmail }) => (
    <View style={styles.container}>
      <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
        {t("Room type")}
      </Text>

      <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
        {t("Room Ids.")}
      </Text>

      <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
        {t("Rate plan")}
      </Text>

      {hotelType?.toUpperCase() !== "ISHA" &&
        (HotelEmail === "df@stayflexi.com" ||
          HotelEmail === "bookings@revenuefirst.in") && (
          <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
            {t("Room rate")} ({symbol})
          </Text>
        )}
      <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
        {t("Room occupancy")}
      </Text>
      {/* <Text style={{ width: "25%", fontSize: "10px", marginTop: "5px" }}>
        Room rate
      </Text> */}
    </View>
  );
  const HotelEmail = localStorage.getItem("HotelEmail");

  const [company, setCompany] = useState([]);
  useEffect(() => {
    if (allCompanyDetails.length > 0) {
      const data = allCompanyDetails.filter(
        (elm) => elm.gst_number === invoice?.company_gst_number
      );

      setCompany(data);
    }
  }, [allCompanyDetails]);
  // Display cancellation policy
  function showNewCancellationPolicy() {
    let ifShowNewCancellationPolicy = true;

    if (invoice?.hasOwnProperty("cancellationPolicies")) {
      for (let policy of invoice?.cancellationPolicies) {
        const policyArr = policy.split(":");
        const policyDesc = policyArr[1].trim();
        if (policyDesc.length === 0) {
          ifShowNewCancellationPolicy = false;
          break;
        }
      }
    } else {
      ifShowNewCancellationPolicy = false;
    }
    return ifShowNewCancellationPolicy;
  }

  // Function to generate the default cancellation policies
  function handleGenerateCancellationPolicies(invoice, customDetails) {
    return (
      <View>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_before}%{" "}
          {t("of total booking value when cancelled before")}{" "}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_within}% {t("if cancelled within")}{" "}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
      </View>
    );
  }
  return (
    <View
      style={[
        styles.section,
        {
          marginTop: 0,
          paddingTop: String(symbol)?.includes("Rs") ? "45px" : "9px",
        },
      ]}
    >
      <View
        style={{
          position: "relative",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {/* <View
          style={[
            styles.dualText,
            {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 10,
            },
          ]}
        > */}
        <View
          style={{
            position: "relative",
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={[styles.boldText, { marginBottom: 3, color: "grey" }]}>
            {hotelData?.hotel_name}
          </Text>
          <Text
            style={[
              styles.boldText,
              { marginBottom: 3, flexWrap: "wrap", color: "grey" },
            ]}
          >
            {handleFullAddString(hotelData?.hotel_address)}
          </Text>
          {hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.dualText}>
              <Text style={[styles.boldText, { color: "grey" }]}>
                {t("Email :")}{" "}
              </Text>
              <Text style={[styles.boldText, { color: "grey" }]}>
                {customDetails &&
                customDetails?.invoice_contact_email &&
                !customDetails?.invoice_contact_email
                  .toLowerCase()
                  .includes("null", "n/a")
                  ? customDetails?.invoice_contact_email
                  : hotelData?.hotel_email}
              </Text>
            </View>
          )}
          <View style={styles.dualText}>
            <Text style={[styles.boldText, { color: "grey" }]}>
              {t("Phone :")}
            </Text>
            <Text style={[styles.boldText, { color: "grey" }]}>
              {customDetails &&
              customDetails?.invoice_contact_phone &&
              !customDetails?.invoice_contact_phone
                .toLowerCase()
                .includes("null", "n/a")
                ? customDetails?.invoice_contact_phone
                : hotelData?.hotel_contact}
            </Text>
          </View>
        </View>

        <View
          style={{ position: "relative", width: "33%", alignItems: "center" }}
        >
          <Text style={{ fontSize: "12.5px" }}>{t("Registration Card")}</Text>
          {customDetails &&
            customDetails?.show_logo.toLowerCase() === "yes" &&
            getExtension(hotelLogo) && (
              <View
                style={{
                  position: "relative",
                  alignSelf: "center",
                  width: "35px",
                  // height: "auto",
                  marginBottom: "10px",
                }}
              >
                <Image src={getExtension(hotelLogo)} />
              </View>
            )}
        </View>

        <View
          style={{
            position: "relative",
            width: "33%",
            marginTop: "auto",
            marginBottom: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <Text style={{ marginBottom: 5, fontSize: "12px" }}>
                Registration card
              </Text> */}
          <View>
            {customDetails?.hide_stayflexi_booking_id.toLowerCase() ===
              "no" && (
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginBottom: 3,
                  color: "grey",
                }}
              >{`${handleInvoiceHeader(customDetails)}#${
                invoice?.bookingid
              }`}</Text>
            )}
            {customDetails && Number(summaryData?.payment_made) > 0 ? (
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginBottom: 3,
                  color: "grey",
                }}
              >{`${handleInvoiceHeader(customDetails)}#${handleCustomBookingId(
                invoice,
                customDetails
              )}`}</Text>
            ) : (
              <Text></Text>
            )}
            <View>
              <InvoiceDualText
                boldText={t("Check-in")}
                normalText={formatDate(invoice?.checkin)}
                color={"grey"}
              />
            </View>
            <View>
              <InvoiceDualText
                boldText={t("Check-out")}
                normalText={formatDate(invoice?.checkout)}
                color={"grey"}
              />
            </View>
            <View>
              <View style={styles.dualText}>
                <InvoiceDualText
                  boldText={t("Nights")}
                  normalText={invoice?.num_nights}
                  color={"grey"}
                />
                <InvoiceDualText
                  boldText={t("Adults")}
                  // normalText={invoice?.adults
                  //   ?.split(",")
                  //   .reduce((acc, cur) => acc + Number(cur), 0)}
                  normalText={invoice?.reservation_room_info_list
                    ?.filter(
                      (room) =>
                        !invoice?.cancelled_room_ids_list.includes(room.roomId)
                    )
                    .reduce((acc, curr) => {
                      return acc + curr.adults;
                    }, 0)}
                  color={"grey"}
                />
                <InvoiceDualText
                  boldText={t("Children")}
                  // normalText={invoice?.children
                  //   ?.split(",")
                  //   .reduce((acc, cur) => acc + Number(cur), 0)}
                  normalText={invoice?.reservation_room_info_list
                    ?.filter(
                      (room) =>
                        !invoice?.cancelled_room_ids_list.includes(room.roomId)
                    )
                    .reduce((acc, curr) => {
                      return acc + curr.children;
                    }, 0)}
                  color={"grey"}
                />
              </View>
            </View>
            {/* <View>
              <InvoiceDualText
                boldText={"Room No.(s)"}
                // normalText={invoice?.roomids?.split(",").length}
                normalText={invoice?.roomids}
                color={"grey"}
              />
            </View>
            s */}
            <View>
              <InvoiceDualText
                boldText={t("Source")}
                normalText={invoice?.booking_source_displayname}
                color={"grey"}
              />
            </View>
          </View>
        </View>

        {/* CTA Details incase bill is settled to DIRECT_BILLING */}
        {/* {invoice?.segment === "DIRECT_BILLING" && (
          <View
            style={{
              position: "relative",
              textAlign: "right",
              width: "33%",
            }}
          >
            <Text
              style={[styles.boldText, { marginBottom: 3.5, color: "grey" }]}
            >
              {ctaDetails?.cta_name}
            </Text>
            {ctaDetails?.cta_address_line1.length > 0 && (
              <Text
                style={[
                  styles.boldText,
                  {
                    marginBottom: 3.5,
                    marginLeft: 10,
                    flexWrap: "wrap",
                    color: "grey",
                  },
                ]}
              >
                {ctaDetails?.cta_address_line1} {ctaDetails?.cta_address_line2}{" "}
                {ctaDetails?.cta_address_city} {ctaDetails?.cta_address_state}
              </Text>
            )}
            <View
              style={[
                {
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: 3.5,
                  color: "grey",
                },
                { textAlign: "right" },
              ]}
            >
              <Text style={[styles.boldText, { color: "grey" }]}>Email : </Text>
              {ctaDetails?.cta_email.length > 0 ? (
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {ctaDetails?.cta_email}
                </Text>
              ) : (
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {"_____________"}
                </Text>
              )}
            </View>
            <View
              style={[
                {
                  flexDirection: "row",
                  marginBottom: 3.5,
                  justifyContent: "flex-end",
                  color: "grey",
                },
                { textAlign: "right" },
              ]}
            >
              <Text style={[styles.boldText, { color: "grey" }]}>Phone:</Text>
              {ctaDetails?.cta_phone?.length > 0 ? (
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {ctaDetails?.cta_phone}
                </Text>
              ) : (
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {"_____________"}
                </Text>
              )}
            </View>
            {ctaDetails?.cta_tax_id_no === undefined ||
            ctaDetails?.cta_tax_id_no === null ||
            ctaDetails?.cta_tax_id_no?.length === 0 ? (
              <View
                style={[
                  {
                    flexDirection: "row",
                    marginBottom: 3.5,
                    justifyContent: "flex-end",
                    color: "grey",
                    // marginRight: 50,
                  },
                ]}
              >
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {" "}
                  {String(symbol).includes("Rs")
                    ? "CTA GST No. :"
                    : "CTA Tax IN :"}
                </Text>
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {"_____________"}
                </Text>
              </View>
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    marginBottom: 3.5,
                    justifyContent: "flex-end",
                    color: "grey",
                  },
                  { textAlign: "right" },
                ]}
              >
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {" "}
                  {String(symbol).includes("Rs")
                    ? "CTA GST No. :"
                    : "CTA Tax IN :"}
                </Text>
                <Text style={[styles.boldText, { color: "grey" }]}>
                  {ctaDetails?.cta_tax_id_no.length}
                </Text>
              </View>
            )}
          </View>
        )} */}

        {/* <View
          style={{
            textAlign: invoice?.segment === "DIRECT_BILLING" ? "left" : "right",
            width: "33%",
            marginTop: invoice?.segment === "DIRECT_BILLING" ? "10px" : "0px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Text
            style={{
              fontSize: "10px",
              paddingRight: "5px",
              marginBottom: 3,
            }}
          >
            {invoice?.segment === "DIRECT_BILLING"
              ? "Guest details"
              : hotelType.toUpperCase() !== "ISHA" && "Bill to"}
          </Text>
          <Text style={[styles.boldText, { marginBottom: 3.5, color: "grey" }]}>
            {invoice?.customer_name}
          </Text>
          {!invoice?.customer_address_full_str.includes("NA", "null") ? (
            <Text
              style={[
                styles.boldText,
                {
                  marginBottom: 3.5,
                  marginLeft: 10,
                  flexWrap: "wrap",
                  color: "grey",
                },
              ]}
            >
              {handleFullAddString(invoice?.customer_address_full_str)}
            </Text>
          ) : (
            <Text
              style={[
                styles.boldText,
                {
                  marginBottom: 3.5,
                  marginLeft: 10,
                  flexWrap: "wrap",
                  color: "grey",
                },
              ]}
            >
              {handleCustomerAddress(invoice?.customer_address)}
            </Text>
          )}
          <View
            style={[
              {
                flexDirection: "row",
                justifyContent:
                  invoice.segment === "DIRECT_BILLING"
                    ? "flex-start"
                    : "flex-end",
                marginBottom: 3.5,
                color: "grey",
              },
              {
                textAlign:
                  invoice.segment === "DIRECT_BILLING" ? "left" : "right",
              },
            ]}
          >
            <Text style={[styles.boldText, { color: "grey" }]}>Email : </Text>
            {invoice?.customer_email.length > 0 ? (
              <Text
                style={[
                  styles.boldText,
                  {
                    color: "grey",
                    display: "flex",
                    wordWrap: "break-word",
                    position: "relative",
                    width: "85%",
                  },
                ]}
              >
                {invoice?.customer_email}
              </Text>
            ) : (
              <Text style={[styles.boldText, { color: "grey" }]}>{"-"}</Text>
            )}
          </View>
          <View
            style={[
              {
                flexDirection: "row",
                marginBottom: 3.5,
                justifyContent:
                  invoice.segment === "DIRECT_BILLING"
                    ? "flex-start"
                    : "flex-end",
                color: "grey",
              },
              {
                textAlign:
                  invoice.segment === "DIRECT_BILLING" ? "left" : "right",
              },
            ]}
          >
            <Text style={[styles.boldText, { color: "grey" }]}>Phone:</Text>
            {invoice?.customer_phone?.length > 0 ? (
              <Text style={[styles.boldText, { color: "grey" }]}>
                {invoice?.customer_phone}
              </Text>
            ) : (
              <Text style={[styles.boldText, { color: "grey" }]}>{"-"}</Text>
            )}
          </View>
        </View> */}

        {/* </View> */}
      </View>
      {invoice?.items && (
        <View style={styles.tableContainer}>
          <ReservationGuestInfo
            symbol={symbol}
            invoice={invoice}
            company={company}
            hotelType={hotelType}
          />
        </View>
      )}
      <View
        style={{
          marginTop: 10,
          flexDirection: "row",
          //   justifyContent: "space-between",
        }}
      >
        {invoice?.reservation_room_info_list && (
          <View>
            <Text style={{ fontSize: "10px", marginBottom: 5 }}>
              {t("Room details")}
            </Text>
            <View style={{ width: "100%" }}>
              <InvoiceRoomHeader
                symbol={symbol}
                hotelType={hotelType}
                HotelEmail={HotelEmail}
              />
              <InvoiceRoomRow
                items={invoice?.reservation_room_info_list?.filter(
                  (room) =>
                    !invoice?.cancelled_room_ids_list.includes(room.roomId)
                )}
                symbol={symbol}
                hotelType={hotelType}
                HotelEmail={HotelEmail}
                invoice={invoice}
              />
            </View>
          </View>
        )}
      </View>
      {/* Displaying guest and hotel signature */}
      {customDetails?.show_signature && (
        <View style={styles.signature}>
          <View style={styles.signatureData}>
            <Text
              style={{ fontSize: "10px", marginBottom: 5, marginTop: "auto" }}
            >
              {t("Guest signature :")}
            </Text>
            {Object.keys(customDetails).length > 0 &&
            invoice?.customer_signature_base64.length > 0 ? (
              <Image
                style={[styles.parentImage, { marginLeft: 5 }]}
                src={getExtension(invoice.customer_signature_base64)}
              />
            ) : (
              <Text style={{ marginLeft: 5 }}>_________________________</Text>
            )}
          </View>
          <View style={styles.signatureData}>
            {symbol === "$" ? (
              <Text style={{ fontSize: "10px", marginBottom: 5 }}>
                {t("Guest Name :")}
              </Text>
            ) : (
              <Text style={{ fontSize: "10px", marginBottom: 5 }}>
                {t("Hotel signature :")}
              </Text>
            )}
            <Text style={{ marginLeft: 5 }}>_________________________</Text>
          </View>
        </View>
      )}

      {/* Displaying Cancellation Policies */}
      {customDetails &&
        Object.keys(customDetails).length > 0 &&
        customDetails?.show_res_cancellation_policy === "YES" && (
          <View>
            <Text
              style={{
                fontSize: "10px",
                marginTop: 7.5,
                marginBottom: 7.5,
                textDecoration: "underline",
              }}
            >
              {t("Cancellation Policies")}
            </Text>
            {invoice.hasOwnProperty("cancellationPolicies") ? (
              invoice?.cancellationPolicies?.length > 0 &&
              showNewCancellationPolicy() ? (
                <View>
                  {invoice?.cancellationPolicies
                    ?.map((policies) => policies.replace(":", "\n"))
                    .map((policy) => (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 5,
                          flexWrap: "wrap",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {policy}
                        </Text>
                      </View>
                    ))}
                </View>
              ) : (
                handleGenerateCancellationPolicies(invoice, customDetails)
              )
            ) : (
              handleGenerateCancellationPolicies(invoice, customDetails)
            )}
          </View>
        )}

      {/* Displaying Terms and Conditions */}
      {customDetails &&
        Object.keys(customDetails).length > 0 &&
        customDetails?.show_res_terms_and_condition.toLowerCase() === "yes" &&
        (localStorage?.getItem("tnc")?.trim()?.length > 0 ||
          localStorage.getItem("hotel_tnc").length > 0) && (
          <View style={{ marginTop: 5 }}>
            <Text
              style={{
                fontSize: "10px",
                marginBottom: 5,
                textDecoration: "underline",
              }}
            >
              {t("Terms and Conditions")}
            </Text>
            <Text style={{ fontSize: "9px" }}>
              {/* {hotelData?.hotel_terms_conditions} */}
              {localStorage?.getItem("tnc") ||
                localStorage?.getItem("hotel_tnc")}
            </Text>
          </View>
        )}
      {/* )} */}
    </View>
  );
}

export default PrintResCard;

// Create styles
const styles = StyleSheet.create({
  parentImage: {
    width: "45px",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain cover",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 3,
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "10px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "grey",
    fontSize: "10px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "left",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    textAlign: "center",
    borderRightWidth: 1,
  },
  amount: {
    textAlign: "center",
    width: "15%",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 4,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
  },
  row: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    padding: "8px",
    inlineSize: "auto",
    fontStyle: "bold",
    justifyContent: "space-between",
  },
  resCardWrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: "10px",
  },
  resCardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flexStart",
    fontSize: "10px",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: "4.5px",
    minHeight: 30,
    inlineSize: "auto",
    overflow: "hidden",
  },
  resCardLeft: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "spaceBetween",
    position: "relative",
    width: "50%",
    flexDirection: "row",
    marginTop: "auto",
    marginBottom: "auto",
  },
  resCardHeader: {
    fontSize: "10px",
    fontWeight: "500",
    width: "40%",
    flexWrap: "wrap",
    display: "flex",
  },
  resCardVal: {
    marginLeft: "7.5px",
    fontSize: "10px",
    flexWrap: "wrap",
    width: "55%",
    display: "flex",
  },
  resCardRight: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "spaceBetween",
    position: "relative",
    width: "50%",
    flexDirection: "row",
    marginLeft: "10px",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingLeft: "10px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  signature: {
    marginTop: 25,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
});
