import { DateRange } from "react-date-range";
import {
  Grid,
  Grow,
  Paper,
  Popper,
  ClickAwayListener,
  TextField,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { handleSpringDataRequest } from "../../../api";
import { toast } from "material-react-toastify";
import { InventoryDateformat } from "../constants/InventoryConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  footer: {
    margin: theme.spacing(2),
    float: "right",
  },
}));

function ShowOfflineRooms({
  selectedRoom,
  rooms,
  setLineInvisible,
  setLineVisible,
}) {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [loading, setloading] = useState(false);
  const [calendarData, setcalendarData] = useState({});
  const [offlineRoomData, setofflineRoomData] = useState([]);

  const [numDays, setNumDays] = useState(7);
  const [value, setValue] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + numDays)),
    },
  ]);

  const getDateText = () => {
    let val = "";
    val = val + moment(value[0].startDate).format("DD MMM YYYY") + " - ";
    val = val + moment(value[0].endDate).format("DD MMM YYYY");
    return val;
  };

  useEffect(() => {
    setLineVisible();
    const fromDate = moment(value[0].startDate).format(InventoryDateformat);
    const endDate = moment(value[0].endDate).format(InventoryDateformat);

    handleSpringDataRequest(
      `common/get-hotel-calander?fromDate=${fromDate}&toDate=${endDate}`
    )
      .then((res) => {
        setcalendarData(res);
        setLineInvisible();
      })
      .catch((err) => toast.error("Something went wrong"));
  }, [value]);

  useEffect(() => {
    if (
      Object.entries(calendarData).length > 0 &&
      selectedRoom !== undefined &&
      selectedRoom !== null &&
      selectedRoom !== ""
    ) {
      const finalData = calendarData?.allRoomTypes?.filter(
        (item) => item.roomTypeId === selectedRoom
      )[0];
      setofflineRoomData(finalData);
      console.log("finalData", finalData);
    }
  }, [calendarData, selectedRoom]);

  return (
    <Grow in timeout={1000} style={{ transformOrigin: "0 0 0" }}>
      <Grid container>
        {!loading && (
          <Grid container className={classes.textField} spacing={2}>
            <Grid item xs={12}>
              <div>
                <ClickAwayListener
                  onClickAway={() => {
                    setShowDatePicker(false);
                    setAnchorElDate(null);
                  }}
                >
                  <div>
                    <TextField
                      fullWidth
                      focused={showDatePicker}
                      InputProps={{ readOnly: true }}
                      value={getDateText()}
                      label={t("Date range")}
                      onClick={(eve) => {
                        setAnchorElDate(eve.currentTarget);
                        setShowDatePicker(true);
                      }}
                    ></TextField>
                    {showDatePicker && (
                      <Popper
                        style={{ zIndex: 2 }}
                        open={showDatePicker}
                        anchorEl={anchorElDate}
                        placement="bottom"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            style={{
                              transformOrigin: (placement = "bottom-end"),
                            }}
                            {...TransitionProps}
                          >
                            <Paper elevation={5}>
                              <DateRange
                                color="#1BC5BD"
                                // maxDate={
                                //   new Date(
                                //     new Date().setDate(
                                //       value[0].startDate.getDate() +
                                //         numDays
                                //     )
                                //   )
                                // }
                                rangeColors={["#1BC5BD"]}
                                editableDateInputs={false}
                                dragSelectionEnabled={false}
                                months={1}
                                onChange={(data) => {
                                  const diffTime = Math.abs(
                                    data.range1.endDate - data.range1.startDate
                                  );
                                  const diffDays = Math.ceil(
                                    diffTime / (1000 * 60 * 60 * 24)
                                  );

                                  if (diffDays <= numDays)
                                    setValue([{ ...data.range1 }]);
                                  else {
                                    const endDate = new Date(
                                      new Date(data.range1.startDate).setDate(
                                        data.range1.startDate.getDate() +
                                          numDays
                                      )
                                    );
                                    setValue([
                                      {
                                        startDate: data.range1.startDate,
                                        endDate: endDate,
                                      },
                                    ]);
                                  }
                                }}
                                ranges={value}
                              ></DateRange>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    )}
                  </div>
                </ClickAwayListener>
              </div>
            </Grid>

            <Grid style={{ width: "100%" }}>
              {Object.entries(offlineRoomData)?.length > 0 &&
                offlineRoomData?.offlineRoomCount?.map((item) => (
                  <div className="w-100 d-flex">
                    <h3 className="w-25" style={{ marginLeft: "1rem" }}>
                      {moment(item.date, "YYYY-MM-DD").format("DD MMM YYYY")}
                    </h3>
                    <p
                      className="w-75"
                      style={{
                        marginRight: "1rem",
                        marginBottom: "2rem",
                        fontSize: "1.3rem",
                      }}
                    >
                      {item.roomIds.length > 0
                        ? item.roomIds.map((item) => item).join(", ")
                        : "No offline rooms available"}
                    </p>
                  </div>
                ))}
            </Grid>
            <Divider />
          </Grid>
        )}
      </Grid>
    </Grow>
  );
}

export default ShowOfflineRooms;
