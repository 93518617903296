import React, { useState, useEffect } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { SecondaryButton } from "../../../../../Utils/ButtonHelper";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import OutletDetails from "./OutletDetails";
import { useHistory, Link, useParams, useLocation } from "react-router-dom";
import ShopWrapper from "../../ShopWrapper";
import { connect } from "react-redux";
import UnauthorisedAccess from "../../UnauthorisedAccess/UnauthorisedAccess";
import { useTranslation } from "react-i18next";

function OutletConfiguration({ shopAccessControl }) {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId, shopName, shopType } = useParams();

  // tab names to render that particular configuration card
  const [tabName, setTabName] = useState("outlet_details");

  // defining all the outlet configuration cards
  const configurationCards = [
    {
      name: `${t("Outlet details")}`,
      description: `${t(
        "Configure and edit your outlet information, like description, default shop tax, order details, tax identification number, etc."
      )}`,
      tabName: "outletConfiguration",
      icon: <BiIcons.BiDetail size="50px" />,
      tag: null,
      displayStatus: "block",
    },
    {
      name: `${t("Outlet timings")}`,
      description: `${t(
        "Configure open hours, close hours, and other important outlet timings based for any day. "
      )}`,
      tabName: "outlet_timings",
      icon: <BsIcons.BsClockHistory size="50px" />,
      tag: "Upcoming",
      displayStatus: "none",
    },
  ];

  return (
    <ShopWrapper
      contentArea={
        shopAccessControl &&
        shopAccessControl?.["POSAddUpdateShop"] &&
        JSON.parse(shopAccessControl?.["POSAddUpdateShop"])?.read === "YES" ? (
          <Stack className="position-relative mt-2 w-100" spacing={4}>
            {configurationCards.map((item, index) => (
              <Box
                className="border position-relative w-75 rounded border-3 p-5 border-dark"
                key={index}
                style={{
                  display: item.displayStatus,
                }}
              >
                <Stack spacing={4}>
                  <Stack direction="row" spacing={3}>
                    {item.icon}
                    <Stack spacing={2}>
                      <Typography variant="h4" style={{ fontSize: "15px" }}>
                        <Stack spacing={1}>
                          <Typography>{item.name}</Typography>
                          {item.tag && (
                            <Typography
                              className="bg-warning text-light border rounded pl-2 pr-2"
                              style={{ width: "fit-content" }}
                            >
                              {item.tag}
                            </Typography>
                          )}
                        </Stack>
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontSize: "12px", fontWeight: 500 }}
                        className="position-relative w-75 d-flex flex-wrap"
                      >
                        {item.description}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Link
                    to={{
                      pathname: `/outlet/${shopId}/${shopName}/${shopType}/${item.tabName}`,
                      search: `?hotelId=${hotelId}`,
                      state: { index: location.state.index },
                    }}
                  >
                    <Stack className="position-relative d-flex align-items-end">
                      <SecondaryButton
                        text="Edit"
                        syle={{ position: "relative", width: "120px" }}
                        onClick={() => setTabName(item.tabName)}
                      />
                    </Stack>
                  </Link>
                </Stack>
              </Box>
            ))}
          </Stack>
        ) : (
          <UnauthorisedAccess />
        )
      }
    />
  );
}

// export default OutletConfiguration;
function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    shopAccessControl: state.auth.shopAccessControl,
  };
}
export default connect(mapStateToProps)(OutletConfiguration);
