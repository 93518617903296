import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { isUndefined } from "lodash-es";
import React, { useState, useEffect } from "react";
import { Link as NewLink } from "react-router-dom";
import * as Yup from "yup";
import { resetMasterPass, resetPasswordLink } from "../_redux/authCrud";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";

export const NewForgetPasswordPage = (props) => {
  const { t } = useTranslation();
  // Initialize useTranslation hook

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);
  const masterAdminCheck =
    window.location.pathname.split("/")[1] === "masterAdmin" ? true : false;
  const emailId = isUndefined(props.location.state)
    ? ""
    : props.location.state.email;

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required(`${t("Password is required")}`)
      .email(`${t("Enter correct mail Id")}`),
  });
  const MasterResetPassword = Yup.object().shape({
    password: Yup.string().required(`${t("Password is required")}`),
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const sendEmail = async (values) => {
    setLoading(true);
    try {
      const resp = await resetPasswordLink(
        values.email,
        window.location.origin + "/auth/reset"
      );
      setShowSuccess(true);
    } catch (e) {
      setInvalidMessage(e.response.data);
      setInvalid(true);
    } finally {
      setLoading(false);
    }
  };

  const resetMasterPassword = async (values) => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const resp = await resetMasterPass(token, values.password);
      setShowSuccess(true);
    } catch (e) {
      setInvalidMessage(e.response.data);
      setInvalid(true);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { email: emailId },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      sendEmail(values);
    },
  });

  const masterFormik = useFormik({
    initialValues: { password: "" },
    validationSchema: MasterResetPassword,
    onSubmit: async (values) => {
      resetMasterPassword(values);
    },
  });
  return (
    <div>
      {!showSuccess && (
        <form
          noValidate
          onSubmit={
            masterAdminCheck ? masterFormik.handleSubmit : formik.handleSubmit
          }
        >
          <Grid container display="grid" spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h4" paddingBottom={1}>
                {`${t("Forgot your password")}?`}
              </Typography>
              {!masterAdminCheck && (
                <Typography variant="body1">
                  {t("We will send you a link to create new password")}
                </Typography>
              )}
            </Grid>
            {masterAdminCheck && (
              <Grid item xs={12}>
                <TextField
                  value={masterFormik.values.password}
                  onChange={(e) => {
                    masterFormik.setFieldValue("password", e.target.value);
                    if (invalid) setInvalid(false);
                  }}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  onBlur={masterFormik.handleBlur}
                  name="passw"
                  id="passw"
                  autoFocus
                  error={
                    (masterFormik.touched.password &&
                      Boolean(masterFormik.errors.password)) ||
                    invalid
                  }
                  helperText={
                    (masterFormik.touched.password &&
                      masterFormik.errors.password) ||
                    (invalid && `${t("Invalid password")}`)
                  }
                  label="New Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
            )}
            {!masterAdminCheck && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoFocus
                  label={`${t("email")}`}
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue("email", e.target.value);
                    if (invalid) setInvalid(false);
                  }}
                  onBlur={formik.handleBlur}
                  name="email"
                  id="email"
                  error={
                    (formik.touched.email && Boolean(formik.errors.email)) ||
                    invalid
                  }
                  helperText={
                    (formik.touched.email && formik.errors.email) ||
                    (invalid && invalidMessage)
                  }
                ></TextField>
              </Grid>
            )}
            <Grid item container xs={12}>
              <Grid container item xs={6} justifyContent="flex-start">
                <Link underline="hover" component={NewLink} to="/auth/login">
                  <Button size="large" variant="outlined" color="primary">
                    {t("Back")}
                  </Button>
                </Link>
              </Grid>
              <Grid container item xs={6} justifyContent="flex-end">
                <LoadingButton
                  loading={loading}
                  type="submit"
                  variant="custom-button"
                  size="large"
                >
                  {t("Submit")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {showSuccess && (
        <Grid container display="grid" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" paddingBottom={1}>
              {t("Email sent")}
            </Typography>
            <Typography variant="body1">
              {t(
                "If the email you entered is associated with the account you should receive a link to create your new password shortly."
              )}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <NewLink to="/auth/login">
              <Button variant="outlined" color="primary">
                {" "}
                {t("Done")}
              </Button>
            </NewLink>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default NewForgetPasswordPage;
