import React, { useState, useEffect } from "react";
import StockWrapper from "../StockWrapper";
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Modal,
  CircularProgress,
} from "@mui/material";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import {
  handleSpringDataRequest,
  handleSpringDataMutationRequest,
} from "../../../api/index";
import Drawer from "@mui/material/Drawer";
import * as AiIcons from "react-icons/ai";
import moment from "moment";
import { toast } from "material-react-toastify";
import MockLoaderTable from "../../Folios/ViewManagePayments/MockLoaderTable";
import "material-react-toastify/dist/ReactToastify.css";
import { getAllRoomTypess } from "../../RevenueManagement/RatePlans/apis/RatePlanApis";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  paddingTop: "10px",
  paddingBottom: "10px",
};

function AddHousekeepingMaterial() {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { SearchBar } = Search;
  // Check if the operation we are performing is EDIT or DELETE
  const [actionType, setActionType] = useState("create");
  const [rmStockFor, setRMStockFor] = useState([]);

  const [selectedKitchen, setselectedKitchen] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  // Format the raw materials string from the array
  function rawMaterialFormatter(cell, row) {
    let finalStr = "";
    for (let i = 0; i < cell.length; i++) {
      finalStr += `${cell[i].name} - ${cell[i].quantity} ${cell[i].unit}, `;
    }
    finalStr = finalStr.replace(/,\s*$/, "");
    return finalStr;
  }

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      //   hidden: true,
    },

    {
      dataField: "hk_stock_items",
      text: "Hk Items",
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      formatter: rawMaterialFormatter,
    },

    {
      isDummyField: true,
      text: "Actions",
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) => (
        <Stack direction="row" spacing={2}>
          <Tooltip title="Edit item">
            <Typography
              className="editIcon"
              onClick={() => {
                setActionType("update");
                handleAllProducts(row.shop_id);
                setModifiedProductRecipePayload(row);
                toggleDrawer(true);
              }}
            >
              <AiIcons.AiFillEdit size="18px" />
            </Typography>
          </Tooltip>
          <Tooltip title="Delete item">
            <Typography
              className="deleteIcon"
              onClick={() => {
                setModifiedProductRecipePayload(row);
                handleOpenConfirmDeleteModal();
              }}
            >
              <AiIcons.AiFillDelete size="18px" />
            </Typography>
          </Tooltip>
        </Stack>
      ),
      editable: false,
    },
  ];

  // kitchen rm
  const [kitchenRmData, setKitchenRmData] = useState(null);
  const [roomTypeData, setroomTypeData] = useState();
  useEffect(async () => {
    await getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const roomType = await getAllRoomTypess();
      setroomTypeData(roomType);
    } catch (e) {
      console.error(e);
    }
  };

  // Get all product for that particular shop id
  const [products, setProducts] = useState(null);
  const handleAllProducts = () => {
    handleSpringDataRequest(`core/api/v1/hk-stock-rt-combo/get-all`)
      .then((res) => setProducts(res))
      .catch((err) =>
        toast.error("Error in getting items ! Please try again later.")
      );
  };
  const [selectedShopsId, setselectedShopsId] = useState("");
  // Map of units of measurement
  const [units, setUnits] = useState([
    "GRAM",
    "KG",
    "LITRE",
    "DISH",
    "PIECE",
    "GLASS",
    "MM",
    "METER",
    "PKTS",
    "NOS",
    "TIN",
    "BOX",
    "CAN",
    "BUN",
    "BAG",
    "QTY",
    "25 KG BAG",
    "BOTTLE",
    "JAR",
    "MILIGRAM",
    "MILLILITER",
  ]);

  //Selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  // row selection for operations
  // selecting the rows
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((selectedRows) => [...selectedRows, row.id]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x !== row.id));
    }
  };

  // selecting all rows at once
  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  // Modify update rm items
  const [modifyUpdateItems, setModifyUpdateItems] = useState(false);
  const toggleDrawer = (action) => {
    setModifyUpdateItems(action);
  };

  // Payload creation to add / update the product recipe
  const [productRecipePayload, setProductRecipePayload] = useState({
    room_type_id: "",
    hotel_id: hotelId,
    name: "",
    hk_stock_items: [
      {
        id: "",
        name: "",
        quantity: 1.0,
        unit: "GRAM",
      },
    ],
  });

  // Defining the modified payload state for adding and updating
  const [modifiedProductRecipePayload, setModifiedProductRecipePayload] =
    useState(null);

  // Setting the modified payload to the initial state
  function resetNewProductRecipePayload() {
    setModifiedProductRecipePayload(productRecipePayload);
  }
  useEffect(() => {
    resetNewProductRecipePayload();
  }, []);

  // Function to add the new raw materials
  function addNewMaterial() {
    let newRawMaterialPayload = {
      id: "",
      name: "",
      quantity: 1,
      unit: "GRAM",
    };

    let data = { ...modifiedProductRecipePayload };
    data["hk_stock_items"].push(newRawMaterialPayload);
    setModifiedProductRecipePayload(data);
  }

  // Function to update the raw material
  function updateRawMaterial(idx, e) {
    const { name, value } = e.target;
    let data = { ...modifiedProductRecipePayload };
    if (name === "id") {
      const stock = kitchenRmData?.filter((item) => item.id === value);
      data["hk_stock_items"][idx]["name"] = stock[0]?.name;
      data["hk_stock_items"][idx]["unit"] = stock[0]?.unit;
      data["hk_stock_items"][idx][name] = value;
    } else {
      data["hk_stock_items"][idx][name] = value;
    }
    setModifiedProductRecipePayload(data);
  }

  // Function to remove the added raw material
  function removeRawMaterial(idx) {
    let data = { ...modifiedProductRecipePayload };
    data["hk_stock_items"].splice(idx, 1);
    setModifiedProductRecipePayload(data);
  }

  //   Function to add or update a field into the new recipe payload
  function handleChangePR(e) {
    const { name, value } = e.target;

    let data = { ...modifiedProductRecipePayload };
    if (name === "room_type_id") {
      data[name] = value;
      data["name"] = roomTypeData[value];
    } else {
      data[name] = value;
    }
    setModifiedProductRecipePayload(data);
  }

  // loader
  const [loading, setLoading] = useState(false);
  // Creating and updating the payload
  function handleCreateUpdatePayload() {
    setLoading(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/hk-stock-rt-combo/create-update",
      modifiedProductRecipePayload
    )
      .catch((err) => toast.error(err))
      .then(() => setLoading(false))
      .then(() => toggleDrawer(false))
      .then(() => handleGetAllProductRecipes())
      .then(() => resetNewProductRecipePayload());
  }

  // Deleting the menu item
  // Delete confirmation modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  function handleOpenConfirmDeleteModal() {
    setConfirmDeleteModal(true);
  }
  function handleCloseConfirmDeleteModal() {
    setConfirmDeleteModal(false);
  }
  function handleDeleteMenuItem() {
    setLoading(true);
    handleSpringDataMutationRequest(
      "DELETE",
      `core/api/v1/hk-stock-rt-combo/delete-one?roomTypeId=${modifiedProductRecipePayload?.room_type_id}`
    )
      .then((res) => toast.success(res))
      .catch((err) => toast.error(err))
      .then(() => handleGetAllProductRecipes())

      .then(() => setLoading(false))
      .then(() => handleCloseConfirmDeleteModal())
      .then(() => resetNewProductRecipePayload());
  }

  function handleGetKitchenRm() {
    handleSpringDataRequest(`core/api/v1/hk-stock/get-all`)
      .then((res) => {
        setKitchenRmData(res);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetKitchenRm();
  }, []);

  // Get the product recipes
  const [productRecipe, setProductRecipe] = useState(null);
  function handleGetAllProductRecipes() {
    setProductRecipe(null);
    handleSpringDataRequest(`core/api/v1/hk-stock-rt-combo/get-all`)
      .then((res) => setProductRecipe(res))
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetAllProductRecipes();
  }, []);

  //   console.log("modifiedProductRecipePayload", modifiedProductRecipePayload);
  //   console.log("kitchenRmData", kitchenRmData);

  const list = () => (
    <Box role="presentation" className="addUpdateModal">
      <Stack className="headerWrapper">
        <Typography className="heading">
          <strong>Add new recipe</strong>
        </Typography>
        <Typography
          className="close"
          onClick={() => {
            toggleDrawer(false);
            resetNewProductRecipePayload();
          }}
        >
          <AiIcons.AiOutlineClose size="20px" />
        </Typography>
      </Stack>
      <Divider />
      <Stack className="fields">
        <Box sx={{ width: "100%" }} className="field">
          <FormControl fullWidth disabled={actionType === "update" && true}>
            <InputLabel id="demo-simple-select-label">Room Type</InputLabel>

            {roomTypeData && (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Room Type"
                value={modifiedProductRecipePayload?.room_type_id}
                onChange={handleChangePR}
                required="true"
                name="room_type_id"
              >
                {Object?.keys(roomTypeData)?.map((key, index) => (
                  <MenuItem key={key + index} value={key}>
                    {roomTypeData[Number(key)]}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>

        <Typography>
          <strong>Add raw materials (Min. 1 is required)</strong>
        </Typography>
        <p className="text-muted">
           If you cannot find the raw material in the drop-down list, you need
          to add it to the outlet first before you can use it.
        </p>
      </Stack>
      <Stack spacing={2} className="position-relative w-100">
        {modifiedProductRecipePayload?.hk_stock_items?.map((item, index) => (
          <Stack className="position-relative w-100 fields">
            <Box sx={{ width: "25%" }} className="field">
              <FormControl fullWidth disabled={actionType === "update" && true}>
                <InputLabel id="demo-simple-select-label">
                  Raw material
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item.id}
                  label="Raw material"
                  name="id"
                  onChange={(e) => updateRawMaterial(index, e)}
                >
                  {kitchenRmData?.map((material, index) => (
                    <MenuItem value={material.id} key={index}>
                      {material.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextField
              id="outlined-name"
              type="number"
              label="Quantity"
              name="quantity"
              value={item?.quantity}
              onChange={(e) => {
                if (e.target.value >= 0) updateRawMaterial(index, e);
                else toast.error("Please enter positive value");
              }}
              className="field"
              sx={{ width: "20%" }}
              InputProps={{ inputProps: { min: 1 } }}
            />
            <Box sx={{ width: "25%" }} className="field">
              <FormControl fullWidth disabled={true}>
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item.unit}
                  label="Unit"
                  name="unit"
                  onChange={(e) => updateRawMaterial(index, e)}
                >
                  {units?.map((unit, index) => (
                    <MenuItem value={unit} key={index}>
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {index !== 0 && (
              <Typography
                onClick={() => removeRawMaterial(index)}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
              >
                <AiIcons.AiOutlineClose size="20px" />
              </Typography>
            )}
          </Stack>
        ))}
        <Button
          style={{
            width: "fit-content",
            textTransform: "none",
            marginLeft: "20px",
          }}
          disabled={isDisabled}
          onClick={addNewMaterial}
          variant="custom-button"
        >
          Add new raw material
        </Button>
      </Stack>
      <Stack className="ctas" direction="row">
        <Button
          className="cancelBtn"
          onClick={() => {
            toggleDrawer(false);
            resetNewProductRecipePayload();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="custom-button"
          className="successBtn"
          onClick={!loading && handleCreateUpdatePayload}
          disabled={isDisabled ? true : loading & true}
        >
          {!loading ? "Confirm" : <CircularProgress size="15px" />}
        </Button>
      </Stack>
    </Box>
  );
  return (
    <>
      <StockWrapper
        backBtn={false}
        hideDrawer={true}
        stockTypeName={"Recipe Management"}
        contentArea={
          <Stack className="position-relative mt-8">
            {productRecipe ? (
              <ToolkitProvider
                keyField="staff_id"
                data={productRecipe}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Stack
                      direction="row"
                      className="d-flex justify-content-between"
                      spacing={2}
                    >
                      <div className="d-flex">
                        <Stack className="searchBarWrapper">
                          <SearchBar
                            {...props.searchProps}
                            className="hkSearchBar"
                            style={{ height: "33.5px" }}
                          />
                        </Stack>
                      </div>
                      <Stack>
                        <Button
                          variant="custom-button"
                          onClick={() => {
                            setModifiedProductRecipePayload({
                              room_type_id: "",
                              hotel_id: hotelId,
                              name: "",
                              hk_stock_items: [
                                {
                                  id: "",
                                  name: "",
                                  quantity: 1.0,
                                  unit: "GRAM",
                                },
                              ],
                            });
                            setActionType("create");
                            toggleDrawer(true);
                          }}
                        >
                          Add new recipe
                        </Button>
                      </Stack>
                    </Stack>
                    <BootstrapTable
                      {...props.baseProps}
                      tabIndexCell
                      headerWrapperClasses="foo"
                      bodyClasses="tableBody"
                      bordered={false}
                      //   selectRow={selectRow}
                      pagination={paginationFactory()}
                      limitTags={3}
                    />
                  </>
                )}
              </ToolkitProvider>
            ) : (
              <MockLoaderTable />
            )}
          </Stack>
        }
      />
      <Drawer
        anchor={"right"}
        open={modifyUpdateItems}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        {list()}
      </Drawer>
      {/* Modal confirming the deletion of the menu item */}
      <Modal
        open={confirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="deleteModalWrapper">
          <Stack direction="row" className="modalHeaderWrapper">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete {modifiedProductRecipePayload?.recipe_name}
            </Typography>
            <Typography
              id="modal-modal-description"
              onClick={() => {
                handleCloseConfirmDeleteModal();
                resetNewProductRecipePayload();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <AiIcons.AiOutlineClose />
            </Typography>
          </Stack>
          <Divider />
          <Stack className="confirm">
            Are you sure you want to delete{" "}
            {modifiedProductRecipePayload?.recipe_name}. Once done, this cannot
            be rolled back.
          </Stack>
          <Stack direction="row" className="btns">
            <Button
              className="cancelBtn"
              onClick={() => {
                handleCloseConfirmDeleteModal();
                resetNewProductRecipePayload();
              }}
            >
              Cancel
            </Button>
            <Button
              className="successBtn"
              variant="custom-button"
              onClick={!loading && handleDeleteMenuItem}
              disabled={loading && true}
            >
              {!loading ? "Confirm" : <CircularProgress size="15px" />}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default AddHousekeepingMaterial;
