import React, { forwardRef, useEffect, useState } from "react";
import { getUnassignedReservations } from "../api/ReservationApi";
import { InventoryDateformat } from "../../InventoryCalendar/constants/InventoryConstants";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close, HorizontalRule } from "@mui/icons-material";
import moment from "moment";
import {
  channelLogoMap,
  channelLogoMapDash,
  dateFormat,
} from "../constants/Calendar-constants";
import ReassignView from "./ReassignView";
import ReleaseBooking from "./ReleaseBookingModal";
import { Link } from "react-router-dom";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

const UnassignedView = (props, ref) => {
  const { date, closeFunc, data, reload, selectedLanguage } = props;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [unassigned, setUnassigned] = useState([]);
  const [showReassign, setShowReassign] = useState(false);
  const [showRelease, setShowRelease] = useState(false);
  const [eventData, setEventData] = useState({});
  const [cancelRes, setCancelRes] = useState(false);
  const [unAssignedCount, setunAssignedCount] = useState(0);
  const [onHoldBookingCount, setonHoldBookingCount] = useState(0);
  const [enquiriesCount, setenquiriesCount] = useState(0);
  const [stateSelected, setstateSelected] = useState("UNASSIGNED");
  const [showOptions, setShowOptions] = useState([
    { title: "Unassigned", id: "unassign" },
    { title: "Hold", id: "hold" },
  ]);
  const [unassignedCopy, setUnassignedCopy] = useState([]);
  const [searchTerm, setsearchTerm] = useState("");

  useEffect(() => {
    const unAssignedData = unassignedCopy?.filter(
      (item) =>
        ((item.reservationStatus === "CONFIRMED" ||
          item.reservationStatus === "ADMIN_CONFIRMED" ||
          item.reservationStatus === "DONE") &&
          item?.physicalRoomId === "UNASSIGNED") ||
        (item.reservationStatus !== "ON_HOLD" && item?.enquiry === "false")
    );
    setunAssignedCount(unAssignedData);
    const onHolddata = unassignedCopy?.filter(
      (item) =>
        item.reservationStatus === "ON_HOLD" && item?.enquiry === "false"
    );
    setonHoldBookingCount(onHolddata);

    const enquiries = unassignedCopy?.filter(
      (item) => item.reservationStatus === "ON_HOLD" && item?.enquiry === "true"
    );
    setenquiriesCount(enquiries);
  }, [unassignedCopy]);

  useEffect(() => {
    if (stateSelected === "UNASSIGNED") {
      const data = unassignedCopy?.filter(
        (item) =>
          ((item.reservationStatus === "CONFIRMED" ||
            item.reservationStatus === "ADMIN_CONFIRMED" ||
            item.reservationStatus === "DONE") &&
            item?.physicalRoomId === "UNASSIGNED") ||
          (item.reservationStatus !== "ON_HOLD" && item?.enquiry === "false")
      );
      setUnassigned(data);
    } else if (stateSelected === "ONHOLD BOOKING") {
      const data = unassignedCopy?.filter(
        (item) =>
          item.reservationStatus === "ON_HOLD" && item?.enquiry === "false"
      );

      setUnassigned(data);
    } else {
      const data = unassignedCopy?.filter(
        (item) =>
          item.reservationStatus === "ON_HOLD" && item?.enquiry === "true"
      );

      setUnassigned(data);
    }
  }, [unassignedCopy, stateSelected]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setUnassigned([]);
    setUnassignedCopy([]);
    const resp = await getUnassignedReservations({
      startDate: moment(date.start).format(InventoryDateformat),
      endDate: moment(date.end).format(InventoryDateformat),
    });

    // setUnassigned(resp);
    setUnassignedCopy(resp);
  };

  return (
    <Grid container sx={{ width: "100%" }} ref={ref}>
      <Card variant="outlined" sx={{ width: "100%" }}>
        <CardHeader
          title={t("Unassigned and Hold reservations")}
          action={
            <IconButton onClick={closeFunc}>
              <Close></Close>
            </IconButton>
          }
        >
          {" "}
        </CardHeader>
        <CardContent>
          <ButtonGroup
            size="secondary"
            aria-label="large button group"
            className="mb-3"
          >
            <Button
              key="one"
              onClick={(e) => {
                if (stateSelected !== "UNASSIGNED") {
                  setUnassigned([]);
                }
                setstateSelected("UNASSIGNED");
              }}
              // variant={
              //   stateSelected === "UNASSIGNED" ? "custom-button" : "outlined"
              // }
              style={{
                textTransform: "none",
                backgroundColor: `${
                  stateSelected === "UNASSIGNED" ? "#36454F" : "white"
                }`,
                color: `${
                  stateSelected === "UNASSIGNED" ? "white" : "#36454F"
                }`,
                borderColor: "#36454F",
              }}
            >
              {`${t("Unassigned bookings")} (${unAssignedCount?.length})`}
            </Button>
            <Button
              key="Two"
              onClick={(e) => {
                if (stateSelected !== "ONHOLD BOOKING") {
                  setUnassigned([]);
                }
                setstateSelected("ONHOLD BOOKING");
              }}
              // variant={
              //   stateSelected === "ONHOLD BOOKING"
              //     ? "custom-button"
              //     : "outlined"
              // }
              style={{
                textTransform: "none",
                backgroundColor: `${
                  stateSelected === "ONHOLD BOOKING" ? "#36454F" : "white"
                }`,
                color: `${
                  stateSelected === "ONHOLD BOOKING" ? "white" : "#36454F"
                }`,
                borderColor: "#36454F",
              }}
            >
              {`${t("On-hold bookings")} (${onHoldBookingCount?.length})`}
            </Button>
            <Button
              key="THREE"
              style={{
                backgroundColor: `${
                  stateSelected === "ENQUIRY ONHOLD" ? "#36454F" : "white"
                }`,
                color: `${
                  stateSelected === "ENQUIRY ONHOLD" ? "white" : "#36454F"
                }`,
                borderColor: "#36454F",
                textTransform: "none",
              }}
              onClick={(e) => {
                if (stateSelected !== "ENQUIRY ONHOLD") {
                  setUnassigned([]);
                }
                setstateSelected("ENQUIRY ONHOLD");
              }}
              // variant={
              //   stateSelected === "ENQUIRY ONHOLD"
              //     ? "custom-button"
              //     : "outlined"
              // }
            >
              {`${t("Enquiries")} (${enquiriesCount?.length})`}
            </Button>
          </ButtonGroup>

          <div id="external-events">
            <TextField
              onChange={(e) => setsearchTerm(e.target.value)}
              placeholder={t("Search by email")}
              label={t("Search by email, name, phone and bookingId")}
              // className="w-75"
              notched={false}
              type="search"
              size="small"
              style={{
                borderRadius: "5px",
                width: "100%",
                marginBottom: "2rem",
                marginTop: "17px",
              }}
            />
            {unassigned?.filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.bookingId.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val.userName.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val.userEmail.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              } else if (
                val.userPhone.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              }
            })?.length > 0 &&
              unassigned
                ?.filter((val) => {
                  if (searchTerm === "") {
                    return val;
                  } else if (
                    val.bookingId
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.userName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.userEmail
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.userPhone
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((resrv, index) => (
                  <Card
                    key={index}
                    variant="outlined"
                    sx={{ mb: 1 }}
                    className="fc-event"
                  >
                    <CardHeader
                      sx={{ pb: 1 }}
                      title={resrv?.userName}
                      action={
                        <Grid className="d-flex align-items-center">
                          {channelLogoMap[resrv?.source]?.includes(".png") && (
                            <img
                              src={channelLogoMap[resrv?.source]}
                              style={{ height: "2rem" }}
                            />
                          )}

                          <Button
                            onClick={() => {
                              setEventData(resrv);
                              setShowReassign(true);
                            }}
                          >
                            {t("Assign")}
                          </Button>
                        </Grid>
                      }
                    ></CardHeader>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item container>
                          <div className="d-flex flex-column">
                            <Typography
                              style={{
                                color:
                                  resrv.reservationStatus === "ON_HOLD"
                                    ? "#F7D59C"
                                    : "#96C7C1",

                                fontWeight: "bold",
                                marginBottom: "0px",
                              }}
                            >
                              {resrv.reservationStatus === "ADMIN_CONFIRMED"
                                ? `${t("CHECKED-IN")}`
                                : resrv.reservationStatus}
                            </Typography>
                            <Typography>
                              <Link
                                to={{
                                  pathname: `/folio/${resrv.bookingId}`,
                                  search: `?hotelId=${
                                    resrv.bookingId.split("_")[1]
                                  }`,
                                }}
                              >{`${resrv.bookingId}`}</Link>
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item container>
                          <Typography>{`${moment(
                            resrv.displayCheckin,
                            dateFormat
                          ).format("MMM Do, YYYY h A")}`}</Typography>
                          <HorizontalRule
                            sx={{ ml: 1, mr: 1 }}
                            htmlColor="silver"
                          />
                          <Typography>{`${moment(
                            resrv.displayCheckout,
                            dateFormat
                          ).format("MMM Do, YYYY h A")}`}</Typography>
                        </Grid>
                        {resrv.reservationStatus !== "ON_HOLD" && (
                          <Grid item container>
                            <Typography>{`${resrv.ratePlanName}`}</Typography>
                            <HorizontalRule
                              sx={{ ml: 1, mr: 1 }}
                              htmlColor="silver"
                            />
                            <Typography>{`${resrv.type}`}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
          </div>
        </CardContent>
      </Card>
      <div>
        {showReassign && (
          <ReassignView
            open={showReassign}
            handleAddEventSidebar={() => {
              setShowReassign(false);
              setEventData({});
            }}
            getData={getData}
            eventData={eventData}
            refetch={() => {
              reload();
              setShowReassign(false);
              setEventData({});
            }}
            selectedLanguage={selectedLanguage}
          />
        )}
        {showRelease && (
          <ReleaseBooking
            open={showRelease}
            closeModal={() => {
              setShowRelease(false);
              setEventData({});
            }}
            option={!cancelRes ? "hold" : "void"}
            reservationData={eventData}
            clickFunc={() => {
              reload();
              setShowRelease(false);
              setEventData({});
            }}
            selectedLanguage={selectedLanguage}
          />
        )}
      </div>
    </Grid>
  );
};

export default forwardRef(UnassignedView);
