import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const FLEXIBLESLOT = "core/api/v1/flex-slot/add-flexible-slots";
const GETFLEXIBLESLOTS = "core/api/v1/flex-slot/get-flexible-slots";
const GETSLOTMODE = "core/api/v1/flex-slot/get-hotel-slot-mode";
const SETSLOTMODE = "core/api/v1/flex-slot/set-hotel-slot-mode";
const GETROOM = "core/api/v1/room/get-available-rooms";

export const getAllSlotsMode = async () => {
  return axios
    .get(COMMON_URL + GETSLOTMODE)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setHotelSlotMode = async (data, hotelEmail) => {
  return axios
    .post(COMMON_URL + SETSLOTMODE + "?hotelEmail=" + hotelEmail, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addNewFlexibleSlots = async (data) => {
  return axios
    .post(COMMON_URL + FLEXIBLESLOT, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllFlexibleSlots = async (data) => {
  return axios
    .get(
      COMMON_URL +
        GETFLEXIBLESLOTS +
        "?fromDate=" +
        data.fromDate +
        "&toDate=" +
        data.toDate
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAvailableRoom = async (params) => {
  return axios
    .get(COMMON_URL + GETROOM + "/?roomTypeId=" + params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllCustomers = async (search, searchByFeild) => {
  return axios
    .get(
      COMMON_URL +
        "core/api/v1/reservation/fetch-customers-elastic-search" +
        "?search=" +
        search +
        "&searchByFeild=" +
        searchByFeild
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllCustomersWithHotelId = async (
  search,
  searchByFeild,
  hotelId
) => {
  return axios
    .get(
      COMMON_URL +
        "core/api/v1/reservation/fetch-customers-elastic-search" +
        "?search=" +
        search +
        "&searchByFeild=" +
        searchByFeild +
        "&hotelId=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const imageUpdateByEmail = async (oldEmail, newEmail) => {
  return axios
    .get(
      COMMON_URL +
        "core/api/v1/image/update-id-to-new-email" +
        "?oldEmailId=" +
        oldEmail +
        "&newEmailId=" +
        newEmail
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

// export const getAllUnassignedReservations = async (startDate, endDate) => {
//   return axios
//     .get(
//       COMMON_URL +
//         "core/api/v1/reservation/get-all-unassigned-reservations" +
//         "?startDate=" +
//         startDate +
//         "&endDate=" +
//         endDate
//     )
//     .then((resp) => resp.data)
//     .catch((err) => {
//       throw new Error(err);
//     });
// };
