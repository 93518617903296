import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import GroupReportsWrapper from "./GroupReportsWrapper.js";
import {
  filterParams,
  cellRenderer,
  generateOriginalValue,
  handleNumDisplay,
} from "../Reports/utils/ReportUtils";
import moment from "moment";
import i18n from "../../modules/Auth/pages/i18n.js";
import { useTranslation } from "react-i18next";

function GroupSalesReport(props) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);
  // getting the hotel id from the params
  //   const url = new URL(window.location.href);
  //   const hotelId = url.searchParams.get("hotelId");
  //   const hotelCurrency = data.accessControl.hotel.hotel_currency;

  // default hotel currency
  const hotelCurrency = props.data.hotelCurrency;
  // column definitions
  const [columns, setColumns] = useState([
    {
      field: "hotelId",
      headerName: `${t("Hotel Id")}`,
      hide: false,
      dataType: "varchar",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "hotelName",
      headerName: `${t("Hotel Name")}`,
      hide: false,
      dataType: "varchar",
    },
    {
      field: "metric",
      headerName: `${t("Date")}`,
      //filter: "agDateColumnFilter",
      filterParams: filterParams,
      floatingFilterComponentParams: { suppressFilterButton: true },
      dataType: "date",
    },
    {
      field: "oooOccupancy",
      headerName: `${t("Occupancy(%)")}`,
      enableValue: true,
      aggFunc: "avg",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => generateOriginalValue(params, "oooOccupancy"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: () => {
        return " ";
      },
      dataType: "float",
    },
    {
      field: "occupancy",
      headerName: `${t("Full occupany(%)")}`,
      enableValue: true,
      aggFunc: "avg",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => generateOriginalValue(params, "occupancy"),
      valueFormatter: (params) => handleNumDisplay(params),
      pinnedRowValueFormatter: () => {
        return " ";
      },
      dataType: "float",
      hide: true,
    },
    {
      field: "adr",
      headerName: `${t(`ADR (${hotelCurrency})`)}`,
      aggFunc: "avg",
      enableValue: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "adr"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: () => {
        return " ";
      },
      filter: "agNumberColumnFilter",
    },
    {
      field: "oooRevpar",
      headerName: `${t(`RevPar (${hotelCurrency})`)}`,
      hide: true,
      dataType: "float",
      filter: "agNumberColumnFilter",
      aggFunc: "avg",
      valueGetter: (params) => generateOriginalValue(params, "oooRevpar"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: () => {
        return " ";
      },
    },
    {
      field: "totalRooms",
      headerName: `${t("Total Rooms")}`,
      aggFunc: "sum",
      enableValue: true,
      // hide: true,
      valueGetter: (params) => generateOriginalValue(params, "totalRooms"),
      valueFormatter: (params) => handleNumDisplay(params, "integer"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
      filter: "agNumberColumnFilter",
      dataType: "integer",
    },
    {
      field: "netRooms",
      headerName: `${t("Net Rooms")}`,
      aggFunc: "sum",
      enableValue: true,
      // hide: true,
      valueGetter: (params) => generateOriginalValue(params, "netRooms"),
      valueFormatter: (params) => handleNumDisplay(params, "integer"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
      filter: "agNumberColumnFilter",
      dataType: "integer",
    },
    {
      field: "ncResCount",
      headerName: `${t("Rooms sold")}`,
      aggFunc: "sum",
      enableValue: true,
      valueGetter: (params) => generateOriginalValue(params, "ncResCount"),
      valueFormatter: (params) => handleNumDisplay(params, "integer"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
      filter: "agNumberColumnFilter",
      dataType: "integer",
    },
    {
      field: "cResCount",
      headerName: `${t("Cancellations")}`,
      aggFunc: "sum",
      enableValue: true,
      // hide: true,
      valueGetter: (params) => generateOriginalValue(params, "cResCount"),
      valueFormatter: (params) => handleNumDisplay(params, "integer"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
      filter: "agNumberColumnFilter",
      dataType: "integer",
    },
    {
      field: "netResCount",
      headerName: `${t("Net Rooms Sold")}`,
      aggFunc: "sum",
      // enableValue: true,
      dataType: "integer",
      valueGetter: (params) => generateOriginalValue(params, "netResCount"),
      valueFormatter: (params) => handleNumDisplay(params, "integer"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "integer"),
      filter: "agNumberColumnFilter",
      printWidth: "5%",
    },
    {
      field: "netResAmount",
      headerName: `${t(`Room Revenue(${hotelCurrency})`)}`,
      hide: true,
      valueGetter: (params) => generateOriginalValue(params, "netResAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "ncResAmount",
      headerName: `${t(`Net Room Revenue (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "ncResAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "ncResTax",
      headerName: `${t(`Net Room Revenue Tax(${hotelCurrency})`)}`,
      hide: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "ncResTax"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "totalRevenue",
      headerName: `${t(`Net Total Revenue (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "totalRevenue"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "paymentMade",
      headerName: `${t(`Payment Made (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      valueGetter: (params) => generateOriginalValue(params, "paymentMade"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      dataType: "float",
      filter: "agNumberColumnFilter",
    },
    {
      field: "taxableAmount",
      headerName: `${t(`Taxable Revenue (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      // hide: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "taxableAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "texmptAmount",
      headerName: `${t(`Tax exempt revenue (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      // hide: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "texmptAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "cResAmount",
      headerName: `${t(`Cancellations Revenue (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      hide: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "cResAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "cResTax",
      headerName: `${t(`Cancellations Revenue Tax (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      hide: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "cResTax"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "sAmount",
      headerName: `${t(`Net Services Revenue (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "sAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "netTax",
      headerName: `${t(`Net Total Tax (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "netTax"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "grossRevenue",
      headerName: `${t(`Gross Revenue  (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "grossRevenue"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "sTax",
      headerName: `${t(`Net Services Tax  (${hotelCurrency})`)}`,
      aggFunc: "sum",
      enableValue: true,
      hide: true,
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "sTax"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
  ]);

  // Create pinned data
  // Setting the pinned row
  const [pinnedRow, setPinnedRow] = useState(null);
  function createPinnedData(params, rowsData) {
    // let result = {
    //   metric: "Total",
    //   adr: 0,
    //   c_res_amount: 0,
    //   c_res_count: 0,
    //   c_res_tax: 0,
    //   nc_res_amount: 0,
    //   nc_res_count: 0,
    //   net_rooms: 0,
    //   net_tax: 0,
    //   payment_made: 0,
    //   revpar: 0,
    //   s_amount: 0,
    //   s_tax: 0,
    //   taxable_amount: 0,
    //   texempt_amount: 0,
    //   total_revenue: 0,
    //   total_rooms: 0,
    //   gross_revenue: 0,
    //   net_res_count: 0,
    //   ooo_revpar: 0,
    //   texmpt_amount: 0,
    //   ooo_occupancy: 0,
    //   occupancy: 0,
    //   net_res_amount: 0,
    //   nc_res_tax: 0,
    // };
    let result = {};

    const exemptColumns = [
      "oooOccupancy",
      "occupancy",
      "adr",
      "oooRevpar",
      "revpar",
    ];

    for (let i = 0; i < columns.length; i++) {
      if (
        ["integer", "float"].includes(columns[i]["dataType"]) &&
        !exemptColumns.includes(columns[i]["dataType"])
      ) {
        result[columns[i]["field"]] = 0;
      }
    }

    // function updateVals(i) {
    //   result.adr += i.adr;
    //   result.c_res_amount += i.c_res_amount;
    //   result.c_res_count += parseInt(i.c_res_count);
    //   result.c_res_tax += i.c_res_tax;
    //   result.nc_res_amount += i.nc_res_amount;
    //   result.nc_res_count += parseInt(i.nc_res_count);
    //   result.net_rooms += parseInt(i.net_rooms);
    //   result.net_tax += i.net_tax;
    //   result.payment_made += i.payment_made;
    //   result.revpar += i.revpar;
    //   result.s_amount += i.s_amount;
    //   result.s_tax += i.s_tax;
    //   result.taxable_amount += i.taxable_amount;
    //   result.texempt_amount += i.texempt_amount;
    //   result.total_revenue += i.total_revenue;
    //   result.total_rooms += i.total_rooms;
    //   result.gross_revenue += i.gross_revenue;
    //   result.net_res_count += parseInt(i.net_res_count);
    //   result.ooo_revpar += i.ooo_revpar;
    //   result.texmpt_amount += i.texmpt_amount;
    //   result.occupancy += i.occupancy;
    //   result.ooo_occupancy += i.ooo_occupancy;
    //   result.net_res_amount += i.net_res_amount;
    //   result.nc_res_tax += i.nc_res_tax;
    // }

    function updateVals(i) {
      Object.keys(result).forEach((fields) => {
        result[fields] += i[`original_${fields}`];
      });
    }

    params.api.getSelectedRows().length === 0
      ? params.api.forEachNodeAfterFilter((row) => updateVals(row.data))
      : params.api.getSelectedRows().map((row) => updateVals(row));

    // let total_ooo_occupancy = 0.0;
    // let total_occupancy = 0.0;
    // let adr = 0.0;
    // let ooo_revpar = 0.0;

    // Finding out the average values
    // function getAvgValues(item) {
    //   total_ooo_occupancy += Number(item.ooo_occupancy);
    //   total_occupancy += Number(item.occupancy);
    //   adr += Number(item.adr);
    //   ooo_revpar += Number(item.ooo_revpar);
    // }

    // params.api.getSelectedRows().length === 0
    //   ? rowsData.map((row) => getAvgValues(row))
    //   : params.api.getSelectedRows().map((row) => getAvgValues(row));

    // if (params.api.getSelectedRows().length === 0) {
    //   total_ooo_occupancy = total_ooo_occupancy / rowsData.length;
    //   total_occupancy = total_occupancy / rowsData.length;
    //   adr = adr / rowsData.length;
    //   ooo_revpar = ooo_revpar / rowsData.length;
    // } else {
    //   total_ooo_occupancy =
    //     total_ooo_occupancy / params.api.getSelectedRows().length;
    //   total_occupancy = total_occupancy / params.api.getSelectedRows().length;
    //   adr = adr / params.api.getSelectedRows().length;
    //   ooo_revpar = ooo_revpar / params.api.getSelectedRows().length;
    // }

    // result.ooo_occupancy = total_ooo_occupancy;
    // result.occupancy = total_occupancy;
    // result.adr = adr;
    // result.ooo_revpar = ooo_revpar;

    result["metric"] = "Total";

    setPinnedRow([result]);
    params.api.setPinnedBottomRowData([result]);
  }

  return (
    <GroupReportsWrapper
      reportName={`${"Group Sales Report"}`}
      columns={columns}
      reportActualType={`${"sales"}`}
      createPinnedData={createPinnedData}
      pinnedRow={pinnedRow}
      grpEmail={props.grpEmail}
      enableDateTypeFilters={false}
      resetToDefault={props.resetToDefault}
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.hotels[0] };
}
export default connect(mapStateToProps)(GroupSalesReport);
