// ** React Imports
import React, { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { Close, ErrorOutline } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  assignRoom,
  getNonBlockedRooms,
  getUpgradePricePayload,
  invalidateNightAuditReservation,
} from "../api/ReservationApi";

import { PriceModel } from "../models/PricesModel";
import { shallowEqual, useSelector } from "react-redux";
import CommonSlider from "./CommonSlider";
import DimmingDotsLoader from "./LoaderCustom";
import { getAllNonHiddenRoomTypes } from "../../InventoryCalendar/api/InventoryApi";
import { customErrorMessage } from "../constants/Calendar-constants";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { handleSpringDataRequest } from "../../../api";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import { updateEnquiryStatus } from "../../BookingDashboard/apis/BookingDashboardApi";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  footer: {
    margin: theme.spacing(3),
    float: "right",
  },
}));

const ReassignView = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const validationSchema = yup.object({
    perNightPrice: yup.number().required(`${t("Please enter a valid amount")}`),
    priceBeforeTax: yup
      .number()
      .required(`${t("Please enter a valid amount")}`),
    priceAfterTax: yup.number().required(`${t("Please enter a valid amount")}`),
    taxValue: yup.number().required(`${t("Please enter a valid amount")}`),
    numNights: yup.number().nullable(),
    roomId: yup.string().required(`${t("Please select a valid room id")}`),
  });

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const hotelId = hotel.hotelId;
  const currency = hotel.hotelCurrency;
  const { eventData, handleAddEventSidebar, open, refetch, getData } = props;
  let [pricingRequestModel, setPricingRequestModel] = useState(
    new PriceModel({})
  );
  let [loadingData, setLoadingData] = useState(false);
  let [load, setLoad] = useState(false);
  const [showError, setShowError] = useState(false);
  const [roomTypes, setRoomTypes] = useState({});
  const [selectedRoomType, setSelectedRoomType] = useState("");
  const [roomIds, setRoomIds] = useState([]);
  const [checked, setChecked] = useState(false);
  const [showButtonOfPricing, setshowButtonOfPricing] = useState("");

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const performCheckin = async (params) => {
    setLoad(true);
    try {
      await assignRoom(params)
        .then(() =>
          toast.success(
            `${t("Successfully assigned room for")} ${eventData.bookingId}`
          )
        )
        .catch((err) => toast.error(err.response.data));
      await invalidateNightAuditReservation(params.reservationId);

      if (eventData.enquiry === "true") {
        const payload = {
          id: eventData.bookingId,
        };
        try {
          await updateEnquiryStatus(payload);
        } catch (e) {
          toast.error(`${t("Error in confirming the enquiry")}`);
        }
      }

      refetch();
      getData();
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      toast.error(
        typeof message === "string" || message instanceof String
          ? message
          : customErrorMessage
      );
      console.log(e);
      getData();
      handleAddEventSidebar();
    }
  };

  const fetchUpdatedBookingPricing = async (val) => {
    try {
      setLoad(true);
      pricingRequestModel.destRoomTypeId = selectedRoomType;
      const resp = await getUpgradePricePayload(
        pricingRequestModel.getUpgradeBookingparams()
      );

      setData(resp);
      setLoad(false);
      setshowButtonOfPricing("");
    } catch (e) {
      console.log(e);
    }
  };
  const setData = (resp) => {
    formik.setFieldValue(
      "perNightPrice",
      Number(resp.perNightPrice).toFixed(2)
    );
    formik.setFieldValue(
      "priceBeforeTax",
      Number(resp.priceBeforeTax).toFixed(2)
    );
    formik.setFieldValue(
      "priceAfterTax",
      Number(resp.priceAfterTax).toFixed(2)
    );
    formik.setFieldValue("taxValue", Number(resp.taxValue).toFixed(2));
  };

  const fetchInitialPricing = async () => {
    const pricesModel = new PriceModel({
      hotelId: hotelId,
      resIds: eventData.id,
      checkin: eventData.actualCheckin,
      checkout: eventData.actualCheckout,
      newRate: null,
      bookingId: eventData.bookingId,
    });
    setPricingRequestModel(pricesModel);

    pricesModel.destRoomTypeId = eventData.roomType;
    const [rooms, resp, ids] = await Promise.all([
      getAllNonHiddenRoomTypes(),
      getUpgradePricePayload(pricesModel.getUpgradeBookingparams()),
      // getNonBlockedRooms(eventData.roomType, eventData.id),
    ]);
    setData(resp);
    setRoomTypes(rooms);
    setSelectedRoomType(eventData.roomType);
    handleSpringDataRequest(
      `core/api/v1/room/non-blocked-rooms-with-status?roomTypeId=${eventData.roomType}&resId=${eventData.id}`
    )
      .then((res) => {
        setRoomIds(res);
      })
      .catch((err) => console.log(err));
  };

  const fetchCheckinPricing = async () => {
    setLoadingData(true);
    try {
      await fetchInitialPricing();
      setLoadingData(false);
    } catch (e) {
      console.log(e);
      setLoadingData(false);
      setShowError(true);
    }
  };

  const classes = useStyles();

  useEffect(() => {
    fetchCheckinPricing();
  }, []);

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      perNightPrice: 0,
      priceBeforeTax: 0,
      priceAfterTax: 0,
      taxValue: 0,
      roomId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (eventData.enquiry === "true" && checked === false) {
        return toast.error(`${t("Please select check box to proceed.")}`);
      }
      var temporary = {};
      temporary = {
        reservationId: eventData.id,
        physicalRoomId: values.roomId,
        hotelId: hotelId,
        destRoomTypeId: selectedRoomType,
        moveCost: values.priceAfterTax,
      };

      temporary = performCheckin(temporary);
    },
  });

  const refetchIds = async (roomId) => {
    formik.setFieldValue("roomId", "");

    handleSpringDataRequest(
      `core/api/v1/room/non-blocked-rooms-with-status?roomTypeId=${roomId}&resId=${eventData.id}`
    )
      .then((res) => {
        setRoomIds(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={classes.root}>
      <CommonSlider open={open} onClose={handleAddEventSidebar}>
        <Grid container sx={{ height: "100%", width: "100%" }}>
          <Grid container height={"max-content"} className={classes.textField}>
            <Grid item xs={10} alignSelf="center">
              <Typography variant="h5">{t("Assign Room")}</Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                sx={{ float: "right" }}
                onClick={handleAddEventSidebar}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          {loadingData && (
            <Grid
              sx={{ width: "100%", height: "max-content" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <DimmingDotsLoader />
            </Grid>
          )}
          {!showError && !loadingData && (
            <form
              noValidate
              style={{ height: "inherit" }}
              onSubmit={formik.handleSubmit}
            >
              <Grid container className={classes.textField} spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    {`${t("Booking ID")}: ${eventData.bookingId}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {`${t("Customer Name")}: ${eventData.userName}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {`${t("Checkin")}: ${eventData.actualCheckin}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {`${t("Checkout")}: ${eventData.actualCheckout}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{`${t("Roomtype")}: ${
                    eventData.type
                  }`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {`${t("Rate plan")}: ${eventData.ratePlanName}`}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.textField} spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    label={t("Room types")}
                    fullWidth
                    value={selectedRoomType}
                    onChange={(e) => {
                      setSelectedRoomType(e.target.value);
                      refetchIds(e.target.value);
                    }}
                    select
                  >
                    {Object.keys(roomTypes).map((id) => (
                      <MenuItem key={id} value={id}>
                        {roomTypes[id]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    label={t("Room id")}
                    name="roomId"
                    id="roomId"
                    required
                    fullWidth
                    select
                    onChange={(e) => {
                      formik.setFieldValue("roomId", e.target.value);
                      pricingRequestModel.newRate = null;
                      pricingRequestModel.newRateType = "TOTAL_PRETAX";
                      fetchUpdatedBookingPricing(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.roomId && Boolean(formik.errors.roomId)
                    }
                    helperText={formik.touched.roomId && formik.errors.roomId}
                    value={formik.values.roomId}
                  >
                    {roomIds.map((id) => (
                      <MenuItem
                        key={id?.roomId}
                        value={id?.roomId}
                        style={{
                          color: `${
                            id?.dirtyStatus === "CLEAN" ? "#1BC5BD" : "#B96E6E"
                          }`,
                          fontWeight: "bold",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={2}
                          // style={{ display: "flex", justifyContent: "space-between" }}
                        >
                          <Typography sx={{ color: "black" }}>
                            {id.roomId}
                          </Typography>

                          <Typography
                            sx={{
                              color: `${
                                id?.dirtyStatus === "CLEAN"
                                  ? "#1BC5BD"
                                  : "#B96E6E"
                              }`,
                            }}
                          >
                            {id.dirtyStatus}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className="d-flex">
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      required
                      name="perNightPrice"
                      id="perNightPrice"
                      error={
                        formik.touched.perNightPrice &&
                        Boolean(formik.errors.perNightPrice)
                      }
                      helperText={
                        formik.touched.perNightPrice &&
                        formik.errors.perNightPrice
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency}
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t("Per night excluding taxes")}
                      type="number"
                      value={formik.values.perNightPrice}
                      onBlur={formik.handleBlur}
                      disabled={
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map
                            ?.EditBookingPrice
                        ).read !== "YES"
                          ? true
                          : load
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setshowButtonOfPricing("perNightPrice");
                        formik.setFieldValue("perNightPrice", e.target.value);

                        if (e.target.value !== "" && e.target.value !== null) {
                          pricingRequestModel.newRate = e.target.value;
                          pricingRequestModel.newRateType = "PER_NIGHT_PRETAX";
                        }
                      }}
                      label={t("Upgrade cost excluding taxes")}
                    />

                    {showButtonOfPricing === "perNightPrice" && (
                      <Button
                        size="small"
                        style={{ marginLeft: "2rem" }}
                        variant="custom-button"
                        onClick={() => {
                          setLoad(true);
                          fetchUpdatedBookingPricing();
                        }}
                        disabled={load}
                      >
                        {load ? (
                          <CircularProgress
                            size={15}
                            color="inherit"
                            sx={{ ml: 1.5 }}
                          />
                        ) : (
                          `${t("Apply")}`
                        )}
                      </Button>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className="d-flex">
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      required
                      name="priceBeforeTax"
                      id="priceBeforeTax"
                      error={
                        formik.touched.priceBeforeTax &&
                        Boolean(formik.errors.priceBeforeTax)
                      }
                      helperText={
                        formik.touched.priceBeforeTax &&
                        formik.errors.priceBeforeTax
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency}
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t("Total excluding tax")}
                      type="number"
                      value={formik.values.priceBeforeTax}
                      onBlur={formik.handleBlur}
                      disabled={
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map
                            ?.EditBookingPrice
                        ).read !== "YES"
                          ? true
                          : load
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        formik.setFieldValue("priceBeforeTax", e.target.value);
                        setshowButtonOfPricing("priceBeforeTax");
                        if (e.target.value !== "" && e.target.value !== null) {
                          pricingRequestModel.newRate = e.target.value;
                          pricingRequestModel.newRateType = "TOTAL_PRETAX";
                        }
                      }}
                      label={t("Total excluding tax")}
                    />
                    {showButtonOfPricing === "priceBeforeTax" && (
                      <Button
                        size="small"
                        style={{ marginLeft: "2rem" }}
                        variant="custom-button"
                        onClick={() => {
                          setLoad(true);
                          fetchUpdatedBookingPricing("priceBeforeTax");
                        }}
                        disabled={load}
                      >
                        {load ? (
                          <CircularProgress
                            size={15}
                            color="inherit"
                            sx={{ ml: 1.5 }}
                          />
                        ) : (
                          `${t("Apply")}`
                        )}
                      </Button>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TextField
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currency}
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                    name="taxValue"
                    id="taxValue"
                    placeholder={t("Total tax")}
                    disabled
                    type="number"
                    value={formik.values.taxValue}
                    label={t("Total tax")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className="d-flex">
                    <TextField
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency}
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                      name="priceAfterTax"
                      id="priceAfterTax"
                      placeholder={t("Total with tax")}
                      disabled={
                        JSON.parse(
                          hotel?.accessControl?.user_feature_map
                            ?.EditBookingPrice
                        ).read !== "YES"
                          ? true
                          : load
                          ? true
                          : false
                      }
                      type="number"
                      error={Boolean(formik.errors.priceAfterTax)}
                      helperText={formik.errors.priceAfterTax}
                      value={formik.values.priceAfterTax}
                      onChange={(e) => {
                        formik.setFieldValue("priceAfterTax", e.target.value);
                        setshowButtonOfPricing("priceAfterTax");
                        if (e.target.value !== "" && e.target.value !== null) {
                          pricingRequestModel.newRate = e.target.value;
                          pricingRequestModel.newRateType = "TOTAL_WITHTAX";
                        }
                      }}
                      onBlur={formik.handleBlur}
                      label={t("Total with tax")}
                    />

                    {showButtonOfPricing === "priceAfterTax" && (
                      <Button
                        style={{ marginLeft: "2rem" }}
                        size="small"
                        variant="custom-button"
                        onClick={() => {
                          setLoad(true);
                          fetchUpdatedBookingPricing("priceAfterTax");
                        }}
                        disabled={load}
                      >
                        {load ? (
                          <CircularProgress
                            size={15}
                            color="inherit"
                            sx={{ ml: 1.5 }}
                          />
                        ) : (
                          `${t("Apply")}`
                        )}
                      </Button>
                    )}
                  </div>
                </Grid>
                {eventData.enquiry === "true" && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={checked} onChange={handleChange} />
                      }
                      label={t(
                        "Confirm the expired booking inquiry by selecting the checkbox and clicking on 'Assign'"
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Divider />
              <div>
                <Fab
                  className={classes.footer}
                  type="submit"
                  disabled={load || loadingData || showButtonOfPricing !== ""}
                  variant="extended"
                  color="primary"
                >
                  {t("Assign")}

                  {load && (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  )}
                </Fab>
              </div>
            </form>
          )}
          {showError && (
            <Grid
              sx={{ width: "100%", height: "max-content" }}
              container
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <ErrorOutline sx={{ width: "50%", height: "50%" }} color="error">
                {/* <Grid item >
                <Typography> These was some error please try again !</Typography>
              </Grid> */}
              </ErrorOutline>
              <Grid item>
                <Typography>
                  {t("These was some error please try again !")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default ReassignView;
