import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import {
  MenuItem,
  TextField,
  Stack,
  InputLabel,
  FormControl,
  Select,
  Button,
  ClickAwayListener,
  Portal,
  Box,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  NativeSelect,
} from "@mui/material";
import ReportsLoader from "../../Utils/ReportsLoader";
import CustomDateComponent from "../Reports/customDateComponent";
import Currencies from "../../Utils/Currencies.json";
import { defaultStaticRanges } from "../../Utils/DefaultRange";
import { sideBar, checkIfSub } from "../../Utils/Utils";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import { handleDate, handleFolioDate } from "../Reports/utils/ReportUtils";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import CustomReportPdf from "../Reports/utils/CustomReportPdf";
import * as RiIcons from "react-icons/ri";
import axios from "axios";
import { BASE_ENDPOINT, LOAD_SERVER_ENDPOINT } from "../../api/config";
import GroupSidebar from "../GroupSidebar/GroupSidebar";
import { useHistory, useLocation } from "react-router-dom";
import * as MdIcons from "react-icons/md";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const styles = {
  position: "fixed",
  width: "fitContent",
  top: "45%",
  left: "45%",
  transform: "translate(-50%, -50%)",
  p: 1,
  boxShadow: 24,
  bgcolor: "white",
};

const clickAwayListenerStyle = {
  position: "absolute",
  top: 35,
  right: 0,
  left: 0,
  zIndex: 1,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  lineHeight: 24,
  borderRadius: "5px",
};

function GroupReportsWrapper({
  data,
  reportName,
  enableSearch = true,
  enablePagination = true,
  disableDatePicker = false, //when true no datepicker will be shown
  enableRangeCalendar = true, //when true show datepicker with range selector
  enableDateTypeFilters = true,
  enableReportExport = true,
  columns,
  reportActualType,
  createPinnedData = null,
  flex = false,
  enableRoomStatusFilter = false,
  pinnedRow = null,
  hotelFilter = true,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const history = useHistory();
  const location = useLocation();

  // Fetch the state for this url location
  const { state } = location;

  // Get all the hotel ids
  const allHotelIds = Array.from(
    new Set(data.hotels.map((item) => item.hotelId))
  );

  // Getting the url params
  const url = new URL(window.location.href);

  //   Setting the AG grid properties

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [allCols, setAllCols] = useState(null);
  const [colmap, setColmap] = useState(null);

  // default column properties
  const defaultColDefs = {
    sortable: true, //sort the table
    floatingFilter: true, // getting the filter below the columns
    filter: "agMultiColumnFilter", // setting the multi column filter for the table
    display: "subMenu", // how the text filter and the set filter will look like
    components: {
      agDateInput: CustomDateComponent,
    },
    resizable: true,
    cellRendererParams: {
      checkbox: true,
    },
    flex: flex && 1,
  };

  //   Quick Filters & Operations
  // dynamically change the page size
  const onPaginationChange = (pageSize) => {
    gridApi.paginationSetPageSize(pageSize);
  };

  // quick search
  const onFilterTextChange = (event) => {
    // setFilterCount((prevCount) => prevCount + 1);
    gridApi.setQuickFilter(event.target.value);
  };

  // Selection of rows and related operations
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowsLength, setSelectedRowsLength] = useState(0);

  const onSelectionChanged = (params) => {
    createPinnedData(params, rowsData);
    setSelectedRowsLength(params.api.getSelectedRows().length);
    setSelectedRows(params.api.getSelectedRows());
  };

  // Getting the number of enteries
  const [numEnteries, setNumEnteries] = useState(0);
  const [selectedNumEnteries, setSelectedNumEnteries] = useState(0);
  useEffect(() => {
    setSelectedNumEnteries(selectedRowsLength);
    setNumEnteries(rowsData?.length);
  }, [rowsData, selectedRowsLength]);

  const [rowsData, setRowsData] = useState(null);
  const [initialDataLoad, setInitialDataLoad] = useState(null);

  const [selectedHotel, setSelectedHotel] = useState([
    { hotelId: "ALL", hotelName: "All" },
  ]);

  // function onChangeHotel(e) {
  //   const val = e.target.value;
  //   console.log("val : ", val)
  //   if (selectedHotel.includes("ALL")) {
  //     let idx = val.indexOf("ALL");
  //     val.splice(idx, 1);
  //     // const data = selectedHotel.filter((item) => item !== "ALL");
  //     setSelectedHotel(val);
  //   } else {
  //     if (!selectedHotel.includes("ALL") && val.includes("ALL")) {
  //       setSelectedHotel(["ALL"]);
  //     } else {
  //       setSelectedHotel(val);
  //     }
  //   }
  // }
  function onChangeHotel(e, value) {
    const val = value.map((option) => option.hotelId);
    if (selectedHotel.map((hotel) => hotel.hotelId).includes("ALL")) {
      let idx = value.findIndex((hotel) => hotel.hotelId === "ALL");
      value.splice(idx, 1);
      setSelectedHotel(value);
    } else {
      if (
        !selectedHotel.map((hotel) => hotel.hotelId).includes("ALL") &&
        val.includes("ALL")
      ) {
        setSelectedHotel([{ hotelId: "ALL", hotelName: "All" }]);
      } else {
        setSelectedHotel(value);
      }
    }
  }

  // Fetching the report data
  const fetchReportData = () => {
    setInitialDataLoad(null);
    setRowsData(null);
    // if (reportActualType !== "salesReport") {
    axios
      .get(
        `https://loadapi.stayflexi.com/core/api/v1/reports/get-group-report/?type=${reportActualType}&fromDate=${moment(
          datePicker[0].startDate
        ).format("yyyy-MM-DD")}&toDate=${moment(datePicker[0].endDate).format(
          "yyyy-MM-DD"
        )}&emailId=${state.groupEmail}${
          enableDateTypeFilters ? `&mode=${filterDateType}` : ""
        }`
      )
      // Migration from django to java layer
      // .then((res) => setInitialDataLoad(res.data.report_data))
      .then((res) => setInitialDataLoad(res.data))
      .catch((err) => toast.error(err))
      .catch((err) => setInitialDataLoad([]))
      .then(() => setRowsData([]));
    // } else {
    //   axios
    //     .post(`${BASE_ENDPOINT}groupdash/getSalesReport/`, {
    //       group_email: state.groupEmail,
    //       start_date: moment(datePicker[0].startDate).format("yyyy-MM-DD"),
    //       end_date: moment(datePicker[0].endDate).format("yyyy-MM-DD"),
    //       hotels: selectedHotel.includes("ALL") ? allHotelIds : selectedHotel,
    //     })
    //     .then((res) => setRowsData(res.data.report_data))
    //     .catch((err) => toast.error(err))
    //     .catch((err) => setRowsData([]))
    //     .then(() => setInitialDataLoad([]));
    // }
  };

  // Set the reports data as soon as one get the inital data
  function updateRowsData() {
    const selectHotelList = selectedHotel?.map((hotel) => hotel?.hotelId);
    if (selectHotelList.includes("ALL")) {
      setRowsData(initialDataLoad);
    } else {
      const data = initialDataLoad?.filter((item) =>
        selectHotelList.includes(String(item.hotelId))
      );
      setRowsData(data);
    }
  }

  useEffect(() => {
    // if (reportActualType !== "salesReport") {
    if (initialDataLoad && state) {
      if (hotelFilter) {
        updateRowsData();
      } else {
        setRowsData(initialDataLoad);
      }
    }
    // }
  }, [initialDataLoad, selectedHotel, state]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setAllCols(params.columnApi.columnController.columnDefs);
    const mp = new Map();
    const dt = params.columnApi.columnController.columnDefs.map((res, idx) => {
      mp.set(res.field, {
        header: res.headerName,
        hidden: false,
        type: "",
        order: idx + 1,
      });
    });
    setColmap(mp);
    createPinnedData && createPinnedData(params, rowsData);
  };

  // settings the grid Option
  const gridOptions = {
    pagination: true,
    // generation of the pinned bottom row data
    onFilterChanged: (params) => {
      createPinnedData(params, rowsData);
    },
    // pinnedBottomRowData: (params) => {
    //   createPinnedData
    //     ? createPinnedData(params, rowsData)
    //     : createDynamicPinnedData(params, rowsData, true);
    // },
  };

  // Hotels filter
  const [hotelList, setAllHotelList] = useState([]);

  // Update the values of the rows data on changing the filter

  /*  CALENDAR */
  /* 1. MULTI RANGE */
  /* 2. SINGLE DATE */

  // Setting up the date range (multi range)
  const [datePicker, setDatePicker] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // Setting up date (single date range)
  const currentDate = moment().format("yyyy-MM-DD");
  const [formattedDate, setFormattedDate] = useState(currentDate);
  const [date, setDate] = useState(new Date());

  // different date type fiters eg : checkin date, check out date, etc.
  const [filterDateType, setFilterDateType] = useState("staythrough");
  const onChangeDateFilter = (filterType) => {
    setFilterDateType(filterType);
  };

  // useEffect(() => {
  //   if (filterDateType) {
  //     fetchReportData();
  //   }
  // }, [filterDateType]);

  // Filtering based on the availability of the rooms
  const [roomStatusFilter, setRoomStatusFilter] = useState("ALL");
  const onChangeRoomStatusFilter = (event) => {
    return setRoomStatusFilter(event.target.value);
  };

  //   Downloading reports in various formats
  // Click away listener for download options
  const [openDownloadType, setOpenDownloadType] = useState(false);
  const handleOpenDownloadType = () => {
    setOpenDownloadType((prev) => !prev);
  };

  const handleClickAwayDownloadType = () => {
    setOpenDownloadType(false);
  };

  // Calling the grid api and then exporting the data into the csv format
  // const onExportClick = () => {
  //   selectedRowsLength === 0
  //     ? gridApi.exportDataAsCsv()
  //     : gridApi.exportDataAsCsv({ onlySelected: true });
  // };

  // //  Exporting it in the Excel format
  // const onExportExcel = () => {
  //   selectedRowsLength === 0
  //     ? gridApi.exportDataAsExcel()
  //     : gridApi.exportDataAsExcel({ onlySelected: true });
  // };

  const exportOptions = {
    processCellCallback: (params) => {
      const requiredCols = columns
        .filter((col) => col.dataType === "float")
        .map((col) => col.field);
      if (requiredCols.includes(params.column.getColDef().field)) {
        return params.value.toFixed(2);
      }
      return params.value;
    },
  };

  const onExportClick = () => {
    const params = selectedRowsLength === 0 ? {} : { onlySelected: true };
    gridApi.exportDataAsCsv(
      Object.assign({}, params, {
        processCellCallback: exportOptions.processCellCallback,
      })
    );
  };

  const onExportExcel = () => {
    const params = selectedRowsLength === 0 ? {} : { onlySelected: true };
    gridApi.exportDataAsExcel(
      Object.assign({}, params, {
        processCellCallback: exportOptions.processCellCallback,
      })
    );
  };

  //   Handling the date range modal
  const [openDateRange, setOpenDateRange] = useState(false);
  const handleOpenDateRange = () => setOpenDateRange(true);
  const handleClickAway = () => setOpenDateRange(false);

  //   Handling the modal for PDF export
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //   Custom report modal
  const [addCustomReport, setAddCustomReport] = useState(false);
  const openAddCustomReport = () => {
    setAddCustomReport(true);
  };
  const hideAddCustomReport = () => {
    setAddCustomReport(false);
  };

  // For folio report toggling between the balance due toggle
  const [balanceDueOnly, setBalanceDueOnly] = useState(false);
  const onToggleBalanceDue = (e) => {
    if (balanceDueOnly === false) {
      setBalanceDueOnly(true);
    } else if (balanceDueOnly === true) {
      setBalanceDueOnly(false);
    }
  };

  // useEffect(() => {
  //   if (folioReport) {
  //     fetchReportData();
  //   }
  // }, [balanceDueOnly]);

  useEffect(() => {
    fetchReportData();
  }, [date, filterDateType]);

  useEffect(() => {
    if (reportActualType === "salesReport") {
      fetchReportData();
    }
  }, [selectedHotel]);

  function onSaveGridColumnState() {
    if (!gridColumnApi) return;
    let columnState = gridColumnApi?.getColumnState();
    sessionStorage.setItem(reportActualType, JSON.stringify(columnState));
    for (let i = 0; i < columns.length; i++) {
      const updatedState = columnState.filter(
        (item) => item.colId === columns[i]["field"]
      );
      columns[i]["hide"] = updatedState[0]?.hide;
      columns[i]["sort"] = updatedState[0]?.sort;
    }
    sessionStorage.setItem("hkCharterColumn", JSON.stringify(columns));
    // debounceSaveGridColumnState(props.id, columnState);
  }

  function onFirsDataRendered() {
    let columnState = JSON.parse(sessionStorage.getItem(reportActualType));
    if (columnState) {
      return gridColumnApi?.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  }

  // Function to update the row data on filter changed
  let [updatedRowsData, setUpdatedRowsData] = useState(rowsData);
  useEffect(() => {
    setUpdatedRowsData(rowsData);
  }, [rowsData]);
  function updateRowData(params) {
    createPinnedData(params);
    let updatedData = [];
    gridApi.forEachNodeAfterFilterAndSort((i) => {
      updatedData.push(i.data);
    });
    return setUpdatedRowsData(updatedData);
  }

  // Getting the hotel logo
  // const [hotelLogo, setHotelLogo] = useState(null);
  // const getHotelLogo = () => {
  //   handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
  //     .then((res) => setHotelLogo(res))
  //     .catch((err) => console.log(err));
  // };
  // useEffect(() => {
  //   getHotelLogo();
  // }, []);

  // If row data gets changed
  function handleRowData(params) {
    createPinnedData(params, rowsData);
  }

  // Last updated reports val
  const [lastUpdated, setLastUpdated] = useState(null);
  function getTheLastUpdatedValForReports() {
    axios
      .get(
        `https://loadapi.stayflexi.com/core/api/v1/reports/last-updated-time-group-reports/?reportType=${reportActualType}`
      )
      .then((res) => setLastUpdated(res.data))
      .catch((err) => toast.error(err));
  }

  useEffect(() => {
    getTheLastUpdatedValForReports();
  }, [reportActualType]);

  var today = new Date();
  var oneMonthBack = new Date();
  if (today.getMonth() === 0) {
    oneMonthBack.setFullYear(today.getFullYear() - 1);
    oneMonthBack.setMonth(11);
  } else {
    oneMonthBack.setMonth(today.getMonth() - 1);
  }
  var oneMonthFwd = new Date();
  if (today.getMonth() === 11) {
    oneMonthFwd.setFullYear(today.getFullYear() + 1);
    oneMonthFwd.setMonth(1);
  } else {
    oneMonthFwd.setMonth(today.getMonth() + 1);
  }

  return (
    <GroupSidebar
      // hideDrawer={true}
      hideDrawer={false}
      logoPath={"../media/sf-logo/sf_logo_new.png"}
      contentArea={
        <Stack
          sx={{
            position: "realtive",
            width: "100%",
            paddingRight: "15px",
            paddingLeft: "15px",
            marginTop: "35px",
          }}
        >
          <Stack
            direction={"row"}
            style={{
              position: "relative",
              position: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              style={{
                position: "relative",
                width: "95%",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <Typography
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  fontSize: "12.5px",
                }}
              >
                <strong>{reportName}</strong>
              </Typography>
              {enableSearch && (
                <TextField
                  id="outlined-basic"
                  onChange={onFilterTextChange}
                  label={t("Search")}
                  size="small"
                  sx={{ minWidth: 120 }}
                />
              )}
              {enableRangeCalendar ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  {!disableDatePicker && (
                    <Box sx={{ position: "relative" }}>
                      <Stack
                        style={{
                          padding: "2px 3px 2px 3px",
                          borderRadius: "5px",
                          width: "150px",
                          alignContent: "center",
                          justifyContent: "center",
                          height: "35px",
                          backgroundColor: "transparent",
                          border: "1px solid #C4C4C4",
                          textAlign: "center",
                          fontSize: "12.5px",
                        }}
                        onClick={handleOpenDateRange}
                      >
                        {moment(datePicker[0].startDate).format("DD MMM YYYY") +
                          " - " +
                          moment(datePicker[0].endDate).format("DD MMM YYYY")}
                      </Stack>
                      {openDateRange ? (
                        <Portal>
                          <Box sx={styles}>
                            <Stack
                              style={{ position: "relative", width: "100%" }}
                            >
                              <DateRangePicker
                                editableDateInputs={true}
                                onChange={(item) =>
                                  setDatePicker([item.selection])
                                }
                                moveRangeOnFirstSelection={false}
                                ranges={datePicker}
                                staticRanges={
                                  reportName === `${"Group Sales Report"}`
                                    ? []
                                    : defaultStaticRanges
                                }
                                minDate={
                                  reportName === "Group Sales Report"
                                    ? oneMonthBack
                                    : new Date("1900,01,01")
                                }
                                maxDate={
                                  reportName === "Group Sales Report"
                                    ? oneMonthFwd
                                    : new Date("9999,12,30")
                                }
                              />
                              <Button
                                variant="custom-button"
                                onClick={() => {
                                  fetchReportData();
                                  handleClickAway();
                                }}
                                style={{
                                  display: "flex",
                                  position: "relative",
                                  alignSelf: "flex-end",
                                  width: "fit-content",
                                  marginTop: "-40px",
                                }}
                              >
                                {t("Apply")}
                              </Button>
                            </Stack>
                          </Box>
                        </Portal>
                      ) : null}
                    </Box>
                  )}
                </ClickAwayListener>
              ) : (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {!disableDatePicker && (
                    <DesktopDatePicker
                      label={t("Select date")}
                      // inputFormat="yyyy-MM-dd"
                      inputFormat="dd MMM yyyy"
                      value={date}
                      onChange={(item) => setDate(item)}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  )}
                </LocalizationProvider>
              )}
              {enableDateTypeFilters && (
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      {t("Filters")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterDateType}
                      label={t("Filters")}
                      onChange={(e) => onChangeDateFilter(e.target.value)}
                    >
                      <MenuItem value={"staythrough"}>
                        {t("Stay Through")}
                      </MenuItem>
                      <MenuItem value={"checkin"}>{t("Checkin Date")}</MenuItem>
                      <MenuItem value={"checkout"}>
                        {t("Checkout Date")}
                      </MenuItem>
                      <MenuItem value={"booking_made_on"}>
                        {t("Booking Made Date")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {enableRoomStatusFilter && (
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      {t("Filters")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={roomStatusFilter}
                      label={t("Filters")}
                      onChange={onChangeRoomStatusFilter}
                    >
                      <MenuItem value={"ALL"}> {t("All")}</MenuItem>
                      <MenuItem value={"OCCUPIED"}>{t("Occupied")}</MenuItem>
                      <MenuItem value={"AVAILABLE"}>{t("Available")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {enableReportExport && (
                <ClickAwayListener
                  onClickAway={handleClickAwayDownloadType}
                  className="exportOptionWrapper"
                >
                  <Box sx={{ position: "relative" }}>
                    <button
                      type="button"
                      className="exportBtns"
                      style={{ minWidth: "max-content" }}
                      onClick={() => setOpenDownloadType((prev) => !prev)}
                    >
                      {t("Export")}
                      {openDownloadType ? (
                        <RiIcons.RiArrowUpSLine />
                      ) : (
                        <RiIcons.RiArrowDownSLine />
                      )}
                    </button>
                    {openDownloadType ? (
                      <Box sx={clickAwayListenerStyle}>
                        <Typography
                          onClick={() => onExportClick()}
                          className="exportOption"
                          sx={{
                            marginBottom: "10px",
                            marginTop: "10px",
                            marginLeft: "10px",
                            fontSize: "15px",
                            cursor: "pointer",
                            color: "black",
                          }}
                        >
                          {t("Export as CSV")}
                        </Typography>
                        <Typography
                          className="exportOption"
                          sx={{
                            // marginBottom: "10px",
                            marginTop: "10px",
                            marginLeft: "10px",
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                        >
                          <CustomReportPdf
                            reportColumns={columns}
                            reportData={
                              selectedRowsLength > 0
                                ? selectedRows
                                : updatedRowsData
                            }
                            reportName={reportName}
                            dateRange={{
                              startDate: enableRangeCalendar
                                ? datePicker[0]["startDate"]
                                : date,
                              endDate: enableRangeCalendar
                                ? datePicker[0]["endDate"]
                                : date,
                            }}
                            pinnedRow={pinnedRow}
                            isGroupDashboard={true}
                            // hotelLogo={hotelLogo}
                          />
                        </Typography>
                        <Typography
                          onClick={() => onExportExcel()}
                          className="exportOption"
                          sx={{
                            marginBottom: "10px",
                            marginTop: "10px",
                            marginLeft: "10px",
                            fontSize: "15px",
                            cursor: "pointer",
                            color: "black",
                          }}
                        >
                          {t("Export as Excel")}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </ClickAwayListener>
              )}
              {lastUpdated && (
                <i
                  style={{
                    fontSize: "12.5px",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  {t("Last updated")} {lastUpdated}
                </i>
              )}
            </Stack>
            <Stack direction={"row"}>
              <Typography sx={{ marginTop: "auto", marginBottom: "auto" }}>
                <MdIcons.MdKeyboardArrowLeft size="15px" />
              </Typography>
              <Typography
                style={{
                  postion: "relative",
                  width: "5%",
                  fontSize: "12px",
                  fontWeight: 500,
                  cursor: "pointer",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: "7.5px",
                }}
                onClick={() => history.goBack()}
              >
                {t("Back")}
              </Typography>
            </Stack>
          </Stack>

          <Stack>
            {hotelFilter && data && (
              // <Box sx={{ minWidth: 120, maxWidth: 240 }}>
              //   <FormControl fullWidth size="small">
              //     <InputLabel id="demo-simple-select-label">
              //       Hotels
              //     </InputLabel>
              //     <Select
              //       labelId="demo-simple-select-label"
              //       id="demo-simple-select"
              //       value={selectedHotel}
              //       label="Filters"
              //       onChange={onChangeHotel}
              //       multiple
              //     >
              //       <MenuItem value={"ALL"}>All</MenuItem>
              //       {data.hotels.map((item) => (
              //         <MenuItem value={item?.hotelId}>
              //           {item.hotelName}
              //         </MenuItem>
              //       ))}
              //     </Select>
              //   </FormControl>
              // </Box>
              <Box
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  // maxWidth: "fit-content",
                }}
              >
                <FormControl fullWidth size="small" sx={{ padding: "0px" }}>
                  <Autocomplete
                    multiple
                    id="hotels-autocomplete"
                    options={[
                      { hotelId: "ALL", hotelName: "All" },
                      ...data?.hotels?.filter(
                        (hotel) => hotel.status === "ACTIVE"
                      ),
                    ]}
                    limitTags={8}
                    size="small"
                    getOptionLabel={(option) => option.hotelName}
                    value={selectedHotel}
                    onChange={onChangeHotel}
                    disableClearable
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ id: "hotels-autocomplete-label" }}
                        label={t("Hotels")}
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            )}
          </Stack>

          <Stack>
            <Stack className="agGridWrapr position-relative">
              <Stack
                spacing={2}
                className="agGridTableWrapper position-relative w-100"
              >
                {rowsData && initialDataLoad ? (
                  <Stack className="agTable">
                    <Stack className="ag-theme-material">
                      <AgGridReact
                        className="agGridTable"
                        onFirstDataRendered={onFirsDataRendered}
                        onColumnVisible={onSaveGridColumnState}
                        onFilterChanged={updateRowData}
                        onGridReady={onGridReady}
                        onRowDataChanged={handleRowData}
                        rowData={rowsData}
                        columnDefs={columns}
                        defaultColDef={defaultColDefs}
                        enableBrowserTooltips={true}
                        onSelectionChanged={onSelectionChanged}
                        enableMultiRowDragging={false}
                        rowMultiSelectWithClick={false}
                        paginationPageSize={50}
                        alwaysShowBothConditions={true}
                        rowSelection={"multiple"}
                        suppressPivotMode={true}
                        suppressColumnMove={true}
                        suppressRowGroups={true}
                        sideBar={sideBar()}
                        groupMultiAutoColumn={true}
                        exportOptions={exportOptions}
                        statusBar={{
                          statusPanels: [
                            {
                              statusPanel:
                                "agTotalAndFilteredRowCountComponent",
                              align: "left",
                            },
                            {
                              statusPanel: "agTotalRowCountComponent",
                              align: "center",
                            },
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" },
                          ],
                        }}
                        gridOptions={gridOptions}
                        rowHeight={32.5}
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <ReportsLoader />
                )}
              </Stack>
            </Stack>
          </Stack>
          {enablePagination && (
            // <Stack
            //   sx={{
            //     position: "relative",
            //     width: "100%",
            //     display: "flex",
            //     justifyContent: "flex-end",
            //   }}
            // >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: 75,
                marginTop: "-35px",
              }}
            >
              <FormControl fullWidth size="small">
                {/* <InputLabel htmlFor="uncontrolled-native" variant="standard">
                  Pages
                </InputLabel> */}
                {/* <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gridApi?.paginationPageSize}
                  label="Pages"
                  onChange={(e) => onPaginationChange(e.target.value)}
                >
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"20"}>25</MenuItem>
                  <MenuItem value={"50"}>50</MenuItem>
                  <MenuItem value={"100"}>100</MenuItem>
                </Select> */}

                <NativeSelect
                  defaultValue={"50"}
                  value={gridApi?.paginationPageSize}
                  inputProps={{
                    name: "pages",
                    id: "uncontrolled-native",
                  }}
                  id="uncontrolled-native"
                  size="small"
                  onChange={(e) => onPaginationChange(e.target.value)}
                >
                  <option value={"10"}>{t("10")}</option>
                  <option value={"25"}>{t("25")}</option>
                  <option value={"50"}>{t("50")}</option>
                  <option value={"100"}>{t("100")}</option>
                </NativeSelect>
              </FormControl>
            </Box>
            // </Stack>
          )}
        </Stack>
      }
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth };
}

export default connect(mapStateToProps)(GroupReportsWrapper);
