import {
  CircularProgress,
  Divider,
  Grid,
  OutlinedInput,
  Button,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalTitle, Card } from "react-bootstrap";

import { connect, shallowEqual, useSelector } from "react-redux";
import "../../../../pages/AccessControl/Views/AccessControlModal.css";
import { toast } from "material-react-toastify";
import { Table } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../api";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const GroupRoleAccessModal = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);
  const [roleName, setroleName] = useState(
    props?.roleData?.role_name ? props?.roleData?.role_name : ""
  );

  const [flag, setflag] = useState(false);
  const [description, setdescription] = useState(
    props?.roleData?.role_desc ? props?.roleData?.role_desc : ""
  );
  const [status, setstatus] = useState(
    props?.roleData?.status ? props?.roleData?.status : "ACTIVE"
  );
  const [loader, setloader] = useState(false);

  const [permissions, setPermissions] = useState();
  const [selectedRole, setselectedRole] = useState(
    props?.roleData?.role_access
  );
  const [searchTerm, setsearchTerm] = useState("");

  const getAllData = () => {
    handleSpringDataRequest(
      `core/api/v1/groupAccesscontrol/get-initial-access-map?hotelEmail=${props.hotelEmail}`
    )
      .then((res) => {
        const accessMap = Object.entries(res);

        const accessMapData = [];
        const ratePlan = accessMap.map((res) => {
          let obj = {};
          obj["name"] = res[0];
          obj["permissions"] = JSON.parse(res[1]);
          obj["displayName"] = props?.getfetaures[res[0]].display_name;
          accessMapData.push(obj);
        });
        accessMapData.sort(function (a, b) {
          return a.displayName.localeCompare(b.displayName);
        });

        setPermissions(accessMapData);
      })
      .catch((err) => toast.error(`${t("Error in fetching roles")}`));
  };

  useEffect(() => {
    if (props?.roleData) {
      setroleName(props?.roleData?.role_name);
      setdescription(props?.roleData?.role_desc);
      setstatus(props?.roleData?.status);
      setselectedRole(props?.roleData?.role_access);

      const accessMapData = [];
      const ratePlan = Object.entries(props?.roleData?.role_access)?.map(
        (res) => {
          let obj = {};
          obj["name"] = res[0];
          obj["permissions"] = JSON.parse(res[1]);
          obj["displayName"] = props?.getfetaures[res[0]].display_name;
          accessMapData.push(obj);
        }
      );
      accessMapData.sort(function (a, b) {
        return a.displayName.localeCompare(b.displayName);
      });

      setPermissions(accessMapData);
    } else {
      setroleName("");
      setdescription("");
      setstatus("ACTIVE");
      setselectedRole({});
      getAllData();
    }
  }, [props?.roleData]);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const saveRole = () => {
    let flag = false;
    permissions.map((item) => {
      if (item.permissions.read === "YES") {
        flag = true;
        setflag(true);
        return;
      }
    });
    if (flag) {
      const payload = {
        id: props?.roleData?.id ? props?.roleData?.id : null,
        group_id: props.allGroupId,
        role_name: roleName,
        role_desc: description,
        status: status,
      };
      setloader(true);
      if (props?.roleData?.id) {
        handleSpringDataMutationRequest(
          "PUT",
          `core/api/v1/group-roles/update?emailId=${props.hotelEmail}`,
          payload
        )
          .then((res) => {
            saveRoles(res);
          })
          .catch((err) => {
            toast.error(`${t("Something went wrong")}`);
            setloader(false);
          });
      } else {
        handleSpringDataMutationRequest(
          "POST",
          `core/api/v1/group-roles/create?emailId=${props.hotelEmail}`,
          payload
        )
          .then((res) => {
            saveRoles(res);
          })
          .catch((err) => {
            toast.error(`${t("Something went wrong")}`);
            setloader(false);
          });
      }
    } else {
      toast.error(`${t("Please select atleast one access")}`);
    }
  };
  console.log("permissions", permissions);
  const saveRoles = (resp) => {
    const accessMapData = [];
    const ratePlan = permissions.map((res) => {
      let obj = {};
      obj["id"] = null;
      obj["access"] = JSON.stringify(res.permissions);
      obj["role_id"] = props?.roleData?.id ? props?.roleData?.id : resp;
      obj["group_id"] = props.allGroupId;
      obj["feature_id"] = res.name;
      accessMapData.push(obj);
    });
    setloader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/group-roles-feature-set/create-update-bulk?email=${props.hotelEmail}`,
      accessMapData
    )
      .then((res) => {
        toast.success(`${t("Successfully created")}`);
        props.getData();
        props.hide();
        setloader(false);
      })
      .catch((err) => {
        toast.error(`${t("Something went wrong . Try again")}`);
        setloader(false);
      });
  };

  const handleCheckboxChange = (event, row, permission) => {
    const updatedPermissions = permissions?.map((p) => {
      if (p.name === row.name) {
        return {
          ...p,
          permissions: {
            ...p.permissions,
            [permission]: event.target.checked ? "YES" : "NO",
          },
        };
      }
      return p;
    });
    setPermissions(updatedPermissions);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.hide}
        dialogClassName={"myModalBody142321"}
        // animation={false}
        contentClassName={"myModalContentssdsx"}
        // style={{ maxWidth: "65vw" }}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <Button variant="white" onClick={props.hide}>
              {" "}
              X{" "}
            </Button>
          </div>
        </ModalTitle>
        <ModalBody>
          <div style={{ width: "100%" }}>
            <Grid item container xs={12} sx={{ width: "100%", height: "100%" }}>
              <div className="d-flex flex-column">
                <h2>
                  {props?.roleData === undefined
                    ? `${t("Create new group role")} `
                    : `${t(`Edit Role`)}`}
                </h2>
                <p>
                  {props?.roleData === undefined
                    ? `${t(
                        "This page is used for creating roles related to group features. When creating a group role, you can give it a name and description to make it easier to identify what the role is for. This makes it easy to assign the role to the right user later on, just by reading the role name and description."
                      )}`
                    : `${t(
                        "Use this page to edit the existing role and view whatever access is given to a particular role."
                      )}`}
                </p>
              </div>
              <Card style={{ width: "100%", height: "100%" }}>
                <Card.Body style={{ width: "100%" }}>
                  <Grid item xs={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="d-flex flex-column w-100">
                        <div className="w-100">
                          <h6>{t(`Name`)}</h6>
                          <OutlinedInput
                            label={t(`Required`)}
                            notched={false}
                            className="input-lg w-100 mb-2 "
                            placeholder={t("Enter name here")}
                            onChange={(e) => {
                              setroleName(e.target.value);
                            }}
                            value={roleName}
                          />
                        </div>
                        <div className="w-100">
                          <h6>{t("Description")}</h6>

                          <OutlinedInput
                            label={t("Required")}
                            notched={false}
                            className="input-lg w-100 mb-4"
                            placeholder={t("Enter description")}
                            onChange={(e) => {
                              setdescription(e.target.value);
                            }}
                            value={description}
                          />
                        </div>
                        <div className="d-flex">
                          <OutlinedInput
                            onChange={(e) => setsearchTerm(e.target.value)}
                            placeholder={t("Search By Access Control Name")}
                            label={t("Search By Access Control Name")}
                            // className="w-75"
                            notched={false}
                            type="search"
                            size="small"
                            style={{
                              borderRadius: "5px",
                              width: "35%",
                              marginBottom: "2rem",
                              marginTop: "1rem",
                              marginRight: "2rem",
                            }}
                          />
                        </div>
                        {permissions?.length > 0 && (
                          <div
                            style={{
                              overflowY: "scroll",
                              height: "48rem",
                              width: "100%",
                            }}
                          >
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>{t("Name")}</th>
                                  <th>
                                    <Switch
                                      className="mr-2 mb-0"
                                      disabled={searchTerm.length > 0}
                                      checked={permissions?.every(
                                        (p) => p.permissions.read === "YES"
                                      )}
                                      onChange={(e) =>
                                        setPermissions(
                                          permissions?.map((p) => ({
                                            ...p,
                                            permissions: {
                                              ...p.permissions,
                                              read: e.target.checked
                                                ? "YES"
                                                : "NO",
                                            },
                                          }))
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                    {t("All Access")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {permissions
                                  ?.filter((val) => {
                                    if (searchTerm === "") {
                                      return val;
                                    } else if (
                                      val.displayName
                                        .toLowerCase()
                                        .includes(searchTerm.toLowerCase())
                                    ) {
                                      return val;
                                    }
                                  })
                                  ?.map((row) => (
                                    <tr key={row.name}>
                                      <td>
                                        <span className="d-flex align-item-center">
                                          {" "}
                                          <p
                                            className="mr-1 mb-0"
                                            style={{ marginTop: "7px" }}
                                          >{`${
                                            props?.getfetaures[row.name]
                                              .display_name
                                          }`}</p>{" "}
                                          <Tooltip
                                            title={`${
                                              props?.getfetaures[row.name]
                                                .display_description
                                            }`}
                                          >
                                            <IconButton>
                                              <InfoIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </span>
                                      </td>
                                      <td>
                                        <Switch
                                          checked={
                                            row.permissions.read === "YES"
                                          }
                                          onChange={(e) =>
                                            handleCheckboxChange(e, row, "read")
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Card.Body>
                <Button
                  className="float-right mr-4 mt-2"
                  variant="custom-button"
                  style={{ background: "#1BC5BD", marginLeft: "auto" }}
                  onClick={() => saveRole()}
                  disabled={loader}
                >
                  {loader ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    `${t("Save")}`
                  )}
                </Button>
              </Card>
            </Grid>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default GroupRoleAccessModal;

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
