import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { Edit, PowerSettingsNew } from "@mui/icons-material";
import { toast } from "material-react-toastify";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../api";
import GroupRoleAccessModal from "./GroupRoleAccessModal";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

function ReadMore({ children = 100 }) {
  const text = children;

  const [isShow, setIsShowLess] = useState(true);
  const result = isShow ? text.slice(0, 100) : text;

  function toggleIsShow() {
    setIsShowLess(!isShow);
  }

  return (
    <p>
      {result}
      <span className="btn btn-link p-0" onClick={toggleIsShow}>
        {isShow
          ? text.length > 99 && "Read More"
          : text.length > 99 && "Read Less"}
      </span>
    </p>
  );
}
function RoleAccess({ allGroupId, groupEmail, selectedLanguage }) {
  const { t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const [raised, setRaised] = useState({});
  const [hotelRolesData, sethotelRolesData] = useState();
  const [hotelRoleModal, sethotelRoleModal] = useState(false);
  const [editData, setEditData] = useState();
  const [getfetaures, setgetfetaures] = useState({});
  const [loader, setloader] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");
  const [hotelEmail, sethotelEmail] = useState("");

  const toggleRaised = (num) => {
    raised[num] = !raised[num];
    setRaised({ ...raised });
  };
  useEffect(() => {
    sethotelEmail(localStorage.getItem("groupEmail"));
  }, [localStorage.getItem("groupEmail")]);

  useEffect(async () => {
    await getAllData();
  }, []);

  const openRoleModal = (num) => {
    setEditData(num);
    sethotelRoleModal(true);
  };

  const hideRoleModal = () => {
    sethotelRoleModal(false);
    getAllData();
  };

  const getAllData = () => {
    setloader(true);
    handleSpringDataRequest(
      `core/api/v1/group-roles/get-all?email=${localStorage.getItem(
        "groupEmail"
      )}`
    )
      .then((res) => {
        sethotelRolesData(res);
        setloader(false);
      })
      .catch((err) => {
        toast.error(`${t("Error in fetching roles")}`);
        setloader(true);
      });
  };

  const getALLfeature = () => {
    handleSpringDataRequest(
      `core/api/v1/groupAccesscontrol/get-all-global-features`
    )
      .then((res) => {
        setgetfetaures(res);
      })
      .catch((err) => toast.error(`${t("Something went wrong")}`));
  };

  useEffect(() => {
    getALLfeature();
  }, []);

  const getAccess = (access) => {
    const accessMapData = [];
    const data = Object.entries(access)?.map((res) => {
      if (JSON.parse(res[1]).read === "YES") {
        accessMapData.push(getfetaures[res[0]].display_name);
      }
    });
    return accessMapData.toString();
  };

  return (
    <div>
      {" "}
      <Grid container>
        <div className="d-flex flex-column">
          <h2>{t("Roles and Accesss")}</h2>
          <p>
            {t(
              "Use this page to create new group roles and define what access each role will have based on the users responsibilities. You can also edit existing roles if needed. These roles can be assigned to multiple users."
            )}
          </p>
        </div>

        <Grid item container sx={{ marginBottom: 3 }} xs={12}>
          <Grid item xs={6} container justifyContent="flex-start">
            <Button onClick={() => openRoleModal()} variant="custom-button">
              {t("Add new role")}
            </Button>
            <OutlinedInput
              onChange={(e) => setsearchTerm(e.target.value)}
              placeholder={t("Search by role name")}
              label={t("Search by role name")}
              // className="w-75"
              notched={false}
              type="search"
              size="small"
              style={{
                borderRadius: "5px",
                width: "40%",
                marginLeft: "2rem",
              }}
            />
          </Grid>
        </Grid>

        {Object.entries(getfetaures).length > 0 && (
          <GroupRoleAccessModal
            show={hotelRoleModal}
            hide={hideRoleModal}
            getData={getAllData}
            roleData={editData}
            getfetaures={getfetaures}
            hotelEmail={hotelEmail}
            allGroupId={allGroupId}
            selectedLanguage={selectedLanguage}
          ></GroupRoleAccessModal>
        )}
        {Object.entries(getfetaures).length > 0 &&
          hotelRolesData
            ?.filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.role_name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              }
            })
            ?.map((num) => {
              return (
                <Grid key={num?.role_name} item xs={12}>
                  <Card
                    onMouseOver={() => {
                      toggleRaised(num?.role_name);
                    }}
                    onMouseOut={() => {
                      toggleRaised(num?.role_name);
                    }}
                    raised={raised[num?.role_name]}
                    sx={{ marginBottom: 2 }}
                  >
                    <CardHeader
                      title={` ${num?.role_name}`}
                      action={
                        <Grid item spacing={2} container>
                          <Grid item>
                            <Tooltip title={t("Edit Role")}>
                              <IconButton color="primary">
                                {num.status === "ACTIVE" ? (
                                  <Edit onClick={() => openRoleModal(num)} />
                                ) : (
                                  ""
                                )}
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      }
                    >
                      <Divider />
                    </CardHeader>
                    <Divider />
                    <CardContent>
                      <Grid item container spacing={1}>
                        <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                          <ListItemText
                            primary={t("Name")}
                            secondary={num?.role_name}
                          ></ListItemText>
                        </Grid>

                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                          <ListItemText
                            primary={t("Description")}
                            secondary={num?.role_desc}
                          ></ListItemText>
                        </Grid>
                        <Grid item xs={4} sm={8} md={8} lg={8} xl={8}>
                          <ListItemText
                            secondaryTypographyProps={{
                              sx: {
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                              },
                            }}
                            primary={t("Role Access")}
                            secondary={
                              <ReadMore>{getAccess(num?.role_access)}</ReadMore>
                            }
                          ></ListItemText>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
      </Grid>
    </div>
  );
}

export default RoleAccess;
