import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card, Modal } from "react-bootstrap";
import {
  addNewBookingNote,
  addNewCustomerNote,
  deleteTheBookingNotes,
  deleteTheCustomerNotes,
  getAllBookingNotes,
  getAllCustomerNotes,
} from "../../CalendarPage/api/BookingAndCustomerNotesApi";
import {
  CircularProgress,
  OutlinedInput,
  Button,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "material-react-toastify";
import { connect } from "react-redux";
import { getuserBookingImages } from "../../BookingDashboard/apis/BookingDashboardApi";
import { handleSpringDataRequest } from "../../../api";

function BookingInformation({
  onCancelBookingBtn,
  data,
  currency,
  bookingId,
  invoiceSummary,
  hotelType,
}) {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  // formatting the date
  const handleFormatDate = (date) => {
    let formatted_date = moment(date).format("MMM DD, YYYY hh:mm A");
    return formatted_date;
  };

  // Getting the total for add ons / services
  const getTotalAddOnVal = (services) => {
    let totalAmt = 0;
    services.map((service) => {
      if (service.service_id !== "0") {
        totalAmt += parseInt(service.service_amount);
      }
    });
    return totalAmt.toFixed(2);
  };

  // Getting the total tax for add ons / services
  const getTotalTaxAddOnVal = (services) => {
    let totalTaxAmt = 0;
    services.map((service) => {
      if (service.service_id !== "0") {
        totalTaxAmt += parseFloat(service.service_tax);
      }
    });
    return parseFloat(totalTaxAmt).toFixed(2);
  };

  // Handling the rate display
  const handleNumbers = (num) => {
    return parseInt(num).toFixed(2);
  };

  // Return room booking amount
  const roomBookingAmt = () =>
    data.invoice.items
      ?.filter((item) => {
        return item.service_id === "0" && item;
      })
      .map((val) => {
        return Number(val?.service_amount).toFixed(2);
      });

  // console.log(roomBookingAmt());

  // useEffect(() => {
  //   roomBookingAmt();
  // }, []);

  // Return Room Booking Tax
  const roomBookingTax = () =>
    data.invoice.items
      ?.filter((item) => {
        return item.service_id === "0" && item;
      })
      .map((val) => {
        return val?.service_tax.toFixed(2);
      });

  const createBookingNote = async () => {
    setloading(true);
    const payload = {
      hotelId: hotelId,
      bookingID: bookingId,
      note: bookingNoteData,
      logged_in_user: localStorage.getItem("email"),
    };
    const data = await addNewBookingNote(payload);
    setloading(false);
    // if (data.message === "Booking note recorded") {
    //   toast.success("Successfully created the booking note");
    // } else {
    //   toast.error("Something went wrong !");
    // }
    await getAllData();
    handleClose();
  };

  const deleteBookingNote = async (id, notes) => {
    const datas = await deleteTheBookingNotes(
      bookingId,
      id,
      localStorage.getItem("email"),
      notes
    );
    if (datas.message === "Booking note deleted") {
      toast.success("Successfully deleted the booking note");
    } else {
      toast.error("Something went wrong !");
    }
    getAllData();
  };

  //Add Booking Note Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Booking Notes
  const [bookingNotes, setbookingNotes] = useState([]);
  const [loading, setloading] = useState(false);
  const [bookingNoteData, setbookingNoteData] = useState("");

  useEffect(async () => {
    await getAllData();
  }, []);

  const getAllData = async () => {
    try {
      const data = await getAllBookingNotes(bookingId);
      setbookingNotes(data);
    } catch (e) {
      console.error(e);
    }
  };

  const [frontUrl, setfrontUrl] = useState("");
  const [backUrl, setbackUrl] = useState("");
  useEffect(async () => {
    const data = await getuserBookingImages(bookingId);
    setfrontUrl(data.frontUrl);
    setbackUrl(data.backUrl);
  }, []);

  const [accssCode, setaccssCode] = useState(
    Object.entries(data?.invoice?.access_codes)
  );
  const [tags, settags] = useState({});
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/booking-tags/get-all?bookingId=${bookingId}`
    )
      .then((res) => settags(res[0]))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="bookingInformationWrapper">
        <div className="info">
          <div className="infoHeader">Booking ID</div>
          <div className="infoVal">{bookingId}</div>
        </div>
        <div className="info">
          <div className="infoHeader">Room type</div>
          <div className="infoVal">
            {data?.invoice.reservation_room_info_list.map((item) => {
              return item.roomTypeName;
            })}
          </div>
        </div>
        <div className="info">
          <div className="infoHeader">Check-in</div>
          <div className="infoVal">
            {handleFormatDate(data?.invoice?.actual_checkin)}
          </div>
        </div>
        <div className="info">
          <div className="infoHeader">Check-out</div>
          <div className="infoVal">
            {handleFormatDate(data?.invoice?.actual_checkout)}
          </div>
        </div>
        <div className="info">
          <div className="infoHeader">Booking made on</div>
          <div className="infoVal">
            {handleFormatDate(data?.invoice?.booking_made_on)}
          </div>
        </div>
        <div className="info">
          <div className="infoHeader">Rate plan</div>
          <div className="infoVal">{data?.invoice.rate_plan_name}</div>
        </div>
        <div className="info">
          <div className="infoHeader">Adults / Children / Infant </div>
          <div className="infoVal">
            {data?.invoice.adults} / {data?.invoice.children} /{" "}
            {data?.invoice.children1}
          </div>
        </div>
        {/* <div className="info">
          <div className="infoHeader">Children</div>
          <div className="infoVal">{data?.invoice.children}</div>
        </div> */}
        <div className="info">
          <div className="infoHeader">Booked Room No.(s)</div>
          <div className="infoVal">
            {data?.invoice.room_ids_list.map((item) => item)}
          </div>
        </div>

        <div className="info">
          <div className="infoHeader">Room booking amount</div>
          <div className="infoVal">
            {currency} {roomBookingAmt()}
          </div>
        </div>
        <div className="info">
          <div className="infoHeader">Room booking tax</div>
          <div className="infoVal">
            {currency} {roomBookingTax()}
          </div>
        </div>

        <div className="info">
          <div className="infoHeader">Addons amount</div>
          <div className="infoVal">
            {currency} {getTotalAddOnVal(data?.invoice.items)}
          </div>
        </div>
        <div className="info">
          <div className="infoHeader">Addons tax</div>
          <div className="infoVal">
            {currency} {getTotalTaxAddOnVal(data?.invoice.items)}
          </div>
        </div>

        <div className="info">
          <div className="infoHeader">Final amount with tax</div>
          <div className="infoVal">
            {currency} {invoiceSummary?.total_room_amount}
          </div>
        </div>

        <div className="info">
          <div className="infoHeader">Total tax</div>
          <div className="infoVal">
            {currency} {invoiceSummary?.total_tax_amount?.toFixed(2)}
          </div>
        </div>
        <div className="info">
          <div className="infoHeader">Payment made</div>
          <div className="infoVal">{invoiceSummary?.payment_made}</div>
        </div>
        <div className="info">
          <div className="infoHeader">Balance due</div>
          <div className="infoVal">
            {currency} {invoiceSummary?.balance_due}
          </div>
        </div>
        {hotelType === "ISHA" && (
          <div className="info">
            <div className="infoHeader">Tags</div>
            <div className="infoVal">{tags?.tags}</div>
          </div>
        )}
        {hotelType === "ISHA" && (
          <div className="info">
            <div className="infoHeader">Remark</div>
            <div className="infoVal">{tags?.remark}</div>
          </div>
        )}
        <div className="info">
          <div className="infoHeader">OTA booking id</div>
          <div className="infoVal">{invoiceSummary?.ota_booking_id}</div>
        </div>
      </div>
      <div
        className="d-flex info w-100 align-items-center"
        style={{ width: "100%" }}
      >
        <div
          className="infoHeader d-flex"
          style={{ fontWeight: "bold", fontSize: "15px", marginRight: "4rem" }}
        >
          Access code
        </div>
        {accssCode?.map((item) => (
          <p
            className="d-flex mb-0 "
            style={{ fontSize: "15px", color: "black" }}
          >{` ${item[0]} - ${item[1] === "" ? "NA " : item[1]} , `}</p>
        ))}
      </div>
      <div>
        <div
          className="d-flex align-items-center mb-3"
          style={{ marginTop: "3rem" }}
        >
          <h5>Booking Notes</h5>
          <AddIcon
            onClick={handleShow}
            disabled={loading}
            sx={{
              "&:hover": { backgroundColor: "#B5B5C3", borderRadius: "50px" },
            }}
          >
            Add booking note
            {loading && (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            )}
          </AddIcon>
        </div>
        {bookingNotes.length <= 0 ? (
          <h6 className="text-muted">No booking notes </h6>
        ) : (
          bookingNotes?.map((e) => (
            <div className="d-flex align-items-center">
              <Card className="mb-2" style={{ marginLeft: "16px" }}>
                <Card.Body
                  style={{
                    width: "40rem",
                    paddingRight: "1rem",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                  }}
                >
                  {e.notes}
                </Card.Body>
              </Card>
              <DeleteIcon
                onClick={() => deleteBookingNote(e.id, e?.notes)}
                className="ml-2"
                style={{ cursor: "pointer" }}
              />
            </div>
          ))
        )}

        <Modal show={show} onHide={handleClose} style={{ zIndex: "3000" }}>
          <Modal.Header closeButton>
            <Modal.Title>Create a booking note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            {/* <OutlinedInput
              label=""
              notched={false}
              className="input-lg w-100"
              placeholder="Enter a note"
              onChange={(e) => setbookingNoteData(e.target.value)}
            /> */}
            <TextField
              required
              id="outlined-required"
              label="Booking notes"
              placeholder="Enter a note"
              className="w-100"
              onChange={(e) => setbookingNoteData(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="custom-button" onClick={createBookingNote}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* <div className="info">
                    <div className="infoHeader">
                        Number of slots booked
                    </div>
                    <div className="infoVal">
                        {bookingInfo?.num_slots}
                    </div>
                </div> */}

      {/* <div className="info">
                    <div className="infoHeader">
                        Access codes
                    </div>
                    <div className="infoVal" style={{display: 'flex', flexDirection: 'column'}}>
                        {bookingInfo?.reservation_rooms.map((item) => (`${item} : ${bookingInfo?.access_codes[item]}` ))}
                    </div>
                </div> */}

      {/* <div className="cancelBookingBtn">
        <button className="cancelBtn" onClick={onCancelBookingBtn}>
          Cancel
        </button>
      </div> */}
    </>
  );
}

export default BookingInformation;
