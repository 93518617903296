import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Divider,
  Stack,
  Box,
  Breadcrumbs,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Toolbar,
  IconButton,
  Button,
} from "@mui/material";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import * as MdIcons from "react-icons/md";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as BsIcons from "react-icons/bs";
import { connect } from "react-redux";
import MuiAppBar from "@mui/material/AppBar";
import { handleSpringDataRequest } from "../../api";
import StockSidebar from "./StockSidebar";
import { useTranslation } from "react-i18next";

const drawerWidth = 190;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  top: 64,
  backgroundColor: "white",
  boxShadow: "none",
  zIndex: 1,
}));

const Card = ({ name, description, index, pathName, parentindex }) => {
  console.log("getting the parent index : ", parentindex);
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId, shopName, shopType } = useParams();
  const history = useHistory();
  const location = useLocation();

  // set the links while creating the drawer
  const constructPathName = () => {
    return `/${pathName}`;
  };

  return (
    <div
      className="reportsTypeCards"
      onClick={() =>
        history.push({
          pathname: constructPathName(),
          search: `?hotelId=${hotelId}`,
          state: { index: index, parentindex: parentindex },
        })
      }
    >
      <div className="reportsTypeBody">
        <h2>{name}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

function StockWrapper({
  backBtn = false,
  contentArea,
  showReportTypes = true,
  showName = true,
  hideDrawer = false,
  stockTypeName,
}) {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");

  // Opening the drawer
  const [open, setOpen] = useState(true);
  const handleCloseDrawer = () => setOpen(false);

  useEffect(() => {
    if (hideDrawer) {
      handleCloseDrawer();
    }
  }, [hideDrawer]);

  // Defining the breadcrumbs

  // Render the tab based upon the clicking of the tab name
  const [tabName, setTabName] = useState(0);
  useEffect(() => {
    if (location.state) {
      setTabName(location.state.parentindex);
    } else {
      setTabName(0);
    }
  }, [location]);

  const [kitchenConfigData, setkitchenConfigData] = useState([]);
  function handleGetAllKitchenConfig() {
    handleSpringDataRequest("core/api/v1/kitchen/get-all")
      .then((res) => setkitchenConfigData(res))
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetAllKitchenConfig();
  }, []);

  const [stockTypes, setstockTypes] = useState([]);

  // let stockTypes = [
  //   {
  //     parent: "Kitchen config",
  //     parent_index: 0,
  //     parent_link: "kc",
  //     child: [],
  //   },
  //   {
  //     parent: "Raw material supplier",
  //     parent_index: 1,
  //     parent_link: "rms",
  //     child: [],
  //   },
  //   {
  //     parent: "Warehouse raw material",
  //     parent_index: 2,
  //     parent_link: "wrm",
  //     child: [],
  //   },
  //   {
  //     parent: "Kitchen raw material",
  //     parent_index: 3,
  //     parent_link: "krm",
  //     child: [],
  //   },
  //   {
  //     parent: "Closing stock",
  //     parent_index: 4,
  //     parent_link: "cls",
  //     child: [],
  //   },
  //   {
  //     parent: "Raw material wastage",
  //     parent_index: 5,
  //     parent_link: "rmw",
  //     child: [],
  //   },
  //   {
  //     parent: "Product recipe",
  //     parent_index: 6,
  //     parent_link: "pr",
  //     child: [],
  //   },
  // ];

  useEffect(() => {
    if (
      kitchenConfigData?.length === 0 ||
      kitchenConfigData[0].type === "single"
    ) {
      setstockTypes([
        {
          parent: `${t("Central raw material")}`,
          parent_index: 0,
          parent_link: "wrm",
          child: [],
        },
        {
          parent: `${t("Closing stock")}`,
          parent_index: 1,
          parent_link: "cls",
          child: [],
        },
        {
          parent: `${t("Raw material wastage")}`,
          parent_index: 2,
          parent_link: "rmw",
          child: [],
        },
        {
          parent: `${t("Item recipes")}`,
          parent_index: 3,
          parent_link: "pr",
          child: [],
        },
        {
          parent: `${t("Outlet configuration")}`,
          parent_index: 4,
          parent_link: "kc",
          child: [],
        },
        {
          parent: `${t("Purchase")}`,
          parent_index: 5,
          parent_link: "po",
          child: [],
        },
        {
          parent: `${t("Reports")}`,
          parent_index: 6,
          parent_link: "reports",
          child: [],
        },
        {
          parent: `${t("Vendors")}`,
          parent_index: 7,
          parent_link: "rms",
          child: [],
        },
      ]);
    } else {
      setstockTypes([
        {
          parent: `${t("Central raw material")}`,
          parent_index: 0,
          parent_link: "wrm",
          child: [],
        },

        {
          parent: `${t("Closing stock")}`,
          parent_index: 1,
          parent_link: "cls",
          child: [],
        },
        {
          parent: `${t("Raw material wastage")}`,
          parent_index: 2,
          parent_link: "rmw",
          child: [],
        },
        {
          parent: `${t("Item recipes")}`,
          parent_index: 3,
          parent_link: "pr",
          child: [],
        },
        {
          parent: `${t("Raw material")}`,
          parent_index: 4,
          parent_link: "krm",
          child: [],
        },
        {
          parent: `${t("Outlet configuration")}`,
          parent_index: 5,
          parent_link: "kc",
          child: [],
        },
        {
          parent: `${t("Purchase")}`,
          parent_index: 6,
          parent_link: "po",
          child: [],
        },
        {
          parent: `${t("Reports")}`,
          parent_index: 7,
          parent_link: "reports",
          child: [],
        },
        {
          parent: `${t("Vendors")}`,
          parent_index: 8,
          parent_link: "rms",
          child: [],
        },
      ]);
    }
  }, [kitchenConfigData]);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // set the links while creating the drawer
  const constructPathName = (pathName) => {
    return `/${pathName}`;
  };

  const handleSelected = (index) => {
    if (tabName === index) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    handleSelected();
  }, [location?.state?.parentindex]);

  const generateLinkForTabs = (parentindex, parentLink) => {
    history.push({
      pathname: `/stock/${parentLink}`,
      search: `?hotelId=${hotelId}`,
      state: { parentindex: parentindex },
    });
  };

  const drawer = (
    <Stack
      className="ml-1"
      style={{
        position: "relative",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      <List
        style={{
          position: "relative",
          height: "fit-content",
          overflow: "hidden",
          overflowY: "scroll",
          marginBottom: "100px",
          marginTop: "8px",
        }}
      >
        <StockSidebar />
        {/* {stockTypes?.map((item, index) => (
          <Stack className="mt-2 ml-1 position-relative w-100">
            <Stack
              spacing={1}
              direction="row"
              style={{ cursor: "pointer", position: "relative", width: "100%" }}
            >
              <ListItem
                key={index}
                disablePadding
                style={{
                  backgroundColor: "f8f8f8",
                }}
                selected={handleSelected(item?.parent_index)}
                onClick={(event) => {
                  setTabName(item?.parent_index);
                  generateLinkForTabs(item?.parent_index, item?.parent_link);
                }}
              >
                <ListItemButton
                  style={{
                    backgroundColor: "f8f8f8",
                  }}
                >
                  <ListItemText
                    style={{ color: "black" }}
                    primary={item?.parent}
                  />
                </ListItemButton>
              </ListItem>
            </Stack>
          </Stack>
        ))} */}
      </List>
    </Stack>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ boxShadow: "none" }}>
          <Stack style={{ position: "relative", width: "100%" }}>
            <Stack
              className="position-relative"
              spacing={2}
              style={{ width: "100%" }}
            >
              <Stack
                className="position-relative w-100 d-flex justify-content-between"
                direction="row"
              >
                <Stack
                  className="position-relative w-90 d-flex justify-content-start"
                  direction={"row"}
                  spacing={2}
                >
                  <Stack
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      onClick={() => setOpen((prev) => !prev)}
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <BsIcons.BsList size="25px" />
                    </Typography>
                  </Stack>
                  <Stack
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    {showName && (
                      <Typography>
                        <strong
                          style={{
                            fontSize: "15px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {stockTypeName}
                        </strong>
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                {backBtn && (
                  <Stack direction="row" onClick={() => history.goBack()}>
                    <Typography
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginRight: "2px",
                      }}
                    >
                      <MdIcons.MdKeyboardArrowLeft size="20px" />
                    </Typography>
                    <Typography
                      style={{
                        cursor: "pointer",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                      className="mr-15"
                    >
                      {t("Back")}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          display: { xs: "none", sm: "block" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            height: "100%",
            top: 65,
            border: "none",
            boxShadow: 24,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {drawer}
      </Drawer>
      <Main open={open}>{contentArea}</Main>
    </Box>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(StockWrapper);
