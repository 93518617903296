import React, { Fragment, useEffect } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import {
  readableTimeConverter,
  validateAddress,
  handleFullAddString,
} from "../../Utils/Utils";
import { useState } from "react";
import { getMapForTheRatePlans } from "./FolioUtils";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

const borderColor = "#E5e5e5";

const InvoiceDualText = ({ boldText, normalText, color = "black" }) => {
  return (
    <View style={styles.dualText}>
      <Text style={[styles.boldText, { color: color }]}>{boldText}:</Text>
      <Text style={[styles.normalText, { color: color }]}>{normalText}</Text>
    </View>
  );
};

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={[styles.description, { fontSize: "10px" }]}>
      Date {"&"} Description
    </Text>
    <Text style={[styles.qty, { fontSize: "10px" }]}>Type</Text>
    <Text style={[styles.rate, { fontSize: "10px" }]}>Sub-total</Text>
    <Text style={[styles.rate, { fontSize: "10px" }]}>Tax</Text>
    <Text style={[styles.amount, { fontSize: "10px" }]}>Total</Text>
  </View>
);

// Handling customer address
const handleCustomerAddress = (address) => {
  let formattedAddress = "";

  Object.values(address).map(function (add) {
    if (validateAddress(add)) {
      formattedAddress += add + ", ";
    }
  });

  const lastCommaIndex = formattedAddress.lastIndexOf(", ");
  const newFormattedAddress = formattedAddress.substring(0, lastCommaIndex);
  return newFormattedAddress;
};

// Handling the date format
const formatDate = (date) => {
  return moment(date).format("MMM DD YYYY hh:mm A");
};

// generating the guest information table

const InvoiceRoomRow = ({ items, symbol, hotelType, HotelEmail, invoice }) => {
  const rows = items.map((item, index) => (
    <View style={styles.row} key={index}>
      {/* <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flexStart",
          flexWrap: "wrap",
          marginLeft: "15px",
        }}
      > */}
      <Text
        style={{
          // marginBottom: 3,
          fontSize: "10px",
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {item?.roomTypeName}
      </Text>
      <Text
        style={{
          // marginBottom: 3,
          fontSize: "10px",
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {item?.roomId}
      </Text>
      <Text
        style={{
          fontSize: "10px",
          // marginBottom: 5,
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {item?.ratePlanName}
      </Text>
      {hotelType?.toUpperCase() !== "ISHA" &&
        (HotelEmail === "df@stayflexi.com" ||
          HotelEmail === "bookings@revenuefirst.in") && (
          <Text
            style={{
              fontSize: "10px",
              // marginBottom: 5,
              width: "33.33%",
              // marginTop: "10px",
            }}
          >
            {item?.roomRate}
          </Text>
        )}

      <Text
        style={{
          fontSize: "10px",
          // marginBottom: 5,
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {item.adults} Adult(s) {item.children} Children
      </Text>
      {/* <Text
        style={{
          fontSize: "10px",
          // marginBottom: 5,
          width: "25%",
          // marginTop: "10px",
        }}
      >
        {symbol} {parseInt(item.roomRate).toFixed(2)}
      </Text> */}
      {/* </View> */}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

// handle invoice header
const handleInvoiceHeader = (customFolioConfig) => {
  if (
    customFolioConfig?.booking_invoice_header &&
    !customFolioConfig?.booking_invoice_header
      .toLowerCase()
      .includes("null", "n/a")
  ) {
    return customFolioConfig?.booking_invoice_header;
  } else {
    return "Folio";
  }
};

// Check if the data is not null or includes n/a
const checkInvoiceData = (data) => {
  if (data === null) {
    return "";
  } else if (String(data).toLowerCase().startsWith("null")) {
    return "";
  } else if (String(data).toLowerCase().startsWith("n/a")) {
    return "";
  } else {
    return String(data);
  }
};

const handleCustomBookingId = (invoice, customFolioConfig) => {
  if (invoice?.status === "CHECKED_OUT") {
    // return (
    //   String(checkInvoiceData(customFolioConfig.cust_booking_invoice_prefix)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_seq_start)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_invoice_suffix))
    // );
    return String(invoice?.custom_invoice_id);
  } else {
    return "";
  }
};

function PrintEmptyVehicleResCard({
  invoice,
  hotelData,
  symbol,
  customDetails,
  hotelLogo,
  summaryData,
  hotelType,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const CustomerInfoTextView = ({ text }) => {
    return (
      <View style={styles.customerInfoTextViewWrapper}>
        <Text style={styles.customerInfoText}>{text}</Text>
      </View>
    );
  };

  const InlineDivider = () => {
    return <View style={styles.divider2}></View>;
  };

  const RowDivider = () => {
    return <View style={styles.divider3}></View>;
  };

  const HiddenDivider = () => {
    return <View style={styles.hiddenDivider}></View>;
  };

  const ReservationGuestInfo = ({ symbol, invoice, hotelType }) => {
    return (
      <View style={[styles.guestInfoContainer, { fontSize: "18px" }]}>
        <View style={styles.guestDetailsWrapper}>
          <View style={styles.guestDetailView1}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"First name"} />
              {/* <HiddenDivider />
              <CustomerInfoTextView text={"Last name"} /> */}
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView text={""} />
              {/* <InlineDivider />
              <CustomerInfoTextView text={""} /> */}
            </View>
          </View>
          <View style={styles.guestDetailView2}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"# of guest"} />
              {/* <HiddenDivider />
              <CustomerInfoTextView text={" "} /> */}
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView text={""} />
              {/* <InlineDivider />
              <CustomerInfoTextView text={" "} /> */}
            </View>
          </View>
        </View>
        <RowDivider />
        <View style={styles.guestDetailsWrapper}>
          <View style={styles.guestDetailView1}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"Address"} />
              <HiddenDivider />
              <CustomerInfoTextView text={" "} />
              <HiddenDivider />
              <CustomerInfoTextView text={" "} />
              <HiddenDivider />
              <CustomerInfoTextView text={" "} />
              <HiddenDivider />
              <CustomerInfoTextView text={" "} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Email Id"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Phone No."} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Birth date"} />
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
            </View>
          </View>
          <View style={styles.guestDetailView2}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"Rate type"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Rate"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Other charges"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Total charges"} />
              <RowDivider />
              <CustomerInfoTextView text={"Payment type"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Credit card info"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Total payments"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Total deposits"} />
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <RowDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
            </View>
          </View>
        </View>
        <RowDivider />
        <View
          style={{
            position: "relative",
            display: "flex",
            width: "100%",
            flexDirection: "row",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <View
            style={{ position: "relative", width: "50%", textAlign: "center" }}
          >
            <Text style={{ fontSize: "10px" }}>ID information</Text>
          </View>
          <View
            style={{ position: "relative", width: "50%", textAlign: "center" }}
          >
            <Text style={{ fontSize: "10px" }}>Vehicle information</Text>
          </View>
        </View>
        <RowDivider />

        <View style={styles.guestDetailsWrapper}>
          <View style={styles.guestDetailView1}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"ID type"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"ID #"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"ID valid"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"ID country"} />
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={""} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
            </View>
          </View>
          <View style={styles.guestDetailView2}>
            <View style={styles.keyWrapper}>
              <CustomerInfoTextView text={"Vehicle make"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"Model / Year"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"License plate #"} />
              <HiddenDivider />
              <CustomerInfoTextView text={"State"} />
            </View>
            <View style={styles.valueWrapper}>
              <CustomerInfoTextView text={" "} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
              <InlineDivider />
              <CustomerInfoTextView text={" "} />
            </View>
          </View>
        </View>
        <RowDivider />
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "5px",
          }}
        >
          <View style={{ display: "flex", position: "relative" }}>
            <Text style={{ fontSize: "10px" }}>Special requests :</Text>
          </View>
          <View style={{ marginLeft: "10px" }}>
            <Text style={{ fontSize: "10px" }}>
              {invoice?.special_requests}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const InvoiceRoomHeader = ({ symbol, hotelType, HotelEmail }) => (
    <View style={styles.container}>
      <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
        {t("Room type")}
      </Text>

      <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
        {t("Room Ids.")}
      </Text>

      <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
        {t("Rate plan")}
      </Text>

      {hotelType?.toUpperCase() !== "ISHA" &&
        (HotelEmail === "df@stayflexi.com" ||
          HotelEmail === "bookings@revenuefirst.in") && (
          <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
            {t("Room rate")} ({symbol})
          </Text>
        )}
      <Text style={{ width: "33.33%", fontSize: "10px", marginTop: "5px" }}>
        {t("Room occupancy")}
      </Text>
      {/* <Text style={{ width: "25%", fontSize: "10px", marginTop: "5px" }}>
        Room rate
      </Text> */}
    </View>
  );

  // Display cancellation policy
  function showNewCancellationPolicy() {
    let ifShowNewCancellationPolicy = true;

    if (invoice?.hasOwnProperty("cancellationPolicies")) {
      for (let policy of invoice?.cancellationPolicies) {
        const policyArr = policy.split(":");
        const policyDesc = policyArr[1].trim();
        if (policyDesc.length === 0) {
          ifShowNewCancellationPolicy = false;
          break;
        }
      }
    } else {
      ifShowNewCancellationPolicy = false;
    }
    return ifShowNewCancellationPolicy;
  }

  // Function to generate the default cancellation policies
  function handleGenerateCancellationPolicies(invoice, customDetails) {
    return (
      <View>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_before}%{" "}
          {t("of total booking value when cancelled before")}{" "}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_within}% {t("if cancelled within")}{" "}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
      </View>
    );
  }

  return (
    <View
      style={[
        styles.section,
        {
          marginTop: 0,
          paddingTop: String(symbol)?.includes("Rs") ? "45px" : "9px",
        },
      ]}
    >
      <View
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {customDetails &&
          customDetails?.show_logo.toLowerCase() === "yes" &&
          getExtension(hotelLogo) && (
            <View
              style={{
                position: "relative",
                alignSelf: "center",
                width: customDetails?.logo_size,
                marginBottom: "10px",
                paddingRight: "25px",
              }}
            >
              <Image
                style={[styles.image, { width: customDetails?.logo_size }]}
                src={getExtension(hotelLogo)}
              />
            </View>
          )}
        <View
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            width: "70%",
            flexWrap: "wrap",
          }}
        >
          <Text style={{ fontSize: "14px" }}>{hotelData?.hotel_name}</Text>
          <Text style={{ fontSize: "10px", marginTop: "5px", color: "grey" }}>
            {handleFullAddString(hotelData?.hotel_address)}
          </Text>
          <View style={[styles.dualText, { marginTop: "5px" }]}>
            <Text style={[styles.boldText, { color: "grey" }]}>
              {t("Phone :")}
            </Text>
            <Text style={[styles.boldText, { color: "grey" }]}>
              {customDetails &&
              customDetails?.invoice_contact_phone &&
              !customDetails?.invoice_contact_phone
                .toLowerCase()
                .includes("null", "n/a")
                ? customDetails?.invoice_contact_phone
                : hotelData?.hotel_contact}
            </Text>
          </View>
          {hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.dualText}>
              <Text style={[styles.boldText, { color: "grey" }]}>
                {t("Email :")}{" "}
              </Text>
              <Text style={[styles.boldText, { color: "grey" }]}>
                {customDetails &&
                customDetails?.invoice_contact_email &&
                !customDetails?.invoice_contact_email
                  .toLowerCase()
                  .includes("null", "n/a")
                  ? customDetails?.invoice_contact_email
                  : hotelData?.hotel_email}
              </Text>
            </View>
          )}
        </View>
      </View>

      <View style={styles.dividerWrapper}>
        <View style={styles.divider}></View>
      </View>

      <View
        style={{
          position: "relative",
          display: "flex",
        }}
      >
        <View
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Text style={{ fontSize: "12px", color: "grey" }}>
            Registration form
          </Text>
        </View>
        <View
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <View>
            {customDetails?.hide_stayflexi_booking_id.toLowerCase() ===
              "no" && (
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginBottom: 3,
                  color: "grey",
                }}
              >{`${handleInvoiceHeader(customDetails)}#${
                invoice?.bookingid
              }`}</Text>
            )}
            {customDetails && Number(summaryData?.payment_made) > 0 ? (
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginBottom: 3,
                  color: "grey",
                }}
              >{`${handleInvoiceHeader(customDetails)}#${handleCustomBookingId(
                invoice,
                customDetails
              )}`}</Text>
            ) : (
              <Text></Text>
            )}
          </View>
          <View>
            <InvoiceDualText
              boldText={t("Nights")}
              normalText={invoice?.num_nights}
              color={"grey"}
            />
            <InvoiceDualText
              boldText={t("Check-in")}
              normalText={formatDate(invoice?.checkin)}
              color={"grey"}
            />
            <InvoiceDualText
              boldText={t("Check-out")}
              normalText={formatDate(invoice?.checkout)}
              color={"grey"}
            />
            <InvoiceDualText
              boldText={t("Source")}
              normalText={invoice?.booking_source_displayname}
              color={"grey"}
            />
          </View>
        </View>
      </View>
      <View style={{ marginTop: "10px" }}>
        {invoice?.items && (
          <ReservationGuestInfo
            symbol={symbol}
            invoice={invoice}
            hotelType={hotelType}
          />
        )}
      </View>

      {/* Displaying Cancellation Policies */}
      {customDetails &&
        Object.keys(customDetails).length > 0 &&
        customDetails?.show_res_cancellation_policy === "YES" && (
          <View style={{ marginTop: "10px" }}>
            <Text
              style={{
                fontSize: "10px",
                marginTop: 7.5,
                marginBottom: 7.5,
                textDecoration: "underline",
              }}
            >
              {t("Cancellation Policies")}
            </Text>
            {invoice.hasOwnProperty("cancellationPolicies") ? (
              invoice?.cancellationPolicies?.length > 0 &&
              showNewCancellationPolicy() ? (
                <View>
                  {invoice?.cancellationPolicies
                    ?.map((policies) => policies.replace(":", "\n"))
                    .map((policy) => (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 5,
                          flexWrap: "wrap",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {policy}
                        </Text>
                      </View>
                    ))}
                </View>
              ) : (
                handleGenerateCancellationPolicies(invoice, customDetails)
              )
            ) : (
              handleGenerateCancellationPolicies(invoice, customDetails)
            )}
          </View>
        )}

      {/* Displaying Terms and Conditions */}
      {customDetails &&
        Object.keys(customDetails).length > 0 &&
        customDetails?.show_res_terms_and_condition.toLowerCase() === "yes" &&
        (localStorage?.getItem("tnc")?.trim()?.length > 0 ||
          localStorage.getItem("hotel_tnc").length > 0) && (
          <View style={{ marginTop: "10px" }}>
            <Text
              style={{
                fontSize: "10px",
                marginBottom: 5,
                textDecoration: "underline",
              }}
            >
              {t("Terms and Conditions")}
            </Text>
            <Text style={{ fontSize: "9px" }}>
              {/* {hotelData?.hotel_terms_conditions} */}
              {localStorage
                ?.getItem("hotel_tnc")
                ?.replace(/<br\s*\/?>/gi, "\n") ||
                localStorage?.getItem("tnc")?.replace(/<br\s*\/?>/gi, "\n")}
            </Text>
          </View>
        )}

      {/* Displaying guest and hotel signature */}
      {customDetails?.show_signature && (
        <View style={styles.signature}>
          <View style={styles.signatureData}>
            <Text
              style={{ fontSize: "10px", marginBottom: 5, marginTop: "auto" }}
            >
              {t("Guest signature :")}
            </Text>
            {Object.keys(customDetails).length > 0 &&
            invoice?.customer_signature_base64.length > 0 ? (
              <Image
                style={[styles.parentImage, { marginLeft: 5 }]}
                src={getExtension(invoice.customer_signature_base64)}
              />
            ) : (
              <Text style={{ marginLeft: 5 }}>_________________________</Text>
            )}
          </View>
          <View style={styles.signatureData}>
            {symbol === "$" ? (
              <Text style={{ fontSize: "10px", marginBottom: 5 }}>
                {t("Guest Name :")}
              </Text>
            ) : (
              <Text style={{ fontSize: "10px", marginBottom: 5 }}>
                {t("Hotel signature :")}
              </Text>
            )}
            <Text style={{ marginLeft: 5 }}>_________________________</Text>
          </View>
        </View>
      )}
    </View>
  );
}

export default PrintEmptyVehicleResCard;

// Create styles
const styles = StyleSheet.create({
  parentImage: {
    width: "45px",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain cover",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    position: "relative",
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 3,
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "10px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "grey",
    fontSize: "10px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "left",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    textAlign: "center",
    borderRightWidth: 1,
  },
  amount: {
    textAlign: "center",
    width: "15%",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 4,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
  },
  row: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    padding: "8px",
    inlineSize: "auto",
    fontStyle: "bold",
    justifyContent: "space-between",
  },
  resCardWrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: "10px",
  },
  resCardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flexStart",
    fontSize: "10px",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: "4.5px",
    height: 30,
    inlineSize: "auto",
  },
  resCardLeft: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "spaceBetween",
    position: "relative",
    width: "50%",
    flexDirection: "row",
    marginTop: "auto",
    marginBottom: "auto",
  },
  resCardHeader: {
    fontSize: "10px",
    fontWeight: "500",
    width: "40%",
    flexWrap: "wrap",
    display: "flex",
  },
  resCardVal: {
    marginLeft: "7.5px",
    fontSize: "10px",
    flexWrap: "wrap",
    width: "55%",
    display: "flex",
  },
  resCardRight: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "spaceBetween",
    position: "relative",
    width: "50%",
    flexDirection: "row",
    marginLeft: "10px",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingLeft: "10px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  signature: {
    marginTop: 25,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
  dividerWrapper: {
    position: "relative",
    marginBottom: "5px",
  },
  divider: {
    position: "absolute",
    left: 0,
    right: 0,
    borderBottom: "1px solid black",
  },
  guestInfoContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    border: "1px sold black",
  },
  guestDetailsWrapper: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  guestDetailView1: {
    position: "relative",
    width: "50%",
    borderRight: "1px solid black",
    display: "flex",
    flexDirection: "row",
  },
  guestDetailView2: {
    position: "relative",
    width: "50%",
    display: "flex",
    flexDirection: "row",
  },
  guestViewText: {
    fontSize: "10px",
  },
  divider2: {
    position: "relative",
    width: "100%",
    marginTop: "3.5px",
    marginBottom: "3.5px",
    borderBottom: "1px solid #e4e4e4",
  },
  hiddenDivider: {
    position: "relative",
    width: "100%",
    marginTop: "3.5px",
    marginBottom: "3.5px",
    borderBottom: "1px solid white",
  },
  keyWrapper: {
    position: "relative",
    width: "30%",
    borderRight: "1px solid black",
    display: "flex",
  },
  valueWrapper: {
    position: "relative",
    width: "70%",
  },
  divider3: {
    position: "relative",
    width: "100%",
    borderBottom: "1px solid black",
  },
  customerInfoTextViewWrapper: {
    position: "relative",
    height: "14px",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    padding: "1px",
    paddingLeft: "2px",
  },
  customerInfoText: {
    fontSize: "10px",
  },
});
