import { Stack, Button, Box, ClickAwayListener, Divider } from "@mui/material";
import { toast } from "material-react-toastify";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { djangoHandleDataRequests } from "../../../../api";
import ShopWrapper from "../ShopWrapper";
import moment from "moment";
import { DateRange } from "react-date-range";
import { SecondaryButton } from "../../../../Utils/ButtonHelper";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from "react-i18next";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const RestaurantDayWiseBalanceSummaryReoprt = () => {
  const { t } = useTranslation();

  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId } = useParams();

  // Getting the start date as well as the end date to load the data
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Get All report data
  const [reportsData, setreportsData] = useState([]);
  const [copyreportsData, setCopyreportsData] = useState([]);

  useEffect(() => {
    getAllRestaurantReportData();
  }, [dateRange[0]?.startDate, dateRange[0]?.endDate]);

  //Function to get report data
  const getAllRestaurantReportData = () => {
    shopId &&
      djangoHandleDataRequests(
        `pos/restaurantDayWiseBalanceSummary/?shop_id=${shopId}&start_date=${moment(
          dateRange[0].startDate
        ).format("YYYY-MM-DD 00:00:00")}&end_date=${moment(
          dateRange[0].endDate
        ).format("YYYY-MM-DD 23:59:59")}&hotel_id=${hotelId}`
      )
        .then((res) => {
          setreportsData(res);
          setCopyreportsData(res);
          setrowsdats(productsGenerator(res.length, res));
        })
        .catch((err) =>
          toast.error(`${t("Error in getting data! Please try again later.")}`)
        );
  };

  //Rows data generator
  const productsGenerator = (quantity, data) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        shop_name: data[i].shop_name,
        order_date: data[i].order_date,
        my_amount: data[i].my_amount,
        total_discount: data[i].total_discount,
        net_sales: data[i].net_sales,
        total_tax: data[i].total_tax,
        cgst_amount: data[i].cgst_amount,
        sgst_amount: data[i].sgst_amount,
        total_sales: data[i].total_sales,
        settled_amount: data[i].settled_amount,
        balance_amount: data[i].balance_amount,
      });
    }
    return items;
  };

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  //columns of table
  const [rowsdats, setrowsdats] = useState([]);
  const [reportsColumns, setreportsColumns] = useState([]);
  useEffect(() => {
    const names = [
      `${t("Shop Name")}`,
      `${t("Order Date")}`,
      `${t("My amount")}`,
      `${t("Total discount")}`,
      `${t("Net sales")}`,
      `${t("Total tax")}`,
      `${t("CGST Amount")}`,
      `${
        localStorage.getItem("utgstStatus") === "ENABLED" ? "UTGST" : "SGST"
      } Amount`,
      `${t("Total sales")}`,
      `${t("Settled Amount")}`,
      `${t("Balanced Amount")}`,
      `${t("Service charge")}`,
      `${t("Order source")}`,
      `${t("Order status")}`,
    ];
    if (reportsData?.length > 0) {
      let arr = [];
      const array = Object.keys(reportsData[0]);
      for (var i = 0; i < array?.length; i++) {
        arr.push({
          dataField: array[i],
          text: names[i],
          sort: true,
          headerStyle: {
            width: array[i] === "Order source" ? "200px" : "150px",
          },
        });
      }

      setreportsColumns(arr);
    }
  }, [reportsData]);

  return (
    <ShopWrapper
      backBtn={true}
      contentArea={
        <>
          <h1 className="mt-3 mb-3">{t("Outlet day wise balance summary")}</h1>
          <Divider className="mt-2 mb-2" />
          <div className="App">
            {/* {reportsColumns.length > 0 && ( */}
            <ToolkitProvider
              keyField="id"
              data={copyreportsData}
              columns={reportsColumns}
              exportCSV
              search
            >
              {(props) => (
                <div>
                  <Stack
                    className="position-relative w-100 justify-content-between"
                    direction="row"
                  >
                    <Stack
                      className="position-relative"
                      direction="row"
                      spacing={2}
                    >
                      <SearchBar
                        {...props.searchProps}
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          height: "40px",
                        }}
                      />
                      <Stack
                        spacing={4}
                        className="position-relative w-100"
                        style={{
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <Box sx={{ position: "relative" }}>
                            <button
                              onClick={handleClick}
                              className="border border-3 border-dark rounded p-2"
                              style={{ backgroundColor: "transparent" }}
                            >
                              {moment(dateRange[0].startDate).format(
                                "DD MMM YYYY"
                              )}{" "}
                              {" to "}
                              {moment(dateRange[0].endDate).format(
                                "DD MMM YYYY"
                              )}
                            </button>
                            {open ? (
                              <Box sx={styles}>
                                <Stack spacing={3}>
                                  <DateRange
                                    editableDateInputs={true}
                                    onChange={(item) =>
                                      setDateRange([item.selection])
                                    }
                                    moveRangeOnFirstSelection={false}
                                    ranges={dateRange}
                                  />
                                  <Stack
                                    className="position-relative w-100 d-flex justify-content-between"
                                    direction="row"
                                  >
                                    <SecondaryButton
                                      text={t("Cancel")}
                                      onClick={() => setOpen(false)}
                                    />
                                    <Button
                                      variant="custom-button"
                                      onClick={() => handleClickAway()}
                                    >
                                      {t("Apply")}
                                    </Button>
                                  </Stack>
                                </Stack>
                              </Box>
                            ) : null}
                          </Box>
                        </ClickAwayListener>
                      </Stack>
                    </Stack>
                    <ExportCSVButton
                      {...props.csvProps}
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        background: "black",
                        color: "white",
                      }}
                    >
                      {t("Export CSV")}
                    </ExportCSVButton>
                  </Stack>

                  {reportsColumns.length > 0 && (
                    <BootstrapTable
                      pagination={paginationFactory({ sizePerPage: 10 })}
                      {...props.baseProps}
                    />
                  )}
                </div>
              )}
            </ToolkitProvider>
            {/* )} */}
          </div>
        </>
      }
    />
  );
};

export default RestaurantDayWiseBalanceSummaryReoprt;
