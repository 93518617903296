import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const COMMON_PREFIX = "core/api/v1/openAI/";
const INITIATE_CONVERSATION = "initiate-assistant-conversation";
const CONTINUE_CONVERSATION = "continue-assistant-conversation";

export const initiateConversation = async (data) => {
  let baseUrl = COMMON_URL + COMMON_PREFIX;
  return axios
    .post(baseUrl + INITIATE_CONVERSATION, data)
    .then((resp) => resp.data)
    .catch((err) => {
      if (err?.response?.data) {
        throw new Error(err?.response?.data);
      }
      throw new Error(err);
    });
};

export const continueConversation = async (data) => {
  let baseUrl = COMMON_URL + COMMON_PREFIX;
  return axios
    .post(baseUrl + CONTINUE_CONVERSATION, data)
    .then((resp) => resp.data)
    .catch((err) => {
      if (err?.response?.data) {
        throw new Error(err?.response?.data);
      }
      throw new Error(err);
    });
};
