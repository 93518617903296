import React from "react";
import { toast, ToastContainer } from "material-react-toastify";
// import Isha from "./Isha.png";

function PayNowHeader() {
  return (
    <div>
      {" "}
      <img
        src="https://d13hqbjnktcib7.cloudfront.net/bookingChannels/sflogos.png"
        style={{ heigth: "8rem", width: "10rem", marginLeft: "2rem" }}
        alt="logo"
      />
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        wid
      />
    </div>
  );
}

export default PayNowHeader;
