import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import moment from "moment";
import { connect } from "react-redux";
import {
  editBooking,
  getCheckinCheckoutSlots,
} from "../../CalendarPage/api/ReservationApi";
import { handleGetDoorLockDetails } from "../../DashBoardApi";
import { DatePicker } from "@mui/lab";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import i18n from "../../../modules/Auth/pages/i18n";
import axios from "axios";

function EditBooking({
  onCancel,
  bookingId,
  invoiceData,
  hotelId,
  reloadGrid,
  reservationId,
  isGrid,
  data,
  updateInvoice,
  updateSummaryData,
  status,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [checkinSlots, setCheckinSlots] = useState([]);
  const [checkoutSlots, setCheckoutSlots] = useState([]);

  const [priceLoader, setpriceLoader] = useState(true);
  const [showButtonOfPricing, setshowButtonOfPricing] = useState("");

  // upgrade price loader
  const [upgradeLoader, setUpgradeLoader] = useState(false);
  const [checkinDate, setcheckinDate] = useState(
    moment(invoiceData?.invoice?.checkin)
  );
  const [selectedCheckinSlot, setselectedCheckinSlot] = useState("");
  const [selectedCheckoutSLot, setselectedCheckoutSLot] = useState("");
  const [checkoutDate, setcheckoutDate] = useState(
    moment(invoiceData?.invoice?.checkout)
  );

  const handleChange = (newValue) => {
    setcheckinDate(newValue);
    const new_end_date = new Date(new Date(newValue).getTime() + 86400000);
    setcheckoutDate(new_end_date);
  };

  const handleCheckoutChange = (newValue) => {
    setcheckoutDate(newValue);
  };

  useEffect(() => {
    getCheckinSlotsANdCheckoutSLots();
  }, []);

  const getCheckinSlotsANdCheckoutSLots = async () => {
    const payload = {
      checkin: moment(invoiceData?.invoice?.checkin).format(
        "DD-MM-YYYY HH:MM:SS"
      ),
      checkout: moment(invoiceData?.invoice?.checkout).format(
        "DD-MM-YYYY HH:MM:SS"
      ),
    };
    const resp = await getCheckinCheckoutSlots(payload);
    setCheckinSlots(resp?.checkinSlots);
    setCheckoutSlots(resp?.checkoutSlots);
    setselectedCheckinSlot(resp?.checkinSlots[0]);
    setselectedCheckoutSLot(resp?.checkoutSlots[0]);
  };

  // rate types and the rate
  const [rateType, setRateType] = useState("TOTAL_PRETAX");
  const [newRate, setNewRate] = useState(null);

  // handling the room upgrade cost
  const [upgradeCost, setUpgradeCost] = useState(null);
  function handleGetUpgradeCost() {
    setpriceLoader(true);
    invoiceData &&
      String(selectedCheckinSlot).length > 0 &&
      String(selectedCheckoutSLot).length > 0 &&
      handleSpringDataMutationRequest(
        "POST",
        "core/api/v1/reservation/getQuickEditPricePayload",
        {
          checkin: moment(checkinDate).format(
            `DD-MM-YYYY ${selectedCheckinSlot}:00:00`
          ),
          checkout: moment(checkoutDate).format(
            `DD-MM-YYYY ${selectedCheckoutSLot}:00:00`
          ),
          hotelId: hotelId,
          newRate: newRate,
          newRateType: rateType,
          bookingId: bookingId,
        }
      )
        .then((res) => setUpgradeCost(res))
        .then((res) => {
          setpriceLoader(false);
          setshowButtonOfPricing("");
        })
        .catch((err) => console.log(`${t("Error getting the prices.")}`));
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      handleGetUpgradeCost();
    }, 500);
    return () => clearTimeout(timer);
  }, [
    rateType,
    newRate,
    checkinDate,
    checkoutDate,
    selectedCheckinSlot,
    selectedCheckoutSLot,
  ]);

  useEffect(() => {
    if (upgradeCost) {
      let localPricePerNight = parseFloat(upgradeCost.perNightPrice).toFixed(2);
      let localpriceBeforeTax = parseFloat(upgradeCost.priceBeforeTax).toFixed(
        2
      );
      let localtaxValue = parseFloat(upgradeCost.taxValue).toFixed(2);
      let localpriceAfterTax = parseFloat(upgradeCost.priceAfterTax).toFixed(2);
      setPricePerNight(localPricePerNight);
      setTotalBeforeTax(localpriceBeforeTax);
      setTotalTax(localtaxValue);
      setTotalWithTax(localpriceAfterTax);
    }
  }, [upgradeCost]);

  // price per change
  const [pricePerNight, setPricePerNight] = useState(0);
  const handlePricePerNightChange = (event) => {
    setshowButtonOfPricing("perNightPrice");
    setPricePerNight(event.target.value);
  };

  // Total price before tax
  const [totalBeforeTax, setTotalBeforeTax] = useState(null);
  const handleTotalBeforeTaxChange = (event) => {
    setshowButtonOfPricing("priceBeforeTax");
    setTotalBeforeTax(event.target.value);
  };

  // Total tax value
  const [totalTax, setTotalTax] = useState(null);
  const handleTotalTaxChange = (event) => {
    setTotalTax(event.target.value);
  };

  // Total price after tax
  const [totalWithTax, setTotalWithTax] = useState(null);
  const handleTotalWithTaxChange = (event) => {
    setshowButtonOfPricing("priceAfterTax");
    setTotalWithTax(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    checkinSlots?.length > 0 && setselectedCheckinSlot(checkinSlots[0]);
    checkoutSlots?.length > 0 && setselectedCheckoutSLot(checkoutSlots[0]);
  }, [checkinSlots, checkoutSlots]);

  const handleEditReservation = async () => {
    setUpgradeLoader(true);
    const payload = {
      bookingId: bookingId,
      checkin: moment(checkinDate).format(
        `DD-MM-YYYY ${selectedCheckinSlot}:00:00`
      ),
      checkout: moment(checkoutDate).format(
        `DD-MM-YYYY ${selectedCheckoutSLot}:00:00`
      ),
      editCost: totalWithTax,
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/edit-booking`,
        payload
      );

      /* XYTRON CALL IS NOT REQUIRED FOR THE EDIT BOOKING */
      // if (status === "CONFIRMED") {
      //   await handleGetDoorLockDetails({
      //     bookingId: bookingId,
      //     reservationId: reservationId,
      //     actionRequired: "recreate",
      //   });
      // }
      updateInvoice();
      updateSummaryData();
      invalidateNightAuditBooking(bookingId);
      setUpgradeLoader(false);
      toast.success(`${t("Successfully edited reservation for")} ${bookingId}`);
    } catch (err) {
      toast.error(
        `${t("Rooms not available. Please re-try some other dates.")}`
      );
      setUpgradeLoader(false);
    }

    // handleSpringDataMutationRequest(
    //   "PUT",
    //   `core/api/v1/reservation/edit-booking`,
    //   payload
    // )
    //   .then((res) => {
    //     toast.success("Successfully edited reservation");
    //     updateInvoice();
    //     updateSummaryData();
    //     setUpgradeLoader(false);
    //   })
    //   .catch((err) => {
    //     toast.error("Rooms not available. Please re-try some other dates.");
    //     setUpgradeLoader(false);
    //   });

    // const data = await editBooking(payload);

    // if (data !== "Rooms not available. Please re-try some other dates.") {
    //   toast.success("Successfully edited reservation");
    //   updateInvoice();
    //   updateSummaryData();
    // } else {
    //   toast.error(data);
    // }
  };

  const invalidateNightAuditBooking = (id) => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/invalidate-night-audit-tracker-booking?bookingId=${id}`
    )
      .then((res) => console.log())
      .catch((err) => console.error(err));
  };

  return (
    <Stack m={3}>
      <Stack spacing={4}>
        {/* <Stack spacing={1}>
          <h4>Select the room id to upgrade room</h4>

          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Room ids</InputLabel>
            <Select
              value={selectedRooms}
              onChange={handleChanges}
              required="true"
              MenuProps={MenuProps}
              label="Room ids"
            >
              {invoiceData?.invoice?.room_ids_list?.map((name) => (
                <MenuItem key={name.id} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack> */}

        <Stack direction="row" spacing={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={t("Checkin")}
              inputFormat="dd MMM, yyyy"
              value={checkinDate}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  disabled
                  size="small"
                  InputProps={{ readOnly: true }}
                  {...params}
                />
              )}
              disabled={status === "ADMIN_CONFIRMED"}
            />
            {/* <DesktopDatePicker
              label="Checkin"
              disabled={status === "ADMIN_CONFIRMED"}
              inputFormat="dd/MM/yyyy"
              value={checkinDate}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} size="small" />}
            /> */}
          </LocalizationProvider>
          <FormControl
            disabled={status === "ADMIN_CONFIRMED"}
            sx={{ width: "100%" }}
          >
            <InputLabel size="small">{t("Time")}</InputLabel>
            <Select
              value={selectedCheckinSlot}
              disabled={status === "ADMIN_CONFIRMED"}
              onChange={(e) => setselectedCheckinSlot(e.target.value)}
              required="true"
              MenuProps={MenuProps}
              label={t("Time")}
              size="small"
            >
              {checkinSlots?.map((name) => (
                <MenuItem
                  disabled={status === "ADMIN_CONFIRMED" || name === 24}
                  key={name}
                  value={name}
                >
                  {`${name > 12 ? name - 12 : name} ${
                    (name - 12) / 12 >= 0 && (name - 12) / 12 !== 1
                      ? "PM"
                      : "AM"
                  }`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={t("Checkout")}
              inputFormat="dd MMM, yyyy"
              value={checkoutDate}
              onChange={handleCheckoutChange}
              renderInput={(params) => (
                <TextField
                  disabled
                  size="small"
                  InputProps={{ readOnly: true }}
                  {...params}
                />
              )}
            />
            {/* <DesktopDatePicker
              label="Checkout"
              inputFormat="dd/MM/yyyy"
              value={checkoutDate}
              onChange={handleCheckoutChange}
              renderInput={(params) => <TextField {...params} size="small" />}
            /> */}
          </LocalizationProvider>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel size="small">{t("Time")}</InputLabel>
            <Select
              value={selectedCheckoutSLot}
              onChange={(e) => setselectedCheckoutSLot(e.target.value)}
              required="true"
              MenuProps={MenuProps}
              label={t("Time")}
              size="small"
            >
              {checkoutSlots?.map((name) => (
                <MenuItem key={name} value={name} disabled={name === 24}>
                  {`${name > 12 ? name - 12 : name} ${
                    (name - 12) / 12 >= 0 && (name - 12) / 12 !== 1
                      ? "PM"
                      : "AM"
                  }`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={4}>
          <div className="d-flex w-100">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Price per night")}
              multiline
              // rows={1}
              value={pricePerNight}
              sx={{ width: "100%" }}
              onChange={handlePricePerNightChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={
                JSON.parse(
                  data?.accessControl?.user_feature_map?.EditBookingPrice
                ).read !== "YES"
                  ? true
                  : priceLoader
                  ? true
                  : false
              }
              // InputProps={{ inputProps: { min: 0 } }}
            />
            {showButtonOfPricing === "perNightPrice" && (
              <Button
                size="small"
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={() => {
                  setRateType("PER_NIGHT_PRETAX");
                  setNewRate(pricePerNight);
                }}
                disabled={priceLoader}
              >
                {priceLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Apply")}`
                )}
              </Button>
            )}
          </div>

          <div className="d-flex w-100">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Total before tax")}
              multiline
              // rows={1}
              value={totalBeforeTax}
              sx={{ width: "100%" }}
              onChange={handleTotalBeforeTaxChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={
                JSON.parse(
                  data?.accessControl?.user_feature_map?.EditBookingPrice
                ).read !== "YES"
                  ? true
                  : priceLoader
                  ? true
                  : false
              }
            />
            {showButtonOfPricing === "priceBeforeTax" && (
              <Button
                size="small"
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={() => {
                  setRateType("TOTAL_PRETAX");
                  setNewRate(totalBeforeTax);
                }}
                disabled={priceLoader}
              >
                {priceLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Apply")}`
                )}
              </Button>
            )}
          </div>
        </Stack>
        <Stack direction="row" spacing={4}>
          <div className="d-flex w-100">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Total tax")}
              multiline
              // rows={1}
              value={totalTax}
              sx={{ width: "100%" }}
              onChange={handleTotalTaxChange}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="d-flex w-100">
            <TextField
              id="outlined-multiline-flexible"
              label={t("Total with tax")}
              multiline
              // rows={1}
              value={totalWithTax}
              sx={{ width: "100%" }}
              onChange={handleTotalWithTaxChange}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={
                JSON.parse(
                  data?.accessControl?.user_feature_map?.EditBookingPrice
                ).read !== "YES"
                  ? true
                  : priceLoader
                  ? true
                  : false
              }
            />
            {showButtonOfPricing === "priceAfterTax" && (
              <Button
                size="small"
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={() => {
                  setRateType("TOTAL_WITHTAX");
                  setNewRate(totalWithTax);
                }}
                disabled={priceLoader}
              >
                {priceLoader ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                ) : (
                  `${t("Apply")}`
                )}
              </Button>
            )}
          </div>
        </Stack>
      </Stack>
      <Stack alignItems="flex-end" mt={2}>
        {upgradeLoader === false ? (
          <Button
            variant="custom-button"
            onClick={() => handleEditReservation()}
            disabled={priceLoader || showButtonOfPricing !== ""}
          >
            {t("Edit reservation")}
          </Button>
        ) : (
          <Button variant="custom-button">
            <CircularProgress size="15px" />
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(EditBooking);
