import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Header from "./Header";
import CheckIcon from "@mui/icons-material/Check";
import ModuleTable from "./ModuleTable";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Forms from "./formsteps/Forms";
import FrontPage from "./formsteps/FrontPage";
import axios from "axios";
import { useEffect } from "react";
import ContactUs from "./formsteps/ContactUs";
import "../css/PricingDashboard.css";
import { useRef } from "react";

export default function PricingDashboard() {
  const pricingModules = {
    Starter: [
      "Property management system",
      "Built-in channel manager (100+ OTAs)",
      "Booking engine (with website)",
      "Payments automation",
      "Magic link (Self check-in)",
      "Mobile app (iOS & Android)",
      "Unlimited reports",
      "Unlimited users",
      "300+ integrations",
      "24/7 premium support",
    ],
    Pro: [
      "Property management system",
      "Built-in channel manager (100+ OTAs)",
      "Booking engine (with website)",
      "Payments automation",
      "Magic link (Self check-in)",
      "POS",
      "Revenue management tool",
      "Inventory manager (stock)",
      "Expenses manager",
      "Reputation manager",
      "Guest messaging (OTAs)",
      "Corporate & direct billing",
      "24/7 Priority support & account manager",
    ],
    Flex: [
      "Mix and match features",
      "Volume discounts",
      "Multi property management",
      "White label and API",
      "Custom integrations",
      "Custom reports and dashboards",
    ],
  };

  const gradientStyle = {
    background: "linear-gradient(-150deg, #1ab394, #1a91ae)",
    WebkitBackgroundClip: "text",
    color: "transparent",
  };

  const tableRows = {
    "Automated Property Management System": [
      {
        name: "Unified reservation calendar",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Multiple dashboard views",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Auto room allocations",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Bulk inventory management",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Guest communications",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Flexible walk-in bookings",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Smart housekeeping",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "User management",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Basic reports",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Advanced reports & scheduling",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Report APIs",
        starter: false,
        pro: false,
        flex: true,
      },
      {
        name: "Guest folios/Invoices",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Cash drawer",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Payment processing",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Direct billing",
        starter: false,
        pro: false,
        flex: true,
      },
      {
        name: "White label solution",
        starter: false,
        pro: false,
        flex: true,
      },
      {
        name: "Folio split / multi reservation",
        starter: false,
        pro: true,
        flex: true,
      },
    ],

    "Magic Link": [
      {
        name: "Self check-in & check-out",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Self early check-in & late check-out",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Self room upgrades",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Door lock automations",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Self POS orders",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Auto payment followups",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "QR code In room services",
        starter: false,
        pro: false,
        flex: true,
      },
      {
        name: "QR code POS orders",
        starter: false,
        pro: false,
        flex: true,
      },
    ],
    "Channel Manager": [
      {
        name: "100+ channels connectivity",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Early check-ins and late check-outs",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Advanced channel logs",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Inventory and price capping",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Advanced channel controls",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Flex collect (Avoid booking cancellations)",
        starter: true,
        pro: true,
        flex: true,
      },
    ],
    "Website & Booking Engine": [
      {
        name: "Modern booking engine",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Promotions & packages",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Booking widgets",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Meta search integrations",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Custom websites",
        starter: false,
        pro: false,
        flex: true,
      },
    ],
    "POS & E-Commerce": [
      {
        name: "POS outlets",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Website E-commerce setup",
        starter: false,
        pro: false,
        flex: true,
      },
      {
        name: "Order management",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Table and staff management",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "KOT support",
        starter: false,
        pro: true,
        flex: true,
      },
    ],
    "Revenue Management": [
      {
        name: "Dynamic pricing",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Rate shopper",
        starter: false,
        pro: false,
        flex: true,
      },
      {
        name: "Rate templates",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Flexibility controller",
        starter: false,
        pro: true,
        flex: true,
      },
    ],
    "Mobile App": [
      {
        name: "Mobile CRS",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Notifications",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Multiple property management",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Multiple user access",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Mobile housekeeping",
        starter: true,
        pro: true,
        flex: true,
      },
      {
        name: "Mobile POS",
        starter: false,
        pro: true,
        flex: true,
      },
      {
        name: "Mobile check-in & check-out",
        starter: true,
        pro: true,
        flex: true,
      },
    ],
  };

  const [dashboard, setDashboard] = useState("info");
  const handleGetStarted = () => {
    setDashboard("form");
  };
  const handleBackHome = () => {
    setDashboard("info");
  };
  const handleFree = () => {
    const redirectLink =
      "https://join.stayflexi.com/signup/?utm_source=custompricing";
    window.open(redirectLink, "_blank");
  };

  //--------------Location-----------
  const [country, setCountry] = useState({
    India: false,
    USA: false,
    Other: true,
  });
  const US = [
    "Mountain Standard Time",
    "Alaska Daylight Time",
    "Hawaii-Aleutian Standard Time",
    "Newfoundland Daylight Time",
    "Atlantic Daylight Time",
    "Central Daylight Time",
    "Central Standard Time",
    "Mountain Daylight Time",
    "Pacific Daylight Time",
  ];
  const handelingTimezone = (timezone) => {
    console.log("timezone", timezone);
    if (!timezone) {
      return;
    }
    if (timezone === "India Standard Time") {
      setCountry({
        India: true,
        USA: false,
        Other: false,
      });
    } else if (US.includes(timezone)) {
      setCountry({
        India: false,
        USA: true,
        Other: false,
      });
    } else {
      setCountry({
        India: false,
        USA: false,
        Other: true,
      });
    }
  };

  const findLocation = (lat, long, timestamp) => {
    // const lat = 19.741755;
    // const long = -155.844437;
    axios({
      method: "get",
      url: `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=${timestamp}&key=AIzaSyA2DNfK6M28R372at9GsRKi1m6TqJMZFD8`,
    })
      .then((item) => {
        handelingTimezone(item?.data?.timeZoneName);
        // console.log(item);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("position", position);
          // setLatitude(position.coords.latitude);
          // setLongitude(position.coords.longitude);
          findLocation(
            position?.coords?.latitude,
            position?.coords?.longitude,
            (position?.timestamp / 1000)?.toFixed(0)
          );
        },
        (error) => {
          console.log(error);
          // setErr(error.message);
        }
      );
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);
  //----------//Location//-------------

  //----------------------------------------Contact Modal---------------------------
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 800,
    minHeight: 200,
    bgcolor: "#666666",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };
  const [openContactModal, setOpenContactModal] = useState();
  const [product, setProduct] = useState();

  const handleContactUs = (item) => {
    setProduct(item);
    setOpenContactModal(true);
  };

  //------------
  const scrollRef = useRef(null);
  const scrollToHeight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          background: "black",
          pl: 3,
          pt: 4,
          zIndex: 2000,
          height: 120,
        }}
      >
        <Header />
      </Box>

      <Box
        sx={{
          // height: "1800px",
          width: "100%",
          background: "black",
          overflow: "scroll",
          position: "absolute",
          top: 100,
          left: 0,
        }}
      >
        <Box sx={{ p: 6 }}>
          <Typography variant="h2" align="center" sx={{ color: "white" }}>
            Customize the{" "}
            <span className="headingspan" style={gradientStyle}>
              best plan
            </span>{" "}
            for your property
          </Typography>
          <Box ref={scrollRef}></Box>
        </Box>
        <Box sx={{ p: 6 }}>
          <Card sx={{ minWidth: 275, backgroundColor: "#181818" }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: 400,
                }}
              >
                {dashboard === "info" && (
                  <FrontPage handleGetStarted={handleGetStarted} />
                )}
                {dashboard === "form" && (
                  <Forms
                    handleBackHome={handleBackHome}
                    region={country}
                    scrollToHeight={scrollToHeight}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box className="pricing-page-plans">
          <Box sx={{ mt: 6 }}>
            <Typography variant="h2" align="center" sx={{ color: "white" }}>
              Or choose the{" "}
              <span className="headingspan" style={gradientStyle}>
                best plan
              </span>{" "}
              for your property
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              mt: 6,
              gap: "20px",
            }}
          >
            <Card
              sx={{
                minWidth: 275,
                background: "#181818",
                position: "relative",
                borderRadius: "24px",
              }}
            >
              <CardHeader
                title={
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{ color: "white" }}
                  >
                    Starter
                  </Typography>
                }
              />

              <CardContent sx={{ p: "50px", mb: 6 }}>
                {/* <Box
                sx={{
                  backgroundColor: "#fff",
                  position: "absolute",
                  top: 5,
                  bottom: "auto",
                  left: "auto",
                  right: 7,
                  borderRadius: 2.5,
                  p: "2px",
                  pl: 2,
                  pr: 2,
                }}
              >
                <Typography variant="h6">POPULAR</Typography>
              </Box> */}
                {pricingModules?.Starter?.map((elm) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6" sx={{ color: "white" }}>
                      {elm}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions
                sx={{
                  position: "absolute",
                  bottom: "1rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Box
                  className="hss"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ color: "white" }}
                  >
                    Starts at {country?.India ? "Rs.2499" : "$ 149"}
                  </Typography>
                  <Button
                    variant="custom-button"
                    onClick={() => handleContactUs("Starter")}
                  >
                    Talk to sales
                  </Button>
                </Box>
              </CardActions>
            </Card>

            <Card
              sx={{
                minWidth: 275,
                background: "#181818",
                position: "relative",
                borderRadius: "24px",
                border: "2px solid #1a99a9",
              }}
            >
              <CardHeader
                title={
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{ color: "white" }}
                  >
                    Pro
                  </Typography>
                }
              />

              <CardContent sx={{ p: "50px", mb: 6 }}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    position: "absolute",
                    top: 5,
                    bottom: "auto",
                    left: "auto",
                    right: 7,
                    borderRadius: 2.5,
                    p: "2px",
                    pl: 2,
                    pr: 2,
                  }}
                >
                  <Typography variant="h6">POPULAR</Typography>
                </Box>
                {pricingModules?.Pro?.map((elm) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6" sx={{ color: "white" }}>
                      {elm}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions
                sx={{
                  position: "absolute",
                  bottom: "1rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Box
                  className="hss"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ color: "white" }}
                  >
                    Starts at {country?.India ? "Rs.4999" : "$ 449"}
                  </Typography>
                  <Button
                    variant="custom-button"
                    onClick={() => handleContactUs("Pro")}
                  >
                    Talk to sales
                  </Button>
                </Box>
              </CardActions>
            </Card>

            <Card
              sx={{
                minWidth: 275,
                background: "#181818",
                position: "relative",
                borderRadius: "24px",
              }}
            >
              <CardHeader
                title={
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{ color: "white" }}
                  >
                    Flex
                  </Typography>
                }
              />

              <CardContent sx={{ p: "50px", mb: 6 }}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    position: "absolute",
                    top: 5,
                    bottom: "auto",
                    left: "auto",
                    right: 7,
                    borderRadius: 2.5,
                    p: "2px",
                    pl: 2,
                    pr: 2,
                  }}
                >
                  <Typography variant="h6">ENTERPRISE</Typography>
                </Box>
                {pricingModules?.Flex?.map((elm) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6" sx={{ color: "white" }}>
                      {elm}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions
                sx={{
                  position: "absolute",
                  bottom: "1rem",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Box
                  className="hss"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ color: "white" }}
                  >
                    Starts at {country?.India ? "Rs.7999" : "$ 999"}
                  </Typography>
                  <Button
                    variant="custom-button"
                    onClick={() => handleContactUs("Flex")}
                  >
                    Talk to sales
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Box>
        </Box>
        {/* <Box sx={{ p: 6 }}>
          {Object.keys(tableRows)?.map((item) => (
            <Accordion
              sx={{ background: "#181818", color: "#fff", pt: 2, pb: 2, mb: 2 }}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" sx={{ color: "white" }}>
                  {item}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ModuleTable tableData={tableRows[item]} />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box> */}
        <Box sx={{ p: 6, mb: 10 }}>
          <Card sx={{ minWidth: 275, background: "#181818" }}>
            <CardContent>
              <Typography variant="h2" align="center" sx={{ color: "white" }}>
                Try{" "}
                <span className="headingspan" style={gradientStyle}>
                  Stayflexi
                </span>{" "}
                for free
              </Typography>

              <Typography align="center" sx={{ mt: 1.5, color: "white" }}>
                Hassle Free Migration. No Hidden Charges. An One stop solution
                for sales, marketing and operations
              </Typography>
              <Typography align="center" sx={{ mt: 1, color: "white" }}>
                of hotels and vacation rentals.
              </Typography>
            </CardContent>
            <CardActions sx={{ flexDirection: "column" }}>
              <Box className="hss">
                <Button variant="outlined" onClick={handleFree}>
                  Get Started
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Box>

        <Modal
          open={openContactModal}
          onClose={() => {
            setOpenContactModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ContactUs
              country={country}
              product={product}
              setOpenContactModal={setOpenContactModal}
            />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}
