import axios from "axios";

const COMMON_URL = `${process.env.REACT_APP_API_URL}`;
const GETALLRATEPLAN = "rate/getAllRatePlans/";
const ADDNEWRATEPLAN = "rate/addRatePlan/";

const ACTIVATERATEPLANS = "rate/enableRatePlan/";
const DEACTIVATERATEPLANS = "rate/disableRatePlan/";
const DELETEDYNAMICPRICING = "rate/deleteDynPricingRule/";
const GETALLPOLICY = "policies/getAllPolicies";
const GETALLROOMTYPES = "room/getAllRoomTypes";
const JAVA_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const GETORDERRATEPLANS = "core/api/v1/rporder/get-ordered-plans";
const ORDERRATEPLANS = "core/api/v1/rporder/order-plans";
const url = new URL(window.location.href);
const hotelId = url.searchParams.get("hotelId");

export const getAllRateplanData = async () => {
  return axios
    .get(COMMON_URL + GETALLRATEPLAN)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const addNewRateplans = async (data) => {
  return axios
    .post(COMMON_URL + ADDNEWRATEPLAN, data)
    .then((resp) => resp.data)
    .catch((err) => {
      // throw new Error(err);
      return err;
    });
};

export const activiateRateplan = async (id, logged_user_email) => {
  return axios
    .get(
      COMMON_URL +
        ACTIVATERATEPLANS +
        "?hotel_id=" +
        hotelId +
        "&id=" +
        id +
        "&logged_user_email=" +
        logged_user_email
    )
    .then((resp) => resp.data)
    .catch((err) => {
      //throw new Error(err);
      return err;
    });
};

export const deactiviateRateplan = async (id, logged_user_email) => {
  return axios
    .get(
      COMMON_URL +
        DEACTIVATERATEPLANS +
        "?hotel_id=" +
        hotelId +
        "&id=" +
        id +
        "&logged_user_email=" +
        logged_user_email
    )
    .then((resp) => resp.data)
    .catch((err) => {
      // throw new Error(err);
      return err;
    });
};

export const deleteDynamicPricingRules = async (id) => {
  return axios
    .get(
      COMMON_URL + DELETEDYNAMICPRICING + "?hotel_id=" + hotelId + "&id=" + id
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getallpolicies = async (id) => {
  return axios
    .get(COMMON_URL + GETALLPOLICY + "?hotel_id=" + hotelId)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllRoomTypess = async () => {
  return axios
    .get(COMMON_URL + GETALLROOMTYPES + "?hotel_id=" + hotelId)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllRoomTypessWithHotelId = async (hotelId) => {
  return axios
    .get(
      COMMON_URL +
        GETALLROOMTYPES +
        "?hotel_id=" +
        hotelId +
        "&hotel_id=" +
        hotelId
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const GetTheOrderedRatePlans = async () => {
  return axios
    .get(JAVA_URL + GETORDERRATEPLANS)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const OrderedRatePlans = async (data) => {
  return axios
    .post(JAVA_URL + ORDERRATEPLANS, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
