import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";

import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { handleNum } from "../../../Utils/Utils";
import { FormControl, InputLabel, Select, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import {
  handleGetDoorLockDetails,
  invalidateNightAuditReservation,
} from "../../CalendarPage/api/ReservationApi";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import i18n from "../../../modules/Auth/pages/i18n";

function ModifyCheckout({
  onCancel,
  hotelId,
  bookingId,
  invoiceData,
  updateData,
  reloadGrid,
  reservationId,
  isGrid,
  updateSummaryData,
  data,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  /* Booking Info */
  const [bookingInfo, setBookingInfo] = useState(null);
  const [checkoutCal, setCheckoutCal] = useState(
    new Date(invoiceData?.invoice.checkout)
  );

  const [currentCSlot, setCurrentCSlot] = useState("");
  const [showButtonOfPricing, setshowButtonOfPricing] = useState("");

  function getBookingInfo() {
    djangoHandleDataRequests(
      `invoice/getBookingInfoById?bookingID=${bookingId}`
    )
      .then((res) => setBookingInfo(res))
      .catch((err) =>
        toast.error(
          `${t("Error fetching the data. and select room id if not selected ")}`
        )
      );
  }

  useEffect(() => {
    getBookingInfo();
  }, []);

  const [roomIdss, setroomIdss] = useState([]);

  useEffect(() => {
    setroomIdss(
      invoiceData.invoice.room_ids_list?.filter((e) => e !== "UNASSIGNED")
    );
  }, []);

  const [selectedRooms, setselectedRooms] = useState([
    invoiceData.invoice.room_ids_list[0],
  ]);

  useEffect(() => {
    setselectedRooms(roomIdss);
  }, [roomIdss]);

  /* CHECKIN */

  // handling the date for checkin
  const [checkinDate, setCheckinDate] = useState(
    new Date(invoiceData?.invoice.checkin)
  );
  const handleChange = (newValue) => {
    setCheckinDate(newValue);
  };

  // date formatting
  function handleDateFormat(date) {
    return moment(date).format("DD-MM-YYYY+hh:mm:ss");
  }

  // getting the checkin and the checkout slots
  // Based on the checkin and the checkout date

  // defining the slots
  const [slots, setSlots] = useState(null);

  function handleGetSlots() {
    handleSpringDataRequest(
      `core/api/v1/reservation/getCheckinAndCheckoutSlots/?checkin=${handleDateFormat(
        checkinDate
      )}&checkout=${handleDateFormat(checkoutCal)}`
    )
      .then((res) => setSlots(res))
      .catch((error) => toast.error(`${t("Error getting the slots.")}`));
  }

  useEffect(() => {
    if (checkinDate && checkoutCal) {
      handleGetSlots();
    }
  }, [checkinDate, checkoutCal]);

  // Formatting the slot time
  function handleSlotTimeFormat(slotTime) {
    return moment(slotTime.toString(), ["HH"]).format("hh:mm A");
  }

  // rate type and new rate for extending checkin
  const [rateType, setRateType] = useState("TOTAL_PRETAX");
  const [newRate, setNewRate] = useState(null);

  // extending checkin loader
  const [checkinLoader, setCheckinLoader] = useState(false);

  // getting the info based on the slots and the date --- checkin
  const [checkinInfo, setCheckinInfo] = useState(null);
  function handleGetCurrentSlot() {
    // handleSpringDataMutationRequest(
    //   "POST",
    //   "core/api/v1/reservation/getEditCheckinPricePayload",
    //   {
    //     hotelId: hotelId,
    //     // bookingId: bookingId,
    //     checkin: moment(checkinDate).format("DD-MM-YYYY HH:mm:ss"),
    //     newRate: newRate,
    //     newRateType: rateType,
    //     resIds: [bookingInfo?.res_id_list[0]],
    //   }
    // )
    //   .then((res) => setCheckinInfo(res))
    //   .catch((error) => toast.error("Error fetching the current slot!"));
  }

  useEffect(() => {
    setTimeout(() => {
      bookingInfo && handleGetCurrentSlot();
    }, 1000);
  }, [checkinDate, bookingInfo, rateType, newRate]);

  useEffect(() => {
    if (checkinInfo) {
      setPricePerNight(checkinInfo.perNightPrice.toFixed(2));
      setTotalBeforeTax(checkinInfo.priceBeforeTax.toFixed(2));
      setTotalTax(checkinInfo.taxValue.toFixed(2));
      setTotalWithTax(checkinInfo.priceAfterTax.toFixed(2));
    }
  }, [checkinInfo]);

  // handling the new check in slot

  // setting the initial value for the checkin slot
  let currentChekcinSlotTime = moment(invoiceData?.invoice.checkin).format(
    "HH"
  );
  useEffect(() => {
    if (slots) {
      if (slots.checkinSlots.some((item) => item === currentChekcinSlotTime)) {
        setCurrentSlot(currentChekcinSlotTime);
      } else {
        setCurrentSlot(slots.checkinSlots[0]);
      }
    }
  }, [slots]);

  const [currentSlot, setCurrentSlot] = useState("");
  const handleUpdateSlot = (event) => {
    setCurrentSlot(event.target.value);
  };

  // // price per night
  const [pricePerNight, setPricePerNight] = useState(0);

  // // Total price before tax
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);

  // // Total Tax
  const [totalTax, setTotalTax] = useState(0);

  // Total price after tax
  const [totalWithTax, setTotalWithTax] = useState(0);

  /* CHECKOUT */

  // calendar

  const handleCheckOutCal = (newValue) => {
    setCheckoutCal(newValue);
  };

  // rate type and new rate for extending checkout
  const [checkoutRateType, setCheckoutRateType] = useState("TOTAL_PRETAX");
  const [checkoutNewRate, setCheckoutNewRate] = useState(null);

  // extending checkout loader
  const [checkoutLoader, setCheckoutLoader] = useState(true);

  // getting the checout info based on the checkout date and the slot chosen --- checkout
  const [checkoutInfo, setCheckoutInfo] = useState(null);

  function handleCheckoutPricePayload() {
    const resId = [];
    for (let i = 0; i < selectedRooms.length; i++) {
      resId.push(reservationId[selectedRooms[i]]);
    }
    setCheckoutLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/reservation/getExtensionPricePayload",
      {
        hotelId: hotelId,
        checkout: moment(checkoutCal).format(
          `DD-MM-YYYY ${currentCSlot}:mm:ss`
        ),
        newRate: checkoutNewRate,
        newRateType: checkoutRateType,
        resIds: resId,
      }
    )
      .then((res) => {
        setCheckoutInfo(res);
        setCheckoutLoader(false);
        setshowButtonOfPricing("");
      })
      .catch((error) => {
        toast.error(`${t("Error fetching the current slot!")}`);
        setCheckoutLoader(false);
        setshowButtonOfPricing("");
      });
  }

  useEffect(() => {
    setCheckoutLoader(true);
    const timer = setTimeout(() => {
      if (bookingInfo) {
        handleCheckoutPricePayload();
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [
    checkoutCal,
    currentCSlot,
    bookingInfo,
    checkoutRateType,
    checkoutNewRate,
  ]);

  useEffect(() => {
    if (checkoutInfo) {
      setPricePerNightCOut(checkoutInfo.perNightPrice.toFixed(2));
      setTotalBeforeTaxCOut(checkoutInfo.priceBeforeTax.toFixed(2));
      setTotalTaxCOut(checkoutInfo.taxValue.toFixed(2));
      setTotalWithTaxCOut(checkoutInfo.priceAfterTax.toFixed(2));
    }
  }, [checkoutInfo]);

  // handling the new check out slot
  // setting the initial value for the checkout slot
  let currentCheckoutSlotTime = moment(invoiceData.invoice.checkout).format(
    "HH"
  );
  useEffect(() => {
    if (slots) {
      if (
        slots.checkoutSlots.some((item) => item === currentCheckoutSlotTime)
      ) {
        setCurrentCSlot(currentCheckoutSlotTime);
      } else {
        setCurrentCSlot(slots.checkoutSlots[0]);
      }
    }
  }, [slots]);

  const handleUpdateCSlot = (event) => {
    setCurrentCSlot(event.target.value);
  };

  // handling performing edit checkout
  // function performEditCheckout() {
  //   if (selectedRooms?.length === 0) {
  //     toast.error("Please select room id");
  //   } else {
  //     const resId = [];
  //     for (let i = 0; i < selectedRooms.length; i++) {
  //       resId.push(reservationId[selectedRooms[i]]);
  //     }
  //     setCheckoutLoader(true);
  //     handleSpringDataMutationRequest(
  //       "POST",
  //       "core/api/v1/reservation/performExtendReservation",
  //       {
  //         checkinDate: moment(checkoutCal).format(
  //           `DD-MM-YYYY ${currentCSlot}:mm:ss`
  //         ),
  //         gAdditionalAmt: checkoutInfo?.priceAfterTax,
  //         hotelId: hotelId,
  //         resIds: resId,
  //       }
  //     )
  //       .then((res) => toast.success("Edit performed successfully."))
  //       .then((res) => handleGetCurrentSlot())
  //       .then((res) => setCheckoutLoader(false))
  //       .then((response) => updateData())
  //       .then((response) => updateSummaryData())
  //       .then((res) => (isGrid ? reloadGrid() : ""))
  //       .catch((error) =>
  //         toast.error("Something wrong happened. Please try again later.")
  //       )
  //       .catch((error) => setCheckoutLoader(false));
  //   }
  // }

  async function performEditCheckout() {
    try {
      if (selectedRooms?.length === 0) {
        toast.error(`${t("Please select room id")}`);
      } else {
        const resId = [];
        for (let i = 0; i < selectedRooms.length; i++) {
          resId.push(reservationId[selectedRooms[i]]);
        }

        setCheckoutLoader(true);
        const res = await handleSpringDataMutationRequest(
          "POST",
          "core/api/v1/reservation/performExtendReservation",
          {
            checkinDate: moment(checkoutCal).format(
              `DD-MM-YYYY ${currentCSlot}:mm:ss`
            ),
            gAdditionalAmt: checkoutInfo?.priceAfterTax,
            hotelId: hotelId,
            resIds: resId,
          }
        );
        await invalidateNightAuditReservation(resId[0]);
        for (let rId of resId) {
          await handleGetDoorLockDetails({
            bookingId: bookingId,
            reservationId: rId,
            actionRequired: "recreate",
          });
        }
        await handleGetCurrentSlot();
        setCheckoutLoader(false);
        await updateData();
        await updateSummaryData();
        toast.success(
          `${t("Successfully modified check out  for")} ${bookingId}`
        );
        if (isGrid) {
          reloadGrid();
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(`${t("Something wrong happened. Please try again later.")}`);
      setCheckoutLoader(false);
    }
  }

  // Price per night
  const [pricePerNightCOut, setPricePerNightCOut] = useState(0);

  const handlePricePerNightChangeCOut = (event) => {
    setPricePerNightCOut(event.target.value);
    setshowButtonOfPricing("perNightPrice");
  };

  // Total price before tax
  const [totalBeforeTaxCOut, setTotalBeforeTaxCOut] = useState(0);

  const handleTotalBeforeTaxChangeCOut = (event) => {
    setTotalBeforeTaxCOut(event.target.value);
    setshowButtonOfPricing("priceBeforeTax");
  };

  // Total tax value
  const [totalTaxCOut, setTotalTaxCOut] = useState(0);

  const handleTotalTaxChangeCOut = (event) => {
    setTotalTaxCOut(event.target.value);
  };

  // Total price after tax
  const [totalWithTaxCOut, setTotalWithTaxCOut] = useState(0);

  const handleTotalWithTaxChangeCOut = (event) => {
    setTotalWithTaxCOut(event.target.value);
    setshowButtonOfPricing("priceAfterTax");
  };

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      setselectedRooms(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {}, [selectedRooms]);

  return (
    <div className="modifyDatesWrapper">
      <div className="modifyCheckOutDates">
        <div className="newCheckOutCalendar">
          <h4
          // style={{
          //   marginLeft: "2rem",
          //   marginTop: "2rem",
          //   marginBottom: "1rem",
          // }}
          >
            {t("Select the room id to modify checkout")}
          </h4>

          <div style={{ marginBottom: "2rem" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>{t("Room ids")}</InputLabel>
              <Select
                value={selectedRooms}
                onChange={handleChanges}
                required="true"
                MenuProps={MenuProps}
                label={t("Room ids")}
                multiple
              >
                {roomIdss?.map((name) => (
                  <MenuItem key={name.id} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Stack spacing={4}>
          <Stack direction="row" spacing={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t("New Check-out date")}
                inputFormat="dd MMM, yyyy"
                value={checkoutCal}
                onChange={handleCheckOutCal}
                renderInput={(params) => (
                  <TextField
                    disabled
                    InputProps={{ readOnly: true }}
                    fullWidth
                    {...params}
                    sx={{ width: "75%" }}
                  />
                )}
                minDate={checkinDate}
              />
            </LocalizationProvider>

            <TextField
              id="outlined-select-currency"
              select
              label={t("New check-out slot")}
              value={currentCSlot}
              onChange={handleUpdateCSlot}
              sx={{ width: "25%" }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {slots?.checkoutSlots.map((item, index) => (
                <MenuItem key={index} value={item} className="checkInSlot">
                  {handleSlotTimeFormat(item)}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction="row" spacing={4}>
            <div className="d-flex w-100">
              <TextField
                id="outlined-multiline-flexible"
                label={t("Price per night")}
                multiline
                // rows={1}
                value={pricePerNightCOut}
                sx={{ width: "100%" }}
                onChange={handlePricePerNightChangeCOut}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.EditBookingPrice
                  ).read !== "YES"
                    ? true
                    : checkoutLoader
                    ? true
                    : false
                }
              />
              {showButtonOfPricing === "perNightPrice" && (
                <Button
                  size="small"
                  style={{ marginLeft: "2rem" }}
                  variant="custom-button"
                  onClick={() => {
                    setCheckoutRateType("PER_NIGHT_PRETAX");
                    setCheckoutNewRate(pricePerNightCOut);
                  }}
                  disabled={checkoutLoader}
                >
                  {checkoutLoader ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    `${t("Apply")}`
                  )}
                </Button>
              )}
            </div>
            <div className="d-flex w-100">
              <TextField
                id="outlined-multiline-flexible"
                label={t("Total before tax")}
                multiline
                // rows={1}
                value={totalBeforeTaxCOut}
                sx={{ width: "100%" }}
                onChange={handleTotalBeforeTaxChangeCOut}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.EditBookingPrice
                  ).read !== "YES"
                    ? true
                    : checkoutLoader
                    ? true
                    : false
                }
              />
              {showButtonOfPricing === "priceBeforeTax" && (
                <Button
                  size="small"
                  style={{ marginLeft: "2rem" }}
                  variant="custom-button"
                  onClick={() => {
                    setCheckoutRateType("TOTAL_PRETAX");
                    setCheckoutNewRate(totalBeforeTaxCOut);
                  }}
                  disabled={checkoutLoader}
                >
                  {checkoutLoader ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    `${t("Apply")}`
                  )}
                </Button>
              )}
            </div>
          </Stack>
          <Stack direction="row" spacing={4}>
            <div className="d-flex w-100">
              <TextField
                id="outlined-multiline-flexible"
                label={t("Total tax")}
                multiline
                // rows={1}
                value={totalTaxCOut}
                sx={{ width: "100%" }}
                onChange={handleTotalTaxChangeCOut}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className=" d-flex w-100">
              <TextField
                id="outlined-multiline-flexible"
                label={t("Total with tax")}
                multiline
                // rows={1}
                value={totalWithTaxCOut}
                sx={{ width: "100%" }}
                onChange={handleTotalWithTaxChangeCOut}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={
                  JSON.parse(
                    data?.accessControl?.user_feature_map?.EditBookingPrice
                  ).read !== "YES"
                    ? true
                    : checkoutLoader
                    ? true
                    : false
                }
              />
              {showButtonOfPricing === "priceAfterTax" && (
                <Button
                  size="small"
                  style={{ marginLeft: "2rem" }}
                  variant="custom-button"
                  onClick={() => {
                    setCheckoutRateType("TOTAL_WITHTAX");
                    setCheckoutNewRate(totalWithTaxCOut);
                  }}
                  disabled={checkoutLoader}
                >
                  {checkoutLoader ? (
                    <CircularProgress
                      size={15}
                      color="inherit"
                      sx={{ ml: 1.5 }}
                    />
                  ) : (
                    `${t("Apply")}`
                  )}
                </Button>
              )}
            </div>
          </Stack>
        </Stack>
        <Stack alignItems="flex-end" mt={2}>
          {checkoutLoader === false ? (
            <Button
              variant="custom-button"
              disabled={showButtonOfPricing !== ""}
              onClick={() => performEditCheckout()}
            >
              {t("Modify checkout")}
            </Button>
          ) : (
            <Button variant="custom-button">
              <CircularProgress size="15px" />
            </Button>
          )}
        </Stack>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(ModifyCheckout);
