import React, { useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import {
  Typography,
  Box,
  Tab,
  Tabs,
  AppBar,
  Breadcrumbs,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import Overview from "./Overview";
import Subscriptions from "./Subscriptions";
import CompanyInfo from "./CompanyInfo";
import TransactionsDashBoard from "./TransactionsDashBoard";
import PaidInvoicesDashboard from "./PaidInvoicesDashboard";
import Documents from "./Documents";
import PaymentMethods from "./PaymentMethods";
import { Link, useLocation } from "react-router-dom";
import { handleSpringDataRequest } from "../../api";
import { toast } from "material-react-toastify";
import { connect } from "react-redux";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const AccountsDashBoard = ({
  bookingData,
  hotelIds,
  groupId,
  data,
  fromRecommendation,
  paynow,
  showAutoPay,
}) => {
  const location = useLocation();
  const [value, setValue] = useState(fromRecommendation ? 1 : 0);
  const [hotelsData, setHotelsData] = useState();
  // console.log("bookingData", bookingData);
  // const [bookingData, setBookingData] = useState(null);
  // const [hotelIds, setHotelIds] = useState(null);
  // const [groupId, setGroupId] = useState(null);
  const [hotelCurr, setHotelCurr] = useState(null);
  const [isValidHash, setIsValidHash] = useState(false);
  // console.log(hash);
  // console.log(location.search);
  // console.log(location);

  useEffect(() => {
    // location &&
    //   location?.state &&
    //   location.state !== undefined &&
    // setHotelsData(location.state);
    // location &&
    //   location?.state &&
    //   location.state !== undefined &&
    //   setBookingData(bookingData);
    // location &&
    //   location?.state &&
    //   location.state !== undefined &&
    // setHotelIds(hotelIds);
    // location &&
    //   location?.state &&
    //   location.state !== undefined &&
    // setGroupId(groupId);
    // bookingData &&
    //   bookingData[0] &&
    //   setHotelCurr(bookingData[0]?.hotelCurrency);
  }, []);

  // useEffect(async () => {
  //   if (hash && hash !== "") {
  //     await axios
  //       .post(
  //         `${process.env.REACT_APP_SPRING_API_URL}` +
  //           "core/api/v1/account/validate-hash",
  //         {
  //           hashval: hash.split(" ").join("+"),
  //         }
  //       )
  //       .then((resp) => {
  //         console.log(resp);
  //         setGroupId(resp.data);
  //         setIsValidHash(true);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, [hash]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
    // // storing the api methods
    // const [invoiceData, setInvoiceData] = useState(null);
  };

  // console.log("group id : ", groupId);

  const [accountInfo, setAccountInfo] = useState(null);

  const getCompanyData = () => {
    handleSpringDataRequest(
      `core/api/v1/account/get-single/?groupId=${groupId}&hotelId=${groupId}`
    )
      .then((res) => {
        setAccountInfo(res);
        // console.log(
        //   "res : ",
        //   res,
        //   "res?.account_details?.billing_address_country?.toLowerCase() -- ",
        //   res?.account_details?.billing_address_country?.toLowerCase()
        // );
        let currency =
          res?.account_details?.billing_address_country?.toLowerCase() ===
          "india"
            ? "INR"
            : "USD";
        // console.log("currency : ", currency);
        setHotelCurr(currency);
      })
      .catch((err) => {
        console.log("the err is : ", err);
        toast.error(err);
      });
  };

  // console.log("accountInfo : ", accountInfo);

  useEffect(() => {
    groupId && getCompanyData();
  }, [groupId]);

  // const breadcrumbs = [
  //   <div style={{ display: "flex", flexDirection: "row" }}>
  //     <Link
  //       key="1"
  //       // to={{
  //       //   pathname: "/hotel-dashboard",
  //       // }}
  //       style={{ fontSize: "17.5px", textDecoration: "underline" }}
  //     >
  //       Dashboard
  //     </Link>
  //     <div
  //       style={{ fontSize: "17.5px", marginLeft: "10px", marginRight: "10px" }}
  //     >
  //       {" > "}
  //     </div>
  //     <Typography style={{ fontSize: "17.5px" }} color="text.primary">
  //       Account & Billing
  //     </Typography>
  //   </div>,
  // ];
  return (
    <div
      style={{
        // position: "absolute",
        // top: "0",
        // bottom: "0",
        // right: "0",
        // left: "0",
        position: "relative",
        marginTop: "5px",
        backgroundColor: "#ffffff",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      {/* <Breadcrumbs
        style={{ marginLeft: "1rem", marginTop: "1rem" }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs> */}
      <Typography variant="h5">Accounts & billing</Typography>
      <Divider className="mt-4 mb-4" />
      {/* <Typography
        variant="h5"
        style={{ fontWeight: "600", marginTop: "24px", marginLeft: "16px" }}
      >
        Account & Billing
      </Typography> */}
      {accountInfo && (
        <div className="AccountfolioTabs">
          <AppBar position="static" className="appBar">
            <Tabs
              value={value}
              onChange={handleChange}
              className="invoiceWrapperTabs"
              indicatorColor="secondary"
              //   textColor="primary"
              //   variant="fullWidth"
              //   aria-label="full width tabs example"
            >
              {/* <Tab label="Overview" {...a11yProps(0)} className="invoiceTab" /> */}
              {/* <Tab
              label="Subscriptions"
              {...a11yProps(0)}
              className="invoiceTab"
            /> */}

              <Tab
                label="Account Info"
                {...a11yProps(0)}
                className="invoiceTab"
              />
              <Tab
                label="Account ledger"
                {...a11yProps(1)}
                className="invoiceTab"
              />
              {/* <Tab label="Documents" {...a11yProps(2)} className="invoiceTab" /> */}
              {hotelCurr !== "INR" && (
                <Tab
                  label="Payment Methods"
                  {...a11yProps(2)}
                  className="invoiceTab"
                />
              )}
              {hotelCurr === "INR" && (
                <Tab
                  label="Paid Invoices"
                  {...a11yProps(2)}
                  className="invoiceTab"
                />
              )}
              {hotelCurr !== "INR" && (
                <Tab
                  label="Paid Invoices"
                  {...a11yProps(3)}
                  className="invoiceTab"
                />
              )}
            </Tabs>
          </AppBar>
          <SwipeableViews
            // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {/* <TabPanel value={value} index={0}>
            <Overview />
          </TabPanel> */}
            {/* <TabPanel value={value} index={0}>
            <Subscriptions
              hotelsData={hotelsData}
              bookingData={bookingData}
              hotelIds={hotelIds}
            />
          </TabPanel> */}

            <TabPanel value={value} index={0}>
              <CompanyInfo
                groupId={groupId}
                res={accountInfo}
                isLanding={"NO"}
                // hash={hash}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TransactionsDashBoard
                groupId={groupId}
                res={accountInfo}
                paynow={paynow}
                showAutoPay={showAutoPay}
                // hash={hash}
              />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
              <Documents />
            </TabPanel> */}

            <TabPanel value={value} index={2}>
              {hotelCurr !== "INR" ? (
                <PaymentMethods
                  groupId={groupId}
                  res={accountInfo}
                  // hash={hash}
                />
              ) : (
                <PaidInvoicesDashboard
                  groupId={groupId}
                  res={accountInfo}
                  // hash={hash}
                />
              )}
            </TabPanel>
            {hotelCurr !== "INR" && (
              <TabPanel value={value} index={3}>
                <PaidInvoicesDashboard
                  groupId={groupId}
                  res={accountInfo}
                  // hash={hash}
                />
              </TabPanel>
            )}
          </SwipeableViews>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.hotels[0] };
}
export default connect(mapStateToProps)(AccountsDashBoard);
