import React, { Fragment, useEffect } from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { readableTimeConverter, handleFullAddString } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import i18n from "../../modules/Auth/pages/i18n";

const borderColor = "#E5e5e5";

const InvoiceDualText = ({ boldText, normalText, color = "black" }) => {
  return (
    <View style={styles.dualText}>
      <Text style={[styles.boldText, { color: color }]}>{boldText}:</Text>
      <Text style={[styles.normalText, { color: color }]}>{normalText}</Text>
    </View>
  );
};

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={[styles.description, { fontSize: "10px" }]}>
      Date {"&"} Description
    </Text>
    <Text style={[styles.qty, { fontSize: "10px" }]}>Type</Text>
    <Text style={[styles.rate, { fontSize: "10px" }]}>Sub-total</Text>
    <Text style={[styles.rate, { fontSize: "10px" }]}>Tax</Text>
    <Text style={[styles.amount, { fontSize: "10px" }]}>Total</Text>
  </View>
);

// Handling customer address
const handleCustomerAddress = (address) => {
  if (!address.length > 0) {
    return null;
  } else if (address.startsWith("null")) {
    return null;
  } else if (address.startsWith("NA")) {
    return null;
  } else {
    return [`${address}, `];
  }
};

// Handling the date format
const formatDate = (date) => {
  return moment(date).format("MMM DD YYYY hh:mm A");
};

const InvoiceRoomRow = ({ items, symbol, hotelType, HotelEmail }) => {
  const rows = items.map((item, index) => (
    <View style={styles.row} key={index}>
      {/* <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flexStart",
          flexWrap: "wrap",
          marginLeft: "15px",
        }}
      > */}
      <Text
        style={{
          // marginBottom: 3,
          fontSize: "8px",
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {/* {item?.roomTypeName} */}
      </Text>
      <Text
        style={{
          fontSize: "8px",
          // marginBottom: 5,
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {/* {item?.ratePlanName} */}
      </Text>
      <Text
        style={{
          fontSize: "8px",
          // marginBottom: 5,
          width: "33.33%",
          // marginTop: "10px",
        }}
      >
        {/* {item.adults} Adult(s) {item.children} Children */}
      </Text>
      {/* <Text
        style={{
          fontSize: "10px",
          // marginBottom: 5,
          width: "25%",
          // marginTop: "10px",
        }}
      >
        {symbol} {parseInt(item.roomRate).toFixed(2)}
      </Text> */}
      {/* </View> */}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

// get the image extension
const getExtension = (img) => {
  if (img?.charAt(0) === "/") {
    return `data:image/jpeg;base64, ${img}`;
  } else if (img?.charAt(0) === "i") {
    return `data:image/png;base64, ${img}`;
  } else if (img?.charAt(0) === "U") {
    return `data:image/webp;base64, ${img}`;
  } else if (img?.charAt(0) === "R") {
    return `data:image/gif;base64, ${img}`;
  }
};

// handle invoice header
const handleInvoiceHeader = (customFolioConfig) => {
  if (
    customFolioConfig?.booking_invoice_header &&
    !customFolioConfig?.booking_invoice_header
      .toLowerCase()
      .includes("null", "n/a")
  ) {
    return customFolioConfig?.booking_invoice_header;
  } else {
    return "Folio";
  }
};

// Check if the data is not null or includes n/a
const checkInvoiceData = (data) => {
  if (data === null) {
    return "";
  } else if (String(data).toLowerCase().startsWith("null")) {
    return "";
  } else if (String(data).toLowerCase().startsWith("n/a")) {
    return "";
  } else {
    return String(data);
  }
};

const handleCustomBookingId = (invoice, customFolioConfig) => {
  if (invoice?.status === "CHECKED_OUT") {
    // return (
    //   String(checkInvoiceData(customFolioConfig.cust_booking_invoice_prefix)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_seq_start)) +
    //   String(checkInvoiceData(customFolioConfig?.cust_booking_invoice_suffix))
    // );
    return String(invoice?.custom_invoice_id);
  } else {
    return "";
  }
};

function PrintEmptyResCard({
  invoice,
  hotelData,
  symbol,
  customDetails,
  hotelLogo,
  summaryData,
  hotelType,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const HotelEmail = localStorage.getItem("HotelEmail");

  // generating the guest information table
  const ReservationGuestInfo = ({ symbol, hotelType }) => (
    <View style={([styles.container], { fontSize: "8px" })}>
      <View style={styles.resCardWrapper}>
        {(symbol === "Rs." || symbol === "INR" || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText
                  boldText={t("Registration No. ")}
                  normalText={""}
                />
              </View>
              <View style={styles.resCardRight}>
                <InvoiceDualText
                  boldText={t("Payment mode ")}
                  normalText={""}
                />
              </View>
            </View>
          )}

        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText boldText={t("Guest name ")} normalText={""} />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText boldText={t("Nationality ")} normalText={""} />
          </View>
        </View>
        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText boldText={t("Email ")} normalText={""} />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText boldText={t("Phone ")} normalText={""} />
          </View>
        </View>
        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText boldText={t("Source ")} normalText={""} />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText
              boldText={t(
                customDetails?.show_room_tariff_reservation_card?.toUpperCase() ===
                  "YES"
                  ? "Booking amount"
                  : "Gov ID "
              )}
              normalText={""}
            />
          </View>
        </View>
        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText boldText={t("Address ")} normalText={""} />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText
              boldText={t("Visa No/Issue/Expiry ")}
              normalText={""}
            />
          </View>
        </View>

        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText boldText={t("DOB ")} normalText={""} />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText
              boldText={t("Purpose of visit ")}
              normalText={""}
            />
          </View>
        </View>
        <View style={styles.resCardContent}>
          <View style={styles.resCardLeft}>
            <InvoiceDualText boldText={t("Arrived from ")} normalText={""} />
          </View>
          <View style={styles.resCardRight}>
            <InvoiceDualText boldText={t("Preferences ")} normalText={""} />
          </View>
        </View>
        {(symbol === "Rs." || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText
                  boldText={t("Company name ")}
                  normalText={""}
                />
              </View>
              <View style={styles.resCardRight}>
                <InvoiceDualText boldText={t("Company GST ")} normalText={""} />
              </View>
            </View>
          )}
        {(symbol === "Rs." || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText boldText={t("Designation ")} normalText={""} />
              </View>
              <View style={styles.resCardRight}>
                <InvoiceDualText
                  boldText={t("Company address ")}
                  normalText={""}
                />
              </View>
            </View>
          )}
        {(symbol === "Rs." || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText
                  boldText={t("Company email ")}
                  normalText={""}
                />
              </View>
              <View style={styles.resCardRight}>
                <InvoiceDualText
                  boldText={t("Company contact ")}
                  normalText={""}
                />
              </View>
            </View>
          )}
        {hotelType?.toUpperCase() !== "ISHA" && (
          <View style={styles.resCardContent}>
            <View style={styles.resCardLeft}>
              <InvoiceDualText boldText={t("Vehicle No. ")} normalText={""} />
            </View>

            {(symbol === "Rs." || symbol === "INR") && (
              <View style={styles.resCardRight}>
                <InvoiceDualText boldText={t("Anniversary ")} normalText={""} />
              </View>
            )}
          </View>
        )}
        {customDetails?.show_room_tariff_reservation_card?.toUpperCase() ===
          "YES" && (
          <View style={styles.resCardContent}>
            <View style={styles.resCardLeft}>
              <InvoiceDualText boldText={t("Gov ID ")} normalText={""} />
            </View>
          </View>
        )}
        {(symbol === "Rs." || symbol === "INR") &&
          hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.resCardContent}>
              <View style={styles.resCardLeft}>
                <InvoiceDualText boldText={t("Remark ")} normalText={""} />
              </View>
            </View>
          )}
      </View>
    </View>
  );

  const InvoiceRoomHeader = ({ hotelType, HotelEmail }) => (
    <View style={styles.container}>
      <Text style={{ width: "33.33%", fontSize: "8px", marginTop: "5px" }}>
        {t("Room type")}
      </Text>
      <Text style={{ width: "33.33%", fontSize: "8px", marginTop: "5px" }}>
        {t("Room Ids.")}
      </Text>
      <Text style={{ width: "33.33%", fontSize: "8px", marginTop: "5px" }}>
        {t("Rate plan")}
      </Text>
      {hotelType?.toUpperCase() !== "ISHA" &&
        (HotelEmail === "df@stayflexi.com" ||
          HotelEmail === "bookings@revenuefirst.in") && (
          <Text style={{ width: "33.33%", fontSize: "8px", marginTop: "5px" }}>
            {t("Room rate")}
          </Text>
        )}
      <Text style={{ width: "33.33%", fontSize: "8px", marginTop: "5px" }}>
        {t("Room occupancy")}
      </Text>
    </View>
  );

  // Display cancellation policy
  function showNewCancellationPolicy() {
    let ifShowNewCancellationPolicy = true;

    if (invoice?.hasOwnProperty("cancellationPolicies")) {
      for (let policy of invoice?.cancellationPolicies) {
        const policyArr = policy.split(":");
        const policyDesc = policyArr[1].trim();
        if (policyDesc.length === 0) {
          ifShowNewCancellationPolicy = false;
          break;
        }
      }
    } else {
      ifShowNewCancellationPolicy = false;
    }
    return ifShowNewCancellationPolicy;
  }

  // Function to generate the default cancellation policies
  function handleGenerateCancellationPolicies(invoice, customDetails) {
    return (
      <View>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_before}%{" "}
          {t("of total booking value when cancelled before")}{" "}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
        <Text
          style={{
            fontSize: "10px",
            marginBottom: 5,
          }}
        >
          {t("Guests will be refunded")}{" "}
          {invoice?.policy?.refund_prestay_within}% {t("if cancelled within")}{" "}
          {readableTimeConverter(invoice?.policy?.prestay_before)}{" "}
          {t("of checkin date.")}
        </Text>
      </View>
    );
  }

  return (
    <View
      style={[
        styles.section,
        {
          paddingTop: String(symbol)?.includes("Rs") ? "45px" : 20,
        },
      ]}
    >
      <View
        style={{
          position: "relative",
          flexWrap: "wrap",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <View
          style={{
            position: "relative",
            width: "33.33%",
            display: "flex",
            flexDirection: "column",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {hotelType?.toUpperCase() !== "ISHA" && (
            <Text style={{ fontSize: "8px", marginBottom: 3.5 }}>
              {t("Bill From")}
            </Text>
          )}
          <Text
            style={[
              styles.boldText,
              { marginBottom: 3, color: "grey", fontSize: "8px" },
            ]}
          >
            {hotelData?.hotel_name}
          </Text>
          <Text
            style={[
              styles.boldText,
              {
                marginBottom: 3,
                flexWrap: "wrap",
                color: "grey",
                fontSize: "8px",
              },
            ]}
          >
            {handleFullAddString(hotelData?.hotel_address)}
          </Text>
          {hotelType?.toUpperCase() !== "ISHA" && (
            <View style={styles.dualText}>
              <Text
                style={[styles.boldText, { color: "grey", fontSize: "8px" }]}
              >
                {t("Email :")}{" "}
              </Text>
              <Text
                style={[styles.boldText, { color: "grey", fontSize: "8px" }]}
              >
                {customDetails &&
                customDetails?.invoice_contact_email &&
                !customDetails?.invoice_contact_email
                  .toLowerCase()
                  .includes("null", "n/a")
                  ? customDetails?.invoice_contact_email
                  : hotelData?.hotel_email}
              </Text>
            </View>
          )}
          <View style={styles.dualText}>
            <Text style={[styles.boldText, { color: "grey", fontSize: "8px" }]}>
              {t("Phone :")}
            </Text>
            <Text style={[styles.boldText, { color: "grey", fontSize: "8px" }]}>
              {customDetails &&
              customDetails?.invoice_contact_phone &&
              !customDetails?.invoice_contact_phone
                .toLowerCase()
                .includes("null", "n/a")
                ? customDetails?.invoice_contact_phone
                : hotelData?.hotel_contact}
            </Text>
          </View>
        </View>

        <View
          style={{ position: "relative", width: "33%", alignItems: "center" }}
        >
          <Text style={{ fontSize: "12.5px" }}>{t("Registration Card")}</Text>
          {customDetails &&
            customDetails?.show_logo.toLowerCase() === "yes" &&
            getExtension(hotelLogo) && (
              <View
                style={{
                  position: "relative",
                  alignSelf: "center",
                  width: "35px",
                  // height: "auto",
                  marginBottom: "10px",
                }}
              >
                <Image src={getExtension(hotelLogo)} />
              </View>
            )}
        </View>

        <View
          style={{
            position: "relative",
            width: "33%",
            marginTop: "auto",
            marginBottom: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <Text style={{ marginBottom: 5, fontSize: "12px" }}>
            Registration card
          </Text> */}
          <View>
            {/* {customDetails?.hide_stayflexi_booking_id.toLowerCase() ===
              "no" && (
              <Text
                style={{
                  fontSize: "8px",
                  fontWeight: "bold",
                  marginBottom: 3,
                  color: "grey",
                }}
              >{`${handleInvoiceHeader(customDetails)}#${
                invoice?.bookingid
              }`}</Text>
            )} */}
            {/* {customDetails && Number(summaryData?.payment_made) > 0 ? (
              <Text
                style={{
                  fontSize: "8px",
                  fontWeight: "bold",
                  marginBottom: 3,
                  color: "grey",
                }}
              >{`${handleInvoiceHeader(customDetails)}#${handleCustomBookingId(
                invoice,
                customDetails
              )}`}</Text>
            ) : (
              <Text></Text>
            )} */}
            {hotelType.toUpperCase() !== "ISHA" && (
              <Text
                style={{ marginTop: "2.5px", fontSize: "8px", fontWeight: 500 }}
              >
                {t("Bill to :")}
              </Text>
            )}
            <View>
              <InvoiceDualText boldText={"Invoice no"} color={"grey"} />
            </View>
            <View>
              <InvoiceDualText
                boldText={t("Check-in")}
                // normalText={formatDate(invoice?.checkin)}
                color={"grey"}
              />
            </View>
            <View>
              <InvoiceDualText
                boldText={t("Check-out")}
                // normalText={formatDate(invoice?.checkout)}
                color={"grey"}
              />
            </View>
            <View>
              <View style={styles.dualText}>
                <InvoiceDualText
                  boldText={t("Nights")}
                  // normalText={invoice?.num_nights}
                  color={"grey"}
                />
                <InvoiceDualText
                  boldText={t("Adults")}
                  // normalText={invoice?.adults}
                  color={"grey"}
                />
                <InvoiceDualText
                  boldText={t("Children")}
                  // normalText={invoice?.children}
                  color={"grey"}
                />
              </View>
            </View>
            {/* <View>
              <InvoiceDualText
                boldText={"Room No.(s)"}
                
                color={"grey"}
              />
            </View>
            s */}
            <View>
              <InvoiceDualText
                boldText={t("Source")}
                // normalText={invoice?.booking_source_displayname}
                color={"grey"}
              />
            </View>
          </View>
        </View>
        {/* </View> */}
      </View>

      {/* <View style={{ width: "50%", textAlign: "right" }}>
          <Text
            style={{
              fontSize: "12px",
              paddingRight: "5px",
              marginBottom: 3,
            }}
          >
            Bill to
          </Text>
          <Text style={[styles.boldText, { marginBottom: 3.5 }]}>
            {invoice?.customer_name}
          </Text>
          {!invoice?.customer_address_full_str.startsWith("null") && (
            <Text
              style={[
                styles.boldText,
                {
                  marginBottom: 3.5,
                  marginLeft: 10,
                  flexWrap: "wrap",
                },
              ]}
            >
              {invoice?.customer_address_full_str}
            </Text>
          )}
          <View
            style={[
              {
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: 3.5,
              },
              { textAlign: "right" },
            ]}
          >
            <Text style={styles.boldText}>Email : </Text>
            <Text style={styles.boldText}>{invoice?.customer_email}</Text>
          </View>
          <View
            style={[
              {
                flexDirection: "row",
                marginBottom: 3.5,
                justifyContent: "flex-end",
              },
              { textAlign: "right" },
            ]}
          >
            <Text style={styles.boldText}>Phone:</Text>
            <Text style={styles.boldText}>{invoice?.customer_phone}</Text>
          </View>
        </View> */}
      {/* </View> */}

      {/* {hotelType.toUpperCase() !== "ISHA" && (
        <Text style={{ marginTop: "2.5px", fontSize: "8px", fontWeight: 500 }}>
          {" "}
          Bill to :{" "}
        </Text>
      )} */}
      <View style={styles.tableContainer}>
        <ReservationGuestInfo symbol={symbol} hotelType={hotelType} />
      </View>
      <View style={styles.tableContainer}>
        <InvoiceRoomHeader hotelType={hotelType} HotelEmail={HotelEmail} />
        <InvoiceRoomRow
          items={[0, 0, 0, 0, 0]}
          hotelType={hotelType}
          HotelEmail={HotelEmail}
        />
      </View>
      {/* <View style={styles.tableContainer}></View> */}

      {/* Displaying guest and hotel signature */}
      <View style={styles.signature}>
        <View style={styles.signatureData}>
          <Text style={{ fontSize: "8px", marginBottom: 5 }}>
            {t("Guest signature :")}
          </Text>
          <Text style={{ marginLeft: 5 }}>_________________________</Text>
        </View>
        <View style={styles.signatureData}>
          {symbol === "$" ? (
            <Text style={{ fontSize: "8px", marginBottom: 5 }}>
              {t("Guest Name :")}
            </Text>
          ) : (
            <Text style={{ fontSize: "8px", marginBottom: 5 }}>
              {t("Hotel signature :")}
            </Text>
          )}
          <Text style={{ marginLeft: 5 }}>_________________________</Text>
        </View>
      </View>

      {/* Displaying Cancellation Policies */}
      {customDetails &&
        Object.keys(customDetails).length > 0 &&
        customDetails?.show_res_cancellation_policy === "YES" && (
          <View>
            <Text
              style={{
                fontSize: "10px",
                marginTop: 7.5,
                marginBottom: 7.5,
                textDecoration: "underline",
              }}
            >
              {t("Cancellation Policies")}
            </Text>
            {invoice.hasOwnProperty("cancellationPolicies") ? (
              invoice?.cancellationPolicies?.length > 0 &&
              showNewCancellationPolicy() ? (
                <View>
                  {invoice?.cancellationPolicies
                    ?.map((policies) => policies.replace(":", "\n"))
                    .map((policy) => (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 5,
                          flexWrap: "wrap",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "10px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {policy}
                        </Text>
                      </View>
                    ))}
                </View>
              ) : (
                handleGenerateCancellationPolicies(invoice, customDetails)
              )
            ) : (
              handleGenerateCancellationPolicies(invoice, customDetails)
            )}
          </View>
        )}

      {/* Displaying Terms and Conditions */}
      {customDetails &&
        Object.keys(customDetails).length > 0 &&
        customDetails?.show_res_terms_and_condition.toLowerCase() === "yes" && (
          <View style={{ marginTop: 5 }}>
            <Text style={{ fontSize: "8px", marginBottom: 5 }}>
              {t("Terms and Conditions")}
            </Text>
            <Text style={{ fontSize: "8px", marginBottom: 5 }}>
              {/* {hotelData?.hotel_terms_conditions} */}
              {localStorage.getItem("tnc")}
            </Text>
          </View>
        )}
    </View>
  );
}

export default PrintEmptyResCard;

// Create styles
const styles = StyleSheet.create({
  parentImage: {
    height: "75px",
    width: "125px",
  },
  image: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 3,
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "7.5px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "black",
    fontSize: "7.5px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderBottomWidth: 1,
    alignItems: "left",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    textAlign: "center",
    borderRightWidth: 1,
  },
  amount: {
    textAlign: "center",
    width: "15%",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 4,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
  },
  row: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    padding: "10px",
    inlineSize: "auto",
    fontStyle: "bold",
    justifyContent: "space-between",
  },
  resCardWrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: "10px",
  },
  resCardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flexStart",
    fontSize: "10px",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: "4.5px",
    height: 30,
    inlineSize: "auto",
  },
  resCardLeft: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "spaceBetween",
    position: "relative",
    width: "50%",
    flexDirection: "row",
    marginTop: "auto",
    marginBottom: "auto",
  },
  resCardHeader: {
    fontSize: "10px",
    fontWeight: "500",
    width: "40%",
    flexWrap: "wrap",
    display: "flex",
  },
  resCardVal: {
    fontSize: "10px",
    marginLeft: "7.5px",
    flexWrap: "wrap",
    width: "55%",
    display: "flex",
  },
  resCardRight: {
    fontSize: "10px",
    display: "flex",
    justifyContent: "spaceBetween",
    position: "relative",
    width: "50%",
    flexDirection: "row",
    marginLeft: "10px",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingLeft: "10px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  signature: {
    marginTop: 25,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
});
