import { Paper } from "@mui/material";
import React from "react";
import App from "./TimeLine";
import { connect } from "react-redux";
import DeniedAccess from "../AccessControl/Views/DeniedAccess";

const Time = (props) => {
  return (
    <div>
      {JSON.parse(props?.data?.accessControl?.user_feature_map?.ReservationGrid)
        .read !== "YES" ? (
        <DeniedAccess />
      ) : (
        <Paper>
          <App selectedLanguage={props.selectedLanguage} />
        </Paper>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(Time);
