import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { resetPassword, validateTokenReset } from "../_redux/authCrud";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { isNull, isUndefined } from "lodash-es";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import i18n from "./i18n.js";

const ResetPasswordPage = (props) => {
  const { t } = useTranslation();

  console.log("localStorage.get)", localStorage.getItem("lang"));
  // Initialize useTranslation hook
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang"));
  }, [localStorage.getItem("lang")]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");
  const LoginSchema = Yup.object().shape({
    passw: Yup.string().required(`${t("Password is required")}`),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const formik = useFormik({
    initialValues: { passw: "" },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      validUser(values);
    },
  });

  const validUser = async (values) => {
    setButtonLoading(true);
    try {
      const resp = await resetPassword({
        token: token,
        newPassword: values.passw,
      });
      setButtonLoading(false);
      setIsSuccess(true);
    } catch (e) {
      setButtonLoading(false);
      if (!isUndefined(e.response)) {
        if (e.response.data === "Password cannot be same as old password") {
          setShowError(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(false);
      }
    }
  };

  const check = async () => {
    setLoading(true);
    if (isUndefined(token) || isNull(token)) {
      setIsValid(false);
      setLoading(false);
      return;
    }
    try {
      const resp = await validateTokenReset(token);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setIsValid(false);
    }
  };
  useEffect(async () => {
    await check();
  }, []);

  return (
    <div>
      {loading && (
        <Grid container justifyContent="center" display="grid">
          <CircularProgress size={60} />
        </Grid>
      )}
      {!loading && isValid && !isSuccess && (
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container display="grid" spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3" paddingBottom={1}>
                {t("Reset password")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formik.values.passw}
                onChange={(e) => {
                  formik.setFieldValue("passw", e.target.value);
                  if (showError) setShowError(false);
                }}
                fullWidth
                type={showPassword ? `${t("text")}` : `${t("password")}`}
                onBlur={formik.handleBlur}
                name="passw"
                id="passw"
                error={
                  (formik.touched.passw && Boolean(formik.errors.passw)) ||
                  showError
                }
                helperText={
                  (formik.touched.passw && formik.errors.passw) ||
                  (showError &&
                    `${t("Password cannot be same as old password")}`)
                }
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                sx={{ height: "150%" }}
                loading={buttonLoading}
                fullWidth
                variant="custom-button"
                size="large"
              >
                {t("Reset password")}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      )}

      {!isValid && (
        <Grid container display="grid" spacing={4}>
          <Grid item justifyContent="center" container xs={12}>
            <Typography variant="h3" whiteSpace={matches ? "nowrap" : "normal"}>
              {" "}
              {t("Something went wrong")}
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent="center" container>
            <Link to="/auth/login">
              <Button variant="outlined" size="large">
                {t("Home")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      )}
      {isSuccess && (
        <Grid container spacing={4}>
          <Grid item justifyContent="center" container xs={12}>
            <Typography variant="h3" whiteSpace={matches ? "nowrap" : "normal"}>
              {" "}
              {t("Password has been changed successfully")}
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent="center" container>
            <Link to="/auth/login">
              <Button variant="outlined" size="large">
                {t("Home")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ResetPasswordPage;
