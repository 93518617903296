import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Switch,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  InputAdornment,
  Slider,
} from "@mui/material";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import { toast } from "material-react-toastify";
import { connect, shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PaymentConfiguration = () => {
  const { t } = useTranslation();

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const [channels, setchannels] = useState({});
  const [autoCharge, setautoCharge] = useState(false);
  const [enquiryOnHold, setenquiryOnHold] = useState("NO");
  const [cancellationPolicy, setcancellationPolicy] = useState(false);
  const [expiryhours, setexpiryhours] = useState(0);
  const [expiryType, setexpiryType] = useState("from_current_time");
  const [notifEmailList, setnotifEmailList] = useState("");
  const [notifPhoneList, setnotifPhoneList] = useState("");
  const [selectedChannel, setselectedChannel] = useState([]);
  const [paymnetData, setpaymnetData] = useState({});
  const [loader, setloader] = useState(false);
  const [partialPaymentPerc, setPartialPaymentPerc] = useState(100);

  useEffect(() => {
    handleSpringDataRequest(`core/api/v1/rate-template/get-connected-channels`)
      .then((res) => {
        setchannels(res);
      })
      .catch((err) => toast.error(`${t("Error in fetching channels")}`));
  }, []);
  const getData = () => {
    handleSpringDataRequest(`core/api/v1/payment/get-payment-configuration`)
      .then((res) => {
        setpaymnetData(res);
        if (Object.entries(res).length > 0) {
          setselectedChannel(res.channels.split(","));
          setautoCharge(res.auto_charge === "NO" ? false : true);
          setcancellationPolicy(
            res.check_cancellation_policy === "NO" ? false : true
          );
          setexpiryhours(res.enquiry_exp_hrs);
          setexpiryType(res.exp_type);
          setnotifEmailList(res.notif_email_list);
          setnotifPhoneList(res.notif_phone_list);
          setenquiryOnHold(res?.change_to_enquiry);
          setPartialPaymentPerc(res?.payment_percent);
        } else {
          setselectedChannel([]);
          setautoCharge(false);
          setcancellationPolicy(false);
          setexpiryhours(0);
          setexpiryType("from_current_time");
          setnotifEmailList("");
          setnotifPhoneList("");
          setenquiryOnHold("NO");
          setPartialPaymentPerc(100);
        }
      })
      .catch((err) => toast.error(`${t("Error in fetching channels")}`));
  };
  useEffect(() => {
    getData();
  }, []);

  const save = () => {
    setloader(true);
    const payload = {
      hotelId: hotel?.hotelId,
      channels: selectedChannel.toString(),
      autoCharge: autoCharge ? "YES" : "NO",
      checkCancellationPolicy: cancellationPolicy ? "YES" : "NO",
      enquiryExpHrs: expiryhours,
      expType: expiryType,
      notifEmailList: notifEmailList,
      notifPhoneList: notifPhoneList,
      changeToEnquiry: enquiryOnHold,
      paymentPerc: partialPaymentPerc,
    };
    if (Object.entries(paymnetData).length === 0) {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/payment/create`,
        payload
      )
        .then((res) => {
          toast.success(`${t("Successfully created")}`);
          getData();
          setloader(false);
        })
        .catch((err) => {
          toast.error(`${t("Error in creating")}`);
          setloader(false);
        });
    } else {
      handleSpringDataMutationRequest(
        "PUT",
        `core/api/v1/payment/update`,
        payload
      )
        .then((res) => {
          toast.success(`${t("Successfully updated")}`);
          getData();
          setloader(false);
        })
        .catch((err) => {
          toast.error(`${t("Error in updating")}`);
          setloader(false);
        });
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      setselectedChannel(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  return (
    <div>
      <Grid container>
        <div className="d-flex flex-column">
          <h2>{t("Payment automation configuration")}</h2>
        </div>
        <div className="w-100">
          <div
            className="d-flex align-item-center"
            style={{ marginTop: "2rem" }}
          >
            <h3 className="mb-0" style={{ marginTop: "5px" }}>
              {t("Enable payment automations")}
            </h3>
            <Switch
              checked={autoCharge}
              onChange={(event) => setautoCharge(event.target.checked)}
            />
          </div>
          <p className="text-muted  mb-3" style={{ fontWeight: "bold" }}>
            {t(
              "The Payment Automation feature streamlines payment collection for property reservations, particularly when guests choose to pay during hotel check-in. Platforms like Booking.com, Expedia, and other OTAs allow guests to provide card details for later payment. If the payment automation feature is enabled, it reduces cancellations, empowers hotels to secure payments promptly, and enhances the overall payment experience for property owners and guests."
            )}
          </p>
          {autoCharge && (
            <div>
              <div
                className="d-flex align-item-center"
                style={{ marginTop: "2rem" }}
              >
                <h3 className="mb-0 mr-3" style={{ marginTop: "5px" }}>
                  {t("Apply payment automation to these channels")}
                </h3>
                {channels?.length > 0 && (
                  <FormControl style={{ width: "25rem" }}>
                    <InputLabel id="days-multiple-chip-label">
                      {t("Channels")}
                    </InputLabel>
                    <Select
                      labelId="days-multiple-chip-label"
                      id="day-multiple-chip"
                      value={selectedChannel}
                      multiple
                      onChange={handleChange}
                      required="true"
                      input={
                        <OutlinedInput
                          id="day-multiple-chip"
                          label={t("Channels")}
                        />
                      }
                      label={t("Channels")}
                      size={"small"}
                    >
                      {channels?.map(
                        (channel) =>
                          channel !== "all" &&
                          channel !== "walkin" &&
                          channel !== "booking engine" &&
                          channel !== "bookflexi" && (
                            <MenuItem key={channel} value={channel}>
                              {channel}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                )}
              </div>
              <p className="text-muted  mb-3" style={{ fontWeight: "bold" }}>
                {t(
                  "If your online channel partner allows the option for guests to create a reservation by submitting credit card details with pay later option. Our system will automatically charge the guest's valid card details. In case of an invalid card, our system will trigger an email to the guest, requesting updated card information. Note: The selected channel here will be activated for payment automation."
                )}
              </p>

              <div
                className="d-flex align-item-center"
                style={{ marginTop: "2rem" }}
              >
                <h3 className="mb-0" style={{ marginTop: "5px" }}>
                  {t("Enable cancellation policy")}
                </h3>
                <Switch
                  checked={cancellationPolicy}
                  onChange={(event) =>
                    setcancellationPolicy(event.target.checked)
                  }
                />
              </div>
              <p className="text-muted  mb-3" style={{ fontWeight: "bold" }}>
                {t(
                  "If this toggle is turned on, here is how it works: For non-refundable policies, the entire booking amount will be charged immediately upfront after the creation of the booking. For partially refundable policies, the system will intelligently process the payment after the refundable time period for the booking is over."
                )}
              </p>

              <div
                className="d-flex align-item-center"
                style={{ marginTop: "2rem" }}
              >
                <h3
                  className="mb-0"
                  style={{ marginTop: "5px", marginRight: "1rem" }}
                >
                  {t("Convert booking into an enquiry when payment fails?")}
                </h3>
                <TextField
                  onChange={(e) => {
                    setenquiryOnHold(e.target.value);
                  }}
                  select
                  label={t("Convert to Enquiry?")}
                  sx={{ width: "12rem" }}
                  value={enquiryOnHold}
                  size={"small"}
                >
                  <MenuItem key={"YES"} value={"YES"}>
                    {t("YES")}
                  </MenuItem>
                  <MenuItem key={"NO"} value={"NO"}>
                    {t("NO")}
                  </MenuItem>
                </TextField>
              </div>
              <p className="text-muted  mb-3" style={{ fontWeight: "bold" }}>
                {t(
                  "If this configuration is selected as Yes, the booking will be converted into an enquiry, and the inventory will be held for a period defined manually by the property in the Expiry Hour box below. If No is selected, the booking will not convert into an enquiry instead, it will remain a confirmed booking, holding the inventory according to the checkout date."
                )}
              </p>

              {enquiryOnHold === "YES" && (
                <div>
                  <div
                    className="d-flex align-item-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <h3 className="mb-0 mr-3" style={{ marginTop: "5px" }}>
                      {t("Set expiry for enquiry bookings")}
                    </h3>

                    <TextField
                      onChange={(e) => {
                        setexpiryhours(e.target.value);
                      }}
                      label={t("Expiry Hours")}
                      sx={{ width: "10rem", marginRight: "1rem" }}
                      value={expiryhours}
                      size={"small"}
                    />

                    <TextField
                      onChange={(e) => {
                        setexpiryType(e.target.value);
                      }}
                      select
                      label={t("Expiry type")}
                      sx={{ width: "12rem" }}
                      value={expiryType}
                      size={"small"}
                    >
                      <MenuItem
                        key={"from_current_time"}
                        value={"from_current_time"}
                      >
                        {t("From the time of booking creation")}
                      </MenuItem>
                      <MenuItem key={"before_checkin"} value={"before_checkin"}>
                        {t("Before checkin")}
                      </MenuItem>
                    </TextField>
                  </div>
                  <p
                    className="text-muted  mb-3"
                    style={{ fontWeight: "bold" }}
                  >
                    {t(
                      "The system will retain inventory according to the expiry hours set by the property. If the guest completes payment within the specified time, the booking status will shift from enquiry to confirmed. If the payment is not made by the expiry time, the system will release the room inventory, making it available for other guests."
                    )}
                  </p>
                </div>
              )}
              <div
                className="d-flex align-item-center"
                style={{ marginTop: "2rem" }}
              >
                <h3
                  className="mb-0"
                  style={{ marginTop: "5px", marginRight: "1rem" }}
                >
                  {t(
                    "What percentage of the booking amount do you wish to charge?"
                  )}
                </h3>
                <Slider
                  value={partialPaymentPerc}
                  onChange={(e) => setPartialPaymentPerc(e.target.value)}
                  min={20}
                  defaultValue={20}
                  step={10}
                  max={100}
                  style={{
                    width: "12rem",
                    marginLeft: "13px",
                    marginRight: "10px",
                  }}
                />
                <TextField
                  label={t("Partial payment")}
                  size="small"
                  sx={{ width: "12rem" }}
                  onChange={(e) => setPartialPaymentPerc(e.target.value)}
                  value={partialPaymentPerc}
                  type="number"
                  disabled
                  InputProps={{
                    min: 20,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div
                className="d-flex align-item-center"
                style={{ marginTop: "2rem" }}
              >
                <h3 className="mb-0 mr-3" style={{ marginTop: "5px" }}>
                  Notify us on email
                </h3>
                <TextField
                  onChange={(e) => {
                    setnotifEmailList(e.target.value);
                  }}
                  label="Notification email list"
                  sx={{ width: "45rem" }}
                  value={notifEmailList}
                  size={"small"}
                />
              </div>

              <div
                className="d-flex align-item-center"
                style={{ marginTop: "2rem" }}
              >
                <h3 className="mb-0 mr-3" style={{ marginTop: "5px" }}>
                  Notify us on whatsapp
                </h3>
                <TextField
                  onChange={(e) => {
                    setnotifPhoneList(e.target.value);
                  }}
                  label="Notification phone list"
                  sx={{ width: "45rem" }}
                  value={notifPhoneList}
                  size={"small"}
                />
              </div>
              <p className="text-muted  mb-3">
                You will receive notifications when bookings are charged
                automatically or put on-hold due to payment failures.
              </p> */}
            </div>
          )}
        </div>
        <Button
          variant="custom-button"
          onClick={save}
          style={{
            float: "right",
            marginLeft: "auto",
            marginRight: "19rem",
            marginTop: "2rem",
          }}
          disabled={loader}
        >
          {loader ? (
            <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
          ) : (
            `${t("Save")}`
          )}
        </Button>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(PaymentConfiguration);
