import React, { useState, useEffect } from "react";
import {
  Divider,
  Stack,
  Box,
  Breadcrumbs,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Toolbar,
} from "@mui/material";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import * as MdIcons from "react-icons/md";
import DashboardPage from "./Dashboard/DashboardPage";
import PlaceOrderPage from "./PlaceOrders/PlaceOrderPage";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import OutletConfiguration from "./Configuration/Components/OutletConfiguration";
import * as BsIcons from "react-icons/bs";
import { connect } from "react-redux";
import { handleSpringDataRequest } from "../../../api/index";
import { toast } from "material-react-toastify";
import * as auth from "../../../modules/Auth/_redux/authRedux";
import { useTranslation } from "react-i18next";

function ShopWrapper({
  backBtn = false,
  contentArea = null,
  data,
  shopAccessControl,
  setShopAccessControl,
}) {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId, shopName, shopType } = useParams();

  // Get the access control for the shops
  async function handleGetShopAccessControl() {
    try {
      const res = await handleSpringDataRequest(
        `core/api/v1/accesscontrol/get-user-shop-access?shopId=${shopId}&email=${localStorage.getItem(
          "email"
        )}`
      );
      setShopAccessControl(res);
    } catch (err) {
      console.log("shop access control : ", err);
    }
  }

  useEffect(() => {
    handleGetShopAccessControl();
  }, []);

  // Defining the breadcrums
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={{
        pathname: `/allOutlets`,
        search: `&hotelId=${hotelId}`,
      }}
    >
      {t("All Outlets")}
    </Link>,
    <Typography key="2" color="text.primary">
      {shopName}
    </Typography>,
  ];

  // Render the tab based upon the clicking of the tab name
  const [tabName, setTabName] = useState(0);
  useEffect(() => {
    if (location.state) {
      setTabName(location.state.index);
    } else {
      setTabName(0);
    }
  }, [location]);

  // Toggling the configuration view
  const [configurationView, setConfigurationView] = useState(false);
  useEffect(() => {
    if (location.state) {
      if (location.state.index >= 4 && location.state.index <= 10) {
        return setConfigurationView(true);
      } else {
        return setConfigurationView(false);
      }
    } else {
      return setConfigurationView(false);
    }
  }, [location]);

  // Generating the random order id
  const handleGenerateRandomId = (index) => {
    handleSpringDataRequest("core/api/v1/orders/get-random-order-id")
      .then((res) =>
        history.push({
          pathname: `/outlet/${shopId}/${shopName}/${shopType}/randomOrder/random/${res}/placeOrder`,
          search: `&hotelId=${hotelId}`,
          state: { index: index },
        })
      )
      .catch((err) =>
        toast.error(
          `${t("Error in generating the ID. Please try again later!")}`
        )
      );
  };

  // Building the drawer
  const drawerWidth = 180;
  const options = [
    {
      name: `${t("Dashboard")}`,
      tabName: "dashboard",
      // access:
      //   JSON.parse(data?.accessControl?.user_feature_map?.POSDashboard).read ===
      //   "YES",
    },
    {
      name: `${t("Place orders")}`,
      tabName: "placeOrder",
      // access:
      //   JSON.parse(data?.accessControl?.user_feature_map?.POSPlaceOrder)
      //     .read === "YES",
    },
    {
      name: `${t("View orders")}`,
      tabName: "viewOrders",
      // access:
      //   JSON.parse(data?.accessControl?.user_feature_map?.POSViewOrders)
      //     .read === "YES",
    },
    {
      name: `${t("Reports")}`,
      tabName: "reports",
      // access:
      //   JSON.parse(data?.accessControl?.user_feature_map?.POSReports).read ===
      //   "YES",
    },
  ];

  const confOptions = [
    {
      name: (
        <Stack direction="row" spacing={1}>
          <BsIcons.BsCircleFill
            style={{ marginTop: "auto", marginBottom: "auto" }}
            size="8px"
          />
          <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
            {" "}
            {t("Outlet")}{" "}
          </Typography>
        </Stack>
      ),
      tabName: "outlet",
      // access:
      //   JSON.parse(data?.accessControl?.user_feature_map?.POSAddUpdateShop)
      //     .read === "YES",
    },
    {
      name: (
        <Stack direction="row" spacing={1}>
          <BsIcons.BsCircleFill
            style={{ marginTop: "auto", marginBottom: "auto" }}
            size="8px"
          />
          <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
            {" "}
            {t("Menu items")}{" "}
          </Typography>
        </Stack>
      ),
      tabName: "menu",
      // access:
      //   JSON.parse(data?.accessControl?.user_feature_map?.POSAddUpdateMenuItem)
      //     .read === "YES",
    },
    {
      name: (
        <Stack direction="row" spacing={1}>
          <BsIcons.BsCircleFill
            style={{ marginTop: "auto", marginBottom: "auto" }}
            size="8px"
          />
          <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
            {" "}
            {t("Categories")}{" "}
          </Typography>
        </Stack>
      ),
      tabName: "categories",
      // access:
      //   JSON.parse(
      //     data?.accessControl?.user_feature_map?.POSAddUpdateItemCategories
      //   ).read === "YES",
    },
    {
      name: (
        <Stack direction="row" spacing={1}>
          <BsIcons.BsCircleFill
            style={{ marginTop: "auto", marginBottom: "auto" }}
            size="8px"
          />
          <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
            {" "}
            {t("Discounts")}{" "}
          </Typography>
        </Stack>
      ),
      tabName: "discounts",
      // access:
      //   JSON.parse(
      //     data?.accessControl?.user_feature_map?.POSAddUpdateItemDiscounts
      //   ).read === "YES",
    },
    {
      name: (
        <Stack direction="row" spacing={1}>
          <BsIcons.BsCircleFill
            style={{ marginTop: "auto", marginBottom: "auto" }}
            size="8px"
          />
          <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
            {" "}
            {t("Table/Area")}{" "}
          </Typography>
        </Stack>
      ),
      tabName: "table",
      // access:
      //   JSON.parse(
      //     data?.accessControl?.user_feature_map?.POSAddUpdateOrderTable
      //   ).read === "YES",
    },
    {
      name: (
        <Stack direction="row" spacing={1}>
          <BsIcons.BsCircleFill
            style={{ marginTop: "auto", marginBottom: "auto" }}
            size="8px"
          />
          <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
            {" "}
            {t("Staff")}{" "}
          </Typography>
        </Stack>
      ),
      tabName: "staff",
      // access:
      //   JSON.parse(data?.accessControl?.user_feature_map?.POSAddUpdateStaff)
      //     .read === "YES",
    },
  ];
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // set display prop for the drawer items which are collapsible
  const displayCollapsibleItems = (type) => {
    const displayVal =
      type === "collapsible"
        ? configurationView === true
          ? "block"
          : "none"
        : "block";
    return displayVal;
  };

  // set the links while creating the drawer
  const constructPathName = (pathName) => {
    return `/outlet/${shopId}/${shopName}/${shopType}/${pathName}`;
  };

  const handleSelected = (index) => {
    if (tabName === index) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    handleSelected();
  }, [location]);

  const generateLinkForTabs = (tabName, index) => {
    history.push({
      pathname: constructPathName(tabName),
      search: `?hotelId=${hotelId}`,
      state: { index: index },
    });
  };

  const drawer = (
    <Stack className="ml-4">
      <Divider />
      <List>
        {options
          // .filter((option) => option.access)
          .map((item, index) => (
            <ListItem
              key={item.name}
              disablePadding
              style={{
                backgroundColor: "f8f8f8",
              }}
              selected={handleSelected(index)}
              onClick={(event) => {
                setTabName(index);
                item.tabName === "placeOrder"
                  ? shopType === "fnb"
                    ? generateLinkForTabs(item.tabName, index)
                    : handleGenerateRandomId(index)
                  : generateLinkForTabs(item.tabName, index);
              }}
            >
              <ListItemButton
                style={{
                  backgroundColor: "f8f8f8",
                }}
              >
                <ListItemText style={{ color: "black" }} primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        <Stack className="mt-4 ml-4 position-relative w-100">
          <Stack
            spacing={2}
            direction="row"
            onClick={() => {
              setConfigurationView((prev) => !prev);
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography>{t("Configuration")}</Typography>
            {configurationView === false ? (
              <ChevronRightIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </Stack>
        </Stack>
        {configurationView === true && (
          <Stack
            style={{
              // display: configurationView === true ? "block" : "none",
              backgroundColor: "f8f8f8",
              marginTop: "8px",
            }}
          >
            {confOptions
              // .filter((conf) => conf.access)
              .map((item, index) => (
                <ListItem
                  key={item.name}
                  disablePadding
                  style={{
                    backgroundColor: "f8f8f8",
                  }}
                  selected={handleSelected(index + options.length + 1)}
                  onClick={(event) => {
                    setTabName(index + options.length + 1);
                    generateLinkForTabs(
                      item.tabName,
                      index + options.length + 1
                    );
                  }}
                >
                  <ListItemButton
                    style={{
                      backgroundColor: "f8f8f8",
                    }}
                  >
                    <ListItemText
                      style={{ color: "black" }}
                      primary={item.name}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </Stack>
        )}
      </List>
    </Stack>
  );

  return (
    <Stack spacing={2} style={{ position: "relative", width: "100%" }}>
      <Stack className="position-relative">
        <Stack
          className="position-fixed"
          spacing={2}
          // direction="row"
          style={{ width: "100%" }}
        >
          <Stack
            className="position-relative w-100 d-flex justify-content-between"
            direction="row"
          >
            <Breadcrumbs
              separator={<MdIcons.MdOutlineArrowForwardIos size={15} />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>

            {backBtn && (
              <Typography
                style={{ cursor: "pointer" }}
                className="mr-15"
                onClick={() =>
                  history.goBack({ state: location?.state?.index })
                }
              >
                {"<"} {t("Back")}
              </Typography>
            )}
          </Stack>
          <Divider />
        </Stack>
      </Stack>

      {/* Drawer logic */}
      <Stack
        direction="row"
        spacing={2}
        className="position-relative"
        style={{ width: "100%" }}
      >
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                height: "calc(100% - 64px)",
                top: 126,
                left: 8,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Stack
          className="d-flex position-relative mt-4 pt-4 mb-4"
          style={{ width: "100%" }}
        >
          <Box
            component="main"
            sx={{
              width: { sm: `calc(100vw - ${drawerWidth})` },
              height: "calc(100vh - 175px)",
            }}
            style={{
              paddingTop: "35px",
              position: "relative",
              overflowY: "scroll",
              width: { sm: `calc(100vw - ${drawerWidth})` },
            }}
          >
            {contentArea}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    shopAccessControl: state.auth.shopAccessControl,
  };
}
export default connect(mapStateToProps, auth.actions)(ShopWrapper);
