import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getAllRoomTypess } from "../../RevenueManagement/RatePlans/apis/RatePlanApis";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { connect } from "react-redux";
import { getAvailableRoom } from "../../FlexibleSlot/api/FlexibleSlotApi";
import moment from "moment";
import {
  spliRoomCosts,
  splitRooms,
} from "../../CalendarPage/Views/SplitRoomApi";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import {
  getBookingData,
  invalidateNightAudit,
} from "../../CalendarPage/api/ReservationApi";
import { getAllNonHiddenRoomTypes } from "../../InventoryCalendar/api/InventoryApi";
import { shallowEqual, useSelector } from "react-redux";
import {
  handleSpringDataRequest,
  handleSpringDataMutationRequest,
} from "../../../api";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const SplitRoomsss = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);
  const { bookingId, closeModal } = props;
  const classes = useStyles();
  const history = useHistory();
  //states
  const [roomTypess, setroomTypess] = useState([]);
  const [roomTypeData, setroomTypeData] = useState();
  const [roomIds, setRoomIds] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState("");

  const [openDirtyModal, setopenDirtyModal] = useState(false);
  const [isRoomDirty, setisRoomDirty] = useState(false);

  const closeDirtysModal = () => setopenDirtyModal(false);
  const openDirtysModal = () => setopenDirtyModal(true);

  //get all room types
  useEffect(async () => {
    const roomType = await getAllRoomTypess();
    setroomTypeData(roomType);
    setroomTypess(Object.keys(roomType)[0]);
  }, []);

  //handle room types  menu drop down
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const sethandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setroomTypess(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //get all avaiable room  ids
  useEffect(async () => {
    if (roomTypess.length > 0) {
      const data = await getAvailableRoom(roomTypess);
      setRoomIds(data.split(","));
      setSelectedRoomId(data.split(",")[0]);
    }
  }, [roomTypess]);

  const [splitSlot, setsplitSlot] = useState("14");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [roomCost, setroomCost] = useState();
  const [loader, setloader] = useState(false);

  const [availableSlots, setAvailableSlots] = useState([]);
  const getAvailableSlots = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/getCheckinAndCheckoutSlots?checkin=${moment(
        new Date()
      ).format("DD-MM-YYYY[Z]HH:mm:ss")}&checkout=${moment(new Date()).format(
        "DD-MM-YYYY[Z]HH:mm:ss"
      )}`
    )
      .then((data) => {
        setAvailableSlots(data?.checkinSlots);
        setsplitSlot(
          data?.checkinSlots[Math.floor(data?.checkinSlots?.length / 2)]
        );
      })
      .catch(() => {});
  };
  useEffect(() => {
    getAvailableSlots();
  }, []);

  const handle12Hours = (hour) => {
    if (hour === 0) {
      return "12 AM";
    } else if (hour >= 1 && hour <= 11) {
      return hour + " AM";
    } else if (hour === 12) {
      return "12 PM";
    } else {
      return hour - 12 + " PM";
    }
  };

  // get split room cost
  useEffect(async () => {
    setloader(true);
    console.log("loader : ", loader);
    if (roomTypess.length > 0) {
      if (selectedRoomId !== undefined && selectedRoomId !== "") {
        const payload = {
          dest_room_type_id:
            roomTypess[0] === ""
              ? null
              : roomTypess.length === 1
              ? roomTypess[0]
              : roomTypess,
          checkinDate: moment(currentDate).format("ddd MMM D yyyy"),
          checkinSlot: splitSlot,
          dest_room_id: selectedRoomId,
          res_id: props?.reservationId[selectedRooms],
          hotel_id: props.data.selectedHotel.hotelId,
        };
        const data = await spliRoomCosts(payload);
        setroomCost(parseInt(data?.upgrade_cost) + parseInt(data?.upgrade_tax));
        setloader(false);
      }
    }
  }, [roomTypess, splitSlot, selectedRoomId, currentDate]);

  // split room functinality
  const saveSplitRoom = async () => {
    const payload = {
      splitRoomCheckinDate: moment(currentDate).format("ddd MMM D yyyy"),
      splitRoomCheckinSlot: splitSlot,
      splitRoomID: selectedRoomId,
      splitRoomCost: roomCost,
      res_id: props?.reservationId[selectedRooms],
      bookingId: bookingId,
      hotel_id: props.data.selectedHotel.hotelId,
      email: props.data.email,
    };
    setloader(true);
    const data = await splitRooms(payload);
    setloader(false);
    if (data.message === "Successfully performed split room") {
      toast.success(`${t("Successfully splitted room")}`);
      closeModal();
      try {
        await invalidateNightAudit(bookingId);
      } catch {}

      if (props.isGrid) {
        props.reloadGrid();
      }
    } else {
      // toast.error("Something went wrong");
      toast.error(data?.message);
    }

    const payloadData = {
      hotelId: props.data.selectedHotel.hotelId,
      numOfDays: 7,
      roomIdsSort: true,
      roomTypes: null,
      startDate: moment(currentDate)
        .set({ hour: 0, minute: 0, second: 0 })
        .format("DD-MM-YYYY HH:mm:ss"),
    };
    const res = await getBookingData(payloadData, history);
    const response = await getAllNonHiddenRoomTypes();

    closeDirtysModal();
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const [selectedRooms, setselectedRooms] = useState([
    props.invoiceData.invoice.room_ids_list[0],
  ]);

  const handleChanges = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 0)
      setselectedRooms(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  useEffect(() => {}, [selectedRooms]);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const [unblockedRooms, setUnblockedRooms] = useState();

  function handleGetUnblockedRooms() {
    handleSpringDataRequest(
      `core/api/v1/room/non-blocked-rooms-for-split/?resId=${
        props?.reservationId[selectedRooms]
      }&roomTypeId=${roomTypess}&startDate=${moment(currentDate).format(
        "DD-MM-YYYY 00:00:00"
      )}`
    )
      .then((res) => {
        const unblocked =
          typeof res === "number" ? res.toString().split(",") : res.split(",");
        const set = new Set();
        unblocked.map((x) => {
          set.add(x);
        });
        setUnblockedRooms(set);
      })
      .catch((error) => console.log(`${t("Error in fetching the room ids.")}`));
  }

  useEffect(() => {
    handleGetUnblockedRooms();
  }, [roomTypess, currentDate]);

  useEffect(() => {
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/reservation/get-room-dirty-status`,
      selectedRooms
    )
      .then((res) => {
        const datas = Object.values(res).filter((e) => e === "DIRTY");
        if (datas?.length > 0) {
          setisRoomDirty(true);
        } else {
          setisRoomDirty(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedRooms]);

  const splitsRoom = (str) => {
    setloader(true);
    if (str === "YES") {
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-dirty`,
          {
            roomIds: selectedRooms,
            staffId: "",
            hotelId: props.data.selectedHotel.hotelId,
          }
        )
        .then((res) => saveSplitRoom())
        .catch((err) => saveSplitRoom());
    } else {
      saveSplitRoom();
    }
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.textField}>
        <Grid item xs={12}>
          <p style={{ fontSize: "15px" }}>
            {t("You are splitting Room No.(s)")} : {selectedRooms}.{" "}
            {t(
              "the reservation will be split and moved to the specified room id with the new specified checkin date."
            )}
          </p>
        </Grid>
        <Grid item xs={6} spacing={2}>
          <h4
          // style={{
          //   marginLeft: "2rem",
          //   marginTop: "2rem",
          //   marginBottom: "1rem",
          // }}
          >
            {t("Select the room id to split room")}
          </h4>

          <div
            className="d-flex"
            // style={{ marginLeft: "2rem", marginRight: "2rem" }}
          >
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>{t("Room ids")}</InputLabel>
              <Select
                value={selectedRooms}
                onChange={handleChanges}
                required="true"
                MenuProps={MenuProps}
                label={t("Room ids")}
              >
                {props?.invoiceData?.invoice?.room_ids_list?.map((name) => (
                  <MenuItem key={name.id} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.textField}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <div className="d-flex flex-column">
              <Typography className="mb-1">{t("Split slot")}</Typography>
              <FormControl fullWidth>
                <InputLabel></InputLabel>

                <Select
                  onChange={(e) => setsplitSlot(e.target.value)}
                  label={t("Split slot")}
                  value={splitSlot}
                  notched={false}
                >
                  {availableSlots.map((hour) => (
                    <MenuItem value={hour}>{handle12Hours(hour)}</MenuItem>
                  ))}
                </Select>

                {/* {hotel.slotType === "NOON" && (
                  <Select
                    label={t("Split slot")}
                    onChange={(e) => setsplitSlot(e.target.value)}
                    value={splitSlot}
                    notched={false}
                  >
                    <MenuItem value={14}>2 PM</MenuItem>
                  </Select>
                )} */}

                {/* {hotel.slotType === "THREE_SLOTS" && (
                  <Select
                    label={t("Split slot")}
                    onChange={(e) => setsplitSlot(e.target.value)}
                    value={splitSlot}
                    notched={false}
                  >
                    <MenuItem value={5}>5 AM</MenuItem>
                    <MenuItem value={14}>2 PM</MenuItem>
                    <MenuItem value={22}>10 PM</MenuItem>
                  </Select>
                )} */}

                {/* {hotel.slotType === "ALL_TIME" && (
                  <Select
                    onChange={(e) => setsplitSlot(e.target.value)}
                    label={t("Split slot")}
                    value={splitSlot}
                    notched={false}
                  >
                    <MenuItem value={0}>12 AM</MenuItem>
                    <MenuItem value={1}>1 AM</MenuItem>
                    <MenuItem value={2}>2 AM</MenuItem>
                    <MenuItem value={3}>3 AM</MenuItem>
                    <MenuItem value={4}>4 AM</MenuItem>
                    <MenuItem value={5}>5 AM</MenuItem>
                    <MenuItem value={6}>6 AM</MenuItem>
                    <MenuItem value={7}>7 AM</MenuItem>
                    <MenuItem value={8}>8 AM</MenuItem>
                    <MenuItem value={9}>9 AM</MenuItem>
                    <MenuItem value={10}>10 AM</MenuItem>
                    <MenuItem value={11}>11 AM</MenuItem>
                    <MenuItem value={12}>12 PM</MenuItem>
                    <MenuItem value={13}>1 PM</MenuItem>
                    <MenuItem value={14}>2 PM</MenuItem>
                    <MenuItem value={15}>3 PM</MenuItem>
                    <MenuItem value={16}>4 PM</MenuItem>
                    <MenuItem value={17}>5 PM</MenuItem>
                    <MenuItem value={18}>6 PM</MenuItem>
                    <MenuItem value={19}>7 PM</MenuItem>
                    <MenuItem value={20}>8 PM</MenuItem>
                    <MenuItem value={21}>9 PM</MenuItem>
                    <MenuItem value={22}>10 PM</MenuItem>
                    <MenuItem value={23}>11 PM</MenuItem>
                  </Select>
                )} */}
              </FormControl>
              <p className="text-muted" style={{ fontSize: "15px" }}>
                {t("Select split checkin slot")}
              </p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="d-flex flex-column">
              <Typography> {t("Split room cost")}</Typography>
              <OutlinedInput
                label={t("Split room cost")}
                notched={false}
                className="input-lg w-100"
                placeholder={t("Enter the cost")}
                onChange={(e) => setroomCost(e.target.value)}
                value={roomCost}
                disabled={
                  JSON.parse(
                    props?.data?.selectedHotel?.accessControl?.user_feature_map
                      ?.EditBookingPrice
                  ).read === "YES"
                    ? false
                    : true
                }
              />
              <p className="text-muted" style={{ fontSize: "15px" }}>
                *{t("Automatically calculated by selections.")}
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={6} spacing={2}>
            <Typography className="mb-4">{t("Choose room type:")}</Typography>
            <FormControl sx={{ width: "19rem" }}>
              <InputLabel>{t("Room type")}</InputLabel>
              {roomTypeData && (
                <Select
                  label={t("Room type")}
                  value={roomTypess}
                  onChange={sethandleChange}
                  required="true"
                  MenuProps={MenuProps}
                >
                  <MenuItem value={""}>{t("All")}</MenuItem>
                  {Object?.keys(roomTypeData)?.map((key, index) => (
                    <MenuItem
                      key={key + index}
                      value={getKeyByValue(
                        roomTypeData,
                        roomTypeData[Number(key)]
                      )}
                    >
                      {roomTypeData[Number(key)]}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
            <p className="text-muted" style={{ fontSize: "15px" }}>
              *
              {t(
                "Let the current roomtype stay or select a different roomtype. Split cost field will be updated automatically."
              )}
            </p>
          </Grid>

          <Grid item xs={6}>
            <Typography className="mb-4">{t("New Room ID:")}</Typography>
            {roomIds?.length > 0 &&
            unblockedRooms.size > 0 &&
            !unblockedRooms?.has("") ? (
              <TextField
                label={t("Room id")}
                name="roomId"
                id="roomId"
                required
                fullWidth
                select
                onChange={(e) => setSelectedRoomId(e.target.value)}
                value={selectedRoomId}
                disabled={loader ? true : false}
              >
                {unblockedRooms &&
                  roomIds?.map((id) => {
                    if (unblockedRooms.has(id)) {
                      return (
                        <MenuItem key={id} value={id}>
                          {id}
                        </MenuItem>
                      );
                    }
                  })}
              </TextField>
            ) : (
              <p className="text-muted" style={{ fontSize: "15px" }}>
                No room available in this category please select different room
                category.
              </p>
            )}
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={6}>
            <div className="d-flex flex-column">
              <Typography className="mb-3">{t("Split Date:")}</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={t("Split Date:")}
                  // inputFormat="MMM dd, yyyy"
                  inputFormat="dd MMM yyyy"
                  value={currentDate}
                  onChange={(newValue) => {
                    setCurrentDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  // minDate={new Date()}
                />
              </LocalizationProvider>

              <p className="text-muted" style={{ fontSize: "15px" }}>
                {t("Select the checkin date for the newly split reservation")}
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          className={classes.textField}
        >
          <Fab
            type="button"
            color="primary"
            onClick={() => {
              if (
                props.invoiceData.invoice.status.toLowerCase() ===
                "admin_confirmed"
              ) {
                if (isRoomDirty) {
                  saveSplitRoom();
                } else {
                  openDirtysModal();
                }
              } else {
                saveSplitRoom();
              }
            }}
            variant="extended"
            disabled={loader}
          >
            {t("Split room")}
            {loader && (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            )}
          </Fab>
        </Grid>
      </Grid>
      <Modal
        show={openDirtyModal}
        onHide={closeDirtysModal}
        style={{ zIndex: "3000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h6>{`${t("Do you want to mark room number")} - ${selectedRooms} ${t(
            "dirty"
          )} ?`}</h6>
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="custom-button"
            onClick={() => splitsRoom("NO")}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("NO")}`
            )}
          </Button>
          <Button
            variant="custom-button"
            onClick={() => splitsRoom("YES")}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${t("YES")}`
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth };
}
export default connect(mapStateToProps)(SplitRoomsss);
