import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalTitle, ModalBody } from "react-bootstrap";
import "./SupportStyle.css";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  IconButton,
  Collapse,
} from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import EventIcon from "@mui/icons-material/Event";
import CloseIcon from "@mui/icons-material/Close";
import SchoolIcon from "@mui/icons-material/School";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ChatIcon from "@mui/icons-material/Chat";
const SupportModal = (props) => {
  const [supportMeet, setSupportMeet] = useState("");
  const [supportName, setSupportName] = useState("");
  const [supportTele, setSupportTele] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [supportLevel, setSupportLevel] = useState("LEVEL2");
  const [expandProdSupp, setExpandProdSupp] = useState(true);
  const [expandContact, setExpandContact] = useState(true);
  const [expandSupport, setExpandSupport] = useState(true);
  const [whiteLabelSupport, setWhiteLabelSupport] = useState("DISABLED");

  const expandCard = (cardName) => {
    if (cardName === "productSupport") {
      setExpandProdSupp(!expandProdSupp);
    } else if (cardName === "supportTeam") {
      setExpandContact(!expandContact);
    } else if (cardName === "relationContact") {
      setExpandSupport(!expandSupport);
    }
  };

  //const initializeWidget = () => {
  /*function initFreshChat() {
      window.fcWidget.init({
        token: "15ae2c70-2954-40bc-b456-4c0b35e7b32f",
        host: "https://wchat.freshchat.com",
        config: {
          headerProperty: {
            hideChatButton: true,
          },
        },
      });
      window.fcWidget.hide();
    }*/

  // function initialize(i, t) {
  //   let e;
  //   const data = i.getElementById(t)
  //     ? initFreshChat()
  //     : (((e = i.createElement("script")).id = t),
  //       (e.async = !0),
  //       (e.src = "https://wchat.freshchat.com/js/widget.js"),
  //       (e.onload = initFreshChat),
  //       i.head.appendChild(e));
  // }

  // function initiateCall() {
  //   initialize(document, "Freshchat-js-sdk");
  // }

  // initiateCall();

  //initFreshChat();
  // To set unique user id in your system when it is available
  //window.fcWidget.setExternalId(props.data.hotelId);

  /*window.fcWidget.user.setFirstName(
      props.data.hotelName + "[" + props.data.hotelId + "]"
    );*/

  //window.fcWidget.user.setEmail(props.data.email);
  // window.fcWidget.open();
  // window.fcWidget.close();
  //};

  // useEffect(() => {
  //   if (helpToggle === true) {
  //     window.fcWidget.open();
  //     window.fcWidget.show();
  //   } else if (helpToggle === false) {
  //     window.fcWidget.hide();
  //     window.fcWidget.close();
  //   }
  // }, [helpToggle]);
  /*useEffect(async () => {
    initializeWidget();
  }, [props?.data?.accessControl?.hotel?.group_id]);*/

  const initializeHubspotChatWidget = () => {
    window.hsConversationsOnReady = function () {
      window.HubSpotConversations.widget.setPath(window.location.pathname);
      //https://developers.hubspot.com/docs/api/events/tracking-code#identify-a-visitor
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        "identify",
        {
          email: props?.data?.accessControl?.hotel?.hotel_email,
          id: props?.data?.accessControl?.hotel?.hotel_id,
        },
      ]);
    };
  };
  useEffect(async () => {
    initializeHubspotChatWidget();
  }, [props?.data?.accessControl?.hotel?.group_id]);

  useEffect(() => {
    if (props?.csmData) {
      const data = props?.csmData;
      if (data?.account_csm_name) {
        setSupportName(data?.account_csm_name);
      }
      if (data?.account_csm_phone) {
        setSupportTele(data?.account_csm_phone);
      }
      if (data?.account_csm_email) {
        setSupportEmail(data?.account_csm_email);
      }
      if (data?.account_csm_calendar_link) {
        setSupportMeet(data?.account_csm_calendar_link);
      }
      if (data?.account_support_level) {
        setSupportLevel(data?.account_support_level);
      }
      if (data?.whitelabel_support_options) {
        setWhiteLabelSupport(data?.whitelabel_support_options);
      }
    }
  }, [props?.csmData]);

  useEffect(() => {}, [
    supportName,
    supportTele,
    supportEmail,
    supportMeet,
    supportLevel,
  ]);

  const [videoUrl, setvideoUrl] = useState("");
  const [documentationLink, setdocumentationLink] = useState(
    "https://support.stayflexi.com/docs/documentation/"
  );
  const [module, setmodule] = useState("");
  const getVideoUrl = () => {
    if (window.location.pathname.includes("/dashboard")) {
      setvideoUrl("https://www.youtube.com/embed/kzY1JKwlTc4");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/dashboard/"
      );
      setmodule("dashboard");
    } else if (window.location.pathname.includes("/calendar")) {
      setvideoUrl("https://www.youtube.com/embed/Y9PucQuCpHc");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/reservation-calendar/"
      );
      setmodule("reservation calendar");
    } else if (window.location.pathname.includes("/inventory-calendar")) {
      setvideoUrl("https://www.youtube.com/embed/8B9-zEbEDJQ");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/rates-inventory/"
      );
      setmodule("inventory calendar");
    } else if (window.location.pathname.includes("/housekeeping")) {
      setvideoUrl("https://www.youtube.com/embed/y0UJyXqpWp0");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/housekeeping/"
      );
      setmodule("housekeeping");
    } else if (window.location.pathname.includes("/inbox")) {
      setvideoUrl("");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/conversations/"
      );
      setmodule("conversations");
    } else if (window.location.pathname.includes("/reputation-dashboard")) {
      setvideoUrl("");
      setdocumentationLink("https://support.stayflexi.com/docs/documentation/");
      setmodule("review management");
    } else if (window.location.pathname.includes("/reports")) {
      setvideoUrl("");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/reports/"
      );
      setmodule("reports");
    } else if (window.location.pathname.includes("/allOutlets")) {
      setvideoUrl("");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/shops-pos/"
      );
      setmodule("shops");
    } else if (window.location.pathname.includes("/directBilling")) {
      setvideoUrl("");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/direct-billing/"
      );
      setmodule("direct billing");
    } else if (window.location.pathname.includes("/revenue-dashboard")) {
      setvideoUrl(
        "https://support.stayflexi.com/docs/documentation/revenue-management/"
      );
      setdocumentationLink("https://support.stayflexi.com/docs/documentation/");
      setmodule("revenue management");
    } else if (window.location.pathname.includes("/expense-dashboard")) {
      setvideoUrl("");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/expense-manager/"
      );
      setmodule("expense manager");
    } else if (window.location.pathname.includes("/stock/stock-dashboard")) {
      setvideoUrl("");
      setdocumentationLink("https://support.stayflexi.com/docs/documentation/");
      setmodule("stock management");
    } else if (window.location.pathname.includes("/payment-configuration")) {
      setvideoUrl("");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/payments/"
      );
      setmodule("payments");
    } else if (window.location.pathname.includes("/nightAudit")) {
      setvideoUrl("");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/night-audit/"
      );
      setmodule("night audit");
    } else if (window.location.pathname.includes("/property-dashboard")) {
      setvideoUrl("");
      setdocumentationLink(
        "https://support.stayflexi.com/docs/documentation/configuration/"
      );
      setmodule("property configuration");
    }
  };

  useEffect(() => {
    getVideoUrl();
  }, [window.location.pathname]);

  return (
    <div>
      <Modal
        show={props?.show}
        onHide={props?.hide}
        dialogClassName={"myModalBody6533"}
        contentClassName={"myModalContent32323"}
      >
        <ModalTitle
          style={{
            fontSize: "1.5rem",
            marginLeft: "0.5rem",
            marginTop: "1.5rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              style={{ fontWeight: "bold", paddingLeft: "3rem" }}
            >
              Support
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton onClick={props?.hide}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </ModalTitle>
        {/* <Divider style={{ marginTop: "0.5rem" }} /> */}
        <ModalBody>
          {whiteLabelSupport === "DISABLED" && (
            <>
              <Card
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  borderRadius: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <CardHeader
                  title="Product documentation"
                  titleTypographyProps={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                  action={
                    <IconButton onClick={(event) => expandCard("supportTeam")}>
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                />

                <Collapse in={expandContact}>
                  <Divider variant="middle" />

                  <CardContent>
                    <Grid container spacing={2}>
                      {videoUrl !== "" && (
                        <div class="video-container">
                          <iframe
                            src={videoUrl}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                          ></iframe>
                        </div>
                      )}

                      <a
                        // underline="hover"
                        href={documentationLink}
                        to={documentationLink}
                        padding={2}
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                          marginLeft: "1rem",
                          marginTop: "2rem",
                        }}
                      >
                        {`Learn how ${module} works ?`}
                      </a>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>

              <Card
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  borderRadius: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <CardHeader
                  title="Contact support team"
                  titleTypographyProps={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                  action={
                    <IconButton onClick={(event) => expandCard("supportTeam")}>
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                />

                <Collapse in={expandContact}>
                  <Divider variant="middle" />

                  <CardContent>
                    <Grid container spacing={2}>
                      {String(supportLevel) === "LEVEL3" ? (
                        <>
                          <Grid item xs={6}>
                            <ChatBubbleIcon /> Chat with us
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "right" }}>
                            <Button
                              variant="outlined"
                              onClick={(event) => {
                                /*open freshchat widget
                            window.fcWidget.open();
                            window.fcWidget.show();*/
                                /*Open hubspot chat widget
                                 * https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
                                 */
                                window.HubSpotConversations.widget.load();
                                window.HubSpotConversations.widget.open();
                              }}
                              className="supportModalMeetButton"
                              startIcon={<ChatIcon />}
                            >
                              Live chat
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {String(supportLevel) === "LEVEL2" ||
                      String(supportLevel) === "LEVEL3" ? (
                        <>
                          <Grid item xs={6}>
                            <CallIcon /> Call
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "right" }}>
                            {whiteLabelSupport === "ENABLED" ? (
                              <>
                                <a
                                  href={"tel:" + supportTele}
                                  className={"supportModalLink"}
                                >
                                  {supportTele}
                                </a>
                              </>
                            ) : (
                              <>
                                {String(
                                  props?.data?.accessControl?.hotel
                                    ?.hotel_country
                                ) === "India" ? (
                                  <a
                                    href="tel:08031404576"
                                    className={"supportModalLink"}
                                  >
                                    08031404576 (toll free)
                                  </a>
                                ) : (
                                  <a
                                    href="tel:+18442342363"
                                    className={"supportModalLink"}
                                  >
                                    +1 844 234 2363 (toll free)
                                  </a>
                                )}
                              </>
                            )}
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {String(supportLevel) === "LEVEL1" ||
                      String(supportLevel) === "LEVEL2" ||
                      String(supportLevel) === "LEVEL3" ? (
                        <>
                          <Grid item xs={4}>
                            <EmailIcon /> Email
                          </Grid>
                          {whiteLabelSupport === "ENABLED" ? (
                            <>
                              <Grid item xs={8} style={{ textAlign: "right" }}>
                                <a
                                  href={"mailto:" + supportEmail}
                                  className={"supportModalLink"}
                                >
                                  {supportEmail}
                                </a>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={8} style={{ textAlign: "right" }}>
                                <a
                                  href="mailto:support@stayflexi.com"
                                  className={"supportModalLink"}
                                >
                                  support@stayflexi.com
                                </a>
                              </Grid>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
              <Card
                variant="outlined"
                style={{
                  borderRadius: "1rem",
                }}
              >
                <CardHeader
                  title="Stayflexi support tools"
                  titleTypographyProps={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                  action={
                    <IconButton
                      onClick={(event) => expandCard("productSupport")}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                />
                <Collapse in={expandProdSupp}>
                  <Divider variant="middle" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          target="_blank"
                          href="https://business.stayflexi.com/sf-product-updates"
                          className={"supportModalButton"}
                          startIcon={<SystemUpdateIcon />}
                        >
                          Product updates
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          className={"supportModalButton"}
                          target="_blank"
                          // href="https://stayflexi.freshdesk.com/support/home"
                          href="https://support.stayflexi.com/docs/documentation/"
                          startIcon={<SchoolIcon />}
                        >
                          Knowledge base
                        </Button>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          className={"supportModalButton"}
                          target="_blank"
                          href="https://stayflexi.freshdesk.com/support/tickets/new"
                          startIcon={<ConfirmationNumberIcon />}
                        >
                          Raise a ticket
                        </Button>
                      </Grid> */}
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          className={"supportModalButton"}
                          target="_blank"
                          href="https://www.youtube.com/playlist?list=PLaPcDGXpN0pjuhJPWMBkjFpngOo7-uBO0"
                          startIcon={<OndemandVideoIcon />}
                        >
                          Product videos
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            </>
          )}
          {supportName !== "" && whiteLabelSupport === "DISABLED" && (
            <Card
              variant="outlined"
              style={{
                marginTop: "1rem",
                borderRadius: "1rem",
              }}
            >
              <CardHeader
                title="Contact relationship manager"
                titleTypographyProps={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
                action={
                  <IconButton
                    onClick={(event) => expandCard("relationContact")}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                }
              />
              <Collapse in={expandSupport}>
                <Divider variant="middle" />
                <CardContent>
                  <Grid item xs={12}>
                    <span style={{ fontWeight: "bold" }}>{supportName}</span>
                    <div style={{ fontSize: "1rem", marginTop: "0.5rem" }}>
                      {`${supportName} is your dedicated contact who is familiar
                      with your account and business needs. They’re typically
                      available during business hours ${
                        props.currency === "INR"
                          ? "10 AM - 6 PM IST"
                          : "10 AM - 6 PM PST"
                      }, but if they’re busy with
                      another customer, you can always contact Support for
                      immediate assistance.`}
                    </div>
                  </Grid>
                  <Divider
                    variant="middle"
                    style={{ marginBottom: "0.75rem", marginTop: "0.75rem" }}
                  />
                  <Grid container spacing={2}>
                    {supportMeet !== "" && (
                      <>
                        <Grid item xs={6}>
                          <EventIcon /> Schedule a meeting
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Button
                            href={supportMeet}
                            className={"supportModalMeetButton"}
                            target="_blank"
                            variant="outlined"
                            startIcon={<EventIcon />}
                          >
                            Schedule
                          </Button>
                        </Grid>
                      </>
                    )}
                    {/* {supportTele !== "" && (
                      <>
                        <Grid item xs={6}>
                          <CallIcon /> Call
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <a
                            href={"tel:" + supportTele}
                            className={"supportModalLink"}
                          >
                            {supportTele}
                          </a>
                        </Grid>
                      </>
                    )} */}
                    {supportEmail !== "" && (
                      <>
                        <Grid item xs={4}>
                          <EmailIcon /> Email
                        </Grid>
                        <Grid item xs={8} style={{ textAlign: "right" }}>
                          <a
                            href={"mailto:" + supportEmail}
                            className={"supportModalLink"}
                          >
                            {supportEmail}
                          </a>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Collapse>
            </Card>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(SupportModal);

const styles = {
  titleAdjustment: {
    display: "flex",
    justifyContent: "space-between",
  },
};
