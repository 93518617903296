import React, { Fragment, useState, useEffect } from "react";
import Currencies from "../../Utils/Currencies.json";
import moment from "moment";
import {
  readableTimeConverter,
  roundOffValues,
  validateAddress,
  handleFullAddString,
} from "../../Utils/Utils";
import {
  PDFDownloadLink,
  View,
  Document,
  Page,
  Image,
  StyleSheet,
  Text,
  pdf,
} from "@react-pdf/renderer";

const borderColor = "#E5e5e5";

// Create styles
const styles = StyleSheet.create({
  parentImage: {
    width: "85px",
    height: "auto",
  },
  image: {
    position: "relative",
    width: "75px",
    alignSelf: "center",
  },
  textHeight: {
    height: "20px",
  },
  page: {
    flexDirection: "column",
    width: "50vw",
    backgroundColor: "",
    height: "100vh",
  },
  section: {
    padding: 10,
    flexGrow: 1,
  },
  dualText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 2,
    alignContent: "flex-end",
  },
  boldText: {
    fontWeight: "bold",
    color: "#222",
    marginRight: "5px",
    fontSize: "10px",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  normalText: {
    fontWeight: 400,
    color: "black",
    fontSize: "10px",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "left",
    marginRight: "5px",
  },
  container: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderLeft: "none",
  },
  headerContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    backgroundColor: "rgba(181, 232, 224, 0.25)",
    borderLeft: "none",
  },
  description: {
    width: "45%",
    wordWrap: "word-break",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // textAlign: "center",
    paddingLeft: 2.5,
    paddingTop: 2,
  },
  taxDescription: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    padding: 2.5,
    wordWrap: "word-break",
  },
  rate: {
    width: "15%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 2.5,
    wordWrap: "word-break",
  },
  amount: {
    width: "15%",
    paddingLeft: 2.5,
    wordWrap: "word-break",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: borderColor,
    borderLeft: "none",
    borderTop: "none",
  },
  row: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #E5e5e5",
    borderTop: "none",
    borderLeft: "none",
  },
  taxRow: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 32,
    fontStyle: "bold",
  },
  signature: {
    marginTop: 25,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  signatureData: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  infoRow: {
    position: "relative",
    width: "12%",
    marginRight: "2px",
    wordBreak: "break-all",
    borderLeft: "1px solid #E5e5e5",
    fontSize: "10px",
    padding: "2.5px",
  },
});

// rounding of the digits
const handleDigits = (num) => {
  let formattedNum = parseFloat(num);
  return parseFloat(formattedNum).toFixed(2);
};

const InvoiceDualText = ({ boldText, normalText }) => {
  return (
    <View style={styles.dualText}>
      <Text style={styles.boldText}>{boldText}:</Text>
      <Text style={styles.normalText}>{normalText}</Text>
    </View>
  );
};

const InvoiceTableHeader = () => (
  <View style={styles.headerContainer}>
    <Text style={[styles.infoRow, { width: "25%" }]}>Raw Material</Text>
    <Text style={styles.infoRow}>Unit</Text>
    <Text style={styles.infoRow}>Quantity</Text>
    <Text style={styles.infoRow}>Base Price</Text>
    <Text style={styles.infoRow}>Tax</Text>
    <Text style={styles.infoRow}>Amount</Text>
  </View>
);

function SendPoPdf({ hotel, supplier, filteredPOData }) {
  const hotelCurrency = localStorage.getItem("hotelCurrency");

  const InvoiceTableRow = ({ items, symbol }) => {
    const rows = items.map((item, index) => (
      <View style={styles.row} key={index}>
        <View style={[styles.infoRow, { width: "25%" }]}>
          <Text>{item?.rm_name}</Text>
        </View>
        <Text style={styles.infoRow}>{item?.rm_unit}</Text>
        <Text style={styles.infoRow}>
          {handleDigits(Math.abs(item.rm_quantity))}
        </Text>
        <Text style={styles.infoRow}>
          {symbol + "" + handleDigits(Math.abs(item.rm_price))}
        </Text>

        <Text style={styles.infoRow}>
          {symbol + "" + handleDigits(Math.abs(item.rm_tax))}
        </Text>

        <Text style={styles.infoRow}>
          {symbol + "" + handleDigits(Math.abs(item.rm_amount))}
        </Text>
      </View>
    ));
    return <Fragment>{rows}</Fragment>;
  };
  const [symbol, setsymbol] = useState("");
  const getHotelCurrency = () =>
    Currencies?.filter((item) => {
      if (item.code === hotelCurrency) {
        sessionStorage.setItem("symbol", item.symbol);
        return setsymbol(item.symbol);
      }
    });

  useEffect(() => {
    getHotelCurrency();
  }, []);

  const getCoreTotal = () => {
    let total = 0;

    const data = filteredPOData?.map((e) => {
      total = total + e?.rm_amount;
    });
    return total;
  };

  const getTotal = () => {
    let total = 0;
    let taxes = 0;
    let delivery = 0;
    const data = filteredPOData?.map((e) => {
      total = total + e?.rm_amount;
      taxes = taxes + e?.rm_tax;
      delivery = delivery + e?.delivery_charge_included_in_invoice;
    });

    return total + taxes + delivery;
  };
  const getTotalTax = () => {
    let taxes = 0;
    const data = filteredPOData?.map((e) => {
      taxes = taxes + e?.rm_tax;
    });
    return taxes;
  };
  const getTotalDeliveryCharge = () => {
    let delivery = 0;
    const data = filteredPOData?.map((e) => {
      delivery = delivery + e?.delivery_charge_included_in_invoice;
    });
    return delivery;
  };

  return (
    <pdf>
      <Page>
        <View
          style={{
            position: "relative",
            padding: "9px",
            paddingTop: String(symbol)?.includes("Rs") ? "45px" : "9px",
            marginTop: "8%",
            marginLeft: "3%",
            marginRight: "3%",
          }}
        >
          <View
            style={{
              position: "relative",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            {/* Hotel details */}
            <View style={{ width: "50%" }}>
              {/* <Text style={{ fontSize: "10px", marginBottom: 3.5 }}>Bill From</Text> */}
              {/* <Text
          style={[
            styles.boldText,
            {
              marginBottom: 2,
              color: String(symbol)?.includes("Rs") ? "black" : "grey",
            },
          ]}
        >
          {"MArriot"}
        </Text> */}
              <View style={styles.dualText}>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  From :
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  {hotel?.hotelName}
                </Text>
              </View>
              <View style={styles.dualText}>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  Phone :
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  {hotel?.accessControl?.hotel?.hotel_contact}
                </Text>
              </View>
              <View style={styles.dualText}>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  GST :
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  {hotel?.accessControl?.hotel?.tax_identification_number}
                </Text>
              </View>
            </View>

            {/* Bill to - logic */}
            <View
              style={{
                width: "50%",
              }}
            >
              <View style={styles.dualText}>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  To
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  {supplier?.name}
                </Text>
              </View>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginBottom: 3,
                }}
              >
                {" "}
                {supplier?.address}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginBottom: 3,
                }}
              >{`${supplier?.city},${supplier?.state},${supplier?.country}`}</Text>
              <View style={styles.dualText}>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  Phone :
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  {supplier?.phone}
                </Text>
              </View>
              <View style={styles.dualText}>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  GST :
                </Text>
                <Text
                  style={[
                    styles.boldText,
                    {
                      color: String(symbol)?.includes("Rs") ? "black" : "grey",
                    },
                  ]}
                >
                  {supplier?.gst_number}
                </Text>
              </View>
            </View>
          </View>

          {/* Folio details */}
          <View
            style={{
              position: "relative",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{ position: "relative", width: "50%", marginTop: "9px" }}
            ></View>
          </View>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >{`Below is the Purchase [Po No.: ${filteredPOData[0]?.invoice_number}] And for dated ${filteredPOData[0]?.invoice_date}`}</Text>
          <View style={styles.tableContainer}>
            <InvoiceTableHeader />
            <InvoiceTableRow items={filteredPOData} symbol={symbol} />
          </View>

          <View
            style={{
              marginTop: 15,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ display: "flex", flexDirection: "column" }}>
              {/* {summaryData?.deposit_amount > 0.0 && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "7.5px",
            }}
          >
            <Text style={{ fontSize: "10.5px" }}> Cash deposit : </Text>

            <Text style={{ fontSize: "10px" }}>
              {" "}
              {symbol} {Number(summaryData?.deposit_amount).toFixed(2)}{" "}
            </Text>
          </View>
        )} */}
              {/* {summaryData?.auths && summaryData?.auths.length > 0 && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "4px",
            }}
          >
            {summaryData?.auths?.map((item, index) => (
              <View
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "7.5px",
                }}
              >
                <Text style={{ wordBreak: "break-word", fontSize: "10px" }}>
                  {item?.pm_payment_type} {item?.pm_payment_issuer}{" "}
                  Authorized:
                </Text>

                <Text style={{ wordBreak: "break-word", fontSize: "10px" }}>
                  {" "}
                  {symbol} {Number(item?.pm_amount)}
                </Text>
              </View>
            ))}
          </View>
        )} */}
              {/* {summaryData?.tax_breakdown && (
          <View>
            <Text style={{ fontSize: "10px", marginBottom: 5 }}>
              Tax breakdown
            </Text>
            <View style={{ width: "60%" }}>
              <InvoiceTaxBreakdownHeader />
              <InvoiceTaxBreakdownHeaderItems
                items={summaryData?.tax_breakdown}
                symbol={
                  hotelCurrency === hotelConvertedCurrency
                    ? symbol
                    : hotelConvertedCurrencySymbol
                }
              />
            </View>
          </View>
        )} */}
              {/* {summaryData &&
          Object.entries(summaryData?.fee_breakdown).length > 0 && (
            <View>
              <Text
                style={{
                  fontSize: "10px",
                  marginBottom: 5,
                  marginTop: "9px",
                }}
              >
                Booking fees breakdown ( Inclusive )
              </Text>
              <View style={{ width: "60%" }}>
                <InvoiceFeeBreakdownHeader />
                <InvoiceTaxBreakdownHeaderItems
                  items={summaryData?.fee_breakdown}
                  symbol={
                    hotelCurrency === hotelConvertedCurrency
                      ? symbol
                      : hotelConvertedCurrencySymbol
                  }
                />
              </View>
            </View>
          )} */}
              {/* {selfCheckin?.length > 0 && (
          <View>
            <Text
              style={{ fontSize: "10px", marginBottom: 5, marginTop: 10 }}
            >
              Magic Link Breakdown
            </Text>
            <View style={{ width: "60%" }}>
              <SelfCheckinBreakdownHeader />
              <SelfCheckinBreakdownHeaderItems
                items={selfCheckin}
                symbol={
                  hotelCurrency === hotelConvertedCurrency
                    ? symbol
                    : hotelConvertedCurrencySymbol
                }
              />
            </View>
          </View>
        )} */}
            </View>

            <View>
              <InvoiceDualText
                boldText={"Sub total"}
                normalText={symbol + handleDigits(Number(getCoreTotal()))}
              />

              <InvoiceDualText
                boldText={"Total Tax"}
                normalText={symbol + handleDigits(Number(getTotalTax()))}
              />

              <InvoiceDualText
                boldText={"Delivery Charge"}
                normalText={
                  symbol + handleDigits(Number(getTotalDeliveryCharge()))
                }
              />

              <InvoiceDualText
                boldText={"Grand Total"}
                normalText={symbol + handleDigits(Number(getTotal()))}
              />
            </View>
          </View>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              marginTop: "1rem",
            }}
          >{`This is a computer generated copy hence signature is not required.`}</Text>
        </View>
      </Page>
    </pdf>
  );
}

export default SendPoPdf;
