// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translations
import translationEN from "../../../locales/locales/en/translation.json";
import translationES from "../../../locales/locales/es/translation.json";
// Configure i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    es: {
      translation: translationES,
    },
  },
  lng: "en", // Default language
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
