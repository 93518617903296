import axios from "axios";
import { toast } from "material-react-toastify";

const DJANGO_URL = `${process.env.REACT_APP_API_URL}`;
const COMMON_URL = `${process.env.REACT_APP_SPRING_API_URL}`;
const BG_URL = `${process.env.REACT_APP_BG_API_URL}`;
const INVENTORY = "core/api/v1/inventory/";
const RESERVATION = "core/api/v1/reservation/";
const ROOMS = "core/api/v1/room/";
const ROOMS_RATE_TEMPLATE = "core/api/v1/rate-template/";
const PRICING = "core/api/v1/pricing/";
const room_types = "getAllNonHiddenRoomTypes";
const room_types_dep = "getAllNonHiddenRoomTypesNoDependency";
const spring_common = `common/`;
const hotel_calendar = "get-hotel-calander";
const hotel_calendar_channel = "get-hotel-calendar-channel";
const hotel_calendar_compare = "get-hotel-calendar-comparison";
const base_hotel_calendar = "get-base-hotel-calander";
const pms_inventory_count = "set-pms-inventory-count";
const pms_inventory_grid_count = "set-pms-inventory-count-grid";

const room_information = "get-roomtype-obj";
const room_information_rate_template = "get-roomtype-obj-with-active-rateplans";
const channel_names = "get-inventory-calender-names";
const get_available_rooms = "get-available-rooms";
const physical_room_id = "getPhysicalRoomIds";
const make_rooms_available = "make-multiple-rooms-available";
const make_rooms_unavailable = "make-multiple-rooms-unavailable";
const block_rooms = "block-multiple-rooms";
const unblock_rooms = "unblock-multiple-rooms";
const delete_rooms = "delete-rooms";
const removed_rooms = "get-deleted-rooms";
const restore_deleted = "make-deleted-room-available";
const sold_out = "set-sold-out";
const update_ota = "update-prices-from-ota";
const move_room = "move-room";
const set_pricing = "set-multiple-source-occupancy-pricing";
const rate_plan_price_change = "set-source-occupancy-pricing";
const registered_channels = "get-registered-channel-names";
const inventory_capping_names = "get-inventory-capping-names";
const rate_plans = "get-all-rateplans";
const edit_ids = "edit-room-ids";
const channels_conditional = "get-registered-channel-names-conditional";
const cta_account = "get-cta-by-hotel";
const cta_rateplan = "get-cta-by-rateplan";
const restriction = "apply-restriction";
const hotel_channels = "get-hotel-channels";
const sync = "bulk-sync";
const rate_multiplier = "set-channel-price-perc";
const inventory_capping = "set-channel-cap-per-sync";
const check_room_availability = "check-room-availability";
const ctaDjango = "getCtaNamesForHotel";

const axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
};

export const getCalendarData = async (params, history) => {
  return axios
    .get(
      COMMON_URL +
        spring_common +
        hotel_calendar +
        `?fromDate=${params.from}&toDate=${params.to}`,
      axiosConfig
    )
    .then((resp) => {
      sessionStorage.setItem("freezed", "NO");
      return resp.data;
    })
    .catch((err) => {
      if (err.response.status === 401 && history) {
        // Handle 401 Unauthorized error
        history.push("/logout");
        localStorage.clear();
      }
      if (err?.response?.data?.message === "Account Suspended!") {
        sessionStorage.setItem("freezed", "YES");
      } else {
        sessionStorage.setItem("freezed", "NO");
      }
      throw new Error(err);
    });
};

export const getCalendarDataWithChannel = async (params) => {
  return axios
    .get(
      COMMON_URL +
        spring_common +
        hotel_calendar_channel +
        `?fromDate=${params.from}&toDate=${params.to}&channel=${params.channel}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getBasePriceCalendarData = async (params) => {
  return axios
    .get(
      COMMON_URL +
        spring_common +
        base_hotel_calendar +
        `?fromDate=${params.from}&toDate=${params.to}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCalendarDataCompare = async (params) => {
  return axios
    .get(
      COMMON_URL +
        spring_common +
        hotel_calendar_compare +
        `?fromDate=${params.from}&toDate=${params.to}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setPmsInventory = async (params) => {
  return axios
    .put(BG_URL + INVENTORY + pms_inventory_count, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setPmsInventorygridcount = async (params) => {
  return axios
    .put(BG_URL + INVENTORY + pms_inventory_grid_count, params)
    .then((resp) => resp.data)
    .catch((err) => {
      toast.error(
        "Inventory change cannot be pushed, Kindly update correct inventory"
      );
    });
};

export const getAllNonHiddenRoomTypes = async (params) => {
  return axios
    .get(COMMON_URL + RESERVATION + room_types, params, axiosConfig)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllNonHiddenRoomTypesNoDependency = async (params) => {
  return axios
    .get(COMMON_URL + RESERVATION + room_types_dep, params, axiosConfig)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getRoomInformation = async (params) => {
  return axios
    .get(COMMON_URL + ROOMS + room_information, {
      params: {
        roomTypeId: params.roomTypeId,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getRoomInformationForWALkin = async (data) => {
  return axios
    .get(COMMON_URL + ROOMS + room_information + "?roomTypeId=" + data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getRoomInformationRateTemplates = async (params) => {
  return axios
    .get(COMMON_URL + ROOMS_RATE_TEMPLATE + room_information_rate_template, {
      params: {
        roomTypeId: params.roomTypeId,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getChannels = async () => {
  return axios
    .get(COMMON_URL + INVENTORY + channel_names)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAvailableRooms = async (params) => {
  return axios
    .get(COMMON_URL + ROOMS + get_available_rooms, {
      params: {
        roomTypeId: params.roomTypeId,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getPhysicalRoomIds = async () => {
  return axios
    .get(COMMON_URL + ROOMS + physical_room_id)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const makeRoomsAvailable = async (params) => {
  return axios
    .post(BG_URL + ROOMS + make_rooms_available, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const makeRoomsUnavailable = async (params) => {
  return axios
    .post(BG_URL + ROOMS + make_rooms_unavailable, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const blockMultipleRooms = async (params) => {
  return axios
    .post(BG_URL + ROOMS + block_rooms, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const blockMultipleRoomsWithHotelId = async (params, hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${ROOMS}block-multiple-rooms-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set content type for JSON
        },
        body: JSON.stringify(params), // Convert params to JSON string
      }
    );

    const data = await response;
    return data;
  } catch (err) {
    throw new Error(err.message || "An error occurred while blocking rooms.");
  }
};

export const unBlockMultipleRooms = async (params) => {
  return axios
    .post(BG_URL + ROOMS + unblock_rooms, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};
export const unBlockMultipleRoomWithHotelId = async (params, hotelId) => {
  try {
    const response = await fetch(
      BG_URL +
        ROOMS +
        `unblock-multiple-rooms-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set content type for JSON
        },
        body: JSON.stringify(params), // Convert params to JSON string
      }
    );

    const data = await response;
    return data;
  } catch (err) {
    throw new Error(err.message || "An error occurred while unblocking rooms.");
  }
};

export const deleteMultipleRooms = async (params) => {
  return axios
    .delete(BG_URL + ROOMS + delete_rooms, { data: params })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteMultipleRoomsWithHotelId = async (params, hotelId) => {
  try {
    const response = await fetch(
      `${BG_URL}${ROOMS}delete-rooms-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "DELETE", // Use DELETE method
        headers: {
          "Content-Type": "application/json", // Set content type for JSON
        },
        body: JSON.stringify(params), // Convert params to JSON string
      }
    );

    const data = await response;
    return data; // Return the response data
  } catch (err) {
    throw new Error(err.message || "An error occurred while deleting rooms.");
  }
};

export const getRemovedRooms = async () => {
  return axios
    .get(COMMON_URL + ROOMS + removed_rooms)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const restoreDeleted = async (params) => {
  return axios
    .post(BG_URL + ROOMS + restore_deleted, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const restoreDeletedWithHotelId = async (params, hotelId) => {
  try {
    const response = await fetch(
      `${BG_URL}${ROOMS}make-deleted-room-available-noauth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "POST", // Use POST method
        headers: {
          "Content-Type": "application/json", // Set content type for JSON
        },
        body: JSON.stringify(params), // Convert params to JSON string
      }
    );

    const data = await response;
    return data; // Return the response data
  } catch (err) {
    throw new Error(
      err.message || "An error occurred while restoring the room."
    );
  }
};

export const setSoldOut = async (params) => {
  return axios
    .post(BG_URL + ROOMS + sold_out, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setSoldOutWithHotelId = async (params, hotelId) => {
  try {
    // Construct the URL with hotelId directly in the string
    const url = `${BG_URL}${ROOMS}set-sold-out-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`;

    // Make the POST request
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify(params), // Convert params to a JSON string
    });

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      const errorText = await response.text(); // Get the response as text for error messages
      throw new Error(errorText || "An error occurred while setting sold out.");
    }

    const data = await response.json(); // Parse the JSON response
    return data; // Return the parsed data
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const updatePricesFromOta = async (reqParams, params) => {
  return axios
    .post(BG_URL + ROOMS + update_ota, params, {
      params: {
        isPrev: reqParams.isPrev,
        channelName: reqParams.channel,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const updatePricesFromOtaWithHotelId = async (
  reqParams,
  params,
  hotelId
) => {
  try {
    // Construct the URL with hotelId and reqParams
    const url = new URL(`${BG_URL}${ROOMS}update-prices-from-ota-noAuth`);
    url.searchParams.append("hotelId", hotelId);
    url.searchParams.append("hotel_id", hotelId);

    // Append reqParams to the URL as query parameters
    if (reqParams) {
      if (reqParams.isPrev !== undefined) {
        url.searchParams.append("isPrev", reqParams.isPrev);
      }
      if (reqParams.channel) {
        url.searchParams.append("channelName", reqParams.channel);
      }
    }

    // Make the POST request
    const response = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
      },
      body: JSON.stringify(params), // Convert params to a JSON string
    });

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      const errorText = await response.text(); // Get the response as text for error messages
      throw new Error(
        errorText || "An error occurred while updating prices from OTA."
      );
    }

    const data = await response.json(); // Parse the JSON response
    return data; // Return the parsed data
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const reAssignRoom = async (params) => {
  return axios
    .post(
      BG_URL + ROOMS + move_room,
      {},
      {
        params: {
          physicalRoomId: params.physicalRoomId,
          roomType: params.roomType,
          newRoomType: params.newRoomType,
          isId: params.isId,
        },
      }
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const reAssignRoomWithHotelId = async (params, hotelId) => {
  try {
    const response = await fetch(
      `${BG_URL}${ROOMS}move-room-noAuth?physicalRoomId=${params.physicalRoomId}&roomType=${params.roomType}&newRoomType=${params.newRoomType}&isId=${params.isId}&hotelId=${hotelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify({}), // Sending an empty object as the body
      }
    );

    const data = await response.text(); // Parse the JSON response
    return data; // Return the parsed data
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const setPricingInventory = async (params) => {
  return axios
    .post(BG_URL + PRICING + set_pricing, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const setPricingInventoryRatePlan = async (params) => {
  return axios
    .post(BG_URL + PRICING + rate_plan_price_change, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getRegisteredChannels = async () => {
  return axios
    .get(COMMON_URL + ROOMS + registered_channels)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getInventoryCappingChannels = async () => {
  return axios
    .get(COMMON_URL + INVENTORY + inventory_capping_names)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllRatePlans = async () => {
  return axios
    .get(COMMON_URL + spring_common + rate_plans)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const editRoomIds = async (params) => {
  return axios
    .put(BG_URL + ROOMS + edit_ids, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const editRoomIdsWithHotelId = async (params, hotelId) => {
  try {
    const url = `${BG_URL}${ROOMS}edit-room-ids-noauth?hotelId=${hotelId}&hotel_id=${hotelId}`;

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "An error occurred while editing room IDs.");
    }

    const data = await response.json(); // Parse the JSON response
    return data; // Return the parsed data
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const getConditionalChannels = async (params) => {
  return axios
    .get(COMMON_URL + ROOMS + channels_conditional, {
      params: {
        roomType: params.roomType,
        ratePlanId: params.ratePlanId,
        restrictionName: params.restrictionName,
        roomById: params.roomById,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getConditionalChannelsWithHotelId = async (params, hotelId) => {
  try {
    const url = `${COMMON_URL}${ROOMS}get-registered-channel-names-conditional-noAuth?roomType=${params.roomType}&roomById=${params.roomById}&ratePlanId=${params.ratePlanId}&restrictionName=${params.restrictionName}&hotelId=${hotelId}&hotel_id=${hotelId}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        errorText || "An error occurred while fetching channels."
      );
    }

    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const getCtaAccounts = async () => {
  return axios
    .get(COMMON_URL + ROOMS + cta_account)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCtaAccountForRatePlan = async (ratePlanId) => {
  return axios
    .get(DJANGO_URL + "cta/" + ctaDjango, {
      params: { rateplan_id: ratePlanId },
    })
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCtaAccountForRatePlanWithHotelId = async (
  ratePlanId,
  hotelId
) => {
  try {
    const url = `${DJANGO_URL}cta/${ctaDjango}?hotelId=${hotelId}&hotelId=${hotelId}&rateplan_id=${ratePlanId}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        errorText || "An error occurred while fetching CTA account."
      );
    }

    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const applyRestrictions = async (params) => {
  return axios
    .post(COMMON_URL + PRICING + restriction, params)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getHotelChannels = async () => {
  return axios
    .get(COMMON_URL + INVENTORY + hotel_channels)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getHotelChannelsAndId = async () => {
  return axios
    .get(COMMON_URL + INVENTORY + "get-hotel-channels-new")
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const bulkSync = async (data) => {
  return axios
    .post(COMMON_URL + INVENTORY + sync, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const rateMultipler = async (data) => {
  return axios
    .post(BG_URL + PRICING + rate_multiplier, data)
    .then((resp) => toast.success("Successfully updated the rates"))
    .catch((err) => {
      toast.error(err.response.data);
    });
};

export const inventoryCapping = async (data) => {
  return axios
    .post(BG_URL + INVENTORY + inventory_capping, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const checkRoomAvailability = async (data) => {
  return axios
    .post(COMMON_URL + INVENTORY + check_room_availability, data)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const checkRoomAvailabilityWithHotelId = async (data, hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${INVENTORY}check-room-availability-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`, // Constructing the URL
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set headers for JSON
        },
        body: JSON.stringify(data), // Converting data to JSON
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const result = await response; // Parsing response data
    return result;
  } catch (err) {
    throw new Error(err.message); // Error handling
  }
};

export const getCtaNamesNew = async () => {
  return axios
    .get(DJANGO_URL + "cta/" + ctaDjango)
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCtaNamesNewWithHotelId = async (hotelId) => {
  try {
    const response = await fetch(
      `${DJANGO_URL}cta/${ctaDjango}?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const getAllNonHiddenRoomTypesWithHotelId = async (hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${RESERVATION}getAllNonHiddenRoomTypesNoAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getRoomInformationWithHotelId = async (roomTypeId, hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${ROOMS}get-roomtype-obj-noAuth?roomTypeId=${roomTypeId}&hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getChannelsWithHotelId = async (hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${INVENTORY}get-inventory-calender-names-noauth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getInventoryCappingChannelsWithHotelId = async (hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${INVENTORY}get-inventory-capping-names-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const getAllRatePlansWithHotelId = async (hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${spring_common}get-all-rateplans-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const rateMultiplerWithHotelId = async (data, hotelId) => {
  try {
    const response = await fetch(
      `${BG_URL}${PRICING}${rate_multiplier}?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "An error occurred");
    }

    toast.success("Successfully updated the rates");
  } catch (err) {
    toast.error(err.message);
  }
};

export const inventoryCappingWithHotelId = async (data, hotelId) => {
  try {
    const response = await fetch(
      `${BG_URL}${INVENTORY}set-channel-cap-per-sync-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    const responseData = await response;
    return responseData;
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const getPhysicalRoomIdsWithHotelId = async (hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${ROOMS}getPhysicalRoomIdsNoAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "An error occurred");
    }

    const data = await response.json();
    return data;
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const getAvailableRoomsWithHotelId = async (roomTypeId, hotelId) => {
  return axios
    .get(
      COMMON_URL +
        ROOMS +
        `get-available-rooms-noauth?roomTypeId=${roomTypeId}&hotelId=${hotelId}&hotel_id=${hotelId}`,
      {}
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw new Error(err);
    });
};

export const getRemovedRoomsWithHotelId = async (hotelId) => {
  try {
    const response = await fetch(
      `${COMMON_URL}${ROOMS}get-deleted-rooms-noauth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "GET",
      }
    );

    const data = await response.text();
    return data;
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
};

export const makeRoomsAvailableWithHotelId = async (params, hotelId) => {
  try {
    const response = await fetch(
      `${BG_URL}${ROOMS}make-multiple-rooms-available-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set content type for JSON
        },
        body: JSON.stringify(params), // Convert params to JSON string
      }
    );

    const data = await response;
    return data;
  } catch (err) {
    throw new Error(
      err.message || "An error occurred while making rooms available."
    );
  }
};

export const makeRoomsUnAvailableWithHotelId = async (params, hotelId) => {
  try {
    const response = await fetch(
      `${BG_URL}${ROOMS}make-multiple-rooms-unavailable-noAuth?hotelId=${hotelId}&hotel_id=${hotelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set content type for JSON
        },
        body: JSON.stringify(params), // Convert params to JSON string
      }
    );

    const data = await response;
    return data;
  } catch (err) {
    throw new Error(
      err.message || "An error occurred while making rooms available."
    );
  }
};
