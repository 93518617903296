import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import GroupReportsWrapper from "./GroupReportsWrapper.js";
import {
  filterParams,
  cellRenderer,
  generateOriginalValue,
  handleNumDisplay,
} from "../Reports/utils/ReportUtils";
import moment from "moment";
import i18n from "../../modules/Auth/pages/i18n.js";
import { useTranslation } from "react-i18next";

function GroupPaymentsReport(props) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);
  // getting the hotel id from the params
  //   const url = new URL(window.location.href);
  //   const hotelId = url.searchParams.get("hotelId");
  //   const hotelCurrency = data.accessControl.hotel.hotel_currency;

  // default hotel currency
  const hotelCurrency = props.data.hotelCurrency;
  // column definitions

  // defining the column headers, etc.
  const columns = [
    {
      field: "hotelId",
      headerName: `${t("Hotel Id")}`,
      hide: false,
      dataType: "varchar",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "hotelName",
      headerName: `${t("Hotel Name")}`,
      hide: false,
      dataType: "varchar",
    },
    {
      field: "capturedTime",
      headerName: `${t("Date")}`,
      //filter: "agDateColumnFilter",
      filterParams: filterParams,
      dataType: "date",
    },
    {
      field: "bookingId",
      headerName: `${t("Booking Id")}`,
      hide: false,
      dataType: "varchar",
    },
    {
      field: "customerName",
      headerName: `${t("Customer name")}`,
      dataType: "varchar",
    },
    {
      field: "amount",
      headerName: `${t("Amount")} (${hotelCurrency})`,
      dataType: "float",
      hide: false,
      aggFunc: "sum",
      valueGetter: (params) => generateOriginalValue(params, "amount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "refundAmount",
      headerName: `${t("Refund amount")} (${hotelCurrency})`,
      dataType: "float",
      hide: false,
      aggFunc: "sum",
      valueGetter: (params) => generateOriginalValue(params, "refundAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "netAmount",
      headerName: `${t("Net amount")} (${hotelCurrency})`,
      dataType: "float",
      hide: false,
      aggFunc: "sum",
      valueGetter: (params) => generateOriginalValue(params, "netAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "gateway",
      headerName: `${t("Gateway")}`,
      dataType: "varchar",
    },
    {
      field: "paymentStatus",
      headerName: `${t("Payment status")}`,
      dataType: "varchar",
    },
    {
      field: "paymentType",
      headerName: `${t("Payment type")}`,
      dataType: "varchar",
    },
    {
      field: "paymentIssuer",
      headerName: `${t("Payment issuer")}`,
      dataType: "varchar",
    },
    {
      field: "cardLast4",
      headerName: `${t("Payment card last 4")}`,
      dataType: "varchar",
    },
    {
      field: "transferAmount",
      headerName: `${t("Transfer amount")} (${hotelCurrency})`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "transferAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "bookingSource",
      headerName: `${t("Source")}`,
      dataType: "varchar",
    },
    {
      field: "currencyCode",
      headerName: `${t("Currency code")}`,
      dataType: "varchar",
      hide: true,
    },
    {
      field: "customerContact",
      headerName: `${t("Customer Contact")}`,
      hide: true,
      dataType: "varchar",
    },
    {
      field: "customerEmail",
      headerName: `${t("Customer Email")}`,
      hide: true,
      dataType: "varchar",
    },
    {
      field: "orderId",
      headerName: `${t("POS Order ID")}`,
      hide: true,
      dataType: "varchar",
      printWidth: "7%",
    },
    {
      field: "pgChargeId",
      headerName: `${t("Gateway Charge ID")}`,
      hide: true,
      dataType: "varchar",
      printWidth: "7%",
    },
    {
      field: "userEmail",
      headerName: `${t("User Email")}`,
      hide: true,
      dataType: "varchar",
    },
  ];

  // Setting the pinned row
  const [pinnedRow, setPinnedRow] = useState(null);
  function createPinnedData(params, rowsData) {
    // let result = {
    //   bookingid_display: "Total",
    //   tax: 0,
    //   commission: 0,
    //   net_amount: 0,
    //   gross_amount: 0,
    //   tds: 0,
    // };

    let result = {};

    for (let i = 0; i < columns.length; i++) {
      if (["integer", "float"].includes(columns[i]["dataType"])) {
        result[columns[i]["field"]] = 0;
      }
    }

    function updateVals(i) {
      Object.keys(result).forEach((fields) => {
        result[fields] += i[`original_${fields}`];
      });
    }

    params.api.getSelectedRows().length === 0
      ? params.api.forEachNodeAfterFilter((row) => updateVals(row.data))
      : params.api.getSelectedRows().map((row) => updateVals(row));

    result["capturedTime"] = "Total";

    setPinnedRow([result]);
    params.api.setPinnedBottomRowData([result]);
  }

  return (
    <GroupReportsWrapper
      reportName={t("Group Gateway Payments Report")}
      columns={columns}
      reportActualType={"payment"}
      createPinnedData={createPinnedData}
      pinnedRow={pinnedRow}
      grpEmail={props.grpEmail}
      resetToDefault={props.resetToDefault}
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.hotels[0] };
}
export default connect(mapStateToProps)(GroupPaymentsReport);
