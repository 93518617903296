import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { Card, Modal } from "react-bootstrap";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { dateFormat } from "../constants/Calendar-constants";
import { unBlockMultipleRooms } from "../../InventoryCalendar/api/InventoryApi";
import { connect } from "react-redux";
import {
  checkingBooking,
  checkingReservation,
  checkoutReservation,
  checkoutReservationById,
  voidCancellationCharges,
} from "../api/ReservationApi";
import { toast } from "material-react-toastify";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import {
  getAllGuestUsers,
  getAllGuestUsersWithHotelId,
} from "../../Promocodes/apis/PromocodeApi";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
  displayHeading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  secondaryHeading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1),
    wordBreak: "break-word",
  },
}));

const UnblockView = (props) => {
  const {
    open,
    eventData,
    closeFunc,
    reload,
    type,
    modalClose,
    roomInformation,
    roomTypeInfo,
    bookingInfo,
    selectedLanguage,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [dirtyStatus, setdirtyStatus] = useState("CLEAN");
  const [blockReason, setblockReason] = useState([]);
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/hk/get-housekeeping-room-dirty-status?roomId=${eventData?.event?._def?.resourceIds[0]}`
    )
      .then((res) => {
        setdirtyStatus(res);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/reservation/blocked-room-reason?roomId=${
        eventData?.event?._def?.resourceIds[0]
      }&fromDate=${moment(eventData.event.start).format(
        "DD-MM-YYYY"
      )}&toDate=${moment(eventData.event.end)
        .add(1, "days")
        .format("DD-MM-YYYY")}`
    )
      .then((res) => {
        setblockReason(res);
      })
      .catch((err) => console.error(err));
  }, [eventData]);

  const [voidOption, setVoidOption] = useState("NO_VOID");
  const [checked, setChecked] = useState(
    roomInformation?.checkin_all?.toLowerCase() === "yes" ? true : false
  );

  const [checkoutChecked, setCheckoutChecked] = useState(true);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: eventData.event.start,
      endDate: eventData.event.end,
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [reason, setReason] = useState("");

  const [selectedGuestEmail, setSelectedGuestEmail] = useState([]);
  const [allGuestList, setAllGuestList] = useState([]);
  const [selectedData, setselectedData] = useState([]);

  useEffect(() => {
    const data = allGuestList?.filter((item) => item.primaryGuest === "YES");
    setselectedData(data);
  }, [allGuestList]);

  // console.log("allGuestList", allGuestList);

  //Early checkout data
  const [showEarlyCheckout, setShowEarlyCheckout] = useState(false);
  const openEarlyCheckoutModal = () => setShowEarlyCheckout(true);
  const closeEarlyCheckoutModal = () => setShowEarlyCheckout(false);
  const [newCheckoutDate, setNewCheckoutDate] = useState(new Date());
  const [earlyCheckoutLoader, setEarlyCheckoutLoader] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.length > 0)
      setSelectedGuestEmail(
        // On autofill we get a the stringified value.
        typeof value === "string" ? value.split(",") : value
      );
  };

  const getAllGuestUsers = async () => {
    const data = await getAllGuestUsersWithHotelId(
      eventData.event.extendedProps.bookingId,
      props?.data?.hotelId
    );
    const res = data.filter(
      (item) =>
        item.reservationId === eventData.event.extendedProps.reservationId
    );
    setAllGuestList(res);
  };

  useEffect(() => {
    getAllGuestUsers();
  }, []);

  useEffect(() => {
    if (allGuestList?.length > 0) {
      setSelectedGuestEmail([allGuestList[0]?.email]);
    }
  }, [allGuestList]);

  // Dirty room  modal
  const [openDirtyStatusModal, setOpenDirtyStatusModal] = useState(false);
  const handleOpenDirtyStatusModal = () => {
    setOpenDirtyStatusModal(true);
  };

  const handleCloseDirtyStatusModal = () => {
    setOpenDirtyStatusModal(false);
  };

  const unblock = async () => {
    try {
      setLoading(true);
      const payload = {
        startDate: moment(
          new Date(currentDateSelection[0].startDate).setHours(0, 0, 0, 0)
        ).format(dateFormat),
        endDate: moment(
          new Date(currentDateSelection[0].endDate).setHours(0, 0, 0, 0)
        ).format(dateFormat),
        physicalRoomIds: [eventData.event.getResources()[0].id],
        reason: reason,
      };
      await unBlockMultipleRooms([payload]).then(() =>
        toast.success(`${t("Successfully unblocked")}`)
      );
      reload();
      closeFunc();
    } catch (e) {
      console.log(e);
      closeFunc();
    }
  };

  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  const checkin = async () => {
    setLoading(true);
    dirtyStatus === "DIRTY" &&
      axios
        .post(
          `${process.env.REACT_APP_BG_API_URL}core/api/v1/hk/bulk-mark-rooms-clean`,
          {
            roomIds: eventData?.event?._def?.resourceIds,
            staffId: "",
          }
        )
        .then((res) => {
          toast.success(`${t(`Successfully marked Clean`)}`);
          handleCloseDirtyStatusModal();
        })
        .catch((err) => handleCloseDirtyStatusModal());
    try {
      if (checked) {
        const data = await checkingBooking(
          eventData.event.extendedProps.bookingId
        );
        // .then(()=>{
        //   toast.success("Successfully checked in")
        // })
        if (data.status === 500) {
          toast.error(
            `${t(
              `Please checkout the previous customer to proceed with check-in`
            )}`
          );
        } else {
          toast.success(
            `${t("Successfully checked in")} ${
              eventData?.event?.extendedProps?.bookingId
            }`
          );
        }
        eventData.event.setExtendedProp("status", "ADMIN_CONFIRMED");
      } else {
        const data = await checkingReservation({
          resIds: [eventData.event.extendedProps.reservationId],
          bookingId: eventData.event.extendedProps.bookingId,
          emailIds:
            JSON.parse(
              props?.data?.accessControl?.user_feature_map?.GuestCheckInCheckOut
            ).read === "YES"
              ? selectedGuestEmail
              : [],
        });
        // .then(()=>{
        //   toast.success("Successfully checked in")
        // })
        if (data.code === "500") {
          toast.error(
            `${t(
              `Please checkout the previous customer to proceed with check-in`
            )}`
          );
        } else {
          toast.success(
            `${t("Successfully checked in")} ${
              eventData?.event?.extendedProps?.bookingId
            }`
          );
        }
        eventData.event.setExtendedProp("status", "ADMIN_CONFIRMED");
      }
      reload();
      setLoading(false);
      closeFunc();
    } catch (e) {
      console.log(e);
      closeFunc();
    }
  };

  const earlyCheckout = async () => {
    setEarlyCheckoutLoader(true);
    setLoading(true);
    try {
      if (checkoutChecked) {
        //If checkout checked perform extend reservation for all rooms
        let resIdsList = [];
        if (bookingInfo?.invoice?.reservation_room_info_list) {
          bookingInfo.invoice.reservation_room_info_list.map((resInfo) => {
            resIdsList.push(resInfo?.reservation_id);
          });
        }
        //Call Perform extend reservation and perform only for the reservation Id - change in logic only display
        try {
          // const res = await handleSpringDataMutationRequest(
          //   "POST",
          //   "core/api/v1/reservation/performExtendReservation",
          //   {
          //     checkinDate: moment(newCheckoutDate)
          //       .minutes(0)
          //       .seconds(0)
          //       .format("DD-MM-YYYY HH:mm:ss"),
          //     gAdditionalAmt: 0,
          //     hotelId: props?.data?.hotelId,
          //     resIds: resIdsList,
          //     systemUpdate: true,
          //   }
          // )
          //   .then((res) => {
          //     setEarlyCheckoutLoader(false);
          //     closeEarlyCheckoutModal();
          //   })
          //   .catch((err) => {
          //     setLoading(false);
          //     console.log("err: ", err);
          //     setEarlyCheckoutLoader(false);
          //     closeEarlyCheckoutModal();
          //     closeFunc();
          //   });
          const data = await checkoutReservation(
            eventData.event.extendedProps.bookingId
          ).catch((err) => {
            // if (err?.message) {
            //   toast.error(err?.message);
            // }
            setLoading(false);
            setEarlyCheckoutLoader(false);
            closeEarlyCheckoutModal();
            closeFunc();
          });
          // await voidCancellationCharges({
          //   resIds: [eventData.event.extendedProps.reservationId],
          //   voidType: voidOption,
          // });

          if (data.code === "200") {
            toast.success(
              `${t("Successfully checked out")} ${
                eventData?.event?.extendedProps?.bookingId
              }`
            );
          } else {
            if (data?.message) {
              toast.error(data.message);
            }
          }
          eventData.event.setExtendedProp("status", "CHECKED_OUT");
          reload();
        } catch (e) {
          toast.error(`${t("Checkout date cannot be changed")}`);
        }

        //Call checkout booking
        setLoading(false);
        setEarlyCheckoutLoader(false);
        closeEarlyCheckoutModal();
        closeFunc();
      } else {
        //Change in logic only display no edit reservations
        //Call extend reservation and perform only for the reservationId
        // const res = await handleSpringDataMutationRequest(
        //   "POST",
        //   "core/api/v1/reservation/performExtendReservation",
        //   {
        //     checkinDate: moment(newCheckoutDate)
        //       .minutes(0)
        //       .seconds(0)
        //       .format("DD-MM-YYYY HH:mm:ss"),
        //     gAdditionalAmt: 0,
        //     hotelId: props?.data?.hotelId,
        //     resIds: [eventData.event.extendedProps.reservationId],
        //     systemUpdate: true,
        //   }
        // ).catch(
        //   (res) => res?.response?.data && toast.error(res?.response?.data)
        // );
        // if (res == "Operation Successful") {
        //Checkout booking based on reservationId
        const data = await checkoutReservationById(
          eventData.event.extendedProps.reservationId,
          JSON.parse(
            props?.data?.accessControl?.user_feature_map?.GuestCheckInCheckOut
          ).read === "YES"
            ? selectedGuestEmail.toString()
            : ""
        );

        if (data.code === "200") {
          toast.success(
            `${t("Successfully checked out")} ${
              eventData?.event?.extendedProps?.bookingId
            }`
          );
        } else {
          toast.error(data.message);
        }
        eventData.event.setExtendedProp("status", "CHECKED_OUT");
        reload();

        // } else {
        //   res?.message !== undefined && toast.error(res?.message);
        // }
        setLoading(false);
        setEarlyCheckoutLoader(false);
        closeEarlyCheckoutModal();
        closeFunc();
      }
    } catch (e) {
      console.log("error: ", e);
      setLoading(false);
      setEarlyCheckoutLoader(false);
      closeEarlyCheckoutModal();
      closeFunc();
    }
  };

  const checkout = async () => {
    setLoading(true);
    try {
      let checkoutDate = moment(
        roomInformation.booking_checkout.replace("T", " "),
        "YYYY-MM-DD HH:mm:ss"
      ).startOf("day");
      let currentDate = moment().startOf("day");
      if (
        props?.data?.accessControl?.user_feature_map?.EarlyCheckoutAlert &&
        JSON.parse(
          props?.data?.accessControl?.user_feature_map?.EarlyCheckoutAlert
        ).read === "YES" &&
        checkoutDate > currentDate
      ) {
        openEarlyCheckoutModal();
        setLoading(false);
        return;
      }
      if (checkoutChecked) {
        const data = await checkoutReservation(
          eventData.event.extendedProps.bookingId
        );
        // await voidCancellationCharges({
        //   resIds: [eventData.event.extendedProps.reservationId],
        //   voidType: voidOption,
        // });

        if (data.code === "200") {
          toast.success(
            `Successfully checked out ${eventData?.event?.extendedProps?.bookingId}`
          );
        } else {
          toast.error(data.message);
        }
        eventData.event.setExtendedProp("status", "CHECKED_OUT");
        reload();
        setLoading(false);
        closeFunc();
      } else {
        const data = await checkoutReservationById(
          eventData.event.extendedProps.reservationId,
          JSON.parse(
            props?.data?.accessControl?.user_feature_map?.GuestCheckInCheckOut
          ).read === "YES"
            ? selectedGuestEmail.toString()
            : ""
        );
        // await voidCancellationCharges({
        //   resIds: [eventData.event.extendedProps.reservationId],
        //   voidType: voidOption,
        // });

        if (data.code === "200") {
          toast.success(
            `${t("Successfully checked out")} ${
              eventData?.event?.extendedProps?.bookingId
            }`
          );
        } else {
          toast.error(data.message);
        }
        eventData.event.setExtendedProp("status", "CHECKED_OUT");
        reload();
        setLoading(false);
        closeFunc();
      }
    } catch (e) {
      console.log(e);
      closeFunc();
    }
  };

  const action = () => {
    const balanceDue = Number(roomInformation?.balance_due);
    const checkInWithoutPayment =
      JSON.parse(
        props?.data?.accessControl?.user_feature_map?.AllowCheckinWithoutPayment
      ).read === "YES";

    const checkoutWithoutPayment =
      JSON.parse(
        props?.data?.accessControl?.user_feature_map
          ?.AllowCheckoutWithoutPayment
      ).read === "YES";

    const paymentMade = Number(roomInformation.payment_made);

    if (type === "CONFIRMED") {
      if (balanceDue > 0) {
        if (
          JSON.parse(
            props?.data?.accessControl?.user_feature_map
              ?.AllowCheckinUnrestrictedPayment
          ).read === "YES"
        ) {
          if (
            props?.data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
            props?.data?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
            JSON.parse(
              props?.data?.accessControl?.user_feature_map?.UploadIdToCheckin
            ).read !== "YES"
          ) {
            checkin();
          } else {
            handleSpringDataRequest(
              `core/api/v1/image/get-user-id-images?emailId=${
                selectedData?.length > 0
                  ? selectedData[0].email
                  : selectedGuestEmail
              }`
            )
              .then((res) => {
                if (
                  res.frontUrl === null ||
                  res.frontUrl === undefined ||
                  res.frontUrl === "" ||
                  res.backUrl === "" ||
                  res.backUrl === null ||
                  res.backUrl === undefined
                ) {
                  toast.warning(
                    `${t("Please add user id images for checkin")}`
                  );
                } else {
                  checkin();
                }
              })
              .catch((err) => toast.error(`${t("Something went wrong")}`));
          }
        } else if (
          JSON.parse(
            props?.data?.accessControl?.user_feature_map
              ?.AllowCheckinPartialPayment
          ).read === "YES" &&
          paymentMade === 0
        ) {
          toast.error(`${t("Partial payment necessary to check-in")}`);
        } else if (
          JSON.parse(
            props?.data?.accessControl?.user_feature_map
              ?.AllowCheckinFullPayment
          ).read === "YES"
        ) {
          toast.error(`${t("Full Payment Necessary")}`);
        } else {
          if (selectedGuestEmail?.length === 1) {
            if (
              props?.data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
              props?.data?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
              JSON.parse(
                props?.data?.accessControl?.user_feature_map?.UploadIdToCheckin
              ).read !== "YES"
            ) {
              checkin();
            } else {
              handleSpringDataRequest(
                `core/api/v1/image/get-user-id-images?emailId=${selectedGuestEmail[0]}`
              )
                .then((res) => {
                  if (
                    res.frontUrl === null ||
                    res.frontUrl === undefined ||
                    res.frontUrl === "" ||
                    res.backUrl === "" ||
                    res.backUrl === null ||
                    res.backUrl === undefined
                  ) {
                    toast.warning(
                      `${t("Please add user id images for checkin")}`
                    );
                  } else {
                    checkin();
                  }
                })
                .catch((err) => toast.error(`${t("Something went wrong")}`));
            }
          } else {
            checkin();
          }
        }
      } else {
        if (selectedGuestEmail?.length === 1) {
          if (
            props?.data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
            props?.data?.accessControl?.ADMINRIGHTS === "SUBADMIN" ||
            JSON.parse(
              props?.data?.accessControl?.user_feature_map?.UploadIdToCheckin
            ).read !== "YES"
          ) {
            checkin();
          } else {
            handleSpringDataRequest(
              `core/api/v1/image/get-user-id-images?emailId=${selectedGuestEmail[0]}`
            )
              .then((res) => {
                if (
                  res.frontUrl === null ||
                  res.frontUrl === undefined ||
                  res.frontUrl === "" ||
                  res.backUrl === "" ||
                  res.backUrl === null ||
                  res.backUrl === undefined
                ) {
                  toast.warning(
                    `${t("Please add user id images for checkin")}`
                  );
                } else {
                  checkin();
                }
              })
              .catch((err) => toast.error(`${t("Something went wrong")}`));
          }
        } else {
          checkin();
        }
      }
    } else {
      if (checkoutWithoutPayment === "NO") {
        if (balanceDue !== 0) {
          toast.error(`${t("Checkout without payment is not allowed")}`);
        } else {
          checkout();
        }
      } else {
        checkout();
      }
    }
  };

  return (
    <div className={classes.root}>
      <CommonSlider onBackdropClick={closeFunc} onClose={closeFunc} open={true}>
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">
              {type === "unblock" && `${t("Unblock room")}`}
              {type === "CONFIRMED" && `${t("Check-in room")}`}
              {type === "ADMIN_CONFIRMED" && `${t("Check-out room")}`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                if (type === "unblock") closeFunc();
                else modalClose();
              }}
              sx={{ float: "right" }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        {type === "unblock" && (
          <Grid container spacing={2} className={classes.textField}>
            <Grid item xs={12}>
              <div>
                <ClickAwayListener
                  onClickAway={() => {
                    setShowDatePicker(false);
                    setAnchorEl(null);
                  }}
                >
                  <div>
                    <TextField
                      fullWidth
                      focused={showDatePicker}
                      id="currentDateSelection"
                      name="currentDateSelection"
                      InputProps={{ readOnly: true }}
                      value={getDateText()}
                      label={t("Date range")}
                      onClick={(eve) => {
                        setAnchorEl(eve.currentTarget);
                        setShowDatePicker(true);
                      }}
                    ></TextField>
                    {showDatePicker && (
                      <Popper
                        style={{ zIndex: 2 }}
                        open={showDatePicker}
                        anchorEl={anchorEl}
                        placement="bottom"
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            style={{
                              transformOrigin: (placement = "bottom-end"),
                            }}
                            {...TransitionProps}
                          >
                            <Paper
                              elevation={5}
                              className="inventory-daterange-custom"
                            >
                              <DateRange
                                color="#1BC5BD"
                                rangeColors={["#1BC5BD"]}
                                editableDateInputs={false}
                                moveRangeOnFirstSelection={false}
                                onChange={(data) => {
                                  setCurrentDateSelection([{ ...data.range1 }]);
                                }}
                                ranges={currentDateSelection}
                              ></DateRange>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    )}
                  </div>
                </ClickAwayListener>
              </div>
            </Grid>
            <Grid item xs={12}>
              {type !== "unblock" && (
                <TextField
                  value={reason}
                  label={t("Reason")}
                  fullWidth
                  multiline
                  rows={3}
                  onChange={(eve) => {
                    setReason(eve.target.value);
                  }}
                ></TextField>
              )}
            </Grid>
          </Grid>
        )}
        {type === "CONFIRMED" && (
          <Grid container spacing={2} className={classes.textField}>
            <Grid item xs={12}>
              <Box className={classes.custom_box} bgcolor="primary.main">
                <Typography>
                  {`${t("You are about to check-in Room")} ${
                    eventData.event.getResources()[0].title
                  }`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={checked}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                    defaultChecked={checked}
                  />
                }
                label={`${t("Check-in all the rooms in booking")} ${
                  eventData.event.extendedProps.bookingId
                } `}
              ></FormControlLabel>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {JSON.parse(
                props?.data?.accessControl?.user_feature_map
                  ?.GuestCheckInCheckOut
              ).read === "YES" &&
                !checked && (
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ width: "100%" }}
                    >
                      {t("Guest List")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t("Guest List")}
                      onChange={handleChange}
                      className="mr-4"
                      value={selectedGuestEmail}
                      // value={[]}
                      multiple
                    >
                      {allGuestList?.length > 0 &&
                        allGuestList?.map((guest, index) => {
                          return (
                            <MenuItem key={guest?.email} value={guest?.email}>
                              {guest?.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}

              {/* <TextField
                select
                className="position-relative"
                name="customer_list"
                id="customer_list"
                fullWidth
                placeholder="All Guest List"
                value={selectedGuestEmail}
                onChange={(e) => setSelectedGuestEmail(e.target.value)}
                label="All Guest List"
                variant="outlined"
              >
                {allGuestList?.length > 0 &&
                  allGuestList?.map((guest, index) => {
                    return (
                      <MenuItem key={guest?.email} value={guest?.email}>
                        {guest?.name}
                      </MenuItem>
                    );
                  })}
              </TextField> */}
            </Grid>
          </Grid>
        )}
        {type === "ADMIN_CONFIRMED" && (
          <Grid container spacing={2} className={classes.textField}>
            {/* <Grid item xs={12}>
              <Box className={classes.custom_box} bgcolor="primary.main">
                <Typography>
                  {`Please make sure that the invoice is accurate and you have saved all changes.`}
                </Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12}>
              <Typography>
                {" "}
                {t("Do you want to continue to check-out?")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={checkoutChecked}
                    onChange={() => {
                      setCheckoutChecked(!checkoutChecked);
                    }}
                    defaultChecked={checkoutChecked}
                  />
                }
                label={`${t("Check-out all the rooms in booking")} ${
                  eventData.event.extendedProps.bookingId
                } `}
              ></FormControlLabel>
            </Grid>
            {JSON.parse(
              props?.data?.accessControl?.user_feature_map?.GuestCheckInCheckOut
            ).read === "YES" &&
              !checkoutChecked && (
                <FormControl
                  fullWidth
                  style={{ marginLeft: "1rem", marginTop: "1rem" }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ width: "100%" }}
                  >
                    {t("Guest List")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("Guest List")}
                    onChange={handleChange}
                    className="mr-4"
                    value={selectedGuestEmail}
                    // value={[]}
                    multiple
                  >
                    {allGuestList?.length > 0 &&
                      allGuestList?.map((guest, index) => {
                        return (
                          <MenuItem key={guest?.email} value={guest?.email}>
                            {guest?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}
            {/* <Grid item xs={12} sx={{ pb: 1 }}>
              <Typography>
                Customer will be notified about the checkout and an invoice will
                be sent to their email.
              </Typography>
            </Grid> */}
            {/* <Grid item xs={12}>
              <TextField
                select
                variant="outlined"
                label="Void charge options"
                value={voidOption}
                fullWidth
                name="options"
                onChange={(e) => {
                  setVoidOption(e.target.value);
                }}
              >
                <MenuItem key={"NO_VOID"} value={"NO_VOID"}>
                  Don't Void
                </MenuItem>
                <MenuItem key={"CHARGE_ONE_NIGHT"} value={"CHARGE_ONE_NIGHT"}>
                  Charge one night and void remaining
                </MenuItem>
                <MenuItem key={"VOID"} value={"VOID"}>
                  Void all remaining charges
                </MenuItem>
              </TextField>
            </Grid>*/}
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            className={classes.textField}
          >
            {type === "unblock" && (
              <Fab
                disabled={loading}
                type="button"
                color="primary"
                onClick={unblock}
                variant="extended"
              >
                {t("Unblock room")}
                {loading && (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                )}
              </Fab>
            )}
            {type !== "unblock" && (
              <Fab
                disabled={loading}
                type="button"
                color="primary"
                onClick={
                  type === "CONFIRMED"
                    ? dirtyStatus === "DIRTY"
                      ? handleOpenDirtyStatusModal
                      : action
                    : action
                }
                variant="extended"
              >
                {type === "CONFIRMED" && `${t("Check-in")}`}
                {type === "ADMIN_CONFIRMED" && `${t("Check-out")}`}
                {loading && (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                )}
              </Fab>
            )}
          </Grid>
          {type === "unblock" && (
            <div
              style={{ width: "100%", marginLeft: "1rem", marginRight: "1rem" }}
            >
              <h4 className="mb-4">{t("Blocked room reasons")}</h4>
              {blockReason.map((item) => (
                <div className="w-100 d-flex">
                  <p
                    style={{
                      fontSize: "15px",
                      width: "70%",
                      marginRight: "1rem",
                    }}
                  >
                    {item.message}
                  </p>
                  <p style={{ fontSize: "15px", width: "30%" }}>
                    {" "}
                    {`${item.fromDate} - ${item.toDate}`}
                  </p>
                </div>
              ))}
            </div>
          )}
        </Grid>
        <Modal
          show={openDirtyStatusModal}
          onHide={handleCloseDirtyStatusModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>{`${t("Room number")} - ${
            eventData?.event?._def?.resourceIds[0]
          } ${t("is Dirty")} `}</Modal.Header>
          <Modal.Body>
            <h6>{t("Click yes to Check-in")}</h6>
          </Modal.Body>
          <Modal.Footer>
            {loading === false ? (
              <Button
                className="submit w-auto"
                variant="custom-button"
                onClick={() => action()}
              >
                {t("Yes")}
              </Button>
            ) : (
              <Button className="submit">
                <CircularProgress size="15px" />
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        {/* Early checkout modal*/}
        <Modal
          show={showEarlyCheckout}
          onHide={closeEarlyCheckoutModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title> {t("Early checkout alert")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container>
              <Grid xs={12}>
                <p className="mb-2">
                  <b> {t("You are requesting an early check-out.")}</b>
                  {t(
                    " The system will automatically update the reservation check-out date to release the inventory."
                  )}
                </p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5} textAlign={"start"}>
                <Typography className={classes.displayHeading}>
                  {t("Guest name")}
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign={"start"}>
                :
              </Grid>
              <Grid item xs={6} textAlign={"start"}>
                <Typography className={classes.secondaryHeading}>
                  {roomInformation?.user_name}
                </Typography>
              </Grid>
              <Grid item xs={5} textAlign={"start"}>
                <Typography className={classes.displayHeading}>
                  {t("Check-in Date")}
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign={"start"}>
                :
              </Grid>
              <Grid item xs={6} textAlign={"start"}>
                <Typography className={classes.secondaryHeading}>
                  {roomInformation !== undefined &&
                    roomInformation.booking_checkin !== undefined &&
                    // roomInformation.booking_checkin
                    moment(
                      roomInformation.booking_checkin.replace("T", " "),
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("MMM Do YYYY, h A")}
                </Typography>
              </Grid>
              <Grid item xs={5} textAlign={"start"}>
                <Typography className={classes.displayHeading}>
                  {t("Original Check-out Date")}
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign={"start"}>
                :
              </Grid>
              <Grid item xs={6} textAlign={"start"}>
                <Typography className={classes.secondaryHeading}>
                  {roomInformation !== undefined &&
                    roomInformation.booking_checkout !== undefined &&
                    moment(
                      roomInformation.booking_checkout.replace("T", " "),
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("MMM Do YYYY, h A")}
                </Typography>
              </Grid>
              {/* <Grid item xs={5} textAlign={"start"}>
                <Typography className={classes.displayHeading}>
                  {t("New Check-out Date")}
                </Typography>
              </Grid>
              <Grid item xs={1} textAlign={"start"}>
                :
              </Grid>
              <Grid item xs={6} textAlign={"start"}>
                <Typography className={classes.secondaryHeading}>
                  {moment(newCheckoutDate).format("MMM Do YYYY, h A")}
                </Typography>
              </Grid> */}
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => closeEarlyCheckoutModal()}
            >
              {t("Close")}
            </Button>
            <Button
              variant="custom-button"
              onClick={() => earlyCheckout()}
              disabled={earlyCheckoutLoader}
            >
              {earlyCheckoutLoader ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${t("Checkout booking")}`
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </CommonSlider>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(UnblockView);
