import React, { useState, useEffect } from "react";
import { Stack, TextField, Typography, Divider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import * as HiIcons from "react-icons/hi";
import { useTranslation } from "react-i18next";

function TaxStats({ customClass }) {
  const { t } = useTranslation();

  const [value, setValue] = useState(new Date());
  return (
    <Stack className={customClass}>
      <Stack className="position-relative">
        <Stack className="d-flex justify-content-between pt-4" direction="row">
          <Typography variant="h6">{t("Taxes")}</Typography>
          <Stack direction="row" spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <MobileDatePicker
                  label={t("Pick a date")}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      style={{ width: "115px" }}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
            <Typography className="pt-2" style={{ cursor: "pointer" }}>
              <HiIcons.HiOutlineRefresh size="18px" />
            </Typography>
          </Stack>
        </Stack>
        <Stack className="mt-10">
          <Stack direction="row" className="d-flex justify-content-between">
            <Typography>{t("Tax Name")}</Typography>
            <Typography>{t("Tax Percentage")}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TaxStats;
