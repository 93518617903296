// ** React Imports
import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { shallowEqual, useSelector } from "react-redux";
import CommonSlider from "./CommonSlider";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const CustomerInformation = (props) => {
  const {
    reservationData,
    closeModal,
    closeBackDrop,
    title,
    buttonText,
    buttonFunction,
    load,
    value,
    reservation_id,
    eventData,
    selectedLanguage,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const hotelId = hotel.hotelId;

  const classes = useStyles();

  const returnParams = () => {
    if (value === "hold-booking") {
      return { hotelId: hotelId, bookingId: reservationData.booking_id };
    }
    if (value === "unassign-room") {
      return { hotelId: hotelId, reservationId: reservation_id };
    }
    if (eventData.event.extendedProps.locked_status === "UNLOCKED") {
      return { reservationId: reservation_id, status: "LOCKED" };
    }
    if (eventData.event.extendedProps.locked_status === "LOCKED") {
      return { reservationId: reservation_id, status: "UNLOCKED" };
    }
  };

  return (
    <div className={classes.root}>
      <CommonSlider onBackdropClick={closeBackDrop} open={true}>
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <Typography variant="h5">{title} </Typography>
            {value === "hold-booking" && (
              <Typography variant="body1">
                Hold booking will release the room inventory
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <IconButton sx={{ float: "right" }} onClick={closeModal}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.textField}>
          <Grid item xs={12}>
            <Box className={classes.custom_box} bgcolor="primary.main">
              {value === "hold-booking" && (
                <Typography>
                  {`${t("You are about to hold booking")} ${
                    reservationData.booking_id
                  }`}
                </Typography>
              )}
              {value === "unassign-room" && (
                <Typography>
                  {`${t(
                    "You are about to un-assign current reservation in Room"
                  )} ${reservationData.reservation_rooms}`}
                </Typography>
              )}
              {value === "lock-booking" && (
                <Typography>
                  {`${t("You are about to")} ${
                    eventData.event.extendedProps.locked_status !== "LOCKED"
                      ? `${t("lock")}`
                      : `${t("unlock")}`
                  } ${t("Room")} ${reservationData.reservation_rooms}`}
                </Typography>
              )}
            </Box>
          </Grid>
          {value === "hold-booking" && (
            <Grid item xs={12}>
              <Typography>{t("Do you want to continue?")}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            className={classes.textField}
          >
            <Fab
              type="button"
              color="primary"
              onClick={() => {
                buttonFunction(returnParams());
              }}
              variant="extended"
              disabled={load}
            >
              {buttonText}
              {load && (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              )}
            </Fab>
          </Grid>
        </Grid>
      </CommonSlider>
    </div>
  );
};

export default CustomerInformation;
