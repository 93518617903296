import React, { useState, useEffect } from "react";
import * as FcIcons from "react-icons/fc";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function UnauthorisedAccess() {
  const { t } = useTranslation();

  const [display, setDisplay] = useState("none");

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplay("block");
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Stack
      className="position-relative w-100 d-flex justify-content-center"
      style={{
        textAlign: "center",
        alignItems: "center",
        display: display,
      }}
    >
      <FcIcons.FcLock size="50px" />
      <Typography style={{ fontSize: "40px", fontWeight: 700, color: "grey" }}>
        {t("Unauthorised access")}{" "}
      </Typography>
    </Stack>
  );
}

export default UnauthorisedAccess;
