import React, { useEffect, useState } from "react";
import CommonSlider from "../../CalendarPage/Views/CommonSlider";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { handleSpringDataRequest } from "../../../api";
import HouseIcon from "@mui/icons-material/House";
import moment from "moment";
import MockLoaderTable from "../../Folios/ViewManagePayments/MockLoaderTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

function ViewHotels({
  modalClose,
  open,
  zipcode,
  date,
  star,
  currency,
  conversionfactor,
  hotelCity,
}) {
  const [loader, setloader] = useState(false);
  const [hotelList, sethotelList] = useState([]);
  const getAllData = () => {
    setloader(true);
    handleSpringDataRequest(
      `core/api/v1/cityDemandHotelList/get-all?zipcode=${zipcode}&date=${date}&star=${star}`
    )
      .then((res) => {
        if (res?.length > 0) {
          const parsedHotelList = JSON.parse(res[0].hotel_list);

          const sortedHotelList = parsedHotelList
            .map((hotel) => ({
              ...hotel,
              hotel_price: Number(hotel.hotel_price),
            }))
            .sort((a, b) => b.hotel_price - a.hotel_price);

          sethotelList(sortedHotelList);
          setloader(false);
        }
        setloader(false);
      })
      .catch((err) => {
        console.error(err);
        setloader(false);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const getPriceValue = (price) => {
    return parseFloat(price * conversionfactor).toFixed(2);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CommonSlider
        onBackdropClick={modalClose}
        onClose={modalClose}
        open={open}
      >
        <Grid container className={classes.textField}>
          <Grid item xs={10} alignSelf="center">
            <div className="d-flex">
              {" "}
              <Typography variant="h5" style={{ marginRight: "1rem" }}>
                {"View hotels"}
              </Typography>
              <span class="badge badge-secondary">
                {moment.unix(date).format("MMM DD, YYYY")}
              </span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                modalClose();
              }}
              sx={{ float: "right" }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />

        {loader === false ? (
          <Grid container spacing={2} className={classes.textField}>
            <Grid item xs={12}>
              {hotelList.length <= 0 ? (
                <div style={{ marginLeft: "37%" }}>
                  {" "}
                  <h2>No data found!</h2>
                </div>
              ) : (
                <>
                  <h4
                    style={{
                      color: "#808080",
                      fontSize: "1.1rem",
                      marginBottom: "2rem",
                      marginLeft: "10px",
                    }}
                  >
                    {`Average price the below hotels in ${hotelCity.toLowerCase()} are selling at.`}
                  </h4>
                  {hotelList?.map(
                    (entry, index) =>
                      entry.hotel_price !== 0 && (
                        <div
                          className="informationCardWrapper"
                          style={{
                            height: "45px",
                            width: "100%",
                            padding: "1rem",
                            marginTop: "8px",
                          }}
                        >
                          <div className="d-flex w-100">
                            <div
                              className="d-flex"
                              style={{ width: "75%", marginRight: "5%" }}
                            >
                              <HouseIcon style={{ color: "#1CBBB3" }} />
                              <p
                                style={{
                                  color: "black",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  marginLeft: "1rem",
                                }}
                              >
                                {entry.hotel_name}
                              </p>
                            </div>

                            <p
                              style={{
                                width: "20%",
                                color: "black",
                                fontWeight: "500",
                                fontSize: "14px",
                                backgroundColor: "#CDEADF",
                                padding: "3px 8px",
                                textAlign: "center",
                                borderRadius: "40px",
                              }}
                            >
                              {`${currency}${getPriceValue(entry.hotel_price)}`}
                            </p>
                          </div>
                        </div>
                      )
                  )}
                </>
              )}
            </Grid>
          </Grid>
        ) : (
          <MockLoaderTable />
        )}
      </CommonSlider>
    </div>
  );
}

export default ViewHotels;
