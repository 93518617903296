import { Stack, Button } from "@mui/material";
import React from "react";
import { useHistory, useParams, useLocation } from "react-router";
import ShopWrapper from "../ShopWrapper";
import "./Reports.css";
import { connect } from "react-redux";
import UnauthorisedAccess from "../UnauthorisedAccess/UnauthorisedAccess";
import { useTranslation } from "react-i18next";

//Individual Card Component

function Reports({ data, shopAccessControl }) {
  const { t } = useTranslation();

  const Card = ({ name, description, id }) => {
    const url = new URL(window.location.href);
    const hotelId = url.searchParams.get("hotelId");
    const { shopId, shopName, shopType } = useParams();
    const history = useHistory();
    const location = useLocation();

    // set the links while creating the drawer
    const constructPathName = (pathName) => {
      return `/outlet/${shopId}/${shopName}/${shopType}/report/${pathName}`;
    };

    return (
      <div className="cardes">
        <div className="card-bodys">
          <h2>{name}</h2>
          <p>{description}</p>
        </div>
        <div>
          <Button
            variant="custom-button"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "1rem",
              marginBottom: "1rem",
            }}
            onClick={() =>
              history.push({
                pathname: constructPathName(id),
                search: `?hotelId=${hotelId}`,
                state: { index: location.state.index },
              })
            }
          >
            {t("View")}
          </Button>
        </div>
      </div>
    );
  };

  //Reports Card data array

  const reports = [
    {
      name: `${t("Outlet day wise sales summary")}`,
      description: `${t("Consolidated sales of all your outlet day wise")}`,
      id: "1",
      access:
        data !== undefined &&
        JSON.parse(shopAccessControl?.["POSRestaurantDayWiseSalesSummary"])
          ?.read === "YES",
    },
    {
      name: `${t("Outlet day wise balance summary")}`,
      description: `${t("Day wise balance summary of your outlet")}`,
      id: "4",
      access:
        data !== undefined &&
        JSON.parse(shopAccessControl?.["POSRestaurantsDayWiseBalanceSummary"])
          ?.read === "YES",
    },
    {
      name: `${t("Hourly item sales summary report")}`,
      description: `${t("A report of hourly variation in your item sales")}`,
      id: "2",
      access:
        data !== undefined &&
        JSON.parse(shopAccessControl?.["POSHourlyItemSalesSummaryReport"])
          ?.read === "YES",
    },
    {
      name: `${t("Item wise category summary report")}`,
      description: `${t("Get the summary of categories")}`,
      id: "3",
      access:
        data !== undefined &&
        JSON.parse(shopAccessControl?.["POSItemWiseCategorySummaryReport"])
          ?.read === "YES",
    },
    {
      name: `${t("Order wise sales summary")}`,
      description: `${t("Get the summary of Order Wise Sales Summary Report")}`,
      id: "5",
      access:
        data !== undefined &&
        JSON.parse(shopAccessControl?.["POSOrderWiseSalesSummaryReportForDay"])
          ?.read === "YES",
    },
    {
      name: `${t("KOT modification")}`,
      description: `${t(
        "Get the summary of all the KOT modifications made for the shop"
      )}`,
      id: "6",
      access: data !== undefined,
    },
    {
      name: `${t("KOT report")}`,
      description: `${t(
        "Get the summary of all the KOTs punched for the shop"
      )}`,
      id: "7",
      access:
        data !== undefined &&
        Object.entries(data?.accessControl?.user_feature_map).length > 0 &&
        JSON.parse(
          data?.accessControl?.user_feature_map
            ?.POSOrderWiseSalesSummaryReportForDay
        ).read === "YES",
    },
    {
      name: `${t("Daywise item sales summary report")}`,
      description: `${t(
        "Get the summary of all the item punched in a day for the shop"
      )}`,
      id: "8",
      access: true,
    },
  ];
  return (
    <ShopWrapper
      contentArea={
        // <div className="d-flex">
        shopAccessControl &&
        shopAccessControl?.["POSReports"] &&
        JSON.parse(shopAccessControl?.["POSReports"])?.read === "YES" ? (
          <Stack
            direction="row"
            // spacing={2}
            className="position-relative w-100 d-flex justify-content-start flex-wrap wrap"
            style={{ marginLeft: "15px" }}
          >
            {reports.map(
              (e) =>
                e.access && (
                  <div
                    className="cards"
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                  >
                    <Card id={e.id} name={e.name} description={e.description} />
                  </div>
                )
            )}{" "}
          </Stack>
        ) : (
          <UnauthorisedAccess />
        )
      }
    />
  );
}

function mapStateToProps(state) {
  return {
    data: state.auth.selectedHotel,
    shopAccessControl: state.auth.shopAccessControl,
  };
}
export default connect(mapStateToProps)(Reports);
