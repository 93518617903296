// LoginEmail.js
import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { checkForUser } from "../_redux/authCrud";
import i18n from "./i18n.js";
import { useTranslation } from "react-i18next";

const LoginEmail = (props) => {
  const { loginTrue, setMail, mailId, selectedLanguage } = props;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Enter correct mail Id"))
      .required(t("Email is required")),
  });

  const masterAdminCheck =
    window.location.pathname.split("/")[1] === "masterAdmin" ? true : false;
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const formik = useFormik({
    initialValues: { email: mailId },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      validUser(values);
    },
  });

  const validUser = async (values) => {
    setLoading(true);
    setInvalid(false);
    try {
      let email_truncated = String(values.email).trim().toLowerCase();
      const resp = await checkForUser(email_truncated);
      if (resp.data === "User Present") {
        setMail(email_truncated);
        loginTrue();
      } else {
        setInvalid(true);
      }
    } catch (e) {
      setInvalid(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container display="grid" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" paddingBottom={1}>
            {masterAdminCheck ? t("Master login") : t("Hello")}
          </Typography>
          <Typography variant="body1">
            {t("Sign in to your account")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            autoFocus
            value={formik.values.email}
            onChange={(e) => {
              formik.setFieldValue("email", e.target.value);
              if (invalid) setInvalid(false);
            }}
            onBlur={formik.handleBlur}
            name="email"
            id="email"
            error={
              (formik.touched.email && Boolean(formik.errors.email)) || invalid
            }
            helperText={
              (formik.touched.email && formik.errors.email) ||
              (invalid && t("No user present"))
            }
            label={masterAdminCheck ? t("Hotel Email") : t("Email")}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={loading}
            type="submit"
            sx={{ height: "150%" }}
            fullWidth
            variant="custom-button"
            size="large"
          >
            {t("Sign In")}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginEmail;
