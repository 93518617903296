import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Divider,
  OutlinedInput,
  Button,
  TextField,
  IconButton,
  Stack,
  CircularProgress,
  TextareaAutosize,
} from "@mui/material";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../api";
import { toast } from "material-react-toastify";
import { default as ReactImageUploading } from "react-images-uploading";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { format } from "date-fns";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import imageCompression from "browser-image-compression";

const GroupBeConfig = (props) => {
  const [env, setEnv] = useState(process.env.REACT_APP_ENV);
  const groupId = localStorage.getItem("mainGroupId");
  const [currUrl, setcurrUrl] = useState();
  const [hotelId, setHotelId] = useState();
  const [name, setname] = useState("");
  const [contact, setcontact] = useState("");
  const [email, setemail] = useState("");
  const [logo, setlogo] = useState("");
  const [loader, setloader] = useState(false);
  const [mockLoader, setmockLoader] = useState(false);
  const [showUrl, setshowUrl] = useState(false);
  const [terms, setterms] = useState("");
  const [website, setwebsite] = useState("");

  useEffect(() => {
    setHotelId(props?.data?.hotelId);
    getAllData();
  }, []);

  const getAllData = () => {
    setmockLoader(true);
    handleSpringDataRequest(`core/api/v1/groupBeConfig/get?groupId=${groupId}`)
      .then((res) => {
        if (res.length > 0) {
          setname(res[0].name);
          setcontact(res[0].contact);
          setemail(res[0].email);
          setlogo([{ data_url: res[0].logo }]);
          setshowUrl(true);
          setterms(res[0].termsAndConditions);
          setwebsite(res[0].website);
        } else {
          setname("");
          setcontact("");
          setemail("");
          setlogo([]);
          setshowUrl(false);
          setterms("");
          setwebsite("");
        }
        setmockLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setmockLoader(false);
      });
  };

  useEffect(() => {
    const tempurl =
      env === "BETA"
        ? `https://beta.bookingengine.stayflexi.com/group-be/?groupId=${groupId}`
        : `https://bookingengine.stayflexi.com/group-be/?groupId=${groupId}`;
    setcurrUrl(tempurl);
  }, []);
  const openLink = () => {
    window.open(currUrl, "_blank");
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.log("Image compression error:", error);
      return imageFile;
    }
  };

  const onChangeLogo = async (imageList) => {
    if (imageList.length > 0) {
      const originalFile = imageList[0].file;

      // Compress the image
      const compressedFile = await compressImage(originalFile);

      // Convert compressed image to base64
      const compressedBase64 = await imageCompression.getDataUrlFromFile(
        compressedFile
      );

      setlogo([{ data_url: compressedBase64 }]);
    } else {
      setlogo([]);
    }
  };

  const save = () => {
    if (name.trim().length <= 0) {
      return toast.error("Please enter group name");
    }
    setloader(true);
    const payload = {
      groupId: groupId,
      name: name,
      contact: contact,
      email: email,
      logo: logo.length > 0 ? logo[0].data_url : "",
      termsAndConditions: terms,
      website: website,
    };

    fetch(
      `${process.env.REACT_APP_SPRING_API_URL}core/api/v1/groupBeConfig/create-update?hotelId=${props.bookingData[0].hotelId}&hotel_id=${props.bookingData[0].hotelId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        if (!response.ok) {
          toast.error("Something went wrong");
        }
      })
      .then((data) => {
        toast.success("Operation Successful");
        getAllData();
        setloader(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setloader(false);
      })
      .finally(() => {
        setloader(false);
      });
  };

  return mockLoader === false ? (
    <div>
      <div>
        <h2>Group booking engine</h2>
        <p>View and configure your group booking engine.</p>
        <Divider />
        {showUrl && (
          <div className="mt-4">
            <div className="d-flex">
              <h5 className="mr-2">Group Booking engine url:</h5>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <OutlinedInput
                label="Required"
                notched={false}
                className="input-lg w-100 mr-4"
                value={currUrl}
                readOnly="true"
                disabled
              />
              <Button variant="custom-button" onClick={openLink}>
                Preview
              </Button>
            </div>
          </div>
        )}
        <div className="mt-4">
          <h5>Group name:</h5>
          <TextField
            label={"Group Name"}
            className="input-lg w-50 mt-3"
            placeholder={"Enter group name here"}
            onChange={(e) => setname(e.target.value)}
            value={name}
            name="schedule_name"
            id="schedule_name"
          />
        </div>
        <div className="mt-4">
          <h5>Group email:</h5>
          <TextField
            label={"Group email"}
            className="input-lg w-50 mt-3"
            placeholder={"Enter group email here"}
            onChange={(e) => setemail(e.target.value)}
            value={email}
            name="schedule_name"
            id="schedule_name"
          />
        </div>
        <div className="mt-4">
          <h5>Group contact:</h5>
          <TextField
            label={"Group contact"}
            className="input-lg w-50 mt-3"
            placeholder={"Enter group contact here"}
            onChange={(e) => setcontact(e.target.value)}
            value={contact}
            name="schedule_name"
            id="schedule_name"
          />
        </div>

        <div className="mt-4">
          <h5>Group website:</h5>
          <TextField
            label={"Group website"}
            className="input-lg w-100 mt-3"
            placeholder={"Enter group website here"}
            onChange={(e) => setwebsite(e.target.value)}
            value={website}
            name="schedule_name"
            id="schedule_name"
          />
        </div>

        <div className="mt-4">
          <h5>Group terms and conditions:</h5>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={4}
            className="input-lg w-100 mt-3"
            placeholder={"Enter Terms and conditions here"}
            onChange={(e) => setterms(e.target.value)}
            value={terms}
          />
        </div>
        <div className="d-flex flex-column mr-4 mt-4 mb-3 w-50">
          <h5 className="mb-3" style={{ marginRight: "23px" }}>
            {"Group logo"}
          </h5>

          <ReactImageUploading
            value={logo}
            onChange={onChangeLogo}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <Stack
                // p="2"
                // bgColor="gray.50"
                // borderRadius="lg"
                className="upload__image-wrapper"
                sx={{
                  borderRadius: "10px",
                  height: "150px",
                  backgroundColor: "#d3d3d336",
                  padding: "1rem",
                }}
              >
                {imageList.length === 0 && (
                  <IconButton
                    children={<UploadIcon icon="bi:upload" htmlColor="black" />}
                    variant="outline"
                    height="100%"
                    sx={{
                      borderRadius: "20px",
                      backgroundColor: "#d3d3d3",
                    }}
                    onClick={onImageUpload}
                    {...dragProps}
                  ></IconButton>
                )}
                <Stack spacing="2">
                  {imageList.map((image, index) => (
                    <Stack
                      sx={{ position: "relative", padding: "0.5rem" }}
                      key={index}
                    >
                      <img
                        src={
                          image["data_url"].includes("base64")
                            ? image["data_url"]
                            : image["data_url"] +
                              "?lastmod=" +
                              format(new Date(), "ddMMyyyyhhmmsstt")
                        }
                        style={{
                          height: "125px",
                          borderRadius: "1rem",
                          boxShadow: "md",
                          border: "1px solid",
                          borderColor: "gray.200",
                        }}
                        alt=""
                      />

                      <IconButton
                        children={<CloseIcon fontSize="xs" htmlColor="black" />}
                        size="xs"
                        sx={{
                          position: "absolute",
                          borderRadius: "100%",
                          backgroundColor: "white",
                          top: "0",
                          // colorScheme="teal"
                          marginTop: "0px !important",
                          left: "0",
                          "&:hover": {
                            color: "black",
                            backgroundColor: "#ffffffcc",
                          },
                        }}
                        onClick={() => {
                          onImageRemove(index);
                        }}
                      />
                      <IconButton
                        children={
                          <ChangeCircleIcon fontSize="xs" htmlColor="black" />
                        }
                        size="xs"
                        sx={{
                          position: "absolute",
                          borderRadius: "100%",
                          top: "0",
                          backgroundColor: "white",
                          // colorScheme="teal"
                          marginTop: "0px !important",
                          right: "0",
                          "&:hover": {
                            color: "black",
                            backgroundColor: "#ffffffcc",
                          },
                        }}
                        onClick={() => onImageUpdate(index)}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
          </ReactImageUploading>
        </div>
        <div className="w-100 d-flex" style={{ marginTop: "1rem" }}>
          <Button
            variant="custom-button"
            onClick={save}
            type="submit"
            disabled={loader}
            style={{ marginLeft: "95%" }}
          >
            {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${"Save"}`
            )}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <MockLoaderTable />
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(GroupBeConfig);
