import React, { useState } from "react";
import NewLogin from "./NewLogin";
import EmailSuccess from "./NewEmailSuccessPage";

const LoginComponent = ({ selectedLanguage }) => {
  const [emailVerified, setEmailVerified] = useState(false);
  const [mailId, setMailId] = useState("");

  const verifiedMail = (emailId) => {
    setMailId(emailId);
  };
  const setEmailFalse = () => {
    setEmailVerified(false);
  };

  const setEmailTrue = () => {
    setEmailVerified(true);
  };

  return (
    <div>
      {!emailVerified && (
        <NewLogin
          loginTrue={setEmailTrue}
          mailId={mailId}
          setMail={verifiedMail}
          selectedLanguage={selectedLanguage}
        />
      )}
      {emailVerified && (
        <EmailSuccess
          loginTrue={setEmailFalse}
          setMail={verifiedMail}
          mailId={mailId}
          selectedLanguage={selectedLanguage}
        ></EmailSuccess>
      )}
    </div>
  );
};

export default LoginComponent;
