import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import {
  Stack,
  Typography,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../../api";
import { useParams } from "react-router-dom";
import { toast } from "material-react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

function ReleaseTable({
  openReleaseDrawer,
  setOpenReleaseDrawer,
  data,
  handleAllTables,
}) {
  const { t } = useTranslation();

  const { shopId } = useParams();
  const toggleDrawer = (open) => {
    setOpenReleaseDrawer(open);
    if (open === false) {
      handleAllTables();
    }
  };

  const [tablesForRelease, setTablesForRelease] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [tableOrder, setTableOrder] = useState({ id: null, order: null });
  const [loadingDetails, setLoadingDetails] = useState(false);
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;
  const [releaseTableLoading, setReleaseTableLoading] = useState(false);
  const [loadingReleaseTableDetails, setLoadingReleaseTableDetails] =
    useState(false);

  useEffect(() => {
    if (selectedTable) {
      const getTableOrder = async () => {
        setLoadingDetails(true);
        try {
          const res = await handleSpringDataRequest(
            `core/api/v1/orders/get-running-order?tableId=${selectedTable}&shopId=${shopId}`
          );

          if (Object.keys(res).length > 0) {
            if (res.id === -1) {
              setTableOrder({ id: null, order: null });
            } else {
              setTableOrder({ id: res.id, order: res });
            }
          } else {
            setTableOrder({ id: null, order: null });
          }
        } catch (err) {
          setTableOrder({ id: null, order: null });
        } finally {
          setLoadingDetails(false);
        }
      };
      getTableOrder();
    }
  }, [selectedTable]);

  const fetchTablesForRelease = async () => {
    try {
      setLoadingReleaseTableDetails(true);
      const res = await handleSpringDataRequest(
        `core/api/v1/orders/get-tables-to-release?shopId=${shopId}`
      );
      setTablesForRelease(res);
    } catch (err) {
      toast.error(`${t("ERROR IN FETCHING THE RELEASE TABLES : ")}`, err);
    } finally {
      setLoadingReleaseTableDetails(false);
    }
  };

  useEffect(() => {
    if (shopId) {
      fetchTablesForRelease();
    }
  }, [shopId]);

  const handleReleaseTable = async () => {
    setReleaseTableLoading(true);
    try {
      let releaseTableUrl = `core/api/v1/orders/release-table?tableId=${selectedTable}&shopId=${shopId}`;
      if (tableOrder?.id && tableOrder?.id !== -1) {
        releaseTableUrl += `&orderId=${tableOrder?.id}`;
      } else {
        releaseTableUrl += `&orderId=-1`;
      }
      const res = await handleSpringDataMutationRequest(
        "PUT",
        releaseTableUrl,
        {}
      );
      toast.success(res);
    } catch (err) {
      toast.error(err);
    } finally {
      setReleaseTableLoading(false);
      toggleDrawer(false);
    }
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={openReleaseDrawer}
        onClose={() => toggleDrawer(false)}
      >
        <Box sx={{ width: 500 }} role="presentation">
          {loadingReleaseTableDetails ? (
            <List>
              <Stack sx={{ padding: 4, alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "#ADADAD",
                  }}
                >
                  {t("Loading....")}
                </Typography>
              </Stack>
            </List>
          ) : tablesForRelease?.length > 0 ? (
            <List>
              <Stack
                sx={{
                  paddingLeft: 4,
                  paddingRight: 4,
                  paddingTop: 1,
                  paddingBottom: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: 700 }}>
                  {t("Release table")}
                </Typography>
                <Typography
                  sx={{ cursor: "pointer" }}
                  onClick={() => toggleDrawer(false)}
                >
                  <AiOutlineClose size={"15px"} />
                </Typography>
              </Stack>
              <Divider />
              <Stack
                direction="row"
                sx={{
                  position: "relative",
                  display: "flex",
                  flexWrap: "wrap",
                  padding: 4,
                }}
              >
                {tablesForRelease?.map((table) => (
                  <Stack
                    key={table.id}
                    sx={{
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      marginRight: "10px",
                      padding: "20px 25px",
                      cursor: "pointer",
                      marginBottom: "10px",
                      backgroundColor:
                        table?.table_status === "PRINTED"
                          ? "#FE8941"
                          : "#7EF9FF",
                      boxShadow: selectedTable === table?.id && 24,
                    }}
                    onClick={() => setSelectedTable(table?.id)}
                  >
                    <Typography>{table?.table_name}</Typography>
                  </Stack>
                ))}
              </Stack>
              <Divider />
              <Stack sx={{ padding: 4 }}>
                {!selectedTable ? (
                  <Stack sx={{ alignItems: "center" }}>
                    <Typography sx={{ fontWeight: 500, color: "#ADADAD" }}>
                      {t(
                        "Please select a table to perform the release operation"
                      )}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack>
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
                        {t("Releasing table")} -{" "}
                        {
                          tablesForRelease?.find(
                            (table) => table.id === selectedTable
                          ).table_name
                        }
                      </Typography>
                    </Stack>
                    {loadingDetails ? (
                      <Stack sx={{ alignItems: "center", marginTop: "25px" }}>
                        <CircularProgress size={"40px"} />
                      </Stack>
                    ) : !tableOrder?.order ? (
                      <Stack sx={{ marginTop: "25px" }}>
                        <Typography>
                          {t(
                            "You have no order associated with the table. You may click on the below button to release the table"
                          )}
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack sx={{ marginTop: "25px" }}>
                        <Typography sx={{ fontWeight: 700 }}>
                          {t("Order details")} :
                        </Typography>
                        <Stack spacing={1} sx={{ marginTop: "15px" }}>
                          <Stack
                            direction={"row"}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 500, color: "#ADADAD" }}
                            >
                              {t("Invoice number")}
                            </Typography>
                            <Typography sx={{ fontWeight: 500 }}>
                              {tableOrder?.order?.invoice_no}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 500, color: "#ADADAD" }}
                            >
                              {t("Subtotal")}
                            </Typography>
                            <Typography sx={{ fontWeight: 500 }}>
                              {hotelCurrency} {tableOrder?.order?.subtotal}
                            </Typography>
                          </Stack>
                          {tableOrder?.order?.total_order_discount > 0 && (
                            <Stack
                              direction={"row"}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: 500, color: "#ADADAD" }}
                              >
                                {t("Discount")}
                              </Typography>
                              <Typography sx={{ fontWeight: 500 }}>
                                {hotelCurrency}{" "}
                                {tableOrder?.order?.total_order_discount}
                              </Typography>
                            </Stack>
                          )}
                          <Stack
                            direction={"row"}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 500, color: "#ADADAD" }}
                            >
                              {t("Tax")}
                            </Typography>
                            <Typography sx={{ fontWeight: 500 }}>
                              {hotelCurrency}{" "}
                              {tableOrder?.order?.tax_percentage}
                            </Typography>
                          </Stack>
                          {tableOrder?.order?.round_off_amount > 0 && (
                            <Stack
                              direction={"row"}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: 500, color: "#ADADAD" }}
                              >
                                {t("Round off amount")}
                              </Typography>
                              <Typography sx={{ fontWeight: 500 }}>
                                {hotelCurrency}{" "}
                                {tableOrder?.order?.round_off_amount}
                              </Typography>
                            </Stack>
                          )}
                          <Stack
                            direction={"row"}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 500, color: "#ADADAD" }}
                            >
                              {t("Total")}
                            </Typography>
                            <Typography sx={{ fontWeight: 500 }}>
                              {hotelCurrency}{" "}
                              {tableOrder?.order?.order_amount +
                                tableOrder?.order?.round_off_amount}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                    <Stack
                      direction="row"
                      sx={{
                        marginTop: "50px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "#e4e4e4",
                          textTransform: "capitalize",
                        }}
                        onClick={() => toggleDrawer(false)}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        variant="custom-button"
                        onClick={handleReleaseTable}
                        disabled={releaseTableLoading || loadingDetails}
                      >
                        {releaseTableLoading ? (
                          <CircularProgress size={"15px"} />
                        ) : !tableOrder?.order ? (
                          `${t("Release table")}`
                        ) : (
                          `${t("Cancel & Release")}`
                        )}
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </List>
          ) : (
            <Stack className="mt-5 d-flex align-items-center">
              <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                {t("You have no tables to release.")}
              </Typography>
            </Stack>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(ReleaseTable);
