import {
  CircularProgress,
  Divider,
  Grid,
  OutlinedInput,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  TextField,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalTitle } from "react-bootstrap";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import InfoIcon from "@mui/icons-material/Info";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../api";
import { connect, shallowEqual, useSelector } from "react-redux";
import "../../../../pages/AccessControl/Views/AccessControlModal.css";
import { toast } from "material-react-toastify";
import { Table } from "react-bootstrap";
import i18n from "../i18n";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const EditGroupUserAccessModal = ({
  show,
  hide,
  getData,
  email,
  allRoleSet,
  selectedRoleSet,
  hotelRolesData,
  hotelEmail,
  allGroupId,
  selectedLanguage,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const [loader, setloader] = useState(false);
  const [userRoleAccessData, setuserRoleAccessData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [bookingChecked, setbookingChecked] = useState(false);
  const [posChecked, setposChecked] = useState(false);
  const [hkChecked, sethkChecked] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [disblaedButton, setdisblaedButton] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getStringData = () => {
    return "";
  };

  const handleCheckAll = (isChecked) => {
    userRoleAccessData.forEach((row) => (row.checked = isChecked));
    setIsCheckedAll(isChecked);
  };
  const handleCheck = (rowId) => {
    const updatedData = userRoleAccessData.map((row) => {
      if (row.id === rowId) {
        row.checked = !row.checked;
      }
      return row;
    });

    setuserRoleAccessData(updatedData);
  };

  useEffect(() => {
    if (
      email !== undefined &&
      email !== null &&
      email !== "" &&
      allRoleSet?.length > 0 &&
      hotelRolesData?.length > 0
    ) {
      setuserRoleAccessData([]);
      const data = allRoleSet?.filter((e) => e.user_email === email);
      if (data[0]?.roles_set.includes("ALL")) {
        let newData = [];
        let objs = {};
        objs["id"] = 1;
        objs["role"] = "SUBADMIN";
        objs["checked"] = true;
        newData.push(objs);
        setdisblaedButton(true);
        setuserRoleAccessData(newData);
      } else {
        let roleSet = data[0]?.roles_set.split(",").map(Number);
        let accessMapData = [];
        let ratePlan = hotelRolesData?.map((res, idx) => {
          let obj = {};
          obj["id"] = res.id;
          obj["role"] = res?.role_name;
          obj["checked"] = roleSet.includes(res.id);
          accessMapData.push(obj);
        });
        setdisblaedButton(false);
        setuserRoleAccessData(accessMapData);
      }
    }
  }, [email, allRoleSet, hotelRolesData]);

  const save = () => {
    setloader(true);
    const ids = userRoleAccessData
      .filter((row) => row.checked === true)
      .map((item) => item.id);
    const emailid = allRoleSet?.filter((e) => e.user_email === email);

    const payload = {
      id: emailid[0].id,
      group_id: allGroupId,
      user_email: email,
      roles_set: ids?.join(","),
      notif_control_set: getStringData(),
    };

    handleSpringDataMutationRequest(
      "PUT",
      `core/api/v1/group-user-role-feature-set/update?emailId=${hotelEmail}`,
      payload
    )
      .then((res) => {
        toast.success(`${t("SuccessFully updated")}`);
        getData();
        setloader(false);
        hide();
      })
      .catch((err) => {
        toast.error(`${t("Something went wrong")}`);
        setloader(false);
      });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={hide}
        dialogClassName={"myModalBody142321"}
        // animation={false}
        contentClassName={"myModalContentssdsx"}
        // style={{ maxWidth: "65vw" }}
      >
        <ModalTitle>
          <div style={styles.titleAdjustment}>
            <Button variant="white" onClick={hide}>
              {" "}
              X{" "}
            </Button>
          </div>
        </ModalTitle>
        <ModalBody>
          <div style={{ width: "100%" }}>
            <div>
              <Grid
                item
                container
                xs={12}
                sx={{ width: "100%", height: "100%" }}
              >
                <div className="d-flex flex-column mb-3">
                  <h2>{t("Edit User")}</h2>
                  <p>
                    {t(
                      "This page provides detailed information about the specific access rights granted to each user. You can easily manage these access rights by enabling or disabling the toggle buttons below."
                    )}
                  </p>
                </div>

                <Card sx={{ width: "100%", height: "100%" }}>
                  <CardContent>
                    <Grid item xs={12}>
                      <div className="w-100">
                        <h4
                          style={{ marginTop: "1rem", marginBottom: "2rem" }}
                        >{`${t("Email")} - ${email}`}</h4>
                        <div className="d-flex justify-content-between w-100">
                          <div>
                            <OutlinedInput
                              onChange={(e) => setsearchTerm(e.target.value)}
                              placeholder={t("Search By Access Control Name")}
                              label={t("Search By Access Control Name")}
                              // className="w-75"
                              notched={false}
                              type="search"
                              size="small"
                              style={{
                                borderRadius: "5px",
                                width: "18rem",
                                marginBottom: "2rem",
                                marginRight: "2rem",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            overflowY: "scroll",
                            height: "40rem",
                            width: "100%",
                          }}
                        >
                          <Table striped bordered hover>
                            <thead
                              style={{
                                position: "sticky",
                                top: "0px",
                                backgroundColor: "gray",
                              }}
                            >
                              <tr>
                                <th hidden>{t("Id")}</th>
                                <th>{t("Role")}</th>
                                <th colSpan={3}>
                                  <Switch
                                    className="mr-2"
                                    checked={isCheckedAll}
                                    onChange={(e) => {
                                      if (
                                        userRoleAccessData[0]?.role !==
                                        "SUBADMIN"
                                      )
                                        handleCheckAll(!isCheckedAll);
                                    }}
                                  />
                                  {t("Check all")}
                                </th>
                              </tr>
                            </thead>
                            {userRoleAccessData !== undefined &&
                              userRoleAccessData.length > 0 && (
                                <tbody>
                                  {userRoleAccessData
                                    ?.filter((val) => {
                                      if (searchTerm === "") {
                                        return val;
                                      } else if (
                                        val.role
                                          .toLowerCase()
                                          .includes(searchTerm.toLowerCase())
                                      ) {
                                        return val;
                                      }
                                    })
                                    .map((row) => (
                                      <tr key={row.id}>
                                        <td hidden>{row.id}</td>
                                        <td>{row.role}</td>
                                        <td>
                                          <Switch
                                            checked={row.checked}
                                            onChange={(e) => {
                                              if (row.role !== "SUBADMIN")
                                                handleCheck(row.id);
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              )}
                          </Table>
                        </div>
                      </div>
                    </Grid>
                    <Button
                      color="primary"
                      className="float-right mr-4 mb-3"
                      variant="custom-button"
                      disabled={loader}
                      onClick={save}
                    >
                      {loader ? (
                        <CircularProgress
                          size={15}
                          color="inherit"
                          sx={{ ml: 1.5 }}
                        />
                      ) : (
                        `${t("Save")}`
                      )}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditGroupUserAccessModal;

const styles = {
  iconColor: {
    borderRadius: "100%",
    bordeRadius: "100%",
    height: "40px",
    width: "40px",
    padding: "4px",
    marginRight: "-10px",
    color: "#COCOCO",
  },
  inputSize: {
    width: "50px",
    bordeRadius: "5px",
    heigth: "30px",
  },
  questionPadding: {
    display: "flex",
    marginBottom: "40px ",
  },
};
