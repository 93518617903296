import {
  Stack,
  Typography,
  Button,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import { toast } from "material-react-toastify";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import {
  handleSpringDataRequest,
  handleSpringDataMutationRequest,
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
} from "../../../api";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { AvailableRoomLoadersForFolio } from "../../../Utils/LoaderUtils";
import { useTranslation } from "react-i18next";
import i18n from "../../../modules/Auth/pages/i18n";

function DisplayAllPosOrders({
  posItems,
  currency,
  hotelCurrency,
  hotelConvertedCurrency,
  handleOpenDetailedItemList,
  handleOrderFormatter,
  hotelConvertedCurrencySymbol,
  taxPercentageFormatter,
  bookingId,
  updateInvoice,
  updateSummaryData,
  handleCloseAllPosOrders,
  loggedUserEmail,
  roomId,
  selectedLanguage,
  access,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  // Fetching the hotel id from the url
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  // Get all the pos order ids
  const allPosOrderIds = posItems.map((posItem) => posItem.pos_order_id);
  // Storing the information for all the orders
  const [orderDetails, setOrderDetails] = useState([]);
  // Creating the payload in order to check if the pos order is settled or not
  function getAllTheOrderDetails() {
    console.log("all the pos order ids : ", allPosOrderIds);
    for (let i = 0; i < allPosOrderIds.length; i++) {
      handleSpringDataRequest(
        `core/api/v1/order-payment/get-all?orderId=${allPosOrderIds[i]}`
      )
        // .then((res) => console.log("Res : ", res))
        .then((details) =>
          setOrderDetails((orderDetails) => [...orderDetails, ...details])
        )
        .catch((err) => toast.error(err));
    }
  }

  useEffect(() => {
    getAllTheOrderDetails();
  }, []);

  // Get the list of already settled POS orders
  function getSettledOrderList() {
    let tempOrders = [];
    for (let i = 0; i < orderDetails.length; i++) {
      if (Number(orderDetails[i].balance_amount) === 0) {
        tempOrders.push(String(orderDetails[i]?.order_id));
      }
    }
    return tempOrders;
  }

  // useEffect(() => {
  //   getSettledOrderList();
  // }, [orderDetails]);

  // Row styles
  const rowStyle = {
    fontSize: "12px",
    position: "relative",
    wordBreak: "break-all",
  };
  // Setting up the columns for the All the pos items
  const columns = [
    // {
    //   dataField: "service_id",
    //   text: "Id",
    //   sort: true,
    //   headerStyle: {
    //     fontSize: "10px",
    //     width: "75px",
    //   },
    //   hidden: true,
    //   editable: false,
    //   style: (row) => {
    //     return rowStyle;
    //   },
    // },
    {
      dataField: "pos_order_id",
      text: `${t("POS Id")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
        width: "75px",
      },
      hidden: true,
      editable: false,
      style: (row) => {
        return rowStyle;
      },
    },
    {
      dataField: "service_date",
      text: `${t("Date")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
        width: "100px",
      },
      editable: false,
      style: (row) => {
        return rowStyle;
      },
    },
    {
      dataField: "service_desc",
      text: `${t("Description")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
        width: "125px",
      },
      style: (row) => {
        return {
          cursor: "pointer",
          fontSize: "10px",
        };
      },
      editable: false,
      formatter: (cell, row) => (
        <Stack
          direction="row"
          spacing={1}
          style={{
            display: "flex",
            flexWrap: "wrap",
            wordBreak: "break-all",
            fontWeight: 500,
            textDecoration: "underline",
            fontSize: "10px",
          }}
        >
          <Typography
            onClick={() => {
              handleOrderFormatter(cell, row);
              handleOpenDetailedItemList();
            }}
          >
            {row?.service_desc}
          </Typography>
        </Stack>
      ),
    },
    {
      dataField: "service_amount_type",
      text: `${t("Type")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
        width: "100px",
      },
      formatter: (cell, row) => (
        <Typography style={{ wordBreak: "break-all" }}>
          {row?.service_amount_type}
        </Typography>
      ),
      editable: false,
      style: (row) => {
        return rowStyle;
      },
    },
    {
      dataField: "service_amount",
      text: `${t("Sub-total")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
        width: "100px",
      },
      formatter: (cell) => {
        return Math.abs(parseFloat(cell)).toFixed(2);
      },
      editable: false,
      style: (row) => {
        return rowStyle;
      },
    },
    {
      isDummyField: true,
      text: `${t("Cumulative tax %")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
        width: "120px",
      },
      formatter: (cell, row) =>
        row.service_id === "-1" ? "-" : taxPercentageFormatter(cell, row),
      editable: false,
      style: (row) => {
        return rowStyle;
      },
    },
    {
      dataField: "service_tax",
      text: `${t("Tax")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
        width: "100px",
      },
      formatter: (cell) => {
        return parseFloat(cell).toFixed(2);
      },
      editable: false,
      style: (row) => {
        return rowStyle;
      },
    },
    {
      dataField: "pos_service_charge",
      text: `${t("Service charge")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
        width: "100px",
      },
      formatter: (cell) => {
        return parseFloat(cell).toFixed(2);
      },
      editable: false,
      style: (row) => {
        return rowStyle;
      },
    },
    {
      dataField: "service_total",
      text: `${t("Total")} (${
        hotelCurrency === hotelConvertedCurrency
          ? currency
          : hotelConvertedCurrencySymbol
      })`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
        width: "100px",
      },
      formatter: (cell) => {
        return Math.abs(parseFloat(cell)).toFixed(2);
      },
      editable: false,
      style: (row) => {
        return rowStyle;
      },
    },
  ];

  // Selected POS orders
  const [selectedPosOrderIds, setSelectedPosOrderIds] = React.useState([]);
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedPosOrderIds((selectedRows) => [
        ...selectedRows,
        row.pos_order_id,
      ]);
    } else {
      setSelectedPosOrderIds(
        selectedPosOrderIds.filter((x) => x !== row.pos_order_id)
      );
    }
  };

  // selecting all rows at once
  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.pos_order_id);
    if (isSelect) {
      setSelectedPosOrderIds(ids);
    } else {
      setSelectedPosOrderIds([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    selected: selectedPosOrderIds,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    nonSelectable: getSettledOrderList(),
  };

  const listedPaymentTypes = [
    {
      id: "Cash",
      name: `${t("Cash payment")}`,
      active: true,
    },
    {
      id: "OfflineCard",
      name: `${t("Card payment")}`,
      active: true,
    },
    {
      id: "UPI",
      name: `${t("UPI payment")}`,
      active: hotelCurrency?.toUpperCase() === "INR",
    },
    {
      id: "Other",
      name: `${t("Other payment mode")}`,
      active: true,
    },
  ];
  const [selectedPaymentType, setSelectedPaymentType] = useState("Cash");

  // Handle the payment type into the invoice
  function handlePaymentType(res) {
    let paymentPayload = {
      hotelId: hotelId,
      bookingID: bookingId,
      reservation_id: null,
      amount: res,
      service_amount_desc: `Bulk settle of POS orders, Order ID(s) - ${selectedPosOrderIds.join(
        ", "
      )}`,
      logged_user_email: loggedUserEmail,
    };

    const paymentRecordedOn = moment().format("YYYY-MM-DD HH:mm:ss");

    switch (selectedPaymentType) {
      case "UPI":
        paymentPayload["offline_upi_date"] = paymentRecordedOn;
        break;
      case "Other":
        paymentPayload["other_payment_date"] = paymentRecordedOn;
        break;
      default:
        paymentPayload["offline_card_date"] = paymentRecordedOn;
        break;
    }

    djangoHandleDataMutationRequest(
      "POST",
      `invoice/record${selectedPaymentType}Payment/`,
      paymentPayload
    )
      .then((res) => toast.success(`${t("Payment logged successfully")}`))
      .catch((err) => toast.error(err));
  }

  // Settle order loader
  const [bulkSettleLoader, setBulkSettleLoader] = useState(false);
  // Bulk Settle Order
  function bulkSettleOrder() {
    setBulkSettleLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/order-payment/bulk-settle",
      {
        booking_id: bookingId,
        payment_type: selectedPaymentType.toUpperCase(),
        order_id_list: selectedPosOrderIds,
      }
    )
      .then((res) => handlePaymentType(res))
      .then(() => updateInvoice())
      .then(() => updateSummaryData())
      .catch((err) => toast.error(err))
      .then(() => setBulkSettleLoader(false))
      .then(() => handleCloseAllPosOrders());
  }

  // Proceed to payment flag
  const [paymentPage, setPaymentPage] = useState(false);
  const [operationType, setOperationType] = useState(null);

  // Bulk cancel pos orders
  // Bulk cancel loader
  const [bulkCancelLoader, setBulkCancelLoader] = useState(false);
  function handleBulkCancelPosOrders() {
    setBulkCancelLoader(true);
    handleSpringDataMutationRequest("POST", "core/api/v1/orders/bulk-cancel", {
      order_id_list: selectedPosOrderIds,
    })
      .then((res) =>
        toast.success(`${t("Successfully cancelled selected pos order")}`)
      )
      .then(() => updateInvoice())
      .then(() => updateSummaryData())
      .catch((err) => toast.error(err))
      .finally(() => setBulkCancelLoader(false))
      .finally(() => handleCloseAllPosOrders());
  }

  // Get the rooms available
  const [inhouseData, setInhouseData] = useState(null);
  var currentDate = new Date();
  var formattedDate = moment(currentDate).format("ddd MMM DD YYYY");
  function getDashboardData() {
    djangoHandleDataRequests(
      `reports/generateDashDataLite/?date=${formattedDate}&is_today=${moment(
        formattedDate
      )
        .isSame(moment(moment().toDate()).format("ddd MMM DD YYYY"))
        .toString()}`
    )
      .then((res) => {
        setInhouseData(res.ADMIN_CONFIRMED);
      })
      .catch((err) => toast.error(`${t("Sorry cannot fetch the data!")}`));
  }

  // Get the room reservation details for the POS orders associated with the booking id
  const [roomOrderDetails, setRoomOrderDetails] = useState([]);
  const [specificRoomOrderIds, setSpecificRoomOrderIds] = useState([]);
  const [loadingRoomOrderDetails, setLoadingRoomOrderDetails] = useState(false);
  const handleGetRoomOrderDetails = () => {
    try {
      setLoadingRoomOrderDetails(true);
      handleSpringDataRequest(
        `core/api/v1/orders/get-room-orders?bookingId=${bookingId}`
      )
        .then((res) => {
          setRoomOrderDetails(res);
          const tempRoomIds = [];
          for (let i = 0; i < selectedPosOrderIds.length; i++) {
            const specificRoomDetails = res.filter(
              (detail) => detail.orderId == selectedPosOrderIds[i]
            );
            tempRoomIds.push(specificRoomDetails[0]["roomId"]);
          }
          setSpecificRoomOrderIds(tempRoomIds);
        })
        .catch((err) => {
          throw new Error(err);
        });
    } catch (err) {
      toast.error(err);
    } finally {
      setLoadingRoomOrderDetails(false);
    }
  };

  // Selected room in order to transfer the pos order
  const [transferredRoom, setTransferredRoom] = useState(null);
  const [bulkShiftLoader, setBulkShiftLoader] = useState(false);
  // Handle shift the room
  function handleShiftRoom() {
    const resId = inhouseData?.filter(
      (data) => data.room_ids === transferredRoom
    )[0]?.reservation_id;
    const bId = inhouseData?.filter(
      (data) => data.room_ids === transferredRoom
    )[0]?.booking_id;
    setBulkShiftLoader(true);
    handleSpringDataMutationRequest("POST", "core/api/v1/orders/bulk-shift", {
      order_id_list: selectedPosOrderIds,
      room_id: transferredRoom,
      booking_id: bId,
      reservation_id: resId,
    })
      .then((res) => toast.success(`${t("Successfully updated pos order")}`))
      .then(() => updateInvoice())
      .then(() => updateSummaryData())
      .catch((err) => toast.success(err))
      .finally(() => setBulkShiftLoader(false))
      .finally(() => handleCloseAllPosOrders());
  }

  return (
    <Stack sx={{ padding: 2 }}>
      <div className="invoiceSummTableWrapper">
        {!operationType && (
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="custom-button"
                disabled={selectedPosOrderIds.length === 0}
                onClick={() => {
                  getDashboardData();
                  handleGetRoomOrderDetails();
                  setOperationType("shift");
                }}
              >
                {t("Bulk shift order")}
              </Button>
              {JSON.parse(
                access?.accessControl?.user_feature_map?.POSCancelOrder
              ).read === "YES" && (
                <Button
                  variant="custom-button"
                  disabled={
                    bulkCancelLoader || selectedPosOrderIds.length === 0
                  }
                  onClick={handleBulkCancelPosOrders}
                >
                  {bulkCancelLoader ? (
                    <CircularProgress size="15px" />
                  ) : (
                    `${t("Bulk cancel")}`
                  )}
                </Button>
              )}
            </Stack>
            <ToolkitProvider
              keyField="pos_order_id"
              data={posItems}
              columns={columns}
            >
              {(props) => (
                <>
                  <div className="folioTableWrapper">
                    <BootstrapTable
                      {...props.baseProps}
                      tabIndexCell
                      headerWrapperClasses="foo"
                      bodyClasses="tableBody"
                      bordered={false}
                      selectRow={selectRow}
                      //   pagination={paginationFactory()}
                      // rowStyle = {rowStyle}
                    />
                  </div>
                </>
              )}
            </ToolkitProvider>
          </Stack>
        )}
        {operationType === "payment" && (
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              {t("Choose the payment mode")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="controlled-radio-buttons-group"
              value={selectedPaymentType}
              onChange={(e) => setSelectedPaymentType(e.target.value)}
            >
              {listedPaymentTypes?.map(
                (mode, index) =>
                  mode.active && (
                    <FormControlLabel
                      value={mode.id}
                      control={<Radio />}
                      label={mode.name}
                    />
                  )
              )}
            </RadioGroup>
          </FormControl>
        )}
        {operationType === "shift" && (
          <Stack spacing={2}>
            <Typography>
              {t("Select a room below to transfer the selected POS orders")}
            </Typography>
            <Stack
              direction={"row"}
              sx={{ position: "relative", display: "flex", flexWrap: "wrap" }}
            >
              {!inhouseData || loadingRoomOrderDetails ? (
                <AvailableRoomLoadersForFolio />
              ) : (
                inhouseData?.map(
                  (cust) =>
                    !(cust?.room_ids).includes(specificRoomOrderIds) && (
                      <Stack
                        onClick={() =>
                          transferredRoom === cust?.room_ids
                            ? setTransferredRoom(null)
                            : setTransferredRoom(cust?.room_ids)
                        }
                        sx={{
                          position: "relative",
                          padding: "2.5px 2.5px 2.5px 5px",
                          border:
                            transferredRoom === cust?.room_ids
                              ? "none"
                              : "1px solid #efefef",
                          backgroundColor:
                            transferredRoom === cust?.room_ids
                              ? "#efefef"
                              : "transparent",
                          borderRadius: "5px",
                          marginRight: "3.5px",
                          marginBottom: "3.5px",
                          cursor: "pointer",
                        }}
                      >
                        <Typography>
                          <b>{cust?.room_ids}</b>
                        </Typography>
                        <Stack direction={"row"} sx={{ marginTop: "2.5px" }}>
                          <Typography sx={{ color: "grey" }}>
                            {cust?.user_name}{" "}
                          </Typography>
                          <Typography sx={{ color: "grey" }}>
                            ({cust?.booking_id?.split("_")[2]})
                          </Typography>
                        </Stack>
                      </Stack>
                    )
                )
              )}
            </Stack>
          </Stack>
        )}
      </div>
      <Stack
        sx={{
          marginTop: "5px",
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
        direction="row"
      >
        <Button
          sx={{ textTransform: "none", color: "black" }}
          onClick={() =>
            operationType === null
              ? handleCloseAllPosOrders()
              : setOperationType(null)
          }
        >
          {operationType === null ? "Cancel" : "Back"}
        </Button>
        <Stack direction={"row"}>
          {(operationType === null || operationType === "payment") && (
            <Button
              variant="custom-button"
              disabled={
                selectedPosOrderIds.length === 0 || bulkSettleLoader
                  ? true
                  : false
              }
              onClick={
                () =>
                  operationType !== "payment"
                    ? setOperationType("payment")
                    : bulkSettleOrder()
                // !paymentPage ? setPaymentPage(true) : bulkSettleOrder()
              }
            >
              {operationType !== "payment" ? (
                `${t("Proceed to payment")}`
              ) : bulkSettleLoader ? (
                <CircularProgress size="15px" />
              ) : (
                `${t("Bulk update")}`
              )}
            </Button>
          )}
          {["shift"].includes(operationType) && (
            <Button
              variant="custom-button"
              disabled={transferredRoom === null || bulkShiftLoader}
              onClick={handleShiftRoom}
            >
              {bulkShiftLoader ? (
                <CircularProgress size={"15px"} />
              ) : (
                `${t("Confirm")}`
              )}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

function mapStateToProps(state) {
  return { access: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(DisplayAllPosOrders);
