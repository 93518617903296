import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import { connect, shallowEqual, useSelector } from "react-redux";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  Grid,
  Button,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  stepButtonClasses,
  CircularProgress,
  ListItemText,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  IconButton,
  Divider,
  Box,
  Modal,
} from "@mui/material";
// import UserModal from "./UserModal";
import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../api";
import { toast } from "material-react-toastify";
import { Edit, PowerSettingsNew } from "@mui/icons-material";
// import EditUserAccessModal from "./EditUserAccessModal";
import CancelIcon from "@mui/icons-material/Cancel";
import MockLoaderTable from "../../../../pages/Folios/ViewManagePayments/MockLoaderTable";
import AddNewUser from "./AddNewUser";
import EditGroupUserAccessModal from "./EditGroupUserAccessModal";
import HistoryIcon from "@mui/icons-material/History";
import DeleteIcon from "@mui/icons-material/Delete";
import i18n from "../i18n";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

function GroupUserAccess({ allGroupId, bookingData, selectedLanguage }) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const [raised, setRaised] = useState({});
  const [hotelUserRoleModal, sethotelUserRoleModal] = useState(false);
  const [hotelUserEditRoleModal, sethotelUserEditRoleModal] = useState(false);
  const [selectedEmail, setselectedEmail] = useState("");
  const [allRoleSet, setallRoleSet] = useState([]);
  const [selectedRoleSet, setselectedRoleSet] = useState();
  const [editUserModal, seteditUserModal] = useState(false);
  const [hotelRolesData, sethotelRolesData] = useState();
  const [loader, setloader] = useState(false);
  const [HasMp, setHasMp] = useState({});
  const [searchTerm, setsearchTerm] = useState("");
  const [groupEmail, setgroupEmail] = useState("");
  const [userEmails, setuserEmails] = useState([]);
  const [hotelEmails, sethotelEmails] = useState([]);
  const [removeHotelsModal, setremoveHotelsModal] = useState(false);
  const [subUserHotels, setsubUserHotels] = useState([]);
  const [selectedSubuserHotels, setselectedSubuserHotels] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedSubuserEmail, setselectedSubuserEmail] = useState("");
  const [deleteSubUserModal, setDeleteSubUserModal] = useState(false);

  const openDeleteModal = (email) => {
    setselectedSubuserEmail(email);
    setDeleteSubUserModal(true);
  };

  const hideDeleteModal = () => setDeleteSubUserModal(false);

  const openModalHotelModal = (email) => {
    setselectedSubuserEmail(email);
    setremoveHotelsModal(true);
  };

  const hideModalHotelModal = () => setremoveHotelsModal(false);
  const openUserRoleModal = () => {
    sethotelUserRoleModal(true);
  };

  const hideUserRoleModal = () => {
    sethotelUserRoleModal(false);
  };

  const openEditUserRoleModal = (num) => {
    setselectedRoleSet(num);
    setselectedEmail(num.user_email);
    seteditUserModal(true);
  };

  const hideEditUserRoleModal = () => {
    seteditUserModal(false);
  };

  const openUserEditRoleModal = (num) => {
    setselectedEmail(num.user_email);
    sethotelUserEditRoleModal(true);
  };

  const hideUserEditRoleModal = () => {
    sethotelUserEditRoleModal(false);
  };

  const toggleRaised = (num) => {
    raised[num] = !raised[num];
    setRaised({ ...raised });
  };

  const getAllRoleSet = () => {
    setloader(true);
    handleSpringDataRequest(
      `core/api/v1/group-user-role-feature-set/get-all?email=${localStorage.getItem(
        "groupEmail"
      )}`
    )
      .then((res) => {
        setallRoleSet(res);
        setloader(false);
      })
      .catch((err) => {
        toast.error(`${t("Something went wrong")}`);
        setloader(false);
      });
  };

  const getAllData = () => {
    setloader(true);
    handleSpringDataRequest(
      `core/api/v1/group-roles/get-all?email=${localStorage.getItem(
        "groupEmail"
      )}`
    )
      .then((res) => {
        sethotelRolesData(res);
        setloader(false);
      })
      .catch((err) => {
        toast.error(`${t("Error in fetching roles")}`);
        setloader(false);
      });
  };

  useEffect(() => {
    getAllRoleSet();
    getAllData();
    getHotelEmails();
  }, [localStorage.getItem("groupEmail")]);

  useEffect(() => {
    let map1 = new Map();
    const data = hotelRolesData?.map((item) =>
      map1.set(item.id, item.role_name)
    );
    setHasMp(map1);
  }, [hotelRolesData]);

  const getAccessRoles = (roles, setRoles) => {
    const rolesArray = roles.split(",");

    const data = [];

    const acc = rolesArray?.map((item) => {
      data.push(HasMp.get(Number(item)));
    });

    return data.toString();
  };
  const [getAllSubusers, setgetAllSubusers] = useState([]);
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/groupAccesscontrol/getAllGroupSubUsers?email=${localStorage.getItem(
        "groupEmail"
      )}`
    )
      .then((res) => setgetAllSubusers(res))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (allRoleSet.length > 0) {
      const data = allRoleSet.map((item) => item.user_email);
      setuserEmails(data);
    }
  }, [allRoleSet]);

  useEffect(() => {
    setgroupEmail(localStorage.getItem("groupEmail"));
  }, [localStorage.getItem("groupEmail")]);

  const getHotelEmails = () => {
    djangoHandleDataRequests(`metaAdmin/getMetaAdminMasterDashData/`)
      .then((res) =>
        sethotelEmails(res.hotelTableData.map((item) => item.email_id))
      )
      .catch((err) => console.error(err));
  };

  const getHotelforSubuser = () => {
    handleSpringDataRequest(
      `core/api/v1/groupAccesscontrol/get-hotel-for-subusers?subuserGroupEmail=${selectedSubuserEmail}`
    )
      .then((res) => {
        setsubUserHotels(res);
        setselectedSubuserHotels([res[0].hotel_id]);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (selectedSubuserEmail !== "") {
      getHotelforSubuser();
    }
  }, [selectedSubuserEmail]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length <= 0) {
      return;
    }

    setselectedSubuserHotels(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const saveDetails = () => {
    setloading(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/groupAccesscontrol/remove-hotel-from-subusers?subuserGroupEmail=${selectedSubuserEmail}`,
      selectedSubuserHotels
    )
      .then((res) => {
        toast.success(`${t("Success")}`);
        setloading(false);
        setselectedSubuserEmail("");
        getAllRoleSet();
        hideModalHotelModal();
      })
      .catch((err) => {
        toast.error(err);
        setloading(false);
      });
  };

  const getName = (hotelId) => {
    const data = bookingData.filter((item) => item.hotelId === hotelId);
    return data[0]?.hotelName + " - " + hotelId;
  };

  const deleteUser = () => {
    setloading(true);
    handleSpringDataRequest(
      `core/api/v1/groupAccesscontrol/delete-subuser?subuserEmail=${selectedSubuserEmail}&groupId=${allGroupId}`
    )
      .then((res) => {
        toast.success(`${t("Success")}`);
        setloading(false);
        getAllRoleSet();
        hideDeleteModal();
      })
      .catch((err) => {
        toast.error(err);
        setloading(false);
      });
  };

  return loader === false ? (
    <div>
      <Grid container>
        <div className="d-flex flex-column">
          <h2>{t("User Access")}</h2>
          <p>
            {t(
              "Use this page to create and edit user access for group features i.e Group reservation and Inventory calendar."
            )}
          </p>
        </div>
        {getAllSubusers.length > 0 && (
          <AddNewUser
            show={hotelUserRoleModal}
            hide={hideUserRoleModal}
            getData={getAllRoleSet}
            getAllSubusers={getAllSubusers}
            allGroupId={allGroupId}
            userEmails={userEmails}
            bookingData={bookingData}
            hotelEmails={hotelEmails}
            selectedLanguage={selectedLanguage}
          ></AddNewUser>
        )}

        {groupEmail !== null &&
          groupEmail !== undefined &&
          groupEmail !== "" && (
            <EditGroupUserAccessModal
              show={editUserModal}
              hide={hideEditUserRoleModal}
              getData={getAllRoleSet}
              email={selectedEmail}
              allRoleSet={allRoleSet}
              selectedRoleSet={selectedRoleSet}
              hotelRolesData={hotelRolesData}
              hotelEmail={groupEmail}
              allGroupId={allGroupId}
              selectedLanguage={selectedLanguage}
            ></EditGroupUserAccessModal>
          )}

        <Grid item container sx={{ marginBottom: 3 }} xs={12}>
          <Grid item container>
            <div className="d-flex justify-content-between align-item-center w-100">
              <div className="w-100 d-flex">
                <div className="d-flex align-item-center">
                  <div
                    style={{
                      marginTop: "18px",
                      heigth: "35px !important",
                    }}
                  >
                    <Button
                      onClick={() => openUserRoleModal()}
                      variant="custom-button"
                    >
                      {t("Manage user")}
                    </Button>
                  </div>

                  <TextField
                    onChange={(e) => setsearchTerm(e.target.value)}
                    placeholder={t("Search by email")}
                    label={t("Search by email")}
                    // className="w-75"
                    notched={false}
                    type="search"
                    size="small"
                    style={{
                      borderRadius: "5px",
                      width: "55%",
                      marginBottom: "2rem",
                      marginLeft: "2rem",
                      marginTop: "17px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {HasMp?.size > 0 &&
          allRoleSet
            ?.filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.user_email.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return val;
              }
            })
            ?.map((num, index) => {
              return (
                <Grid key={num.id} item xs={12}>
                  <Card
                    onMouseOver={() => {
                      toggleRaised(num.id);
                    }}
                    onMouseOut={() => {
                      toggleRaised(num.id);
                    }}
                    raised={raised[num.id]}
                    sx={{ marginBottom: 2 }}
                  >
                    <CardHeader
                      title={`${num.user_email}`}
                      action={
                        <Grid item spacing={2} container>
                          <Grid item>
                            <Tooltip title={t("Delete User")}>
                              <IconButton color="primary">
                                <DeleteIcon
                                  onClick={() =>
                                    openDeleteModal(num.user_email)
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title={t("Edit User Role")}>
                              <IconButton color="primary">
                                <Edit
                                  onClick={() => openEditUserRoleModal(num)}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title={t("Remove Access")}>
                              <IconButton
                                color="primary"
                                onClick={
                                  () => openModalHotelModal(num.user_email)
                                  // getHotelforSubuser(num.user_email)
                                }
                              >
                                <HistoryIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      }
                    >
                      <Divider />
                    </CardHeader>
                    <Divider />
                    <CardContent>
                      <Grid item container spacing={2}>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <ListItemText
                            primary={t("User email id")}
                            secondary={`${num.user_email}`}
                          ></ListItemText>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <ListItemText
                            primary={t("Access given")}
                            secondary={
                              num.roles_set === "ALL"
                                ? "SUBADMIN"
                                : getAccessRoles(
                                    num.roles_set,
                                    num.notif_control_set
                                  )
                            }
                          ></ListItemText>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
      </Grid>

      <Modal open={removeHotelsModal} onClose={hideModalHotelModal}>
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <h3>{t("Select the hotel you want to keep access")}</h3>
          <Divider />
          <div className="d-flex flex-column">
            <div className="d-flex">
              <FormControl fullWidth style={{ marginTop: "9px" }}>
                <InputLabel id="demo-simple-select-label">
                  {t("Select hotels")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select hotels"
                  value={selectedSubuserHotels}
                  onChange={handleChange}
                  required="true"
                  multiple
                  // MenuProps={MenuProps}
                  placeholder={t("Select hotels")}
                >
                  {subUserHotels?.map((item) => (
                    <MenuItem value={item.hotel_id}>
                      {getName(item.hotel_id)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <Button
              variant="custom-button"
              className="mt-3 mb-3"
              onClick={hideModalHotelModal}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="custom-button"
              className="mt-3 mb-3"
              onClick={saveDetails}
              disabled={
                subUserHotels.length === 1 ||
                loading ||
                selectedSubuserHotels.length <= 0
              }
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${t("Submit")}`
              )}
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal open={deleteSubUserModal} onClose={hideDeleteModal}>
        <Box
          className="settleDuesModal"
          style={{ boxShadow: "0px 0px 25px 10px #0000001a" }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <h3>{`${t(
            "Do you want to delete user"
          )} - ${selectedSubuserEmail} ?`}</h3>
          <Divider />

          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "1rem" }}
          >
            <Button
              variant="custom-button"
              className="mt-3 mb-3"
              onClick={hideDeleteModal}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="custom-button"
              className="mt-3 mb-3"
              onClick={deleteUser}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                `${t("Yes, delete")}`
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  ) : (
    <MockLoaderTable />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(GroupUserAccess);
