import React, { useState, useEffect, SyntheticEvent } from "react";
import {
  Stack,
  TextField,
  Typography,
  Divider,
  Tabs,
  Tab,
  Box,
  Button,
  ClickAwayListener,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import * as HiIcons from "react-icons/hi";
import { DateRange } from "react-date-range";
import moment from "moment";
import {
  OutlinedButton,
  SecondaryButton,
} from "../../../../../Utils/ButtonHelper";
import { djangoHandleDataRequests } from "../../../../../api";
import { toast } from "material-react-toastify";
import { useParams } from "react-router";
import * as BiIcons from "react-icons/bi";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const styles = {
  position: "absolute",
  top: -100,
  right: 0,
  left: 0,
  zIndex: 100,
  border: "1px solid",
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
};

function DiscountStats({ customClass, startDate, endDate, hotelCurrency }) {
  const { t } = useTranslation();

  // Calendar
  const [value, setValue] = useState(new Date());
  const { shopId } = useParams();

  //get order stats data
  const [discountStatsDatas, setDiscountStatsDatas] = useState([]);

  useEffect(() => {
    getOrderStatsData();
  }, [startDate, endDate]);

  const getOrderStatsData = () => {
    const new_end_date = new Date(new Date(endDate).getTime() + 86400000);
    shopId &&
      djangoHandleDataRequests(
        `pos/getDiscountAndAmount?shop_id=${shopId}&start_date=${startDate}&end_date=${moment(
          new_end_date
        ).format("YYYY-MM-DD")}`
      )
        .then((res) => setDiscountStatsDatas(res[0]))
        .catch((err) => toast.error(`${t("Error fetching the details")}`));
  };

  return (
    <Stack className={customClass}>
      <Stack className="position-relative">
        <Stack className="d-flex justify-content-between" direction="row">
          <Typography variant="h6">{t("Total discount")}</Typography>
        </Stack>
        <Stack className="mt-3">
          {/* <Stack direction="row" className="d-flex justify-content-between">
            <Typography variant="h6" style={{ fontSize: "12.5px" }}>
              Discount Name
            </Typography>
            <Typography variant="h6" style={{ fontSize: "12.5px" }}>
              Discount Amount
            </Typography>
          </Stack> */}
          {discountStatsDatas.discount !== null ? (
            <Stack className="mt-5 position-relative w-100" spacing={2}>
              <Typography style={{ fontSize: "15px" }}>
                {hotelCurrency} {Number(discountStatsDatas.discount).toFixed(2)}
              </Typography>
            </Stack>
          ) : (
            <Stack
              className="position-relative w-100 d-flex justify-content-center"
              spacing={2}
              direction="row"
            >
              <Typography style={{ color: "grey", fontSize: "15px" }}>
                {t("No data found!")}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(DiscountStats);
