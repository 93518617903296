import React, { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  Typography,
  Divider,
  ClickAwayListener,
  Box,
  Button,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import * as HiIcons from "react-icons/hi";
import { DateRange } from "react-date-range";
import moment from "moment";
import {
  OutlinedButton,
  SecondaryButton,
} from "../../../../../Utils/ButtonHelper";
import { toast } from "material-react-toastify";
import { djangoHandleDataRequests } from "../../../../../api";
import { useParams } from "react-router";
// import Highcharts from "highcharts/highstock";
// import HighchartsReact from "highcharts-react-official";
// import PieChart from "highcharts-react-official";
import { useTranslation } from "react-i18next";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 0,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
};

function OrderStats({ customClass, startDate, endDate }) {
  const { t } = useTranslation();

  const [value, setValue] = useState(new Date());
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId } = useParams();

  // Click away listener tin order to toggle calendar state
  // const [open, setOpen] = useState(false);
  // const handleClick = () => setOpen((prev) => !prev);
  // const handleClickAway = () => {
  //   setOpen(false);
  // };

  // Getting the start date as well as the end date to load the data
  // const [dateRange, setDateRange] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  //get order stats data
  const [orderStatsDatas, setorderStatsDatas] = useState([]);

  useEffect(() => {
    getOrderStatsData();
  }, [startDate, endDate]);

  const getOrderStatsData = () => {
    const new_end_date = new Date(new Date(endDate).getTime() + 86400000);
    shopId &&
      djangoHandleDataRequests(
        `pos/getTotalOrderStatistics?shop_id=${shopId}&hotel_id=${hotelId}&start_date=${startDate}&end_date=${moment(
          new_end_date
        ).format("YYYY-MM-DD")}`
      )
        .then((res) => setorderStatsDatas(res))
        // .then((res) => {
        //   const tempPayload = res.map((item) => {
        //     return { name: item?.order_status, y: Number(item?.count) };
        //   });
        //   return setorderStatsDatas(tempPayload);
        // })
        .catch((err) => toast.error(`${t("Error fetching the details")}`));
  };

  //Orders State
  const [successfullOrderCount, setsuccessfullOrderCount] = useState(0);
  const [cancelledOrderCount, setcancelledOrderCount] = useState(0);
  const [complimentaryCount, setcomplimentaryCount] = useState(0);
  const [duesCount, setDuesCount] = useState(0);
  const [inroomCount, setInroomCount] = useState(0);

  useEffect(() => {
    const status = orderStatsDatas?.map((item) => item.order_status);

    if (status.includes("SETTLED")) {
      const successFullOrderCount = orderStatsDatas?.filter(
        (item) => item.order_status === "SETTLED"
      );

      setsuccessfullOrderCount(successFullOrderCount[0].count);
    }

    if (status.includes("CANCELLED")) {
      const cancelOrderCount = orderStatsDatas?.filter(
        (item) => item.order_status === "CANCELLED"
      );
      setcancelledOrderCount(cancelOrderCount[0].count);
    }

    if (status.includes("COMPLIMENTARY")) {
      const complimentaryOrderCount = orderStatsDatas?.filter(
        (item) => item.order_status === "COMPLIMENTARY"
      );
      setcomplimentaryCount(complimentaryOrderCount[0].count);
    }

    if (status.includes("DUE")) {
      const duesCount = orderStatsDatas?.filter(
        (item) => item?.order_status === "DUE"
      );
      setDuesCount(duesCount[0].count);
    }

    if (status.includes("DUE")) {
      const inroomCount = orderStatsDatas?.filter(
        (item) => item?.order_status === "DUE"
      );
      setInroomCount(inroomCount[0].count);
    }
  }, [orderStatsDatas]);

  // const [options, setOptions] = {
  //   chart: {
  //     plotBackgroundColor: null,
  //     plotBorderWidth: null,
  //     plotShadow: false,
  //     type: "pie",
  //   },
  //   title: {
  //     text: "Browser market shares in January, 2018",
  //   },
  //   tooltip: {
  //     pointFormat: "{series.name}: <b>{point.percentage:.1f}</b>",
  //   },
  //   accessibility: {
  //     point: {
  //       valueSuffix: "%",
  //     },
  //   },
  //   plotOptions: {
  //     pie: {
  //       allowPointSelect: true,
  //       cursor: "pointer",
  //       dataLabels: {
  //         enabled: true,
  //         format: "<b>{point.name}</b>: {point.percentage:.1f}",
  //       },
  //     },
  //   },
  //   series: [
  //     {
  //       name: "Order stats",
  //       colorByPoint: true,
  //       data: orderStatsDatas.length>0 && orderStatsDatas,
  //     },
  //   ],
  // };

  return (
    <Stack className={customClass}>
      <Stack className="position-relative">
        <Stack className="d-flex justify-content-between pt-4" direction="row">
          <Typography variant="h6">{t("Order statistics")}</Typography>
          {/* <Stack direction="row" spacing={1}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box sx={{ position: "relative" }}>
                <button
                  onClick={handleClick}
                  className="border border-3 border-dark rounded bg-light p-2"
                >
                  {moment(dateRange[0].startDate).format("DD-MM-YY")} {" to "}
                  {moment(dateRange[0].endDate).format("DD-MM-YY")}
                </button>
                {open ? (
                  <Box sx={styles}>
                    <Stack spacing={3}>
                      <DateRange
                        editableDateInputs={true}
                        onChange={(item) => setDateRange([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                      />
                      <Stack
                        className="position-relative w-100 d-flex justify-content-between"
                        direction="row"
                      >
                        <SecondaryButton
                          text="Cancel"
                          onClick={() => setOpen(false)}
                        />
                        <Button
                          variant="custom-button"
                          onClick={() => handleClickAway()}
                        >
                          Apply
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                ) : null}
              </Box>
            </ClickAwayListener>
            <Typography className="pt-2" style={{ cursor: "pointer" }}>
              <HiIcons.HiOutlineRefresh size="18px" />
            </Typography>
          </Stack> */}
        </Stack>
        {/* <Stack> */}
        {/* <PieChart highcharts={Highcharts} options={options} /> */}
        {/* </Stack> */}
        <Stack className="mt-15 d-flex justify-content-center" spacing={7}>
          <Stack
            direction="row"
            className="d-flex position-relative w-125 position-relative"
            style={{ width: "100%" }}
            spacing={5}
          >
            <Stack
              direction="row"
              spacing={1}
              className="position-relative"
              style={{ width: "50%" }}
            >
              <Divider
                className="border rounded"
                orientation="vertical"
                style={{ width: "7.5px", backgroundColor: "green" }}
                flexItem
              />
              <Stack spacing={1}>
                <Typography variant="h5">{successfullOrderCount}</Typography>
                <Typography variant="h7">{t("Successful orders")}</Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              className="position-relative"
              style={{ width: "50%" }}
            >
              <Divider
                className="border rounded"
                orientation="vertical"
                style={{ width: "7.5px", backgroundColor: "red" }}
                flexItem
              />
              <Stack spacing={1}>
                <Typography variant="h5">{cancelledOrderCount}</Typography>
                <Typography variant="h7">{t("Cancelled orders")}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            className="d-flex position-relative"
            style={{ width: "100%" }}
            spacing={5}
          >
            <Stack
              direction="row"
              spacing={1}
              className="position-relative"
              style={{ width: "50%" }}
            >
              <Divider
                className="border rounded"
                orientation="vertical"
                style={{ width: "7.5px", backgroundColor: "green" }}
                flexItem
              />
              <Stack spacing={1}>
                <Typography variant="h5">{complimentaryCount}</Typography>
                <Typography variant="h7">
                  {t("Complimentary orders")}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              className="position-relative"
              style={{ width: "50%" }}
            >
              <Divider
                className="border rounded"
                orientation="vertical"
                style={{ width: "7.5px", backgroundColor: "red" }}
                flexItem
              />
              <Stack spacing={1}>
                <Typography variant="h5">{duesCount}</Typography>
                <Typography variant="h7">{t("Dues orders")}</Typography>
              </Stack>
            </Stack>

            {/* <Stack direction="row" spacing={1}>
              <Divider
                className="border rounded"
                orientation="vertical"
                style={{ width: "7.5px", backgroundColor: "red" }}
                flexItem
              /> */}
            {/* <Stack spacing={1}>
                <Typography variant="h5">
                  0{" "}
                  <Typography style={{ fontSize: "12px" }} variant="h8">
                    min
                  </Typography>
                </Typography>
                <Typography variant="h7">Table turn around time</Typography>
               </Stack> */}
            {/* </Stack> */}
          </Stack>
          <Stack
            direction="row"
            className="d-flex position-relative"
            style={{ width: "100%" }}
            spacing={5}
          >
            <Stack
              direction="row"
              spacing={1}
              className="position-relative"
              style={{ width: "50%" }}
            >
              <Divider
                className="border rounded"
                orientation="vertical"
                style={{ width: "7.5px", backgroundColor: "green" }}
                flexItem
              />
              <Stack spacing={1}>
                <Typography variant="h5">{inroomCount}</Typography>
                <Typography variant="h7">{t("In-room orders")}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default OrderStats;
