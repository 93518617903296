import React, { useEffect, useRef, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import i18n from "../../modules/Auth/pages/i18n";

export default function StripeCheckoutForm({
  clientSecret,
  performPaymentButton,
  setPerformPaymentButton,
  paymentIntentId,
  bookingId,
  successPayment,
  setButtonLoader,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const stripe = useStripe();
  const elements = useElements();
  const stripeButton = useRef();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    performPaymentButton && handleSubmit();
  }, [performPaymentButton]);
  const handleSubmit = async (e) => {
    // e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setButtonLoader(true);

    const data = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (data.error) {
      setMessage(data?.error?.message);
      setButtonLoader(false);
    } else {
      setMessage("");
      //   setButtonLoader(false);

      switch (data?.paymentIntent?.status) {
        case "succeeded":
          successPayment(data?.paymentIntent?.id, bookingId);
          break;

        case "processing":
          //   setMessage("Payment processing. We'll update you when payment is received.");
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          //   setMessage('Payment failed. Please try another payment method.');
          break;

        default:
          //   setMessage('Something went wrong.');
          break;
      }
    }
    setPerformPaymentButton(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div className="border-1 mt-3 p-2" style={{ borderRadius: "10px" }}>
      <PaymentElement options={paymentElementOptions} />
      <button
        className="btn d-none"
        ref={stripeButton}
        onClick={handleSubmit}
        disabled={!stripe}
      >
        {t("Submit")}
      </button>
      {/* Show error message to your customers */}
      {message && (
        <p
          className="mb-0"
          style={{
            color: "#f44336",
            fontSize: "1.3rem",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
}
