import React, { useState, useEffect } from "react";
import StockWrapper from "./StockWrapper";
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Modal,
  CircularProgress,
} from "@mui/material";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  handleSpringDataRequest,
  handleSpringDataMutationRequest,
} from "../../api/index";
import Drawer from "@mui/material/Drawer";
import * as AiIcons from "react-icons/ai";
import moment from "moment";
import { toast } from "material-react-toastify";
import MockLoaderTable from "../Folios/ViewManagePayments/MockLoaderTable";
import "material-react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  paddingTop: "10px",
  paddingBottom: "10px",
};

function KitchenConfig() {
  const { t } = useTranslation();

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { SearchBar } = Search;
  // Check if the operation we are performing is EDIT or DELETE
  const [actionType, setActionType] = useState("create");
  const [hoursMap, sethoursMap] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ]);
  const columns = [
    {
      dataField: "id",
      text: `${t("ID")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      hidden: true,
    },
    {
      dataField: "name",
      text: `${t("Outlet Name")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "type",
      text: `${t("Type")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      dataField: "closing_stock_update_hour",
      text: `${t("Closing stock time")}`,
      sort: true,
      headerStyle: {
        fontSize: "10px",
      },
      formatter: (cell, row) => {
        return `${cell < 12 ? `${cell}:00 AM` : `${cell}:00 PM`}`;
      },
    },
    {
      dataField: "hotel_id",
      text: `${t("HotelId")}`,
      sort: true,
      hidden: true,
      headerStyle: {
        fontSize: "10px",
      },
    },
    {
      isDummyField: true,
      text: `${t("Actions")}`,
      sort: true,
      headerStyle: {
        fontSize: "12px",
        width: "115px",
      },
      formatter: (cell, row) => (
        <Stack direction="row" spacing={2}>
          <Tooltip title={`${t("Edit item")}`}>
            <Typography
              className="editIcon"
              onClick={() => {
                setActionType("update");
                setModifiedClosingStockPayload(row);
                toggleDrawer(true);
              }}
            >
              <AiIcons.AiFillEdit size="18px" />
            </Typography>
          </Tooltip>
          {/* <Tooltip title="Delete item">
            <Typography
              className="deleteIcon"
              onClick={() => {
                setModifiedClosingStockPayload(row);
                handleOpenConfirmDeleteModal();
              }}
            >
              <AiIcons.AiFillDelete size="18px" />
            </Typography>
          </Tooltip> */}
        </Stack>
      ),
      editable: false,
    },
  ];

  // Get the closing stock
  const [closingStockData, setClosingStockData] = useState([]);
  function handleGetAllClosingStock() {
    handleSpringDataRequest("core/api/v1/kitchen/get-all")
      .then((res) => setClosingStockData(res))
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    handleGetAllClosingStock();
  }, []);

  // Map of units of measurement
  const [units, setUnits] = useState(["single", "multiple"]);

  //Selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  // row selection for operations
  // selecting the rows
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows((selectedRows) => [...selectedRows, row.id]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x !== row.id));
    }
  };

  // selecting all rows at once
  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedRows,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  // Modify update rm items
  const [modifyUpdateItems, setModifyUpdateItems] = useState(false);
  const toggleDrawer = (action) => {
    setModifyUpdateItems(action);
  };
  const [updateHour, setupdateHour] = useState(24);
  //   Payload creation to add / update the kitchen material
  const [closingStockPayload, setClosingStockPayload] = useState({
    id: -1,
    hotel_id: hotelId,
    name: "",
    type: "",
    closing_stock_update_hour: "",
  });

  const [rawMaterialList, setRawMaterialList] = useState([]);

  // Defining the modified payload state for adding and updating
  const [modifiedClosingStockPayload, setModifiedClosingStockPayload] =
    useState(null);
  //   Setting the modified payload to the initial state
  function resetClosingStockPayload() {
    setModifiedClosingStockPayload(closingStockPayload);
  }
  useEffect(() => {
    resetClosingStockPayload();
  }, []);

  //   Function to add or update a field into the kitchen payload
  function handleChangeRMS(e) {
    const { name, value } = e.target;
    setModifiedClosingStockPayload({
      ...modifiedClosingStockPayload,
      [`${name}`]: value,
    });
  }

  // loader
  const [loading, setLoading] = useState(false);
  // Creating and updating the payload
  function handleCreateUpdatePayload() {
    if (modifiedClosingStockPayload?.closing_stock_update_hour === "") {
      toast.error(`${t("Please select closing stock update hour")}`);
    } else if (modifiedClosingStockPayload?.name === "") {
      toast.error(`${t("Please enter shop outlet name")}`);
    } else if (modifiedClosingStockPayload?.type === "") {
      toast.error(`${t("Please select type")}`);
    } else {
      setLoading(true);
      if (actionType === "create") {
        handleSpringDataMutationRequest(
          "POST",
          "core/api/v1/kitchen/create",
          modifiedClosingStockPayload
        )
          .catch((err) => toast.error(err))
          .then(() => setLoading(false))
          .then(() => toggleDrawer(false))
          .then(() => handleGetAllClosingStock())
          .then(() => resetClosingStockPayload())
          .then(() => window.location.reload());
      } else {
        handleSpringDataMutationRequest(
          "PUT",
          `core/api/v1/kitchen/update?id=${modifiedClosingStockPayload.id}`,
          modifiedClosingStockPayload
        )
          .catch((err) => toast.error(err))
          .then(() => setLoading(false))
          .then(() => toggleDrawer(false))
          .then(() => handleGetAllClosingStock())
          .then(() => resetClosingStockPayload())
          .then(() => window.location.reload());
      }
    }
  }

  // Deleting the menu item
  // Delete confirmation modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  function handleOpenConfirmDeleteModal() {
    setConfirmDeleteModal(true);
  }
  function handleCloseConfirmDeleteModal() {
    setConfirmDeleteModal(false);
  }
  function handleDeleteMenuItem() {
    setLoading(true);
    handleSpringDataMutationRequest(
      "DELETE",
      `core/api/v1/kitchen/delete?id=${modifiedClosingStockPayload.id}`
    )
      .then((res) => toast.success(res))
      .catch((err) => toast.error(err))
      .then(() => handleGetAllClosingStock())
      .then(() => setLoading(false))
      .then(() => handleCloseConfirmDeleteModal());
  }

  const list = () => (
    <Box role="presentation" className="addUpdateModal">
      <Stack className="headerWrapper">
        <Typography className="heading">
          <strong>{t("Add outlet configuration")}</strong>
        </Typography>
        <Typography
          className="close"
          onClick={() => {
            toggleDrawer(false);
            resetClosingStockPayload();
          }}
        >
          <AiIcons.AiOutlineClose size="20px" />
        </Typography>
      </Stack>
      <Divider />
      <p
        style={{
          color: "orange",
          marginTop: "1rem",
          marginBottom: "1rem",
          marginLeft: "1rem",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        {t("Once an outlet is created it cannot be deleted.")}
      </p>
      <Stack className="fields">
        <p className="text-muted">
          {t(
            "To ensure easy configuration with a specific shop and effective tracking of raw material data, it is recommended to use the same name for the outlet as that of the shop."
          )}
        </p>

        <TextField
          id="outlined-name"
          label={t("Shop Outlet Name")}
          name="name"
          value={modifiedClosingStockPayload?.name}
          onChange={handleChangeRMS}
          className="field"
          sx={{ width: "29%" }}
          //   disabled={actionType === "update" && true}
        />
        <Box sx={{ width: "29%" }} className="field">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t("Type")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={modifiedClosingStockPayload?.type}
              label={t("Type")}
              name="type"
              onChange={handleChangeRMS}
            >
              {units?.map((unit, index) => (
                <MenuItem value={unit} key={index}>
                  {unit.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: "29%" }} className="field">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("Closing stock time")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={modifiedClosingStockPayload?.closing_stock_update_hour}
              label={t("Closing stock time")}
              name="closing_stock_update_hour"
              onChange={handleChangeRMS}
            >
              {hoursMap?.map((unit, index) => (
                <MenuItem value={unit} key={index}>
                  {unit < 12 ? `${unit}:00 AM` : `${unit}:00 PM`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Stack className="ctas" direction="row">
        <Button
          className="cancelBtn"
          onClick={() => {
            toggleDrawer(false);
            resetClosingStockPayload();
          }}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="custom-button"
          className="successBtn"
          onClick={!loading && handleCreateUpdatePayload}
          disabled={loading & true}
        >
          {!loading ? `${t("Confirm")}` : <CircularProgress size="20px" />}
        </Button>
      </Stack>
    </Box>
  );
  return (
    <>
      <StockWrapper
        backBtn={false}
        hideDrawer={true}
        stockTypeName={t("Outlet configuration")}
        contentArea={
          <Stack className="position-relative mt-8">
            {closingStockData ? (
              <ToolkitProvider
                keyField="id"
                data={closingStockData}
                columns={columns}
                search
              >
                {(props) => (
                  <>
                    <Stack direction="row" spacing={2}>
                      <Stack className="searchBarWrapper">
                        <SearchBar
                          {...props.searchProps}
                          className="hkSearchBar"
                          style={{ height: "33.5px" }}
                        />
                      </Stack>
                      <Stack>
                        <Button
                          variant="custom-button"
                          onClick={() => {
                            setActionType("create");
                            toggleDrawer(true);
                          }}
                        >
                          {t("Add outlet configuration")}
                        </Button>
                      </Stack>
                    </Stack>
                    <BootstrapTable
                      {...props.baseProps}
                      tabIndexCell
                      headerWrapperClasses="foo"
                      bodyClasses="tableBody"
                      bordered={false}
                      //   selectRow={selectRow}
                      pagination={paginationFactory()}
                      limitTags={3}
                    />
                  </>
                )}
              </ToolkitProvider>
            ) : (
              <MockLoaderTable />
            )}
          </Stack>
        }
      />
      <Drawer
        anchor={"right"}
        open={modifyUpdateItems}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        {list()}
      </Drawer>
      {/* Modal confirming the deletion of the menu item */}
      <Modal
        open={confirmDeleteModal}
        onClose={handleCloseConfirmDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="deleteModalWrapper">
          <Stack direction="row" className="modalHeaderWrapper">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("Delete")} {modifiedClosingStockPayload?.rm_name}
            </Typography>
            <Typography id="modal-modal-description">
              <AiIcons.AiOutlineClose />
            </Typography>
          </Stack>
          <Divider />
          <Stack className="confirm">
            {t("Are you sure you want to delete")}{" "}
            {modifiedClosingStockPayload?.rm_name}.{" "}
            {t("Once done, this cannot be rolled back.")}
          </Stack>
          <Stack direction="row" className="btns">
            <Button className="cancelBtn">{t("Cancel")}</Button>
            <Button
              className="successBtn"
              variant="custom-button"
              onClick={!loading && handleDeleteMenuItem}
              disabled={loading && true}
            >
              {!loading ? `${t("Confirm")}` : <CircularProgress size="25px" />}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default KitchenConfig;
