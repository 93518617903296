import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import moment from "moment";
import { connect } from "react-redux";
import {
  editBooking,
  getCheckinCheckoutSlots,
  getRoomBookings,
  perfomBooking,
} from "../../CalendarPage/api/ReservationApi";
import { getAllGuestUsers } from "../../Promocodes/apis/PromocodeApi";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";
import axios from "axios";

function AddRoomToBooking({
  onCancel,
  bookingId,
  invoiceData,
  hotelId,
  reloadGrid,
  reservationId,
  isGrid,
  data,
  updateInvoice,
  updateSummaryData,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [checkinDate, setcheckinDate] = useState(
    moment(invoiceData?.invoice?.checkin)
  );
  const [checkoutDate, setcheckoutDate] = useState(
    moment(invoiceData?.invoice?.checkout)
  );

  const handleChange = (newValue) => {
    setcheckinDate(newValue);
    setcheckoutDate(new Date(new Date(newValue).getTime() + 86400000));
  };

  const handleCheckoutChange = (newValue) => {
    setcheckoutDate(newValue);
  };
  const [checkinSlots, setCheckinSlots] = useState([]);
  const [checkoutSlots, setCheckoutSlots] = useState([]);
  const [adult, setadult] = useState(1);
  const [maxAdult, setmaxAdult] = useState(1);
  const [children, setchildren] = useState(0);
  const [maxChidren, setmaxChidren] = useState(0);
  const [maxOccupancy, setmaxOccupency] = useState(0);

  const [priceLoader, setpriceLoader] = useState(false);

  // upgrade price loader
  const [upgradeLoader, setUpgradeLoader] = useState(false);
  const [roomType, setRoomType] = useState(
    roomTypes ? Object.keys(roomTypes)[0] : ""
  );

  // rate types and the rate
  const [rateType, setRateType] = useState("TOTAL_PRETAX");
  const [newRate, setNewRate] = useState(null);

  // handling the room upgrade cost
  const [upgradeCost, setUpgradeCost] = useState(null);

  // Total price after tax
  const [totalWithTax, setTotalWithTax] = useState(null);

  // price per change
  const [pricePerNight, setPricePerNight] = useState(0);

  // getting all the room types
  const [roomTypes, setRoomTypes] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState("");

  const [selectedCheckinSlot, setselectedCheckinSlot] = useState("");
  const [selectedCheckoutSLot, setselectedCheckoutSLot] = useState("");

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");

  const [allRooms, setAllRooms] = useState(null);

  const [allRatePlans, setallRatePlans] = useState([]);
  const [ratePlanLoader, setratePlanLoader] = useState(false);
  const [selectedRatePlan, setselectedRatePlan] = useState(
    invoiceData?.invoice?.rate_plan_id
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [groupBookingData, setgroupBookingData] = useState({});
  const [avaiableRoom, setavaiableRoom] = useState([]);
  useEffect(async () => {
    const payload = {
      hotelId: hotelId,
      checkin: moment(invoiceData?.invoice?.checkin).format(
        "DD-MM-YYYY HH:MM:SS"
      ),
      checkout: moment(invoiceData?.invoice?.checkout).format(
        "DD-MM-YYYY HH:MM:SS"
      ),
      isAdmin: true,
      ctaId: null,
    };
    const data = await getRoomBookings(payload);
    setgroupBookingData(data.roomTypeMap);
  }, []);

  useEffect(() => {
    const arr = [];
    if (Object.entries(groupBookingData).length > 0) {
      const data = Object.entries(groupBookingData).map(([key, value]) => {
        if (value["hidden"] === "no") arr.push(value);
      });

      setavaiableRoom(arr);
    }
  }, [roomType, groupBookingData]);

  function handleGetUnblockedRooms() {
    handleSpringDataRequest(
      `core/api/v1/room/non-blocked-rooms/?resId=${
        Object.values(reservationId)[0]
      }&roomTypeId=${roomType === "all" ? "" : roomType}`
    )
      .then()
      .catch((error) => console.log(`${t("Error in fetching the room ids.")}`));
  }

  useEffect(() => {
    roomType && handleGetUnblockedRooms();
  }, [roomType]);

  useEffect(() => {
    getCheckinSlotsANdCheckoutSLots();
  }, []);

  const getCheckinSlotsANdCheckoutSLots = async () => {
    const payload = {
      checkin: moment(invoiceData?.invoice?.checkin).format(
        "DD-MM-YYYY HH:MM:SS"
      ),
      checkout: moment(invoiceData?.invoice?.checkout).format(
        "DD-MM-YYYY HH:MM:SS"
      ),
    };
    const resp = await getCheckinCheckoutSlots(payload);
    setCheckinSlots(resp?.checkinSlots);
    setCheckoutSlots(resp?.checkoutSlots);
    setselectedCheckinSlot(
      moment(invoiceData?.invoice?.checkin)
        .format("DD-MM-YYYY HH:MM:SS")
        .split(" ")[1]
        .split(":")[0]
    );
    setselectedCheckoutSLot(
      moment(invoiceData?.invoice?.checkout)
        .format("DD-MM-YYYY HH:MM:SS")
        .split(" ")[1]
        .split(":")[0]
    );
  };

  function handleGetUpgradeCost() {
    setpriceLoader(true);
    invoiceData &&
      handleSpringDataMutationRequest(
        "POST",
        "core/api/v1/reservation/getQuickBookPricePayload",
        {
          checkin: moment(checkinDate).format(
            `DD-MM-YYYY ${selectedCheckinSlot}:00:00`
          ),
          checkout: moment(checkoutDate).format(
            `DD-MM-YYYY ${selectedCheckoutSLot}:00:00`
          ),
          hotelId: hotelId,
          numAdults: adult,
          numChildren: children,
          newRate: newRate,
          roomTypeId: roomType,
          ratePlanId: selectedRatePlan,
          ctaId: null,
          newRateType: rateType,
        }
      )
        .then((res) => setUpgradeCost(res))
        .then((res) => setpriceLoader(false))
        .catch((err) => console.log(`${t("Error getting the prices.")}`));
  }

  useEffect(() => {
    if (roomType) {
      setTimeout(() => {
        handleGetUpgradeCost();
      }, 1000);
    }
  }, [
    rateType,
    newRate,
    checkinDate,
    checkoutDate,
    selectedRatePlan,
    roomType,
    selectedCheckinSlot,
    selectedCheckoutSLot,
    adult,
    children,
  ]);

  useEffect(() => {
    if (upgradeCost) {
      setPricePerNight(upgradeCost.perNightPrice.toFixed(2));
      setTotalBeforeTax(upgradeCost.priceBeforeTax.toFixed(2));
      setTotalTax(upgradeCost.taxValue.toFixed(2));
      setTotalWithTax(upgradeCost.priceAfterTax.toFixed(2));
    }
  }, [upgradeCost]);

  const handlePricePerNightChange = (event) => {
    setPricePerNight(event.target.value);
    setRateType("PER_NIGHT_PRETAX");
    setNewRate(event.target.value);
  };

  // Total price before tax
  const [totalBeforeTax, setTotalBeforeTax] = useState(null);
  const handleTotalBeforeTaxChange = (event) => {
    setTotalBeforeTax(event.target.value);
    setRateType("TOTAL_PRETAX");
    setNewRate(event.target.value);
  };

  // Total tax value
  const [totalTax, setTotalTax] = useState(null);
  const handleTotalTaxChange = (event) => {
    setTotalTax(event.target.value);
  };

  const handleTotalWithTaxChange = (event) => {
    setTotalWithTax(event.target.value);
    setRateType("TOTAL_WITHTAX");
    setNewRate(event.target.value);
  };

  const getAllRatePlans = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/getAllRatePlansToChangePrice?roomTypeId=${roomType}`
    ).then((res) => setallRatePlans(Object.entries(res)));
    // .catch((e) => toast.error("Something went wrong"));
  };

  useEffect(() => {
    getAllRatePlans();
  }, [roomType]);

  useEffect(() => {
    checkinSlots?.length > 0 && setselectedCheckinSlot(checkinSlots[0]);
    checkoutSlots?.length > 0 && setselectedCheckoutSLot(checkoutSlots[0]);
  }, [checkinSlots, checkoutSlots]);

  const handleEditReservation = async () => {
    if (name.trim().length <= 0) {
      toast.error(`${t("Please enter characters in name")}`);
      return;
    }
    if (
      Number(adult) <= maxAdult &&
      Number(adult) > 0 &&
      Number(children) <= maxChidren
    ) {
      if (name !== "") {
        setUpgradeLoader(true);
        let roomandrate = {};
        roomandrate[`${roomType}-${selectedRatePlan}`] = 1;
        let amount = {};
        amount[`${roomType}-${selectedRatePlan}`] = [
          {
            adults: adult,
            children: children,
            amount: totalBeforeTax,
          },
        ];

        let requriedRoom = {};
        requriedRoom[`${roomType}-${selectedRatePlan}`] = [`${selectedRoom}`];
        let emails =
          email === ""
            ? name.replace(/\s+/g, ".").toLowerCase() +
              hotelId +
              Math.round(Date.now() / 1000) +
              "@example.com"
            : email;
        // const payload = {
        //   userBookingInfo: {
        //     id: "",
        //     userEmail: emails,
        //     checkin: moment(checkinDate).format("DD-MM-YYYY HH:00:00"),
        //     checkout: moment(checkoutDate).format("DD-MM-YYYY HH:00:00"),
        //     actualCheckin: moment(checkinDate).format("DD-MM-YYYY HH:00:00"),
        //     actualCheckout: moment(checkoutDate).format("DD-MM-YYYY HH:00:00"),
        //     bookingAmount: totalWithTax,
        //     bookingDiscount: 0,
        //     bookingSavings: 0,
        //     hotelId: hotelId,
        //     ratePlanId: selectedRatePlan,
        //     bookingSource: "STAYFLEXI_HD",
        //     refund: 0,
        //     taxPercentage: 0,
        //     externalPayment: 0,
        //     externalPaymentCard: 0,
        //     username: name,
        //     userPhone: phone,
        //     specialRequests: "",
        //     appliedPromocode: "",
        //     promoAmount: 0,
        //   },
        //   customer: {
        //     userName: name,
        //     phoneNumber: phone,
        //     email: emails,
        //     zipCode: "",
        //     address: "",
        //     city: "",
        //     state: null,
        //     country: "",
        //     address1: "",
        //   },
        //   newReservationInfo: {
        //     booking_id: "",
        //     hotel_id: hotelId,
        //     timestamps: null,
        //     rooms_requested: roomandrate,
        //     checkin: moment(checkinDate).format("DD-MM-YYYY HH:00:00"),
        //     checkout: moment(checkoutDate).format("DD-MM-YYYY HH:00:00"),
        //     type: "Guest",
        //     all_reservation_room_info: amount,
        //     message_source: "STAYFLEXI_HD",
        //     message_type: null,
        //   },
        //   is_external: false,
        //   is_enquiry: false,
        //   expiry_time: "",
        //   expiry_type: "HOURS",
        //   duration_type: "BEFORE",
        //   addons: [],
        // };

        const payload = {
          userBookingInfo: {
            id: "",
            userEmail: emails,
            checkin: moment(checkinDate).format("DD-MM-YYYY HH:00:00"),
            checkout: moment(checkoutDate).format("DD-MM-YYYY HH:00:00"),
            actualCheckin: moment(checkinDate).format("DD-MM-YYYY HH:00:00"),
            actualCheckout: moment(checkoutDate).format("DD-MM-YYYY HH:00:00"),
            bookingAmount: totalWithTax,
            bookingDiscount: 0,
            bookingSavings: 0,
            hotelId: hotelId,
            ratePlanId: selectedRatePlan,
            bookingSource: "WALK-IN",
            refund: 0,
            taxPercentage: 0,
            externalPayment: 0,
            externalPaymentCard: 0,
            username: name,
            userPhone: phone,
            specialRequests: "",
            appliedPromocode: "",
            segment: "DIRECT",
            promoAmount: 0,
          },
          userId: {
            frontData: null,
            frontType: null,
            backData: null,
            backType: null,
          },
          admin_reservation_info: {
            newReservationInfo: {
              booking_id: "",
              hotel_id: hotelId,
              timestamps: null,
              rooms_requested: roomandrate,
              checkin: moment(checkinDate).format("DD-MM-YYYY HH:00:00"),
              checkout: moment(checkoutDate).format("DD-MM-YYYY HH:00:00"),
              type: "Guest",
              all_reservation_room_info: amount,
              message_source: "WALK-IN",
              message_type: null,
            },
            physicalRoomIdsMap: requriedRoom,
          },
          is_external: false,
          is_enquiry: false,
          expiry_time: "",
          expiry_type: "HOURS",
          duration_type: "AFTER",
          addons: [],
          userDetails: {
            userName: name,
            phoneNumber: phone,
            email: emails,
            zipCode: "",
            address: "",
            city: "",
            state: null,
            country: null,
            address1: "",
            govtIdType: "",
            govtIdValue: "",
            dateOfBirth: "",
            gender: "Male",
            nationality: "",
            occupation: "",
          },
          miscDetails: {
            companyName: "",
            programsAttended: "No",
            purposeOfVisit: "-",
            eventType: "",
          },
          otherGuestDetails: [],
          exempt_tax_names: [],
          taxId: "",
        };

        axios
          .post(
            `${process.env.REACT_APP_BG_API_URL}core/api/v1/reservation/performBooking/adminAddNewRooms/${bookingId}`,
            payload
          )
          .then((res) => {
            toast.success(
              `${t("Successfully added new room to")} ${bookingId}`
            );
            invalidateNightAuditBooking(bookingId);
            if (res?.reservationId) {
              let resId = res?.reservationId;
              const addGuestPayoad = {
                bookingId: bookingId,
                name: name,
                contact: phone,
                email: emails,
                address1: "",
                address2: "",
                city: "",
                state: "",
                country: "",
                zipcode: "",
                govtIdType: "",
                govtIdValue: "",
                roomId: "",
                hotelId: hotelId,
                reservationId: resId,
                roomId: selectedRoom,
                oldEmail: emails,
              };

              handleSpringDataMutationRequest(
                "POST",
                `core/api/v1/addGuestInfo`,
                addGuestPayoad
              )
                .then((res) => {
                  getAllGuestUsers(bookingId);
                })
                .catch((err) => {
                  getAllGuestUsers(bookingId);
                });
            }
            updateInvoice();
            updateSummaryData();
            setUpgradeLoader(false);
          })
          .catch((err) => {
            toast.error(`${t("Something went wrong")}`);
            setUpgradeLoader(false);
          });
      } else {
        toast.error(`${t("Please enter name")}`);
      }
    } else {
      toast.error(`${t("Please enter correct adult and children")}`);
    }
  };

  // This is an unnecessary API call , as the state is not being used anywhere else
  function getBookingInfo() {
    djangoHandleDataRequests(
      `invoice/getBookingInfoById?bookingID=${bookingId}`
    )
      .then((res) => setBookingInfo(res))
      .catch((err) => toast.error(`${t("Error fetching the data.")}`));
  }

  useEffect(() => {
    getBookingInfo();
  }, []);

  // Handling getting all the room types
  function handleGetAllRoomTypes() {
    handleSpringDataRequest("core/api/v1/reservation/getAllNonHiddenRoomTypes")
      .then((res) => {
        setRoomTypes(res);
        setRoomType();
        //   Object.keys(res).find(
        //     (key) =>
        //       res[key] ===
        //       invoiceData?.invoice.reservation_room_info_list[0].roomTypeName
        //   )
      })
      .catch((err) => toast.error(`${t("Error getting all the room types.")}`));
  }

  useEffect(() => {
    handleGetAllRoomTypes();
  }, []);

  // choosing room type

  const handleSelectRoomTypeChange = (event) => {
    setRoomType(event.target.value);
  };

  useEffect(() => {
    roomTypes && setRoomType(Object.keys(roomTypes)[0]);
  }, [roomTypes]);

  useEffect(() => {
    allRooms && setSelectedRoom(allRooms[0]);
  }, [allRooms]);

  const handleSelectRoomIdChange = (event) => {
    setSelectedRoom(event.target.value);
    handleGetUpgradeCost();
  };
  const [showRooms, setshowRooms] = useState(false);
  useEffect(() => {
    if (avaiableRoom.length > 0) {
      const data = avaiableRoom?.filter((e) => e.roomTypeId === roomType);
      setmaxOccupency(data[0]?.maxPossible);
      setmaxChidren(data[0]?.maxChildren);
      setmaxAdult(data[0]?.maxOccupancy);
      setAllRooms(
        data[0]?.combos[0]?.offlineRoomIds.concat(
          data[0]?.combos[0]?.onlineRoomIds
        )
      );
      setSelectedRoom(
        data[0]?.combos[0]?.offlineRoomIds.concat(
          data[0]?.combos[0]?.onlineRoomIds
        )[0]
      );
      if (data[0]?.combos[0].availableRooms > 0) {
        setshowRooms(true);
      } else {
        setshowRooms(false);
      }
    }
  }, [roomType, avaiableRoom]);

  const invalidateNightAuditBooking = (id) => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/invalidate-night-audit-tracker-booking?bookingId=${id}`
    )
      .then((res) => console.log())
      .catch((err) => console.error(err));
  };

  return (
    <Stack m={3}>
      <Stack spacing={4}>
        <Stack direction="row" spacing={4}>
          <TextField
            label={t("Name")}
            className="input-lg w-100"
            placeholder={t("Enter  name here")}
            onChange={(e) => setname(e.target.value)}
            value={name}
            name="name"
            id="name"
          />
          <TextField
            label={t("Phone")}
            className="input-lg w-100"
            placeholder={t("Enter phone here")}
            onChange={(e) => setphone(e.target.value)}
            value={phone}
            name="phone"
            id="phone"
          />
        </Stack>
        <Stack direction="row" spacing={4}>
          <TextField
            label={t("Email")}
            className="input-lg w-100 "
            placeholder={t("Enter email here")}
            onChange={(e) => setemail(e.target.value)}
            value={email}
            name="email"
            id="email"
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("Rate plan")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e) => setselectedRatePlan(e.target.value)}
              className="mr-4"
              label={t("Rate plan")}
              value={selectedRatePlan}
            >
              {allRatePlans?.map((item, index) => (
                <MenuItem value={item[0]}>{item[1].ratePlanName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={4}>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Checkin"
              inputFormat="MM/dd/yyyy"
              value={checkinDate}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} size="small" />}
              shouldDisableDate={() => false}
            />
          </LocalizationProvider> */}
          <TextField
            label={t("Checkin")}
            className="input-lg w-100 "
            placeholder="Enter email here"
            disabled={true}
            value={moment(checkinDate, "DD-MM-YYYY hh:mm:ss").format(
              "Do MMMM, YYYY"
            )}
            name="email"
            id="email"
          />
          <TextField
            label={t("Checkin slot")}
            className="input-lg w-100 "
            placeholder="Enter email here"
            disabled={true}
            value={selectedCheckinSlot}
            name="email"
            id="email"
          />
          {/* <FormControl style={{ width: "10rem" }}>
            <InputLabel size="small">Time</InputLabel>
            <Select
              value={selectedCheckinSlot}
              onChange={(e) => setselectedCheckinSlot(e.target.value)}
              required="true"
              MenuProps={MenuProps}
              label="Time"
              size="small"
            >
              {checkinSlots?.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Checkout"
              inputFormat="MM/dd/yyyy"
              value={checkoutDate}
              onChange={handleCheckoutChange}
              renderInput={(params) => <TextField {...params} size="small" />}
              shouldDisableDate={() => false}
            />
          </LocalizationProvider> */}
          <TextField
            label={t("Checkout")}
            className="input-lg w-100 "
            placeholder="Enter email here"
            disabled={true}
            value={moment(checkoutDate, "DD-MM-YYYY hh:mm:ss").format(
              "Do MMMM, YYYY"
            )}
            name="email"
            id="email"
          />
          <TextField
            label={t("Checkout slot")}
            className="input-lg w-100 "
            placeholder="Enter email here"
            disabled={true}
            value={selectedCheckoutSLot}
            name="email"
            id="email"
          />
          {/* <FormControl style={{ width: "10rem" }}>
            <InputLabel size="small">Time</InputLabel>
            <Select
              value={selectedCheckoutSLot}
              onChange={(e) => setselectedCheckoutSLot(e.target.value)}
              required="true"
              MenuProps={MenuProps}
              label="Time"
              size="small"
            >
              {checkoutSlots?.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Stack>
        {/* <Stack direction="row" spacing={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Checkout"
              inputFormat="MM/dd/yyyy"
              value={checkoutDate}
              onChange={handleCheckoutChange}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
          <FormControl>
            <InputLabel size="small">Time</InputLabel>
            <Select
              value={selectedCheckoutSLot}
              onChange={(e) => setselectedCheckoutSLot(e.target.value)}
              required="true"
              MenuProps={MenuProps}
              label="Time"
              size="small"
            >
              {checkoutSlots?.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack> */}
        <Stack direction="row" spacing={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("Choose room type")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="outlined-select-currency"
              select
              label={t("Choose room type")}
              value={roomType}
              onChange={handleSelectRoomTypeChange}
              // helperText="Choose Room Type"
              sx={{ width: "100%" }}
            >
              {avaiableRoom &&
                avaiableRoom.map((e) => (
                  <MenuItem value={e.roomTypeId}> {e.roomTypeName} </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("Choose room ID")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="outlined-select-currency"
              select
              label={t("Choose room ID")}
              value={selectedRoom}
              onChange={handleSelectRoomIdChange}
              // helperText="Choose Room Type"
              sx={{ width: "100%" }}
            >
              {allRooms &&
                allRooms.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {" "}
              {t("Adults")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="outlined-select-currency"
              select
              label={t("Adults")}
              value={adult}
              onChange={(e) => setadult(e.target.value)}
              // helperText="Choose Room Type"
              sx={{ width: "100%" }}
            >
              {maxAdult < maxOccupancy - children
                ? Array.from(
                    { length: maxAdult },
                    (elm, index) => index + 1
                  ).map((item) => <MenuItem value={item}> {item} </MenuItem>)
                : Array.from(
                    { length: maxOccupancy - children },
                    (elm, index) => index + 1
                  ).map((item) => <MenuItem value={item}> {item} </MenuItem>)}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {" "}
              {t("Children")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="outlined-select-currency"
              select
              label={t("Children")}
              value={children}
              onChange={(e) => setchildren(e.target.value)}
              // helperText="Choose Room Type"
              sx={{ width: "100%" }}
            >
              {maxChidren < maxOccupancy - adult
                ? Array.from(
                    { length: maxChidren + 1 },
                    (elm, index) => index
                  ).map((item) => <MenuItem value={item}> {item} </MenuItem>)
                : Array.from(
                    { length: maxOccupancy + 1 - adult },
                    (elm, index) => index
                  ).map((item) => <MenuItem value={item}> {item} </MenuItem>)}
            </Select>
          </FormControl>
        </Stack>
        {/* <Stack direction="row" spacing={4}>
          <TextField
            label="Adults"
            className="input-lg w-100 "
            placeholder="Enter adults here"
            onChange={(e) => setadult(e.target.value)}
            helperText={`Please enter minimum 1 and maximum ${maxAdult} adults`}
            value={adult}
            name="Adults"
            id="Adults"
          />
          <TextField
            label="Children"
            className="input-lg w-100 "
            placeholder="Enter children here"
            onChange={(e) => setchildren(e.target.value)}
            helperText={`Enter value less than ${maxChidren}`}
            value={children}
            name="Children"
            id="Children"
          />
        </Stack> */}
        <Stack direction="row" spacing={4}>
          <TextField
            id="outlined-multiline-flexible"
            label={t("Price per night")}
            multiline
            // rows={1}
            value={pricePerNight}
            sx={{ width: "100%" }}
            // onChange={(e) =>
            //   e.target.value.match("^[0-9]*.[0-9]{0,2}$")
            //     ? setPricePerNight(e.target.value)
            //     : setPricePerNight(pricePerNight)
            // }
            onChange={handlePricePerNightChange}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={
              JSON.parse(
                data?.accessControl?.user_feature_map?.EditBookingPrice
              ).read === "YES"
                ? false
                : true
            }
            // InputProps={{ inputProps: { min: 0 } }}
          />

          <TextField
            id="outlined-multiline-flexible"
            label={t("Total before tax")}
            multiline
            // rows={1}
            value={totalBeforeTax}
            sx={{ width: "100%" }}
            onChange={handleTotalBeforeTaxChange}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={
              JSON.parse(
                data?.accessControl?.user_feature_map?.EditBookingPrice
              ).read === "YES"
                ? false
                : true
            }
          />
        </Stack>
        <Stack direction="row" spacing={4}>
          <TextField
            id="outlined-multiline-flexible"
            label={t("Total tax")}
            multiline
            // rows={1}
            value={totalTax}
            sx={{ width: "100%" }}
            onChange={handleTotalTaxChange}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="outlined-multiline-flexible"
            label={t("Total with tax")}
            multiline
            // rows={1}
            value={totalWithTax}
            sx={{ width: "100%" }}
            onChange={handleTotalWithTaxChange}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={
              JSON.parse(
                data?.accessControl?.user_feature_map?.EditBookingPrice
              ).read === "YES"
                ? false
                : true
            }
          />
        </Stack>
      </Stack>
      {showRooms ? (
        <Stack alignItems="flex-end" mt={2}>
          {upgradeLoader === false ? (
            <Button
              variant="custom-button"
              onClick={() => handleEditReservation()}
              disabled={priceLoader || !name}
            >
              {t("Save")}
            </Button>
          ) : (
            <Button variant="custom-button">
              <CircularProgress size="15px" />
            </Button>
          )}
        </Stack>
      ) : (
        <Stack alignItems="flex-end" mt={2}>
          <Button
            variant="custom-button"
            onClick={() => handleEditReservation()}
            disabled={true}
          >
            {t("No rooms avaiable")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(AddRoomToBooking);
