import React, { useEffect } from "react";
import i18n from "../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

function InformationCard({
  colorCode,
  value,
  heading,
  transform,
  marginLeft,
  cardWidth,
  cardHeight,
  fontSize,
  boxShadow,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const getHeading = (heading) => {
    if (heading === "New bookings") {
      return `${t("New bookings")}`;
    } else if (heading === "In-house") {
      return `${t("In-house")}`;
    } else if (heading === "Arrivals") {
      return `${t("Arrivals")}`;
    } else if (heading === "Departures") {
      return `${t("Departures")}`;
    } else if (heading === "Cancellations") {
      return `${t("Cancellations")}`;
    } else if (heading === "On hold") {
      return `${t("On hold")}`;
    } else if (heading === "No shows") {
      return `${t("No shows")}`;
    } else {
      return heading;
    }
  };
  return (
    <div
      className="informationCardWrapper"
      style={{
        boxShadow: boxShadow,
        transform: transform,
        marginLeft: marginLeft,
      }}
    >
      <div className="colorCode" style={{ backgroundColor: colorCode }} />
      <div className="figure">{value}</div>
      <div className="heading">{getHeading(heading)}</div>
    </div>
  );
}

export default InformationCard;
