import {
  Stack,
  Button,
  Box,
  ClickAwayListener,
  Divider,
  Typography,
  TextField,
} from "@mui/material";
import { toast } from "material-react-toastify";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useParams } from "react-router";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { djangoHandleDataRequests } from "../../../../api";
import ShopWrapper from "../ShopWrapper";
import moment from "moment";
import { DateRange } from "react-date-range";
import { SecondaryButton } from "../../../../Utils/ButtonHelper";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import ReportsLoader from "../../../../Utils/ReportsLoader";
import { useTranslation } from "react-i18next";

const styles = {
  position: "absolute",
  top: 28,
  right: 0,
  left: 10,
  zIndex: 100,
  p: 1,
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const DaywiseItemSummaryReport = ({ data }) => {
  const { t } = useTranslation();

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  // getting the hotel currency
  const hotelCurrency = data?.accessControl?.hotel?.hotel_currency;

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  const { shopId } = useParams();

  // Getting the start date as well as the end date to load the data
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Get All report data
  const [reportsData, setreportsData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  //Function to get report data
  const fetchData = () => {
    setreportsData(null);
    shopId &&
      djangoHandleDataRequests(
        `pos/daywise-item-sales-summary-report/?shop_id=${shopId}&start_date=${moment(
          dateRange[0].startDate
        ).format("YYYY-MM-DD 00:00:00")}&end_date=${moment(
          dateRange[0].endDate
        ).format("YYYY-MM-DD 23:59:59")}`
      )
        .then((res) => {
          setreportsData(res);
        })
        .catch((err) =>
          toast.error(`${t("Error in getting  data! Please try again later.")}`)
        );
  };

  //open and close date picker
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  const headerStyle = {
    position: "relative",
    width: "150px",
  };

  // Report columns
  const columns = [
    {
      dataField: "shop_name",
      text: `${t("Shop name")}`,
      headerStyle: headerStyle,
    },
    {
      dataField: "date",
      text: `${t("Date")}`,
      headerStyle: headerStyle,
    },
    {
      dataField: "product_name",
      text: `${t("Item name")}`,
      headerStyle: headerStyle,
    },
    {
      dataField: "avg_product_price",
      text: `${t(`Avg. item price (${hotelCurrency})`)}`,
      headerStyle: headerStyle,
    },
    {
      dataField: "total_quantity",
      text: `${t("Total qty")}`,
      headerStyle: headerStyle,
      // sort: true,
    },
    {
      dataField: "total_sub_total",
      text: `${t("Total subtotal")}`,
      headerStyle: headerStyle,
    },
    {
      dataField: "total_discount",
      text: `${t(`Total discount (${hotelCurrency})`)}`,
      headerStyle: headerStyle,
    },
    {
      dataField: "total_tax",
      text: `${t(`Total tax (${hotelCurrency})`)}`,
      headerStyle: headerStyle,
    },
    {
      dataField: "total_amount",
      text: `${t(`Total (${hotelCurrency})`)}`,
      headerStyle: headerStyle,
    },
  ];

  return (
    <ShopWrapper
      backBtn={true}
      contentArea={
        <>
          <h1 className="mt-3 mb-3">
            {t("Daywise item sales summary report")}
          </h1>
          <Divider className="mt-2 mb-2" />
          {reportsData ? (
            <div className="App">
              {/* {reportsColumns.length > 0 && ( */}
              <ToolkitProvider
                keyField="id"
                data={reportsData}
                columns={columns}
                exportCSV
                search
                noDataIndication="No data found"
              >
                {(props) => (
                  <>
                    <Stack
                      direction="row"
                      className="position-relative w-100 justify-content-between mt-4"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <Stack
                        className="position-relative w-50"
                        direction="row"
                        spacing={2}
                      >
                        <SearchBar
                          {...props.searchProps}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        />
                        <Stack spacing={4} className="position-relative">
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <Box sx={{ position: "relative" }}>
                              <button
                                onClick={handleClick}
                                className="border border-1 rounded p-2"
                                style={{
                                  backgroundColor: "transparent",
                                  height: "37.5px",
                                }}
                              >
                                {moment(dateRange[0].startDate).format(
                                  "DD MMM YYYY"
                                )}{" "}
                                {" to "}
                                {moment(dateRange[0].endDate).format(
                                  "DD MMM YYYY"
                                )}
                              </button>
                              {open ? (
                                <Box sx={styles}>
                                  <Stack spacing={3}>
                                    <DateRange
                                      editableDateInputs={true}
                                      onChange={(item) =>
                                        setDateRange([item.selection])
                                      }
                                      moveRangeOnFirstSelection={false}
                                      ranges={dateRange}
                                    />
                                    <Stack
                                      className="position-relative w-100 d-flex justify-content-between"
                                      direction="row"
                                    >
                                      <SecondaryButton
                                        text={t("Cancel")}
                                        onClick={() => setOpen(false)}
                                      />
                                      <Button
                                        variant="custom-button"
                                        onClick={() => {
                                          fetchData();
                                          handleClickAway();
                                        }}
                                      >
                                        {t("Apply")}
                                      </Button>
                                    </Stack>
                                  </Stack>
                                </Box>
                              ) : null}
                            </Box>
                          </ClickAwayListener>
                        </Stack>
                      </Stack>
                      <ExportCSVButton
                        {...props.csvProps}
                        style={{
                          display: "block",
                          background: "black",
                          color: "white",
                          marginTop: -1.5,
                          marginBottom: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        {t("Export CSV")}
                      </ExportCSVButton>
                    </Stack>
                    <Stack className="mt-4">
                      {reportsData.length > 0 ? (
                        <BootstrapTable
                          pagination={paginationFactory({ sizePerPage: 10 })}
                          {...props.baseProps}
                        />
                      ) : (
                        <Typography
                          className="position-relative mt-8 text-align-center"
                          style={{
                            fontSize: "15px",
                            marginTop: "15px",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          {t("No data found!")}
                        </Typography>
                      )}
                    </Stack>
                  </>
                )}
              </ToolkitProvider>
              {/* )} */}
            </div>
          ) : (
            <ReportsLoader />
          )}
        </>
      }
    />
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(DaywiseItemSummaryReport);
