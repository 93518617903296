import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import GroupReportsWrapper from "./GroupReportsWrapper.js";
import {
  filterParams,
  cellRenderer,
  generateOriginalValue,
  handleNumDisplay,
} from "../Reports/utils/ReportUtils";
import moment from "moment";
import i18n from "../../modules/Auth/pages/i18n.js";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

function GroupChannelManagerReport(props) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);
  // getting the hotel id from the params
  //   const url = new URL(window.location.href);
  //   const hotelId = url.searchParams.get("hotelId");
  //   const hotelCurrency = data.accessControl.hotel.hotel_currency;

  // default hotel currency
  const hotelCurrency = props.data.hotelCurrency;
  // column definitions

  // defining the column headers, etc.
  const columns = [
    {
      field: "hotelId",
      headerName: `${t("Hotel Id")}`,
      hide: false,
      dataType: "varchar",
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: "hotelName",
      headerName: `${t("Hotel Name")}`,
      hide: false,
      dataType: "varchar",
    },
    {
      field: "bookingId",
      headerName: `${t("Booking ID")}`,
      // cellRendererFramework: (params) => cellRenderer(params),
      dataType: "varchar",
    },
    {
      field: "otaBookingId",
      headerName: `${t("OTA Booking ID")}`,
      dataType: "varchar",
    },
    {
      field: "customerName",
      headerName: `${t("Customer Name")}`,
      dataType: "varchar",
    },
    {
      field: "source",
      headerName: `${t("Booking Source")}`,
      dataType: "varchar",
    },
    {
      field: "bookingMadeOn",
      headerName: `${t("Booking Made On")}`,
      // filter: "agDateColumnFilter",
      filterParams: filterParams,
      dataType: "date",
    },
    // {
    //   field: "checkIn",
    //   headerName: "Checkin",
    //   filter: "agDateColumnFilter",
    //   filterParams: filterParams,
    //   dataType: "date",
    // },
    // {
    //   field: "checkOut",
    //   headerName: "Checkout",
    //   filter: "agDateColumnFilter",
    //   filterParams: filterParams,
    //   dataType: "date",
    // },
    {
      field: "displayCheckIn",
      headerName: `${t("Checkin")}`,
      // filter: "agDateColumnFilter",
      filterParams: filterParams,
      dataType: "date",
    },
    {
      field: "displayCheckOut",
      headerName: `${t("Checkout")}`,
      // filter: "agDateColumnFilter",
      filterParams: filterParams,
      dataType: "date",
    },
    {
      field: "grossAmount",
      headerName: `${t(`Gross Amount (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "grossAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "commission",
      headerName: `${t(`Commission (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "commission"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "tax",
      headerName: `${t(`Tax Amount (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "tax"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },
    {
      field: "netAmount",
      headerName: `${t(`Net Amount (${hotelCurrency})`)}`,
      aggFunc: "sum",
      dataType: "float",
      valueGetter: (params) => generateOriginalValue(params, "netAmount"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
      filter: "agNumberColumnFilter",
    },

    {
      field: "cardDetails",
      headerName: `${t("Card Details")}`,
      hide: true,
      dataType: "varchar",
    },
    {
      field: "specialRequest",
      headerName: `${t("Special Requests")}`,
      hide: true,
      dataType: "varchar",
    },
    {
      field: "additionalDetails",
      headerName: `${t("Additional Details")}`,
      hide: true,
      dataType: "varchar",
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      hide: true,
      dataType: "varchar",
    },
    {
      field: "tcs",
      headerName: `${t("TCS")}`,
      dataType: "float",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => generateOriginalValue(params, "tcs"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
    },
    {
      field: "tds",
      headerName: `${t("TDS")}`,
      dataType: "float",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
      valueGetter: (params) => generateOriginalValue(params, "tds"),
      valueFormatter: (params) => handleNumDisplay(params, "float"),
      pinnedRowValueFormatter: (params) => handleNumDisplay(params, "float"),
    },
  ];

  // Setting the pinned row
  const [pinnedRow, setPinnedRow] = useState(null);
  function createPinnedData(params, rowsData) {
    // let result = {
    //   bookingid_display: "Total",
    //   tax: 0,
    //   commission: 0,
    //   net_amount: 0,
    //   gross_amount: 0,
    //   tds: 0,
    // };

    let result = {};

    for (let i = 0; i < columns.length; i++) {
      if (["integer", "float"].includes(columns[i]["dataType"])) {
        result[columns[i]["field"]] = 0;
      }
    }

    // function updateVals(i) {
    //   result.tax += i.tax;
    //   result.commission += i.commission;
    //   result.net_amount += i.net_amount;
    //   result.gross_amount += i.gross_amount;
    //   result.tds = i.tds;
    // }

    function updateVals(i) {
      Object.keys(result).forEach((fields) => {
        result[fields] += i[`original_${fields}`];
      });
    }

    params.api.getSelectedRows().length === 0
      ? params.api.forEachNodeAfterFilter((row) => updateVals(row.data))
      : params.api.getSelectedRows().map((row) => updateVals(row));

    result["bookingId"] = "Total";

    setPinnedRow([result]);
    params.api.setPinnedBottomRowData([result]);
  }

  return (
    <GroupReportsWrapper
      reportName={t("Group Channel Manager Report")}
      columns={columns}
      reportActualType={"channelManager"}
      createPinnedData={createPinnedData}
      pinnedRow={pinnedRow}
      grpEmail={props.grpEmail}
      resetToDefault={props.resetToDefault}
    />
  );
}

function mapStateToProps(state) {
  return { data: state.auth.hotels[0] };
}
export default connect(mapStateToProps)(GroupChannelManagerReport);
