import { Page, StyleSheet } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import TaxInvoicePage from "./TaxInvoicePage";
import moment from "moment";

function Invoice({
  advInvoice,
  invoice,
  symbol,
  hotelData,
  customDetails,
  userId,
  hotelLogo,
  summaryData,
  customerIdentification,
  ctaDetails,
  billToPref,
  custCompanyDetails,
  ifShowNewCancellationPolicy,
  igst,
  dbigst,
  utgst,
  customerState,
  propertyState,
  selectedLanguage,
}) {
  const bookingFees = Number(invoice?.total_fees);
  const items = advInvoice?.items;
  let rowItems = [];
  if (bookingFees > 0) {
    let bookingItem = {
      service_id: "-2",
      line_item_type: "Booking Fee",
      service_amount: Number(invoice?.total_fees),
      service_tax: "-",
      service_total: Number(invoice?.total_fees),
      service_date: moment(invoice?.checkin, "YYYY-MM-DDTHH:mm").format(
        "MMM DD YYYY"
      ),
      pos_order_id: "",
      service_desc: "Booking fees",
      service_amount_type: "DEBIT",
    };
    rowItems = [...items, bookingItem];
  } else {
    rowItems = [...items];
  }
  const bookingItem = {
    service_id: "grandTotal",
    service_total_credit: Number(summaryData?.payment_made),
    service_total_debit: Number(summaryData?.invoice_total_amount),
    balance_due: Number(summaryData?.balance_due),
  };
  rowItems.push(bookingItem);

  const ItemsArr = [];
  rowItems.map((elm, idx) => {
    if (idx % 5 === 0) {
      ItemsArr.push(rowItems.slice(idx, idx + 5));
    }
  });
  // console.log(rowItems);
  return (
    <>
      {ItemsArr.map((item, idx) => (
        <Page size="A4" style={styles.page}>
          <TaxInvoicePage
            advInvoice={advInvoice}
            invoice={invoice}
            symbol={symbol}
            hotelData={hotelData}
            customDetails={customDetails}
            userId={userId}
            hotelLogo={hotelLogo}
            summaryData={summaryData}
            customerIdentification={customerIdentification}
            ctaDetails={ctaDetails}
            billToPref={billToPref}
            custCompanyDetails={custCompanyDetails}
            ifShowNewCancellationPolicy={ifShowNewCancellationPolicy}
            rowItems={item}
            igst={igst}
            dbigst={dbigst}
            utgst={utgst}
            customerState={customerState}
            propertyState={propertyState}
            correntPage={idx + 1}
            totalPage={ItemsArr.length}
            selectedLanguage={selectedLanguage}
          />
        </Page>
      ))}
    </>
  );
}

export default Invoice;

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});
